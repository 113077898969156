/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import type { Node } from 'react';
import React from 'react';

const popularIPIDLink = "//boi.com/insurancemotoripidpop";
const prestigeIPIDLink = "//boi.com/insurancemotoripidprs";

type IPIDLinkDownloadProps = {
  link: string;
  children: Node;
};

const IPIDLinkDownload = ({ link, children }: IPIDLinkDownloadProps) => (
  <>
    Insurance Product Information Document (IPID){' '}
    <a rel="noopener noreferrer" target="_blank" href={link}>
      {children}
    </a>
  </>
);

const PrestigeIPID = () => <IPIDLinkDownload link={prestigeIPIDLink} >Prestige</IPIDLinkDownload>;
const PopularIPID = () => <IPIDLinkDownload link={popularIPIDLink} >Popular</IPIDLinkDownload>;

export const thirdPartyFireAndTheftPopular = [
  { label: () => 'Monthly payment plan with no fees or charges', tick: true },
  { label: () => 'Breakdown/Rescue in Ireland', tick: true },
  { label: () => 'Driving of other car – Third party only (subject to eligibility criteria)', tick: true },

  { label: () => 'Unlimited cover for permanently fitted audio equipment', tick: true },
  { label: () => 'EU Cover – cover in the EU for up to 45 days', tick: true },
  { label: () => 'Fire brigade Charges up to €1,500', tick: true },
  { label: () => 'A quick, simple and hassle-free claims process', tick: true },
  { label: PopularIPID, tick: true }
]

export const thirdPartyFireAndTheftPrestige = [
  { label: () => 'Monthly payment plan with no fees or charges', tick: true },
  { label: () => 'Breakdown/Rescue in Ireland', tick: true },
  { label: () => 'Driving of Other Cars –  Third Party cover', tick: true },
  { label: () => 'No Claims Discount (NCD) –Step-back cover only i.e. one claim will reduce your NCD by only 3 years', tick: true },

  { label: () => 'Unlimited cover for permanently fitted audio equipment', tick: true },
  { label: () => 'EU Cover – cover in the EU for up to 45 days', tick: true },
  { label: () => 'Fire brigade charges up to €1,500', tick: true },
  { label: () => 'Towing and Storage', tick: true },
  { label: () => 'A quick, simple and hassle-free claims process', tick: true },
  { label: PrestigeIPID, tick: true },
];

export const comprehensivePopular = [
  { label: () => 'Monthly payment plan with no fees or charges', tick: true },
  { label: () => 'Breakdown/Rescue in Ireland', tick: true },
  { label: () => 'Driving of other car – Third party only (subject to eligibility criteria)', tick: true },

  { label: () => 'Unlimited cover for permanently fitted audio equipment', tick: true },
  { label: () => 'EU Cover – cover in the EU for up to 45 days', tick: true },
  { label: () => 'Fire brigade charges up to €1,500', tick: true },
  { label: () => 'Windscreen Breakage Cover', tick: true },
  { label: () => 'A quick, simple and hassle-free claims process', tick: true },
  { label: PopularIPID, tick: true },
];

export const comprehensivePrestige = [
  { label: () => 'Monthly payment plan with no fees or charges', tick: true },
  { label: () => 'Breakdown/Rescue in Ireland', tick: true },
  { label: () => 'Driving of Other Cars – Third Party cover', tick: true },
  { label: () => 'No Claims Discount (NCD) –Step-back cover only i.e. one claim will reduce your NCD by only 3 years', tick: true },
  { label: () => 'New Car Replacement - Prestige Only', tick: true },

  { label: () => 'Unlimited cover for permanently fitted audio equipment', tick: true },
  { label: () => 'EU Cover – cover in the EU for up to 45 days', tick: true },
  { label: () => 'Fire brigade charges up to €1,500', tick: true },
  { label: () => 'Windscreen Breakage Cover', tick: true },
  { label: () => 'A quick, simple and hassle-free claims process', tick: true },
  { label: () => 'Additional Standard for Prestige Only', tick: true, header: true },
  { label: () => 'Personal Belongings – Up to €500', tick: true },
  { label: () => 'Replacement Locks – Up to €1,000', tick: true },
  { label: () => 'Car Hire – Up to €200 for a replacement car', tick: true },
  { label: () => 'Incorrect Fuel – Up to €750', tick: true },
  { label: () => 'Personal Protection Injury Benefit– €6,500', tick: true },
  { label: PrestigeIPID, tick: true },
];
