/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../../../../styles/commonFormStyles.scss';
import './AdditionalDrivers.scss';

import { Button, Dropdown, WideDivider, XIcon } from '@boi/core/lib';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { dividerColor } from '../../../../../constants/colors';
import { relationshipToProposerValues } from '../../../../../constants/dropdowns';
import { CAR_INSURANCE_TYPE } from '../../../../../constants/insuranceTypeConstants';
import { checkRepeatingFieldError, getRepeatingFieldErrorMessage, mapDropdownOptions } from '../../../../../helpers';
import { getOccupationOptions } from '../../../../../mocks/MainOccupationValues';
import { BaseDrivingDetails } from '../../../AboutYouForm/AboutYouFormSections/DrivingDetails/DrivingDetails';
import { BaseEmploymentDetails } from '../../../AboutYouForm/AboutYouFormSections/EmploymentDetails/EmploymentDetails';
import { BasePersonalDetails } from '../../../AboutYouForm/AboutYouFormSections/PersonalDetails/PersonalDetails';
import { PenaltyPoints } from '../index';
import { emptyAdditionalDriver, untouchedAdditionalDriver } from './AdditionalDriversInitialValues';

const AdditionalDrivers = (props) => {

  const className = 'c-AdditionalDrivers';
  const {
    setFieldValue,
    values,
    errors,
    setFieldTouched,
    touched,
    handleBlur,
    handleChange,
    baseFieldName = 'additionalDrivers',
    fieldFilledBefore,
    onBlur
  } = props;
  const mainOccupationOptions = getOccupationOptions();
  
  useEffect(() => {
    let initialValues = values[baseFieldName] && values[baseFieldName].length > 0 ? values[baseFieldName] : [{ ...emptyAdditionalDriver }];
    setFieldValue(baseFieldName, initialValues);
    setFieldTouched(baseFieldName, [{ ...untouchedAdditionalDriver }]);
  }, [setFieldValue, setFieldTouched]);

  const addAdditionalDriver = (arrayHelpers) => {
    arrayHelpers.push({ ...emptyAdditionalDriver });
    setFieldTouched(baseFieldName, [ ...touched[baseFieldName], { ...untouchedAdditionalDriver }]);
  };

  const removeAdditionalDriver = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  };

  const getEntryValue = (allEntries = [], index = 0, fieldName = '') => {
    return allEntries.length > index && fieldName !== '' ? allEntries[index][fieldName] : '';
  };

  const getAdditionalDriverFormAttribute = (attribute, index) => {
    return attribute && attribute[baseFieldName] && attribute[baseFieldName][index] ? attribute[baseFieldName][index] : {};
  };

  const getAdditionalDriverProps = (index) => {
    return {
      ...props,
      values: getAdditionalDriverFormAttribute(values, index),
      touched: getAdditionalDriverFormAttribute(touched, index),
      errors: getAdditionalDriverFormAttribute(errors, index)
    };
  };

  const getRelationshipToProposerField = (index) => {
    const relationshipFieldName = `${baseFieldName}[${index}].relationshipToProposer`;
    return (
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={relationshipFieldName} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Relationship to you
        </label>
        <span className={`${className}__input`}>
          <Dropdown
            key={`relationshipToProposer_${index}`}
            name={relationshipFieldName}
            placeholder="Select here"
            error={checkRepeatingFieldError(errors[baseFieldName], touched[baseFieldName], 'relationshipToProposer', index)}
            errorMessage={getRepeatingFieldErrorMessage(errors[baseFieldName], touched[baseFieldName], 'relationshipToProposer', index)}
            value={getEntryValue(values[baseFieldName], index, 'relationshipToProposer')}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Select here</option>
            {mapDropdownOptions(relationshipToProposerValues, 'relationship_to_proposer')}
          </Dropdown>
        </span>
      </div>
    );
  };

  const getAdditionalDriverComponent = (arrayHelpers, index, additionalDriversCount) => {
    return (
      <div key={`component_${index}`}>
        <div className={`${commonFormStylesIdentifier}__itemCountContainer`}>
          <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>
            Additional Driver {index + 1}
          </label>
          {additionalDriversCount > 1 &&
          <div
            className={`${className}__closeIcon`}
            onClick={() => removeAdditionalDriver(arrayHelpers, index)}
          >
            <XIcon />
            
          </div>
          }
        </div>
        <BasePersonalDetails
          formik={getAdditionalDriverProps(index)}
          fieldNamePrefix={`${baseFieldName}[${index}].`}
        />
        <BaseEmploymentDetails
          formik={getAdditionalDriverProps(index)}
          occupationOptions={mainOccupationOptions}
          insuranceType={CAR_INSURANCE_TYPE}
          fieldNamePrefix={`${baseFieldName}[${index}].`}
          isSubFormComponent={true}
        />
        <BaseDrivingDetails
          formik={getAdditionalDriverProps(index)}
          fieldNamePrefix={`${baseFieldName}[${index}].`}
          hideFieldsList={['passedTestInIrelandOrUk']}
          isSubFormComponent={true}
        />
        {getRelationshipToProposerField(index)}
        <PenaltyPoints
          {...getAdditionalDriverProps(index)}
          onBlur={onBlur}
          fieldNamePrefix={`${baseFieldName}[${index}].`}
          driverTypePrefix={`additionalDriver`}
          repeatingFieldsCustomClass={`${className}__penaltyPointsRepeatingContainer`}
          fieldFilledBefore={fieldFilledBefore}
          values={values.additionalDrivers[index]}
          touched={touched.additionalDrivers[index]}
        />
        {index < additionalDriversCount - 1 &&
        <div className={`${className}__dividerContainer`}>
          <WideDivider height={1} color={dividerColor} />
        </div>
        }
      </div>);
  };

  return (
    <FieldArray
      name="additionalDrivers"
      render={(arrayHelpers) => (
        <div className={className}>
          <div>
            {values[baseFieldName] && values[baseFieldName].length > 0 ? (
              values[baseFieldName].map((additionalDriver, index) => getAdditionalDriverComponent(arrayHelpers, index, values[baseFieldName].length))
            ) : null}
          </div>
          <div className={`${className}__addComponentButtonContainer`}>
            <Button
              fluid={true}
              onClick={() => {
                addAdditionalDriver(arrayHelpers);
              }}
            >
              Add additional drivers
            </Button>
          </div>
        </div>
      )}
    />
  );
};

AdditionalDrivers.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  setFieldTouched: PropTypes.func,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  baseFieldName: PropTypes.string,
  fieldFilledBefore: PropTypes.bool,
  onBlur: PropTypes.func
}

export default AdditionalDrivers;
