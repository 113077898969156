const offences = [
  {
    displayName: 'Breach of cycle track driving rules',
    dropdownValue: 'BREACH_OF_CYCLE_TRACK_DRIVING_RULES',
  },
  {
    displayName: 'Careless driving',
    dropdownValue: 'CARELESS_DRIVING',
  },
  {
    displayName: 'Crossing continuous white line',
    dropdownValue: 'CROSSING_CONTINUOUS_WHITE_LINE',
  },
  {
    displayName: 'Dangerous overtaking',
    dropdownValue: 'DANGEROUS_OVERTAKING',
  },
  {
    displayName: 'Driving whilst using mobile phone',
    dropdownValue: 'DRIVING_WHILST_USING_MOBILE_PHONE',
  },
  {
    displayName: 'Driving a vehicle when unfit',
    dropdownValue: 'DRIVING_A_VEHICLE_WHEN_UNFIT',
  },
  {
    displayName: 'Driving against motorway flow',
    dropdownValue: 'DRIVING_AGAINST_MOTORWAY_FLOW',
  },
  {
    displayName: 'Driving a dangerously defective vehicle',
    dropdownValue: 'DRIVING_DANGEROUSLY_DEFECTIVE_VEHICLE',
  },
  {
    displayName: 'Driving on a footpath',
    dropdownValue: 'DRIVING_ON_A_FOOTPATH',
  },
  {
    displayName: 'Driving on a median strip',
    dropdownValue: 'DRIVING_ON_A_MEDIAN_STRIP',
  },
  {
    displayName: 'Driving on banned areas of a motorway',
    dropdownValue: 'DRIVING_ON_BANNED_AREAS_OF_MOTORWAY',
  },
  {
    displayName: 'Driving a vehicle without fixing defects',
    dropdownValue: 'DRIVING_VEHICLE_WITHOUT_FIXING_DEFECTS',
  },
  {
    displayName: 'Driving a vehicle restricted to 80 km/ph on the outside lane of a motorway',
    dropdownValue: 'DRIVING_VEHICLE_RESTRICTED_TO_80_KMPH_ON_OUTSIDE_LANE_OF_MOTORWAY',
  },
  {
    displayName: 'Driving without reasonable consideration',
    dropdownValue: 'DRIVING_WITHOUT_REASONABLE_CONSIDERATION',
  },
  {
    displayName: 'Entering a marked hatched area',
    dropdownValue: 'ENTERING_MARKED_HATCHED_AREA',
  },
  {
    displayName: 'Exceeding speed limit',
    dropdownValue: 'EXCEEDING_SPEED_LIMIT',
  },
  {
    displayName: 'Failing to display a valid NCT',
    dropdownValue: 'FAILING_TO_DISPLAY_A_VALID_NCT',
  },
  {
    displayName: 'Failing to stop for a school warden',
    dropdownValue: 'FAILING_TO_STOP_AT_SCHOOL_WARDEN',
  },
  {
    displayName: 'Failure by driver to comply with front seat belt requirements',
    dropdownValue: 'FAILURE_BY_DRIVER_TO_COMPLY_WITH_FRONT_SEAT_BELT_REQUIREMENTS',
  },
  {
    displayName: 'Failure by driver to comply with rear seat belt requirements for passengers',
    dropdownValue: 'FAILURE_BY_DRIVER_TO_COMPLY_WITH_REAR_SEAT_BELT_REQUIREMENTS_FOR_PASSENGERS',
  },
  {
    displayName: 'Failure to comply with Garda signals',
    dropdownValue: 'FAILURE_TO_COMPLY_WITH_GARDA_SIGNALS',
  },
  {
    displayName: 'Failure to comply with keep left keep right signs',
    dropdownValue: 'FAILURE_TO_COMPLY_WITH_KEEP_LEFT_KEEP_RIGHT_SIGNS',
  },
  {
    displayName: 'Failure to comply with mandatory traffic signs at junctions',
    dropdownValue: 'FAILURE_TO_COMPLY_WITH_MANDATORY_TRAFFIC_SIGNS_AT_JUNCTIONS',
  },
  {
    displayName: 'Failure to comply with prohibitory traffic signs',
    dropdownValue: 'FAILURE_TO_COMPLY_WITH_PROHIBITORY_TRAFFIC_SIGNS',
  },
  {
    displayName: 'Failure to comply with traffic lane markings',
    dropdownValue: 'FAILURE_TO_COMPLY_WITH_TRAFFIC_LANE_MARKINGS',
  },
  {
    displayName: 'Failure to drive on the left hand side of the road',
    dropdownValue: 'FAILURE_TO_DRIVE_ON_THE_LEFT_HAND_SIDE_OF_THE_ROAD',
  },
  {
    displayName: 'Failure to leave appropriate distance between you and the vehicle in front',
    dropdownValue: 'FAILURE_TO_LEAVE_APPROPRIATE_DISTANCE_BETWEEN_YOU_AND_THE_VEHICLE_IN_FRONT',
  },
  {
    displayName: 'Failure to obey junction requirements',
    dropdownValue: 'FAILURE_TO_OBEY_JUNCTION_REQUIREMENTS',
  },
  {
    displayName: 'Failure to obey reversing requirements',
    dropdownValue: 'FAILURE_TO_OBEY_REVERSING_REQUIREMENTS'
  },
  {
    displayName: 'Failure to obey traffic lights',
    dropdownValue: 'FAILURE_TO_OBEY_TRAFFIC_LIGHTS',
  },
  {
    displayName: 'Failure to obey traffic rules at railway level crossing',
    dropdownValue: 'FAILURE_TO_OBEY_TRAFFIC_RULES_AT_RAILWAY_LEVEL_CROSSING',
  },
  {
    displayName: 'Failure to stop before stop sign or stop line',
    dropdownValue: 'FAILURE_TO_STOP_BEFORE_STOP_SIGN_STOP_LINE',
  },
  {
    displayName: 'Failure to stop when so required by a member of the Garda Siochana',
    dropdownValue: 'FAILURE_TO_STOP_WHEN_SO_REQUIRED_BY_A_MEMEBR_OF_THE_GARDA_SIOCHANA',
  },
  {
    displayName: 'Failure to turn left when entering a roundabout',
    dropdownValue: 'FAILURE_TO_TURN_LEFT_WHEN_ENTERING_A_ROUNDABOUT',
  },
  {
    displayName: 'Failure to yield',
    dropdownValue: 'FAILURE_TO_YIELD',
  },
  {
    displayName: 'Failure to yield right of way at a yield sign line',
    dropdownValue: 'FAILURE_TO_YIELD_RIGHT_OF_WAY_AT_A_YIELD_SIGN_LINE',
  },
  {
    displayName: 'Illegal entry onto a one way street',
    dropdownValue: 'ILLEGAL_ENTRY_ONTO_A_ONE_WAY_STREET',
  },
  {
    displayName: 'No insurance',
    dropdownValue: 'NO_INSURANCE',
  },
  {
    displayName: 'Parking a vehicle in a dangerous position',
    dropdownValue: 'PARKING_A_VEHICLE_IN_A_DANGEROUS_POSITION'
  },
];

export default offences;
