/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './AnimatedSpinner.scss';

import React from 'react';
interface Props {
    size: string;
  }
const className = 'c-AnimatedSpinner';
const AnimatedSpinner = (props: Props) => {
    return (
      <div className={`c-AnimatedSpinnerBaseClass ${className}__${props.size}`} />
    );
  };

export default AnimatedSpinner;