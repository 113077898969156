/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './SearchAllQuotes.scss';
import '../../styles/commonPageStyles.scss';

import { BOIGroupLogo, Dropdown, InputField, Typeahead } from '@boi/core/lib';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants';
import { primaryBlue } from '../../constants/colors';
import {
  ERROR_TEXT_LATEST_QUOTE,
  ERROR_TEXT_LATEST_QUOTE_BRANCH
} from '../../constants/commonConstants';
import {
  LATEST_QUOTE_OPTION_SEARCH_USER_CONSTANT,
  LATEST_QUOTE_SEARCH_USER_CONSTANT,
  TITLES_TO_SHOW
} from '../../constants/home';
import { ABOUT_YOU } from '../../constants/insuranceTypeConstants';
import {
  LABELMAPING,
  PLACEHOLDERMAPING
} from '../../constants/retriveUserConstant';
import { isFieldError } from '../../helpers/FieldErrorHelper';
import { routes } from '../../routes';
import {
  getLatestHomeQuotes,
  getLatestQuoteForPolicyCode,
  getLatestQuotes,
  getLatestQuotesForEmail
} from '../../services/common/commonServices';
import AgGridTable from '../AgGridTable/AgGridTable';
import AnimatedSpinner from '../AnimatedSpinner/AnimatedSpinner';
import SearchAllQuotesYupSchema from './SearchAllQuotesYupSchema';

type Props = {};
type Values = {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  dropdownValue: string
};

const parsePremium = ({ params }: { params: null }) => {
  if (
    params?.value === undefined ||
    params?.value === null ||
    params?.value === 0
  ) {
    return `-`;
  } else {
    return `€${parseFloat(params?.value).toFixed(2)}`;
  }
};

// eslint-disable-next-line complexity
const SearchAllQuotesForm = (props: Props & FormikProps<Values>) => {
  const className = 'c-SearchAllQuotes';
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    setFieldValue,
    values
  } = props;

  const [responseError, setResponseError] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [animatedSpinner, setAnimatedSpinner] = useState(false);
  const [response, setResponse] = useState({});
  const [loadingQuote, setLoadingQuote] = useState(false);
  const [showBranch, setShowBranch] = useState({});
  const { branchList } = useContext(CognitoContext);
  const history = useHistory();

  const handleURLClick = useCallback(async (policyCode: string) => {
    setLoadingQuote(true);
    await getLatestQuoteForPolicyCode(policyCode);
    setLoadingQuote(false);
    history.push(`${routes.getQuotePage.url}${ABOUT_YOU}`);
  }, []);

  const formatDate = (value: string, formatString: string) => {
    const dateTime = new Date(value);
    return format(dateTime, formatString);
  };

  const columnDefs = [
    { headerName: 'First Name', field: 'first_name', floatingFilter: true },
    { headerName: 'Last Name', field: 'last_name', floatingFilter: true },
    {
      headerName: 'Date of Birth',
      field: 'date_of_birth',
      floatingFilter: true
    },
    {
      headerName: 'Email',
      field: 'email',
      minWidth: 400,
      cellRenderer: (params: any) => {
        const tooltipText = `Email: ${params.value}`;
        return `<span title='${tooltipText}'>
              <p id='emailLink${params.value}'>${params.value}</p>
            </span>`;
      },
      onCellClicked: function(params: any) {
        if (params.column.colId === 'email') {
          handleURLClick(params.data.policy_code);
        }
      },
      floatingFilter: true
    },
    {
      headerName: 'Policy Code',
      field: 'policy_code',
      floatingFilter: true
    },
    {
      headerName: 'RSA',
      field: 'rsa_quote_amount',
      floatingFilter: true,
      valueFormatter: (params: any) => {
        return parsePremium({ params: params });
      }
    },
    {
      headerName: 'FBD',
      field: 'fbd_quote_amount',
      floatingFilter: true,
      valueFormatter: (params: any) => {
        return parsePremium({ params: params });
      }
    },
    {
      headerName: 'Timestamp',
      field: 'quote_timestamp',
      floatingFilter: true
    },
    {
      headerName: 'Dwelling type',
      field: 'dwelling_type',
      floatingFilter: true
    },
    { headerName: 'Branch', field: 'branch', floatingFilter: true }
  ];

  // eslint-disable-next-line complexity
  const requestLatestHomeQuotes = async (value: any, type: string) => {
    const search_criteria = value;
    const search_type = type;
    let page_number = 0;
    const pageSize = 1000;
    let arrData = [];
    let continueLoop = true;

    while (continueLoop) {
      const responseData = await getLatestHomeQuotes(
        search_criteria,
        search_type,
        page_number,
        pageSize,
        type
      );

      if (
        responseData === undefined ||
        responseData?.length === 0 ||
        responseData?.error
      ) {
        setResponseError(ERROR_TEXT_LATEST_QUOTE_BRANCH);
        continueLoop = false;
        return null;
      }
      arrData.push(...responseData.content);
      page_number++;
      if (page_number >= responseData.total_pages) {
        continueLoop = false;
        return arrData;
      }
    }
  };

  // eslint-disable-next-line complexity
  const searchUser = async (values: any) => {
    const data = await getLatestQuotes(values);
    setAnimatedSpinner(false);
    if (
      data.length === 0 ||
      (data?.error !== undefined && data?.error !== null)
    ) {
      setResponseError(ERROR_TEXT_LATEST_QUOTE);
    } else if (data.length === 1 && !data?.error) {
      setResponseError('');
      setResponse(data);
      const quoteData = await getLatestQuotesForEmail(data[0].email);
      setFilteredData(processDate(quoteData));
    } else {
      setResponseError('');
      setResponse(data);
      setIsOpen(true);
    }
  };

  const processDate = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      date_of_birth: formatDate(item.date_of_birth, 'DD-MM-YYYY'),
      quote_timestamp: formatDate(item.quote_timestamp, 'DD-MM-YYYY HH:mm')
    }));
  };

  const searchBranch = async (value: any) => {
    const data = await requestLatestHomeQuotes(value, values.dropdownValue);
    setAnimatedSpinner(false);
    if (data !== undefined && data !== null && data.length !== undefined) {
      setFilteredData(processDate(data));
    }
  };

  // eslint-disable-next-line complexity
  const handleEmailClick = async (email: string) => {
    if (email.length === 0) {
      setIsOpen(false);
      setResponseError(ERROR_TEXT_LATEST_QUOTE);
    } else {
      setIsOpen(false);
      const details = await getLatestQuotesForEmail(email);
      setFilteredData(processDate(details));
    }
  };

  const getEmailList = () => {
    const keys = response.map((result: { email: string }) => result.email);
    return keys.map((email: string, index: number) => (
      <li key={`email-key-${index}`} onClick={() => handleEmailClick(email)}>
        {email}
      </li>
    ));
  };

  const getOptions = () => {
    const optionElements = getEmailList();

    return optionElements.length > 0 ? (
      <ul
        className={`${className}__options`}
        id="EmailOptionsList"
        data-testid="EmailOptionsList"
      >
        {optionElements}
      </ul>
    ) : (
      <></>
    );
  };

  const handleInputClick = () => {
    setAnimatedSpinner(true);
    searchUser(values);
    setResponseError('');
    setResponse({});
    setFilteredData([]);
  };

  return (
    <div className={`${className}`}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo--flex`}>
            <BOIGroupLogo color={primaryBlue} />
          </div>
          <Form>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="searchDropdown"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
                data-testid={`${commonFormStylesIdentifier}__searchDropdownLabel`}
              >
                Select A Search Option
              </label>
              <span className={`${className}__input`}>
                <Dropdown
                  data-testid={`${className}__dropdownValue`}
                  error={isFieldError('dropdownValue', touched, errors)}
                  errorMessage={errors.dropdownValue}
                  name="dropdownValue"
                  placeholder="Type here"
                  type="text"
                  value={values.dropdownValue}
                  onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setFieldValue('searchInput', '');
                    setShowBranch({});
                    setFilteredData([]);
                  }}
                  onBlur={(e: string) => {
                    handleBlur(e);
                  }}
                >
                  {LATEST_QUOTE_SEARCH_USER_CONSTANT.slice(
                    0,
                    TITLES_TO_SHOW
                  ).map((title: string, index: number) => {
                    return (
                      <option
                        value={LATEST_QUOTE_OPTION_SEARCH_USER_CONSTANT[index]}
                        key={`${index}_${title}`}
                      >
                        {LATEST_QUOTE_SEARCH_USER_CONSTANT[index]}
                      </option>
                    );
                  })}
                </Dropdown>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="searchInput"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
                data-testid={`${commonFormStylesIdentifier}__searchInputLabel`}
              >
                {LABELMAPING[`${values.dropdownValue}`]}
              </label>
              <span className={`${className}__input`}>
                {values.dropdownValue !== 'branch' ? (
                  <>
                    <InputField
                      error={isFieldError('searchInput', touched, errors)}
                      errorMessage={errors.searchInput}
                      name="searchInput"
                      onBlur={handleBlur}
                      onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                        handleChange(e);
                        setResponse({});
                        setFilteredData([]);
                        setIsOpen(false);
                      }}
                      placeholder={PLACEHOLDERMAPING[`${values.dropdownValue}`]}
                      touched={touched.searchInput}
                      type="text"
                      value={values.searchInput}
                      suffix={faSearch}
                      onKeyDown={(event: any) => {
                        if (event.key === 'Enter' && !errors?.searchInput) {
                          handleInputClick();
                        }
                      }}
                      onClickIcon={() => {
                        if (!errors?.searchInput) {
                          handleInputClick();
                        }
                      }}
                    />
                    {isOpen ? getOptions() : null}
                  </>
                ) : (
                  <Typeahead
                    onBlur={handleBlur}
                    data={branchList}
                    error={isFieldError('searchInput', touched, errors)}
                    errorMessage={errors.searchInput}
                    name="searchInput"
                    placeholder={PLACEHOLDERMAPING[`${values.dropdownValue}`]}
                    touched={touched.searchInput}
                    onChange={(selectedOpt: any) => {
                      setShowBranch(selectedOpt);
                      if (selectedOpt) {
                        setAnimatedSpinner(true);
                        searchBranch(selectedOpt?.value);
                        setResponseError('');
                        setResponse({});
                        setFilteredData([]);
                      }
                    }}
                    // $FlowFixMe
                    value={showBranch?.value || ''}
                  />
                )}
              </span>
            </div>
          </Form>
        </div>
      </div>
      {responseError && (
        <div className={`${className}__responseError`}>
          <pre>{responseError}</pre>
        </div>
      )}
      {animatedSpinner && (
        <div>
          <h1 className="search_text">
            Searching for users matching your query
          </h1>
          <AnimatedSpinner size="large" />
        </div>
      )}

      <>
        {loadingQuote && (
          <div className={`${className}`}>
            <AnimatedSpinner size="large" />
          </div>
        )}
        {!loadingQuote && filteredData.length > 0 && (
          <div>
            <AgGridTable rowData={filteredData} columnDefs={columnDefs} />
          </div>
        )}
      </>
    </div>
  );
};

const SearchAllQuotes = withFormik<Props, Values>({
  mapPropsToValues(): {} {
    return {
      dropdownValue: 'email',
      searchInput: ''
    };
  },
  handleSubmit(values: Values, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape(SearchAllQuotesYupSchema),
  displayName: 'SearchAllQuotesForm'
})(SearchAllQuotesForm);

export default SearchAllQuotes;
