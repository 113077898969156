import { Button } from '@boi/core/lib';
import { TitleWithUnderLine } from '@boi/core/lib';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { commonPageStylesIdentifier } from '../../../constants';
import { JOURNEY_YOUR_COVER } from '../../../constants/journey';
import {
  HOME_QUOTE_THANK_YOU_STORAGE,
  HOME_RESUME_QUOTE
} from '../../../constants/sessionStorage/homeStorageIdentifiers';
import {
  getItemFromSessionStorage,
  getObjectFromSessionStorage
} from '../../../helpers';
import ProgressBar from '../../ProgressBar';
import conditions from '../conditions';
import ConditionList from '../CondtionsList/ConditionList';
import { scrollToFirstError } from '../errorHelper';
import TermsAndConditionsCheckbox from '../TermsAndConditionsCheckbox';

const MainResidence = props => {
  const {
    goToThankYouPage,
    errors,
    values,
    isValid,
    submitCount,
    submitForm,
    setFieldValue,
    touched,
    className
  } = props;
  const yesSetConditions = 4;
  const noSetConditions = conditions.length;

  const [next, setNext] = useState(false);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (submitCount > 0) scrollToFirstError(conditions, values);
  }, [submitCount]);

  useEffect(() => {
    if (JSON.parse(getItemFromSessionStorage(HOME_RESUME_QUOTE))) {
      setNext(true);
      const data = getObjectFromSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE);

      data.assumptions.forEach(obj => {
        setFieldValue(obj.code, obj.response);
      });

      Object.keys(data.extraQuestionsValues).forEach(key => {
        setFieldValue(key, data.extraQuestionsValues[key]);
      });

      setFieldValue('acceptedTermsAndConditions', true);
    } else {
      setNext(false);
    }
  }, [triggerEffect]);

  return (
    <>
      <ProgressBar stage={JOURNEY_YOUR_COVER} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>
          Questions about your household and the property
        </TitleWithUnderLine>
      </div>
      <div className={`${className}__subtitle`}>
        You are under duty to answer all the questions that have been asked,
        honestly and with reasonable care and attention
      </div>
      <hr />
      <ConditionList
        conditions={conditions.slice(0, yesSetConditions)}
        setFieldValue={setFieldValue}
        endQuestionIdx={yesSetConditions}
        touched={touched}
        errors={errors}
        values={values}
        triggerEffect={setTriggerEffect}
      />
      <hr />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>
          Additional Questions about your household and the property
        </TitleWithUnderLine>
      </div>
      <hr />
      <ConditionList
        conditions={conditions.slice(yesSetConditions, noSetConditions)}
        setFieldValue={setFieldValue}
        endQuestionIdx={noSetConditions - yesSetConditions}
        touched={touched}
        errors={errors}
        values={values}
        triggerEffect={setTriggerEffect}
      />
      <hr />
      <TermsAndConditionsCheckbox
        setFieldValue={setFieldValue}
        values={values}
      />
      <hr />
      Your quote is based on a Policy Excess of €500, a Water Damage Excess of
      €850 and Subsidence Excess of €1000.
      <hr />
      <div>
        <a
          data-ga
          id={`GetQuote__getCoveredButton`}
          data-testid={`GetQuote__getCoveredButton`}
        >
          <Button
            quaternary
            fluid
            onClick={() => {
              if (!isValid) {
                submitForm();
              } else if (next) {
                history.push('/wallet/viewQuote/home');
              } else {
                goToThankYouPage(values);
              }
            }}
          >
            {next ? 'Next' : 'Get your quote'}
          </Button>
        </a>
      </div>
    </>
  );
};

const errorMessage = 'Answer is required';
const conditionInitialValues = conditions
  .map(condition => condition.code)
  .reduce((acc, element) => {
    acc[element] = null;
    return acc;
  }, {});

const validationSchema = conditions.reduce((acc, { code }) => {
  acc[code] = yup
    .boolean(errorMessage)
    .oneOf([true, false], errorMessage)
    .required(errorMessage)
    .nullable();
  return acc;
}, {});

const FormikMainResidence = withFormik({
  mapPropsToValues: () => ({
    ...conditionInitialValues,
    acceptedTermsAndConditions: null
  }),
  validationSchema: yup.object().shape({
    ...validationSchema,
    acceptedTermsAndConditions: yup
      .boolean()
      .oneOf([true], errorMessage)
      .required(errorMessage)
      .nullable()
  }),
  displayName: 'MainResidence'
})(MainResidence);

MainResidence.propTypes = {
  goToThankYouPage: PropTypes.func,
  setFieldValue: PropTypes.func,
  submitForm: PropTypes.func,
  submitCount: PropTypes.number,
  setFieldTouched: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  className: PropTypes.string
};

export default FormikMainResidence;
