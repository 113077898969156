const lastFiveYearsDrivingExperienceValues = [
  {
    displayName: 'Ireland or UK',
    dropdownValue: 'IRELAND_OR_UK',
  },
  {
    displayName: 'EU Country',
    dropdownValue: 'EU_COUNTRY',
  },
  {
    displayName: 'Non EU Country',
    dropdownValue: 'NON_EU_COUNTRY',
  },
  {
    displayName: 'NA',
    dropdownValue: 'NOT_APPLICABLE',
  }
];

export default lastFiveYearsDrivingExperienceValues;
