// @flow
import '../../../../../styles/commonFormStyles.scss';

import { InputField } from '@boi/core/lib';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { Address } from './AddressInterface';

interface Props {
  fieldNamePrefix: string
}

const AddressInfo = (props: Address & Props) => {
  const { fieldNamePrefix, addressLine1, addressLine2, addressLine3, town, county, eircode } = props;
  return (
    <div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`${fieldNamePrefix}Line1`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Address line 1
        </label>
        <InputField
          name={`${fieldNamePrefix}Line1`}
          value={addressLine1}
          readOnly={true}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`${fieldNamePrefix}Line2`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Address line 2
        </label>
        <InputField
          name={`${fieldNamePrefix}Line2`}
          value={addressLine2}
          readOnly={true}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`${fieldNamePrefix}Line3`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Address line 3
        </label>
        <InputField
          name={`${fieldNamePrefix}Line3`}
          value={addressLine3}
          readOnly={true}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`${fieldNamePrefix}Town`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Town
        </label>
        <InputField
          name={`${fieldNamePrefix}Town`}
          value={town}
          readOnly={true}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`${fieldNamePrefix}County`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          County
        </label>
        <InputField
          name={`${fieldNamePrefix}County`}
          value={county}
          readOnly={true}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`${fieldNamePrefix}Eircode`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Eircode
        </label>
        <InputField
          name={`${fieldNamePrefix}Eircode`}
          readOnly={true}
          value={eircode}
        />
      </div>
    </div>
  );
};

export default AddressInfo;
