/* eslint-disable react/prop-types */
import './Tabs.scss'

import React, { useCallback,useEffect,useState } from "react";

const tabWidth = {
  "2": '40%',
  "3": '28%'
}
// eslint-disable-next-line react/prop-types
const Tabs = ({children}) => {

  const [selected, setSelected] = useState(0)
  const [tabElement, setTabElements] = useState([])
  const handleChange = useCallback((index) => {
    setSelected(index)
  }, [])

  useEffect(() => {
    setTabElements(children.filter((item) => {
      if(item) {
        return item
      }
    }))
  }, [])

    return (
      <div className="tab-container">
        <ul className='tabs'>
          {tabElement && tabElement.map((elem, index) => {
            let style = index === selected ? "selected" : "";
            if(elem && elem.props.title !== '') {
              return (
                <li
                  style={{
                    width: `${tabWidth[tabElement.length.toString()]}`
                  }}
                  key={index}
                  className={style}
                  onClick={() => {handleChange(index)}}
                >
                  {elem.props.title}
                </li>
              );
            }
          })}
        </ul>
        <div className="tab">{children[selected]}</div>
      </div>
    );
}

export default Tabs;