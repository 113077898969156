/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import { WideDivider } from '@boi/core/lib';
import { colors } from '@boi/core/lib/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { commonFormStylesIdentifier } from '../../constants';
import { dividerColor } from '../../constants/colors';
import { CALL_TEXT, CLAIM_TEXT, EMAIL_TEXT, EMERGENCY_TEXT } from '../../constants/submitClaimConstants';


interface Props {
  className: string,
  label: string,
  info: any,
  icon: FontAwesomeIcon,
  contactData: string
}

const ContactCard = ({ className, label, info, icon, contactData }: Props) => {

  // eslint-disable-next-line complexity
  const renderLink = () => {
    switch (label) {
      case CALL_TEXT:
        return <a href={`tel:${contactData}`}>{contactData}</a>;
      case EMAIL_TEXT:
        return <a href={`mailto:${contactData}`}>Send Email</a>;
      case CLAIM_TEXT:
        return <a href={contactData}>Find Out More</a>;
      case EMERGENCY_TEXT:
        return <>Call <a href={`tel:${contactData}`}>{contactData}</a></>;
      default:
        return <span>{''}</span>;
    }
  };

  return (
    <div>
      <div className={`${className}__content`}>
        <FontAwesomeIcon className="signupIcon" icon={icon} size="2x" color={colors.primaryColor} />
      </div>
      <h3>
        {label}
      </h3>
      <p>
        {renderLink()}
      </p>
      <div>
        {info}
      </div>
      <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
        <WideDivider color={dividerColor} height={1} />
      </div>
    </div>
  );
};

export default ContactCard;
