/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../styles/commonFormStyles.scss'
import './AboutYouForm.scss';

import { WideDivider } from '@boi/core/lib';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import { dividerColor } from '../../../../constants/colors'
import { TRAVEL_INSURANCE_TYPE } from '../../../../constants/insuranceTypeConstants';
import MainOccupationValues from '../../../../mocks/MainOccupationValues';
import {
  AddressDetails,
  BOICustomerAndPromoCode,
  ContactDetails,
  DrivingDetails,
  EmploymentDetails,
  InsuranceDetails,
  PersonalDetails
} from '../AboutYouFormSections';

const className = 'c-AboutYouForm';

const mainOccupationOptions = MainOccupationValues.map((occupation: { value: string, label: string }) => {
  return {
    key: occupation.value,
    value: occupation.value,
    text: occupation.label
  };
});

interface Props {
  emailParam: string;
  insuranceType: string;
  openAccordions: () => {};
}

const AboutYouForm = (props: Props) => {
  const { insuranceType, emailParam, openAccordions } = props;
  return (
    <div className={`${className}__container`}>
      <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
        <PersonalDetails />
        <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
          <WideDivider color={dividerColor} height={1} />
        </div>
        <AddressDetails />
        <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
          <WideDivider color={dividerColor} height={1} />
        </div>
        <ContactDetails insuranceType={insuranceType}  emailParam={emailParam} openAccordions={openAccordions} />
      </div>
      {insuranceType !== TRAVEL_INSURANCE_TYPE ? <EmploymentDetails
        insuranceType={insuranceType}
        occupationOptions={mainOccupationOptions}
      /> : null}
      <InsuranceDetails />
      <DrivingDetails />
      <BOICustomerAndPromoCode />
    </div>
  );
};

export default AboutYouForm;
