/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './ResetPassword.scss';

import Auth from '@aws-amplify/auth';
import { BOIGroupLogo, Button, InputField } from '@boi/core/lib';
import { FormikProps, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import FormHeader from '../../components/FormHeader';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants';
import { primaryBlue } from '../../constants/colors';
import {
  passswordMsg,
  passwordRegexPattern
} from '../../constants/loginRegistrationConstants';
import { isFieldError } from '../../helpers';
import { routes } from '../../routes';

interface Props {
  email: string;
}

interface Values {
  email: string;
  password: string;
}

const RESET_FRESH = 'ResetPassword/RESET_FRESH';
const RESET_USER_INVALID = 'ResetPassword/RESET_USER_INVALID';
const RESET_CODE_SENDING = 'ResetPassword/RESET_CODE_SENDING';
const RESET_CODE_SENT = 'ResetPassword/RESET_CODE_SEND';

const ResetPasswordForm = ({
  errors,
  handleBlur,
  handleChange,
  isValid,
  setStatus,
  status,
  touched,
  setFieldError,
  values,
  history
}: Props & FormikProps<Values>) => {
  const className = 'c-ResetPassword';

  const titleText = 'Reset your password';
  const [isEmailValid, setIsEmailValid] = useState(true);
  const location = useLocation();
  const buttonRef = useRef(null);

  const sendResetCode = async () => {
    setStatus(RESET_CODE_SENDING);
    try {
      await Auth.forgotPassword(values.email);
      setStatus(RESET_CODE_SENT);
    } catch (e) {
      let msg: string = e.message;
      if (e.code === 'InvalidParameterException') {
        setStatus(RESET_USER_INVALID);
        setIsEmailValid(true);
        msg =
          'Your account needs to be verified. Please check your email for your verification code before resetting your password.';
      }
      setFieldError('email', msg);
    }
  };

  const resetPassword = async () => {
    Auth.forgotPasswordSubmit(
      values.email,
      values.verificationCode,
      values.newPassword
    )
      .then(() => {
        history.push(routes.loginPage.url);
      })
      .catch(() => {
        setFieldError(
          'confirmNewPassword',
          'Invalid verification code provided, please try again.'
        );
      });
  };

  const renderVerifyEmailLink = () => {
    if (status !== RESET_USER_INVALID) return <></>;
    return (
      <Link
        to={{
          pathname: routes.verifyEmail.url,
          state: { email: values.email }
        }}
        className={`${className}__link`}
        data-ga
        id="ResetPassword__verifyEmailLink"
        data-testid="ResetPassword__verifyEmailLink"
      >
        Verify email
      </Link>
    );
  };

  const showPasswordChange = () => {
    return status === RESET_CODE_SENT;
  };

  const checkForEmailError = () => {
    if (Object.keys(errors).length === 0 || errors.email) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const shouldShowHalfComponent =
      searchParams.get('resetCodeSent') === 'true';

    if (shouldShowHalfComponent) setStatus(RESET_CODE_SENT);
  }, [location.search]);

  useEffect(() => {
    const handleKeyPress = async (event: any) => {
      if (event.key === 'Enter' && buttonRef.current) {
        buttonRef.current.props.children === 'Change password'
          ? await resetPassword()
          : await sendResetCode();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [buttonRef, sendResetCode]);

  const renderPasswordChange = () => {
    if (!showPasswordChange()) {
      return (
        <div>
          <div className={`${className}__button`}>
            <Button
              id="ResetPassword__button"
              fluid={true}
              quaternary
              disabled={isEmailValid}
              onClick={async () => {
                await sendResetCode();
              }}
              type="submit"
              ref={buttonRef}
            >
              {'Send verification code'}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="verificationCode"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Verification Code
          </label>
          <div className={`${commonFormStylesIdentifier}__infoText`}>
            Please note you will shortly receive a Password Reset email with the new verification code to be entered here
          </div>
          <span className={`${className}__input`}>
            <InputField
              error={isFieldError('verificationCode', touched, errors)}
              errorMessage={errors.verificationCode}
              name="verificationCode"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter verification code"
              touched={touched.verificationCode}
              type="text"
              value={values.verificationCode}
            />
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="newPassword"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            New Password
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={isFieldError('newPassword', touched, errors)}
              errorMessage={errors.newPassword}
              name="newPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter new password"
              touched={touched.newPassword}
              type="password"
              value={values.newPassword}
            />
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="confirmNewPassword"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Confirm New Password
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={isFieldError('confirmNewPassword', touched, errors)}
              errorMessage={errors.confirmNewPassword}
              name="confirmNewPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Confirm new password"
              touched={touched.confirmNewPassword}
              type="password"
              value={values.confirmNewPassword}
            />
          </span>
        </div>
        <div className={`${className}__button`}>
          <Button
            id="ResetPassword__button"
            fluid={true}
            quaternary
            disabled={!isValid}
            onClick={async () => {
              await resetPassword();
            }}
            ref={buttonRef}
          >
            {'Change password'}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo--flex`}>
            <BOIGroupLogo color={primaryBlue} />
          </div>
          <FormHeader className={className} titleText={titleText} />
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label
              htmlFor="email"
              className={`${commonFormStylesIdentifier}__fieldLabel`}
            >
              Email address
            </label>
            <span className={`${className}__input`}>
              <InputField
                error={isFieldError('email', touched, errors)}
                errorMessage={errors.email}
                name="email"
                onBlur={(e: string) => {
                  handleBlur(e);
                  checkForEmailError();
                }}
                onChange={(e: string) => {
                  handleChange(e);
                  checkForEmailError();
                }}
                placeholder="Enter email address"
                touched={touched.email}
                type="email"
                value={values.email}
              />
            </span>
          </div>
          {renderPasswordChange()}
          {renderVerifyEmailLink()}
        </div>
      </div>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object
};

const ResetPassword = withFormik<Props, Values>({
  mapPropsToValues(): {} {
    return {
      email: '',
      verificationCode: '',
      newPassword: '',
      confirmNewPassword: ''
    };
  },
  handleSubmit(values: Values, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  mapPropsToStatus(): string {
    return RESET_FRESH;
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    verificationCode: yup.string().required('Answer is Required'),
    newPassword: yup
      .string()
      .required('Please enter a new password')
      .matches(passwordRegexPattern, passswordMsg),
    confirmNewPassword: yup
      .string()
      .required('Please confirm new password')
      .test('passwords-match', 'Passwords must match', function(
        value: string
      ): boolean {
        return this.parent.newPassword === value;
      })
  }),
  displayName: 'ResetPasswordForm'
})(ResetPasswordForm);

export default ResetPassword;
