const genderValues = [
  {
    displayName: 'Male',
    dropdownValue: 'MALE'
  },
  {
    displayName: 'Female',
    dropdownValue: 'FEMALE'
  }
];

export default genderValues;
