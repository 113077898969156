/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { rawToIrishFormat } from '../../helpers';
import { makeRequest } from '../../helpers';
import { checkCognitoUser } from '../../utils/utils';

const getPayloadForAgent = email => (email ? { payload: { email } } : {});

export const getLatestMotorQuote = async (email, props = {}) => {
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const payload = getPayloadForAgent(email);
  return await makeRequest({
    method: email ? 'POST' : 'GET',
    url: `${basePath}/motor/motor_quotes/latest`,
    history: props.history,
    ...payload
  });
};

export const hasPurchasedMotorPolicy = async (props) => {
  if(!checkCognitoUser()) {
    return false
  }
  return getLatestMotorQuote(null, props).then(response => {
    if (response.quote) {
      const data = formatResponse(response.quote);
      return !!data.policy_reference;
    }
    return false;
  });
};

export const getLatestUnpurchasedMotorQuote = async (props, email) => {
  if (!checkCognitoUser()) {
    // If cognitoAccessTokenKey is undefined just return an object that specifies no token was passed
    return { hasNoAccessToken: true };
  }
  return getLatestMotorQuote(email, props).then(response => {
    if (response.quote) {
      const data = formatResponse(response.quote);
      return !data.policy_reference ? data : {};
    }
    return {};
  });
};

const getPostalAddress = (primaryAddress, postalAddress) => {
  if (primaryAddress.post_code !== postalAddress.post_code) {
    return {
      isThisYourPostalAddress: false,
      postalAddressLine1: postalAddress.address_line1,
      postalAddressLine2: postalAddress.address_line2,
      postalAddressLine3: postalAddress.address_line3,
      postalAddressTown: postalAddress.town,
      postalAddressCounty: postalAddress.county,
      postalAddressEircode: postalAddress.post_code
    };
  }
  return {
    isThisYourPostalAddress: true,
    postalAddressLine1: '',
    postalAddressLine2: '',
    postalAddressLine3: '',
    postalAddressTown: '',
    postalAddressCounty: '',
    postalAddressEircode: ''
  };
};

const getAdditionalDrivers = (additionalDrivers = []) => {
  return additionalDrivers.map((driver) => {
    return {
      title: driver.title,
      firstName: driver.first_name,
      lastName: driver.last_name,
      dob: rawToIrishFormat(driver.date_of_birth),
      relationshipToProposer: driver.relationship_to_proposer,
      driversLicenseType: driver.license_type,
      lastFiveYearsExperience: driver.driving_experience,
      employmentStatus: driver.employment_status,
      mainOccupation: driver.occupation,
      yearsHeld: driver.license_years,
      additionalDriverPenaltyPointOffenceCount: 0,
      additionalDriverPenaltyPointOffences: []
    };
  });
};

const getPassedTestInIrelandOrUk = (licenseIssueCountry) => {
  return licenseIssueCountry === 'IE';
};

const getCarDetails = (carDetails) => {
  if (carDetails) {
    return {
      carReg: carDetails.registration,
      carModel: carDetails.model,
      carMake: carDetails.make,
      carYear: carDetails.year,
      carVersion: carDetails.version,
      carFuelType: carDetails.fuel,
      carEngineSize: carDetails.cc,
      carPurchased: carDetails.years_owned_vehicle,
      carRisk: carDetails.risk,
      vehicleValue: carDetails.value,
      carUsedForWork: carDetails.use,
      annualKm: carDetails.km_per_year
    };
  }
  return {};
};

const getNoClaimsYears = (noClaimsYears = 0) => {
  if (noClaimsYears > 0) {
    return {
      noClaimsBonus: noClaimsYears
    };
  }
  return {};
};

const getClaimsDetails = (claims) => {
  if (claims) {
    return {
      motorClaims: claims,
      claimsCount: claims.length
    };
  }
  return {};
};

function formatResponse(data = {}) {
  const {
    quote_reference,
    policy_reference
  } = data;

  if (!quote_reference && !policy_reference) {
    return {};
  }

  const {
    title,
    first_name,
    last_name,
    date_of_birth,
    primary_address,
    mobile_phone,
    email,
    employment_status,
    occupation,
    license_number,
    license_type,
    driving_experience,
    license_issue_country,
    license_years,
    ncb_year_on_other_policy,
    claims,
    boi_customer,
    has_boi_house_policy
  } = data.main_driver;

  const {
    risk_address,
    vehicle_details,
    type,
    driving_exp_type
  } = data.cover_details;

  const additionalDrivers = data.additional_drivers || [];

  return {
    quote_reference,
    policy_reference,
    values: {
      title,
      firstName: first_name,
      lastName: last_name,
      addressLine1: primary_address.address_line1,
      addressLine2: primary_address.address_line2,
      addressLine3: primary_address.address_line3,
      addressCounty: primary_address.county,
      addressTown: primary_address.town,
      addressEircode: primary_address.post_code,
      ...getPostalAddress(primary_address, risk_address),
      phoneNo: mobile_phone,
      email: email,
      dob: rawToIrishFormat(date_of_birth),
      licenseNumber: license_number,
      employmentStatus: employment_status,
      mainOccupation: occupation,
      driversLicenseType: license_type,
      passedTestInIrelandOrUk: getPassedTestInIrelandOrUk(license_issue_country),
      lastFiveYearsExperience: driving_experience,
      drivingExpType: driving_exp_type,
      yearsHeld: license_years,
      bankOfIrelandCustomer: boi_customer,
      bankOfIrelandHomeInsurancePolicy: has_boi_house_policy,
      ...getCarDetails(vehicle_details),
      ...getNoClaimsYears(ncb_year_on_other_policy),
      coverType: type,
      ...getClaimsDetails(claims),
      additionalDrivers: getAdditionalDrivers(additionalDrivers),
      hasAdditionalDrivers: additionalDrivers.length > 0,
      promoCode: data.promotion_code
    }
  };
}
