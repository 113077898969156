/* eslint-disable flowtype/no-types-missing-file-annotation */
import { DatePicker, Dropdown, InputField } from '@boi/core/lib';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import {
  OPTION_TITLE_VALUES_CONSTANT,
  OTHER_TITLE_CONSTANT,
  TITLES_CONSTANT,
  TITLES_TO_SHOW
} from '../../../../constants/home';
import { saveData } from '../../../../helpers/HomeCommonMethods';
import { ChildComponentProps } from '../models/AboutYouFormModels';

const AboutYouJointlyForm = (props: ChildComponentProps) => {
  const {
    className,
    checkError,
    getIn,
    errors,
    getJointlyOwnedFieldValue,
    handleChange,
    setFieldValue,
    handleCustomBlurFunc,
    values,
    touched
  } = props;
  return (
    <div className={`${className}`}>
      <div
        className={`${commonFormStylesIdentifier}__dynamicFieldsContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
      >
        <h2 className={`${className}__jointOwnerHeader`}>
          Please complete the following fields for the joint owner
        </h2>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="jointlyOwnedDetails.title"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Title
          </label>
          <span className={`${className}__input`}>
            <Dropdown
              data-testid={`${commonFormStylesIdentifier}__jointlyOwnedDetailsTitle`}
              error={checkError('jointlyOwnedDetails', 'title')}
              errorMessage={getIn(errors, 'jointlyOwnedDetails.title')}
              placeholder="Type here"
              name="jointlyOwnedDetails.title"
              value={getJointlyOwnedFieldValue('jointlyOwnedDetails.title')}
              onChange={(e: string) => {
                handleChange(e);
                setFieldValue('jointlyOwnedDetails.otherTitle', '');
                saveData('aboutYouObj','jointlyOwnedDetails.title', e.target.value)
              }}
              onBlur={e => {
                handleCustomBlurFunc(e, 'jointlyOwnedDetails');
              }}
            >
              {TITLES_CONSTANT.slice(0, TITLES_TO_SHOW).map(
                (title: string, index: number) => {
                  return (
                    <option
                      value={OPTION_TITLE_VALUES_CONSTANT[index]}
                      key={`${index}_${title}`}
                    >
                      {TITLES_CONSTANT[index]}
                    </option>
                  );
                }
              )}
            </Dropdown>
          </span>
        </div>
        {values?.jointlyOwnedDetails?.title === OTHER_TITLE_CONSTANT ? (
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label
              htmlFor="jointlyOwnedDetails.otherTitle"
              className={`${commonFormStylesIdentifier}__fieldLabel`}
              data-testid={`${commonFormStylesIdentifier}__otherTitleLabel`}
            >
              Other title
            </label>
            <span className={`${className}__input`}>
              <InputField
                error={checkError('jointlyOwnedDetails', 'otherTitle')}
                errorMessage={getIn(errors, 'jointlyOwnedDetails.otherTitle')}
                placeholder="Type here"
                name="jointlyOwnedDetails.otherTitle"
                value={getJointlyOwnedFieldValue(
                  'jointlyOwnedDetails.otherTitle'
                )}
                onChange={(e) => {
                  handleChange(e)
                  saveData('aboutYouObj','otherTitle', e.target.value)
                }}
                onBlur={e => {
                  handleCustomBlurFunc(e, 'jointlyOwnedDetails');
                }}
              />
            </span>
          </div>
        ) : null}
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="jointlyOwnedDetails.firstName"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            First name
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={checkError('jointlyOwnedDetails', 'firstName')}
              errorMessage={getIn(errors, 'jointlyOwnedDetails.firstName')}
              placeholder="Type here"
              name="jointlyOwnedDetails.firstName"
              value={getJointlyOwnedFieldValue('jointlyOwnedDetails.firstName')}
              onChange={(e) => {
                handleChange(e)
                saveData('aboutYouObj','jointlyOwnedDetails.firstName', e.target.value)
              }}
              onBlur={e => {
                handleCustomBlurFunc(e, 'jointlyOwnedDetails');
              }}
            />
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="jointlyOwnedDetails.lastName"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Last name
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={checkError('jointlyOwnedDetails', 'lastName')}
              errorMessage={getIn(errors, 'jointlyOwnedDetails.lastName')}
              placeholder="Type here"
              name="jointlyOwnedDetails.lastName"
              value={getJointlyOwnedFieldValue('jointlyOwnedDetails.lastName')}
              onChange={(e) => {
                handleChange(e)
                saveData('aboutYouObj','jointlyOwnedDetails.lastName', e.target.value)
              }}
              onBlur={e => {
                handleCustomBlurFunc(e, 'jointlyOwnedDetails');
              }}
            />
          </span>
        </div>
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer ${className}__lastRepeatingFieldContainer`}
        >
          <label
            htmlFor="jointlyOwnedDetails.dateOfBirth"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Date of Birth
          </label>
          <span className={`${className}__input`}>
            <DatePicker
              touched={getIn(touched, 'jointlyOwnedDetails.dateOfBirth')}
              error={getIn(errors, 'jointlyOwnedDetails.dateOfBirth')}
              placeholder="DD/MM/YYYY"
              name="jointlyOwnedDetails.dateOfBirth"
              value={getJointlyOwnedFieldValue(
                'jointlyOwnedDetails.dateOfBirth'
              )}
              onChange={(e) => {
                handleChange(e)
                saveData('aboutYouObj','jointlyOwnedDetails.dateOfBirth', e.target.value)
              }}
              onBlur={e => {
                handleCustomBlurFunc(e, 'jointlyOwnedDetails');
              }}
              textType
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutYouJointlyForm;
