/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import CarClaimPage from './CarClaimPage';
import HomeClaimPage from './HomeClaimPage';
import TravelClaimPage from './TravelClaimPage';

type Props = {
  match: Match,
  history: RouterHistory
};

  // eslint-disable-next-line complexity
const SubmitClaimPage = ({ match, history }: Props) => {

  const type = match.params.type || '';

  switch (type) {
    case TRAVEL_INSURANCE_TYPE:
      return <TravelClaimPage history={history} />;
    case HOME_INSURANCE_TYPE:
      return <HomeClaimPage history={history} type={type} />
    case CAR_INSURANCE_TYPE:
      return <CarClaimPage history={history} type={type} />
    default:
      return <></>;
  }
};

export default withRouter(SubmitClaimPage);
