/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './QuoteDisplay.scss';

import { Button, Text } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { REQUEST_LOADING } from '../../constants';
import { MONTHLY, TOTAL } from '../../constants/paymentTypes';
import type { PayloadType } from '../../types';

type buttonType = 'proceed' | 'recalculate' | 'Buy';

interface Props {
  buttonType: buttonType;
  callStatus?: string;
  disabled: boolean;
  price?: number;
  recalculate?: noop;
  onClickFunction: noop;
  sticky: boolean;
  buyButtonTitle?: string;
  payload?: PayloadType;
  title?: string;
  paymentType?: string;
  insurerType: string;
}

const className = 'c-QuoteDisplay';

const QuoteDisplay = ({
  disabled,
  buttonType,
  callStatus,
  price = 0,
  recalculate,
  sticky,
  onClickFunction,
  title = 'Total',
  paymentType = TOTAL,
  buyButtonTitle = 'Buy',
  insurerType
}: Props) => {

  const [callMade, setCallMade] = useState(false);

  const getActiveClassNames = () => {
    const classNames = [className];
    if (sticky) {
      classNames.push(`${className}--sticky`);
    }
    return classNames.join(' ');
  };

  const spinner =
    callMade && callStatus === REQUEST_LOADING ? (
      <FontAwesomeIcon icon={faSpinner} spin />
    ) : (
      ''
    );

  const getRecalculatedDisabled = () =>
    disabled || (callMade && callStatus === REQUEST_LOADING);

  const getButton = () => {
    switch (buttonType) {
      case 'recalculate':
        return (
          <Button
            data-ga
            id={`QuoteDisplay__recalculateQuoteButton`} data-testid={`QuoteDisplay__recalculateQuoteButton`}
            type="button"
            fluid
            disabled={getRecalculatedDisabled()}
            className={`${className}__button`}
            onClick={() => {
              if (recalculate) {
                setCallMade(true);
                recalculate();
              }
            }}
          >
            {`Recalculate `}
            {spinner}
          </Button>
        );
      case 'Buy':
        // TODO if the styling of this component is still around say by the end of October
        // it should be refactored. As is, it does too much for what should be a small presentational
        // component
        // @dogpawhat
        return (
          <Button
            data-ga
            id={`QuoteDisplay__buyButton`} data-testid={`QuoteDisplay__buyButton`}
            type="button"
            fluid
            disabled={disabled}
            className={`${className}__button`}
            onClick={onClickFunction}
          >
            {buyButtonTitle}
          </Button>
        );
      case 'proceed':
      default:
        return (
          <Button
            data-ga
            id={`QuoteDisplay__proceedButton`} data-testid={`QuoteDisplay__proceedButton`}
            type="button"
            fluid
            disabled={disabled}
            className={`${className}__button`}
            onClick={onClickFunction}
          >
            Proceed
          </Button>
        );
    }
  };

  const getTotalTitle = () => paymentType === TOTAL ? <Text id={"quoteDisplayLabel"}>{title}</Text> : <></>;
  const getMonthlyTitle = () => paymentType === MONTHLY ?
    <span className={`${className}__monthlyPaymentTitle`}>{title}</span> : <></>;
  const roundCentsUp = (price: number) => Math.ceil(price * 100) / 100;
  const getPrice = (price: number, isMonthlyPayment: boolean) => (isMonthlyPayment ?
    roundCentsUp(price) : price).toFixed(2);

  return (
    <div className={getActiveClassNames()}>
      {insurerType !== null ?
      (<div className={`${className}__insurerLogo`}>
        <img className={`${className}__${insurerType.toLowerCase()}Logo`} alt={`${insurerType.toLowerCase()} logo`}
          src={`/asset/${insurerType.toLowerCase()}_logo.jpg`} />
      </div>) : null}
      <div className={`${className}__priceContainer`}>
        {getTotalTitle()} 
        <div className={`${className}__price`}>
          <Text size="inherit" className={`${className}__priceCurrencyIcon`}>
            €
          </Text>
          <Text size="inherit" className={`${className}__priceNumber`}>
            {getPrice(price, paymentType === MONTHLY)}
            {getMonthlyTitle()}
          </Text>
        </div>
      </div>
      <div className={`${className}__buttonContainer`}>{getButton()}</div>
    </div>
  );
};

export default QuoteDisplay;
