import React from 'react';

export const LegalTextContentFBD =
  'You have the right to cancel your SEPA Direct Debit any time by writing to FBD Insurance plc and informing your bank or Building Society in good time.';
export const AuthoriseContentFBD = 'Please note that whether you have signed a paper mandate or have signed up online or by telephone, you authorise (A) FBD Insurance plc to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with the instructions from FBD Insurance plc. As part of your rights, you are entitled to a full refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. You rights are explained in a statement that you can obtain from your bank.';
export const YourRightsContentFBD = (<>FBD Insurance plc terms and conditions for direct debits. You understand that failure to pay direct debits may result in:
<ul>
<li>An administration fee being payable to FBD Insurance plc</li>
<li>Your insurance cover being cancelled</li>
<li>FBD Insurance plc demanding immediate payment on any or all outstanding instalment(s)</li>
<li>Future ineligibility for direct debits with FBD Insurance plc</li>
</ul>
The service charge may be revised at the discretion of FBD Insurance plc at each renewal. You agree to advise FBD Insurance plc of any changes of bank account or address. FBD Insurance plc may continue direct debits automatically at next renewal. The cost of your monthly repayments may vary due to rounding.</>);


export const LegalTextContentRSA =
  'Direct Debit instalments will be collected by RSA Insurance Ireland DAC using the following reference number: Creditor Identifier Number:  IE63SDD994021 ';
export const AuthoriseContentRSA = 'You authorise (A) RSA Insurance Ireland DAC to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with the instruction from RSA Insurance Ireland DAC.';
export const YourRightsContentRSA = (<>As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can receive from your bank. RSA Insurance Ireland DAC will appear on your bank or building society statement. The cost of your monthly repayments may vary due to rounding.</>);


export const LegalTextContentRSACar = 'The Direct Debit instalments will be collected by Bank of Ireland Insurance Services Limited using the following reference number: Creditor Identifier Number:IE63ZZZ350038'
export const AuthoriseContentRSACar = 'You authorise (A) Bank of Ireland Insurance Services Limited to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with the instruction from Bank of Ireland Insurance Services Limited.'
export const YourRightsContentRSACar = (<>
  As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can receive from your bank. Bank of Ireland Insurance Services Limited “BIIS” will appear on your bank or building society statement.</>)
