import './TermsAndConditionsCheckbox.scss';

import { Checkbox } from '@boi/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { GET_HOME_DOCUMENT, REQUIRED_DOCUMENTS } from '../../../constants/home';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { getObjectFromSessionStorage } from '../../../helpers';
import { getDownloadLink } from '../../../utils/utils';

const TermsAndConditionsCheckbox = ({ setFieldValue, values, showStatementOfFact = false }) => {
  const className = 'c-TermsAndConditionsCheckbox';

  const getUrl = (e) =>{
    const reqDocs = getObjectFromSessionStorage(REQUIRED_DOCUMENTS)
    if(reqDocs.statement_of_fact) {
      return getDownloadLink(
        e,
        reqDocs?.statement_of_fact?.document_id,
        reqDocs?.statement_of_fact?.display_name,
        GET_HOME_DOCUMENT
      );
    }
  }


  return (
    <div className={className}>
      <div className={`${className}__title`}>Terms and Conditions</div>
      <div>
        You are under a duty to answer all the questions that have been asked, honestly and with reasonable care and attention. 
        These questions are material in determining if the insurance underwriters can accept this risk, what terms are applied and what premium is charged. 
        Failure to do so could result in the insurance underwriters cancelling the contract, rejecting a claim, or limiting the claim payment. See your Policy documentation for full details of cover {' '}
        {showStatementOfFact ? <a rel="noopener noreferrer" href='#' onClick={(e)=> getUrl(e)}>here.</a> : 
        <a rel="noopener noreferrer" target="_blank" href="//personalbanking.bankofireland.com/insure-and-protect/insurance/home-insurance-quote/important-documents/">here.</a>}
      </div>
      <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
        <Checkbox
          id="acceptedTermsAndConditions"
          name="acceptedTermsAndConditions"
          label={
            <>
              Please tick to confirm that you understand the above information and to confirm your acceptance of Bank of Ireland Insurance Services{' '}
              <a rel="noopener noreferrer" target="_blank"
                 href="//boi.com/insurancehometob">Terms of
                Business</a>, <a rel="noopener noreferrer"
                                 target="_blank"
                                 href="//boi.com/insurancecss">Commission
              Summary Document</a>, <a rel="noopener noreferrer" target="_blank"
                                       href="//boi.com/insurancehometou">Terms
              of Use</a> and <a
              rel="noopener noreferrer" target="_blank"
              href="//boi.com/insurancehomedpn">Data Privacy
              Notice</a> before proceeding.
            </>
          }
          isChecked={values.acceptedTermsAndConditions}
          onChange={() =>
            setFieldValue(
              'acceptedTermsAndConditions',
              !values.acceptedTermsAndConditions
            )
          }
          value={values.acceptedTermsAndConditions}
        />
      </div>
    </div>
  );
};


TermsAndConditionsCheckbox.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  showStatementOfFact: PropTypes.bool,
};

export default TermsAndConditionsCheckbox;
