export const PropertyMapper = {
    RSA: {
        AFTERLOADDISC: 'AMT',
        AFTEROPTEX: 'AMT',
        INCLEVY: 'AMT',
        AFTERSALES: 'AMT'
    },
    FBD: {
        AFTERLOADDISC: 'RUN_AMT',
        AFTEROPTEX: 'RUN_AMT',
        INCLEVY: 'RUN_AMT',
        AFTERSALES: 'RUN_AMT'
    }
}
