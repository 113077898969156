/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import { basePath } from '../constants/commonConstants';
import { QUOTE_REFERENCE } from '../constants/home';
import { HOME_RESUME_QUOTE } from '../constants/sessionStorage/homeStorageIdentifiers';
import { isAgent, makeRequest, rawToIrishFormat } from '../helpers';
import { getCoverTypeForDwellingType } from '../helpers/CoverTypeObject';
import {
  getItemFromSessionStorage,
  saveObjectInSessionStorage
} from '../helpers/sessionStorageHelper';
import { checkCognitoUser } from '../utils/utils';

const getPayloadForAgent = email => (email ? { payload: { email } } : {});

export const getLatestHomeQuote = async (props = {}, email) => {
  const payload = getPayloadForAgent(email);
  return await makeRequest({
    method: email ? 'POST' : 'GET',
    url: `${basePath}/home/applied_home_quotes/latest`,
    history: props.history,
    ...payload
  });
};

export const hasPurchasedHomePolicy = async props => {
  if (!checkCognitoUser()) {
    return false;
  }
  return getLatestHomeQuote(props).then(response => {
    if (response.home_quote) {
      const data = formatResponse(response.home_quote);
      return !!data.policy_reference;
    }
    return false;
  });
};

export const getLatestUnpurchasedHomeQuote = async (props, home) => {
  const flag =
    JSON.parse(getItemFromSessionStorage(HOME_RESUME_QUOTE)) || false;
  if (!checkCognitoUser()) {
    return false;
  } else if (flag) {
    return false;
  }
  return getLatestHomeQuote(props, home).then(response => {
    if (response.home_quote) {
      const data = formatResponse(response.home_quote);
      return !data.policy_reference ? data : {};
    }
    return {};
  });
};

const nullToEmptyString = value => {
  return value === null ? '' : value;
};

// eslint-disable-next-line complexity
export function formatResponse(homeQuoteData) {
  let quote_reference = homeQuoteData.quote_reference;
  let policy_reference = '';

  if (homeQuoteData.policy_reference) {
    policy_reference = homeQuoteData.policy_reference;
  }

  if (!quote_reference && !policy_reference) return {};
  if (isAgent()) {
    saveObjectInSessionStorage(QUOTE_REFERENCE, quote_reference);
  }
  const {
    first_name,
    last_name,
    primary_address,
    mobile_phone,
    email,
    date_of_birth,
    // employment_status,
    occupation,
    title,
    other_title,
    has_boi_mortgage,
    has_boi_personal_loan,
    has_boi_motor_insurance,
    has_boi_pension,
    first_time_buyer,
    mortgage_provider,
    mortgage_reference,
    correspondence_address,
    is_boi_customer,
    marketing_consent,
    marketings,
    analyse_transactions
  } = homeQuoteData.policy_holder_details;
  const {
    buildings_amount,
    contents_amount,
    unspecified_personal_items_amount,
    specified_items,
    risk_address,
    claims_details,
    additional_policy_holders_details = [],
    contact_preference,
    ncd_num_years
  } = homeQuoteData.cover_details;

  const getRebuildCost = () =>
    buildings_amount === null ? '' : `${buildings_amount}`;
  const getContentsCover = () =>
    contents_amount === null ? '' : `${contents_amount}`;
  const findAssumptionIdValue = ele => {
    return homeQuoteData?.assumptions.find(item => {
      if (parseInt(item.code) === ele) {
        return item;
      }
    });
  };

  const assumptionIds = id => {
    if (homeQuoteData) {
      return homeQuoteData?.assumptions?.length
        ? findAssumptionIdValue(id)?.response
        : '';
    }
  };

  return {
    quote_reference,
    policy_reference,
    aboutYou: {
      title: title || '',
      otherTitle: other_title || '',
      firstName: first_name || '',
      lastName: last_name || '',
      ...primary_address,
      addressLine1: primary_address.address_line1 || '',
      addressLine2: primary_address.address_line2 || '',
      county: primary_address.county || '',
      town: primary_address.town || '',
      eircode: primary_address.post_code || '',
      lookup: primary_address.lookup_response || '',
      phoneNo: mobile_phone || '',
      email: email || '',
      dob: rawToIrishFormat(date_of_birth),
      // employmentStatus: employment_status,
      mainOccupation: occupation || '',
      promoCode: homeQuoteData.promotion_code || '',
      staffNumber: homeQuoteData.staff_number || '',
      boiMortgage: has_boi_mortgage || '',
      boiPersonalLoan: has_boi_personal_loan || '',
      boiMotorInsurance: has_boi_motor_insurance || '',
      boiPension: has_boi_pension || '',
      firstTimeBuyer: first_time_buyer?.toString() || '',
      mortgageProvider: mortgage_provider || 1,
      mortgageApplicationNumber: mortgage_reference || '',
      is_correspondence_address: !correspondence_address?.is_correspondence_address,
      correspondence_addressLine1: correspondence_address?.address_line1 || '',
      correspondence_addressLine2: correspondence_address?.address_line2 || '',
      correspondence_county: correspondence_address?.county || '',
      correspondence_town: correspondence_address?.town || '',
      correspondence_eircode: correspondence_address?.eircode || '',
      correspondence_ecad_match_level:
        correspondence_address?.correspondence_match_level || '',
      correspondence_ecad_match_result:
        correspondence_address?.correspondence_ecad_match_result || '',
      correspondence_ecad_identifier:
        correspondence_address?.correspondence_ecad_identifier || '',
      correspondence_geo_code:
        correspondence_address?.correspondence_geo_code || '',
      correspondence_id: correspondence_address?.correspondence_id || '',
      correspondence_match_level:
        correspondence_address?.correspondence_match_level || '',
      correspondence_match_type:
        correspondence_address?.correspondence_match_type || '',
      correspondence_post_code:
        correspondence_address?.correspondence_post_code ||
        correspondence_address?.eircode,
      correspondence_reference:
        correspondence_address?.correspondence_reference || '',
      correspondence_street_name:
        correspondence_address?.correspondence_street_name || '',
      correspondence_updated_date:
        correspondence_address?.correspondence_updated_date || '',
      modeOfContact: contact_preference || '',
      bankOfIrelandCustomer: is_boi_customer?.toString() || '',
      propertyJointlyOwned:
        additional_policy_holders_details.length > 0 ? 'true' : 'false',
      jointlyOwnedDetails: getAdditionalHolderObject(
        additional_policy_holders_details
      )
    },
    yourCover: {
      rebuildCost: getRebuildCost(),
      contentsCoverOf: getContentsCover(),
      unspecifiedItems: unspecified_personal_items_amount
        ? unspecified_personal_items_amount
        : 0,
      specifiedItems:
        specified_items.length > 0
          ? specified_items
          : [
              {
                item: '',
                value: '',
                description: ''
              }
            ],
      unspecifiedItemsSwitch: unspecified_personal_items_amount ? true : false,
      specifiedItemsSwitch: specified_items.length > 0 ? true : false,
      marketingConsent: marketing_consent,
      marketingConsentEmail: marketings?.email_consent,
      marketingConsentPost: marketings?.post_consent,
      marketingConsentPhone: marketings?.mobile_sms_consent,
      marketingConsentText: marketings?.phone_consent,
      consent: analyse_transactions
    },
    yourHome: {
      coverType: getCoverTypeForDwellingType(
        risk_address.dwelling_type,
        buildings_amount,
        {
          tenantType: nullToEmptyString(
            risk_address.tenanted_property.tenant_type
          ),
          isFamilyUnit: nullToEmptyString(
            risk_address.tenanted_property.family_unit
          ),
          holidayHomeIsRented: nullToEmptyString(
            risk_address.holiday_home.rented
          )
        },
        true
      ),
      isFamilyUnit: nullToEmptyString(
        risk_address.tenanted_property.family_unit
      ),
      numTenants: nullToEmptyString(
        risk_address.tenanted_property.number_of_tenants
      ),
      tenantType: nullToEmptyString(risk_address.tenanted_property.tenant_type),
      occupiedByMember: assumptionIds(23),
      tenancyAgreement: assumptionIds(30),
      privateLiving: assumptionIds(25),
      isInUse: nullToEmptyString(risk_address.holiday_home.furnished),
      holidayHomeIsRented: nullToEmptyString(risk_address.holiday_home.rented),
      isLetMoreThan5PerYear: nullToEmptyString(
        risk_address.holiday_home.rented_num_times
      ),
      homeType: risk_address.home_type || '',
      hasBeenReroofed: risk_address?.has_been_re_roofed || '',
      hasBeenRewired: risk_address?.has_been_re_wired || '',
      yearOfConstruction: risk_address.year_of_construction || '',
      numBedrooms: risk_address.num_bedrooms || '',
      numBathrooms: risk_address.num_bathrooms || '',
      heatingSource: risk_address.heating_type || '',
      alarmType: risk_address.alarm_type || '',
      numberOfSmokeDetectors: risk_address.num_smoke_alarms || '',
      neighbourhoodWatch: risk_address.neighbourhood_watch || '',
      noClaimYears: ncd_num_years !== null ? ncd_num_years : -1,
      claimsValue: claims_details.length === 0 ? 'false' : 'true',
      claimsCount: claims_details.length === 0 ? 0 : claims_details.length,
      claims:
        claims_details.length > 0
          ? claims_details.map(claim => {
              claim.claim_date =
                claim.claim_date !== null
                  ? rawToIrishFormat(claim.claim_date)
                      .split('/')
                      .reverse()
                      .join('-')
                  : '';
              claim.claim_amount = `${claim.claim_amount}`;
              claim.claim_status = `${claim.claim_status}`;
              claim.occurred_at = `${claim.occurred_at}`;
              claim.claim_type = `${claim.claim_type}`;
              return claim;
            })
          : []
    }
  };
}

function getAdditionalHolderObject(additionalHolders) {
  if (additionalHolders.length === 0) return {};
  else {
    const {
      other_title,
      first_name,
      surname,
      title,
      date_of_birth
    } = additionalHolders[0];
    return {
      title,
      otherTitle: other_title || '',
      firstName: first_name,
      lastName: surname,
      dateOfBirth: rawToIrishFormat(date_of_birth)
    };
  }
}
