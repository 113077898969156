/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import { useState } from 'react';

export type Validity = {
  aboutYou: boolean,
  yourHome: boolean,
  yourCover: boolean
};

/**
 * Hook for the stateful logic of Form Details
 * Returns the validity status of the three forms and of all the forms overall,
 * and a submit handler to pass down to the child forms
 * @returns an Array with the validity state as the first element,
 *  the submit handler as the second
 *
 */
const useGetQuotePageValidity = () => {
  const [baseValidity, setBaseValidity] = useState<Validity>({
    aboutYou: false,
    yourHome: false,
    yourCover: false
  });

  const handleFormSubmit = (formName: string, formValid: boolean) => {
    setBaseValidity((bv: Validity) => ({
      ...bv,
      [formName]: formValid
    }));
  };

  const validity = {
    ...baseValidity,
    allForms: !Object.values(baseValidity).includes(false)
  };

  return [validity, handleFormSubmit];
};

export default useGetQuotePageValidity;
