/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './BoiRetrieveCard.scss';

import { CardWrapper, Text } from '@boi/core/lib';
import { faCar, faHome, faPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import {
  SELECTED_HOME_QUOTE_STORAGE,
  SELECTED_MOTOR_QUOTE_STORAGE
} from '../../constants';
import { primaryBlue } from '../../constants/colors';
import { DECLARATION_DETAILS_STORAGE } from '../../constants/sessionStorage';
import { HOME_RESUME_QUOTE } from '../../constants/sessionStorage/homeStorageIdentifiers';
import {
  getObjectFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { routes } from '../../routes';

interface Props {
  userProfile: {
    quote: {
      policy_code: string,
      cover_premium: number
    }
  };
}

interface State {}

class BoiRetrieveCard extends PureComponent<Props, State> {
  static contextType = CognitoContext;

  signUpInsuranceType = () => {
    if (getObjectFromSessionStorage(SELECTED_HOME_QUOTE_STORAGE)) {
      return 'Home';
    } else if (getObjectFromSessionStorage(SELECTED_MOTOR_QUOTE_STORAGE)) {
      return 'Motor';
    } else if (getObjectFromSessionStorage(DECLARATION_DETAILS_STORAGE)) {
      return 'Travel';
    } else {
      return '';
    }
  };

  renderCorrectIcon(insuranceType: string) {
    switch (insuranceType) {
      case 'Home':
        return faHome;
      case 'Motor':
        return faCar;
      case 'Travel':
        return faPlane;
    }
  }

  // eslint-disable-next-line complexity
  render() {
    const className = 'c-BoiRetrieveCard';

    let quoteDetails = this.props.userProfile.quote;
    const { feature } = this.context;

    let insuranceType = this.signUpInsuranceType();

    let tableDetails = {
      policyCode: '',
      premium: ''
    };

    if (quoteDetails) {
      tableDetails.policyCode = quoteDetails.policy_code;
      const { cover_premium } = quoteDetails;
      const numericValue = parseFloat(cover_premium);
      if (!isNaN(numericValue)) {
        const formattedNumber = numericValue.toString().includes('.')
          ? numericValue.toFixed(2)
          : numericValue % 1 !== 0
          ? numericValue.toFixed(1)
          : numericValue.toFixed(2);

        tableDetails.premium = formattedNumber;
      }
    }

    const handleClick = () => {
      saveInSessionStorage(HOME_RESUME_QUOTE, true);
    };

    return (
      <div className={className}>
        <CardWrapper>
          <Link
            to={{
              pathname: `${
                feature.flags.marketingconsent_enabled
                  ? routes.marketingConsent.url
                  : routes.viewQuote.url
              }${insuranceType.toLowerCase()}`
            }}
            className={`${className}__link`}
            data-ga
            id="WalletLanding__resumeQuoteLink"
            data-testid="WalletLanding__resumeQuoteLink"
            onClick={handleClick}
          >
            <div className={`${className}__cardContents`}>
              <div className={`${className}__icon`}>
                <FontAwesomeIcon
                  icon={this.renderCorrectIcon(insuranceType)}
                  style={{ color: primaryBlue }}
                />
              </div>
              <div className={`${className}__cardInformation`}>
                <div className={`${className}__cardText`}>
                  <Text align="left">
                    <b>Continue with your latest {insuranceType} quote</b>
                  </Text>
                </div>
                <div className={`${className}__cardDetails`}>
                  <Text align="left">
                    {/*Quote ref used as display name to stay consistent with emails at request of customer, value presented is correct in being policy code and not quote reference*/}
                    <b>Quote Ref:</b> {tableDetails.policyCode}
                  </Text>
                  <Text align="left">
                    <b className={`${className}__premiumTitle`}>Premium:</b>€
                    {tableDetails.premium}
                  </Text>
                </div>
              </div>
            </div>
          </Link>
        </CardWrapper>
      </div>
    );
  }
}

export default BoiRetrieveCard;
