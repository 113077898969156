/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './WebsiteLoadingPage.scss';

import { BOIGroupLogo } from "@boi/core/lib";
import React from 'react';

import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';

const WebsiteLoadingPage = () => {
  const className = 'c-WebsiteLoadingPage';

  return (<div className={`${className}__pageContainer`}>
    <div className={`${className}__iconAndSpinnerContainer`}>
      <div className={`${className}__logoContainer`}>
        <BOIGroupLogo />
      </div>
      <AnimatedSpinner size="large"></AnimatedSpinner>
    </div>
  </div>);
};

export default WebsiteLoadingPage;
