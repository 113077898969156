/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './YourTravelCoverForm.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionText, ButtonGroup, Checkbox, Label } from '@boi/core/lib';
import { FormikProps } from 'formik';
import React, { useEffect, useMemo } from 'react';

import { commonFormStylesIdentifier } from '../../../constants';
import { isFieldError } from '../../../helpers';
import { allowedDriverAge, irishToISOFormat } from '../../../helpers/DateHelper';
import type { AboutYouFormType, YourTripFormType } from '../../../types';
import { EXCESS_CODES } from './values';

type Values = AboutYouFormType & YourTripFormType;

const CAR_HIRE_ONLY_EUROPE = 'YourTravelCoverForm/CAR_HIRE_ONLY_EUROPE';
const CAR_HIRE_NO_UNDERAGE = 'YourTravelCoverForm/CAR_HIRE_NO_UNDERAGE';
const CAR_HIRE_OK = 'YourTravelCoverForm/CAR_HIRE_OK';

const EUROPE_ZONE_STRING = '1';
const EUROPE_ZONE_NUMBER = 1;

const carHireStatusMessages = {
  [CAR_HIRE_NO_UNDERAGE]:
    'You must be between 21 and 75 years of age to avail of car hire excess',
  [CAR_HIRE_ONLY_EUROPE]:
    'Car hire excess is only available for European destinations',
  [CAR_HIRE_OK]: ''
};

const YourTravelCoverForm = (props: FormikProps<Values>) => {
  const className = 'c-YourTravelCoverForm';
  const { values, setFieldValue, touched, errors } = props;
  const { excess } = values;
  const excessOptions = [
    { label: 'No', value: false },
    { label: 'Yes', value: true }
  ];

  const carHireStatus = useMemo(() => {
    if (!allowedDriverAge(irishToISOFormat(values.dob))) {
      return CAR_HIRE_NO_UNDERAGE;
    }
    if (values.destinationZone !== EUROPE_ZONE_STRING && values.destinationZone !== EUROPE_ZONE_NUMBER) {
      return CAR_HIRE_ONLY_EUROPE;
    }
    return CAR_HIRE_OK;
  }, [values.dob, values.destinationZone]);

  const carHireExcessDisabled = carHireStatus !== CAR_HIRE_OK;
  const carHireExcess = carHireExcessDisabled ? '' : values.carHireExcess;
  const winterSportsExcess = values.winterSportsExcess;
  const businessExpenseExcess = values.businessExpenseExcess;

  useEffect(() => {
    if (carHireExcessDisabled) {
      setFieldValue('carHireExcess', '');
    }
  }, [carHireExcessDisabled]);

  const updateAllowedExcessValue = (
    excessTypeCode: number,
    fieldName: string,
    value: boolean
  ) => {
    const newExcess =
      excess && excess.includes(excessTypeCode)
        ? excess.filter((e: number) => e !== excessTypeCode)
        : [...(excess || []), excessTypeCode];
    setFieldValue('excess', newExcess);
    setFieldValue(fieldName, value);
  };

  const updateExcessValue = (
    excessTypeCode: number,
    fieldName: string,
    value: boolean
  ) => {
    if (
      !(
        excessTypeCode === EXCESS_CODES.CAR_HIRE_EXPENSE &&
        carHireExcessDisabled
      )
    ) {
      updateAllowedExcessValue(excessTypeCode, fieldName, value);
    }
  };

  const showError = () => {
    return carHireExcessDisabled ? (
      <div className={`c-InputErrorMessage`}>
        {carHireStatusMessages[carHireStatus]}
      </div>
    ) : null;
  };

  return (
    <div className={`${className}__container`}>

      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <Label htmlFor="carHireExcess" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label="Car hire excess"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${className}__infoText`}>
              Please note you do not have to purchase the optional extra in order to buy travel insurance. Car Hire
              Excess provides insurance cover for the Care Hire Excess amount if you are renting a car in Europe. Cover
              is capped at €4,000 for any one incident and €5,000 overall within the period of insurance. See the <a
                rel="noopener noreferrer" target="_blank"
                href="https://personalbanking.bankofireland.com/insure-and-protect/insurance/travel-insurance/faqs/">
                FAQ </a> page for more details.
            </div>
          </AccordionText>
        </Label>
        {showError()}
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            name="carHireExcess"
            error={isFieldError('carHireExcess', touched, errors)}
            errorMessage={errors.carHireExcess}
            options={excessOptions}
            onClick={(selectedValue: string) => {
              updateExcessValue(EXCESS_CODES.CAR_HIRE_EXPENSE, "carHireExcess", selectedValue === 'true');
            }}
            spaceBetween={false}
            selected={carHireExcess}
            disabled={carHireExcessDisabled}
          />
        </div>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <Label htmlFor="winterSportsExcess" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label="Winter sports"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${className}__infoText`}>
              Please note you do not have to purchase the optional extra in order to buy travel insurance.
              This covers you if you are going skiing, snowboarding or tobogganing on holiday. You can go off-piste
              too as long as you are accompanied by or under the instruction of a qualified local guide. The winter
              sports cover is limited to a maximum of up to 21 days in any insurance period.
            </div>
          </AccordionText>
        </Label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            name="winterSportsExcess"
            error={isFieldError('winterSportsExcess', touched, errors)}
            errorMessage={errors.winterSportsExcess}
            options={excessOptions}
            onClick={(selectedValue: string) => {
              updateExcessValue(EXCESS_CODES.WINTER_SPORTS_EXPENSE, "winterSportsExcess", selectedValue === 'true');
            }}
            spaceBetween={false}
            selected={winterSportsExcess}
          />
        </div>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainerWithNoMargin`}>
        <Label htmlFor="businessExpenseExcess" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label="Business travel"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${className}__infoText`}>
              Please note you do not have to purchase the optional extra in order to buy travel insurance.
              This extends your policy to cover business trips. This covers you for repair or replacement of lost,
              stolen or damaged business equipment (limited to audio, visual, video, photographic,
              computer equipment and samples). Cover includes hire of replacement business equipment and business
              money lost or stolen up to €1000.
            </div>
          </AccordionText>
        </Label>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <ButtonGroup
            name="businessExpenseExcess"
            error={isFieldError('businessExpenseExcess', touched, errors)}
            errorMessage={errors.businessExpenseExcess}
            options={excessOptions}
            onClick={(selectedValue: string) => {
              updateExcessValue(EXCESS_CODES.BUSINESS_EXPENSE, "businessExpenseExcess", selectedValue === 'true');
            }}
            spaceBetween={false}
            selected={businessExpenseExcess}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <Checkbox
            id="dataProtectionConsent"
            name="dataProtectionConsent"
            label={
              <div className={`${className}__termsAndConditionsText`}>
                Please tick here to confirm you<br />
                <ol type="i" className={`${className}__termsList`}>
                  <li> have read and agree to the
                    {' '}<a target="_blank"
                      rel="noopener noreferrer"
                      href='//boi.com/insurancetraveltou'>
                      Terms of Use
                    </a>, the <span className={`${className}__companyName`}>Bank of Ireland Insurance Services</span>
                    {' '}<a target="_blank"
                      rel="noopener noreferrer"
                      href='//boi.com/insurancetraveltc'>
                      Terms of Business
                    </a>,
                    {' '}<a target="_blank"
                      rel="noopener noreferrer"
                      href='//boi.com/insurancecss'>
                      Commission Summary Document
                    </a> and
                    {' '}<a target="_blank"
                      rel="noopener noreferrer"
                      href='//boi.com/insurancetraveldpn'>
                      Data Protection Notice
                    </a>;
                  </li>
                  <li> agree to receiving all documentation online and understand you can request paper copies at any
                  time by contacting us.
                  </li>
                </ol>
              </div>
            }
            isChecked={values.dataProtectionConsent}
            onChange={() => setFieldValue('dataProtectionConsent', !values.dataProtectionConsent)}
            value={values.dataProtectionConsent}
          />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <Checkbox
            id="chubbTOBConsent"
            name="chubbTOBConsent"
            label={
              <div className={`${className}__termsAndConditionsText`}>
                Please tick to confirm you have read and accept in full the <b>Chubb</b> <a
                  href="https://boi.com/insurancetravelctc" target="_blank"
                  rel="noopener noreferrer">Terms of Business</a> and <a
                    href="https://chubb.com/ie-en/footer/privacy-policy.aspx" target="_blank"
                    rel="noopener noreferrer">Privacy Policy</a> before proceeding
              </div>
            }
            isChecked={values.chubbTOBConsent}
            onChange={() => setFieldValue('chubbTOBConsent', !values.chubbTOBConsent)}
            value={values.chubbTOBConsent}
          />
        </div>
      </div>
    </div>
  );
};

export default YourTravelCoverForm;
