/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import CallUsPage from '../../components/CallUsPage/CallUsPage';
import { iconsAndTitles } from '../../constants';
import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { carinsurancePhoneNumber,insurancePhoneNumber } from '../../constants/phoneNumbers'

const getInsuranceNumber = (insuranceType: string) => insuranceType === CAR_INSURANCE_TYPE ? carinsurancePhoneNumber : insurancePhoneNumber;

const RenewPolicyPage = ({ match, history }: { match: Match, history: RouterHistory }) => {
  const insuranceType = match.params.type || '';


  return <CallUsPage
    history={history}
    icon={insuranceType && iconsAndTitles[insuranceType].icon}
    title="Renew"
    contentText="If you wish to renew your policy please call us on"
    phoneNumber={getInsuranceNumber(insuranceType)}
  />
};

export default withRouter(RenewPolicyPage);
