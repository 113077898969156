"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var doveGrayColor = exports.doveGrayColor = '#6b6d6f';
var dividerColor = exports.dividerColor = '#c0c1c3';
var whiteColor = exports.whiteColor = '#FFFFFF';
var blueNotification = exports.blueNotification = '#00f';
var darkOrange = exports.darkOrange = '#ef6c00';
var offWhite = exports.offWhite = '#fafbfc';
var deepBlue = exports.deepBlue = '#00f';
var black = exports.black = '#000';
var mediumGray = exports.mediumGray = '#999';
var fontColorSecondary = exports.fontColorSecondary = '#6e7881';
var green = exports.green = '#6db841';