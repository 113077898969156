const employmentStatusValue = [
  {
    displayName: 'Housewife/Husband',
    dropdownValue: 'OCC143',
  },
  {
    displayName: 'Employed',
    dropdownValue: 'EMP',
  },
  {
    displayName: 'Retired',
    dropdownValue: 'OCC234',
  },
  {
    displayName: 'Student',
    dropdownValue: 'OCC260',
  },
  {
    displayName: 'Self Employed',
    dropdownValue: 'SEF',
  },
  {
    displayName: 'Unemployed',
    dropdownValue: 'UAC',
  }
];

export default employmentStatusValue;
