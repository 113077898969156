/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */


import { AnimatedSpinner } from '@boi/core/lib';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import { whiteColor } from '../../../constants/colors';
import { TRAVEL_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { routes } from '../../../routes';
import { getTravelResources } from '../../../services/travel/travelResourcesServices';
import type { RouterPropsType } from '../../../types/commonTypes';
import { TRIP_TYPES } from './values';
import YourTravelForm from './YourTravelForm';
import type { TravelResourcesType, TripType, ZoneForTripTypeMap } from './YourTravelTypes';

const className = 'c-YourTravelForm';

const YourTravelFormWrapper = (props: RouterPropsType & FormikProps) => {
  const { history } = props;
  const [resources, setResources] = useState(null);

  useEffect(() => {
    getTravelResources(props).then((res: TravelResourcesType) => {
      res.trip_types ? setResources(res) :
        /* If the trip types haven't been loaded correctly we can't proceed with
           the journey so redirect the user to the error page */
        history.push(`${routes.genericErrorPage.url}${TRAVEL_INSURANCE_TYPE}`);
    });
  }, []);

  if (!resources) return <div className={`${className}__spinnerContainer`}><AnimatedSpinner backgroundColor={whiteColor} /></div>;

  const getZonesForTripType = (tripTypes: Array<TripType>) => {
    return tripTypes.reduce((map: ZoneForTripTypeMap, tripType: TripType) => {
      map[tripType.id.toString()] = tripType.destination_zones.map((zone: { id: string, value: string }) => ({
        value: zone.id,
        label: zone.value
      }));
      return map;
    }, {});
  };

  const getTripTypeOptions = (tripTypes: Array<TripType>) => {
    return tripTypes.map((type: TripType) => ({
      value: type.id,
      label: TRIP_TYPES[type.name]
    }));
  };

  return (
    <YourTravelForm
      {...props}
      zonesForTripType={getZonesForTripType(resources.trip_types)}
      tripTypeOptions={getTripTypeOptions(resources.trip_types)}
      destinationCountryList={resources.destination_country_list}
    />
  );
};

export default YourTravelFormWrapper;
