/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './LegalTextHomeDirectDebit.scss';

import { Checkbox } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import React from 'react';

import { INSURER_TYPE } from '../../constants/home'
import { AuthoriseContentFBD, AuthoriseContentRSA, AuthoriseContentRSACar, LegalTextContentFBD, LegalTextContentRSA, LegalTextContentRSACar, YourRightsContentFBD, YourRightsContentRSA, YourRightsContentRSACar } from './legalTextDirectDebitContent';

interface Props {
  values: { directDebitLegalText: string };
  setFieldValue: noop;
  insurerType: string;
  insurerMode: string;
}

let LegalTextContent, AuthoriseContent, YourRightsContent;

const className = 'c-LegalTextDirectDebit';

const checkboxLabelFontSize = 16;

const LegalTextHomeDirectDebit = (props: Props) => {
  const { values, setFieldValue, insurerType, insurerMode='home' } = props;

  switch(insurerMode) {
    case 'home':
      if (insurerType === INSURER_TYPE.RSA) {
        LegalTextContent = LegalTextContentRSA
        AuthoriseContent = AuthoriseContentRSA
        YourRightsContent = YourRightsContentRSA
      } else {
        LegalTextContent = LegalTextContentFBD
        AuthoriseContent = AuthoriseContentFBD
        YourRightsContent = YourRightsContentFBD
      }
      break;
    case 'car': 
        LegalTextContent = LegalTextContentRSACar
        AuthoriseContent = AuthoriseContentRSACar
        YourRightsContent = YourRightsContentRSACar
      break;
  }

  return (
    <div className={`${className}`}>
      <div className={`${className}__checkbox`}>
        <Checkbox
          id="directDebitLegalText"
          name="directDebitLegalText"
          label={LegalTextContent}
          labelFontSize={checkboxLabelFontSize}
          isChecked={values.directDebitLegalText}
          onChange={() => setFieldValue('directDebitLegalText', !values.directDebitLegalText)}
          value={values.directDebitLegalText}
        />
      </div>
      <div className={`${className}__text`}>
        {AuthoriseContent}
      </div>
      <div className={`${className}__text`}>
        {YourRightsContent}
      </div>
    </div>
  );
};


export default LegalTextHomeDirectDebit;
