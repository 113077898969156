export const BUTTONTEXT = 'Match';

export const SEARCH_USER_CONSTANT = ['FirstName', 'LastName', 'Email'];
export const OPTION_SEARCH_USER_CONSTANT = [
  'firstName',
  'lastName',
  'email',
  'branch'
];
export const TITLES_TO_SHOW = SEARCH_USER_CONSTANT.length - 1;

export const EMAIL = 'email';
export const FIRSTNAME = 'firstName';
export const LASTNAME = 'lastName';

export const LABELMAPING = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  branch: 'Branch'
};

export const PAYLOADVALUE = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email'
};

export const PLACEHOLDERMAPING = {
  firstName: 'Enter first name',
  lastName: 'Enter last name',
  email: 'Enter email address',
  branch: 'Select branch'
};

export const ERROR_CODE =
  'The portfolio supplied has already been registered for the customer portal.';

export const COMMON_ERROR_TEXT = 'Answer is Required';

export const INVALID_PORTFOLIO_CODE_FORMAT =
  'Invalid portfolio code format. The portfolio code must follow the format: 4 UPPERCASE letters followed by 2 numbers';
export const INVALID_PHONE_NUMBER_FORMAT =
  "Please enter a valid phone number starting with '08' followed by 8 digits or starting with '+3538' followed by 8 digits";

export const PHONE_NUMBER_REGEX = /^(08\d{8}|\+3538\d{8})$/;
export const PORTFOLIO_CODE_REGEX = /^[A-Z]{4}\d{2}$/;

export const ERROR_TEXT = `The user you have entered has not been found. Please check the following, and try again:
                         1) Customers name(s) and or email have been spelt correctly, please use contact centre to confirm.
              2) Confirmation that the customer has been registered for the Digital Insurance Wallet.`;

export const TOOLTIP =
  'Click on the edit icon next to the mobile number in order to change the mobile number used for Multi Factor Authentication';
