/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { ButtonGroup, Dropdown } from '@boi/core/lib';
import { connect, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { driverLicenseTypeValues, lastFiveYearsDrivingExperienceValues, yearsHeldValues } from '../../../../../constants/dropdowns';
import { getRepeatingFieldName, isFieldError } from '../../../../../helpers';
import { mapDropdownOptions } from '../../../../../helpers/MapDropdownOptions';
import { InsuranceTypeContext } from '../../../../../pages/GetGenericQuotePage';
import { inFormStructure } from '../../../../../pages/GetGenericQuotePage/getQuoteFormStructure';
import { aboutYouFormIdentifier } from '../../../../Forms/CommonFiles/commonFormConstants';

interface Props {
  fieldNamePrefix?: string,
  isSubFormComponent?: boolean,
  hideFieldsList?: Array<string>
}

const FULL_UK_DRIVING_LICENSE_CONSTANT = 'FULL_UK';
const FULL_IRISH_DRIVING_LICENSE_CONSTANT = 'FULL_IRISH';

export const BaseDrivingDetails = (props: Props & FormikProps) => {
  const insuranceType = useContext(InsuranceTypeContext);

  const className = 'c-DrivingDetails';
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    touched,
    values,

  } = props.formik;

  const { isSubFormComponent = false } = props;

  const { fieldNamePrefix, hideFieldsList = [] } = props;

  const fieldInHideFieldsList = (fieldName: string) => {
    return hideFieldsList.includes(fieldName);
  };

  const hasFullIrishOrFullUk = () => {
    return values.driversLicenseType === FULL_UK_DRIVING_LICENSE_CONSTANT || values.driversLicenseType === FULL_IRISH_DRIVING_LICENSE_CONSTANT;
  };

  const getFieldActiveClassNames = (isDynamicField: boolean) => {
    const classNames: Array<string> = [`${commonFormStylesIdentifier}__fieldContainer`];
    if (isDynamicField) {
      classNames.push(isSubFormComponent ? `${commonFormStylesIdentifier}__dynamicFieldsContainer--with-no-padding` : `${commonFormStylesIdentifier}__dynamicFieldsContainer`);
    } else if (!isSubFormComponent) {
      classNames.push(`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`);
    }
    return classNames.join(' ');
  };

  const getPassedTestInIrelandOrUkFields = () => {
    const passedTestField = 'passedTestInIrelandOrUk';
    return (
      hasFullIrishOrFullUk() &&
        !fieldInHideFieldsList(passedTestField) &&
        inFormStructure(passedTestField, insuranceType, aboutYouFormIdentifier) ?
        <div className={getFieldActiveClassNames(true)}>
          <label htmlFor={getRepeatingFieldName(passedTestField, fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Did you pass your test in Ireland or the UK
          </label>
          <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
            <ButtonGroup
              name={getRepeatingFieldName(passedTestField, fieldNamePrefix)}
              onClick={(value: boolean) => {
                setFieldValue(getRepeatingFieldName(passedTestField, fieldNamePrefix), value);
                setFieldTouched(getRepeatingFieldName(passedTestField, fieldNamePrefix), true);
              }}
              options={[
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ]}
              value={values.passedTestInIrelandOrUk}
              touched={touched.passedTestInIrelandOrUk}
              selected={values.passedTestInIrelandOrUk}
              error={isFieldError('passedTestInIrelandOrUk', touched, errors)}
              errorMessage={errors.passedTestInIrelandOrUk}
            />
          </div>
        </div> : null
    );
  };

  return (
    <div className={className}>
      {inFormStructure('driversLicenseType', insuranceType, aboutYouFormIdentifier) &&
        <div className={getFieldActiveClassNames(false)}>
          <label htmlFor={getRepeatingFieldName("driversLicenseType", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Drivers Licence Type
          </label>
          <Dropdown
            placeholder="Select here"
            error={isFieldError('driversLicenseType', touched, errors)}
            errorMessage={errors.driversLicenseType}
            name={getRepeatingFieldName("driversLicenseType", fieldNamePrefix)}
            value={values.driversLicenseType}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Select here</option>
            {mapDropdownOptions(driverLicenseTypeValues, 'driver_license_type')}
          </Dropdown>
        </div>
      }
      {getPassedTestInIrelandOrUkFields()}
      {inFormStructure('lastFiveYearsExperience', insuranceType, aboutYouFormIdentifier) &&
        <div className={getFieldActiveClassNames(false)}>
          <label htmlFor={getRepeatingFieldName("lastFiveYearsExperience", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Where is your driving experience in the last 5 years
          </label>
          <Dropdown
            placeholder="Select here"
            error={isFieldError('lastFiveYearsExperience', touched, errors)}
            errorMessage={errors.lastFiveYearsExperience}
            name={getRepeatingFieldName("lastFiveYearsExperience", fieldNamePrefix)}
            value={values.lastFiveYearsExperience}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Select here</option>
            {mapDropdownOptions(lastFiveYearsDrivingExperienceValues, 'last_five_years_driving_experience')}
          </Dropdown>
        </div>
      }
      {inFormStructure('yearsHeld', insuranceType, aboutYouFormIdentifier) &&
        <div className={getFieldActiveClassNames(false)}>
          <label htmlFor={getRepeatingFieldName("yearsHeld", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Years Held
          </label>
          <Dropdown
            placeholder="Select here"
            error={isFieldError('yearsHeld', touched, errors)}
            errorMessage={errors.yearsHeld}
            name={getRepeatingFieldName("yearsHeld", fieldNamePrefix)}
            value={values.yearsHeld}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Select here</option>
            {mapDropdownOptions(yearsHeldValues, 'yearsHeld')}
          </Dropdown>
        </div>
      }
    </div >
  );
};

BaseDrivingDetails.propTypes = {
  formik: PropTypes.object
};

const DrivingDetails = connect(BaseDrivingDetails);

export default DrivingDetails;
