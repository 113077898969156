const carTitleValues = [
  {
    displayName: 'Mr.',
    dropdownValue: 'MR'
  },
  {
    displayName: 'Mrs.',
    dropdownValue: 'MRS'
  },
  {
    displayName: 'Miss.',
    dropdownValue: 'MISS'
  },
  {
    displayName: 'Ms.',
    dropdownValue: 'MS'
  }
];

export default carTitleValues;