/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './CallUsPage.scss';

import {
  Button,
  CardWrapper,
  PhoneButton,
  TitleWithUnderLine
} from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import React from 'react';
import type { RouterHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { backToHome } from '../../constants/buttonLabelConstants';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { routes } from '../../routes';
import { MobileBackButton } from '../MobileBackButton';

type Props = {
  icon: noop,
  contentText?: string,
  phoneNumber: string,
  title: string,
  history: RouterHistory
};

const CallUsPage = ({
  icon,
  title,
  contentText,
  phoneNumber,
  history
}: Props) => {
  const className = 'c-CallUsPage';

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <CardWrapper
        alignItemsCenter={false}
        className={`${className}__CardWrapper`}
      >
        {icon ? (
          <div className={`${className}__iconContainer`}>
            <div className={`${className}__titleText`}>
              <TitleWithUnderLine>{title}</TitleWithUnderLine>
            </div>
            <div className={`${className}__icon`}>{icon}</div>
          </div>
        ) : (
          <></>
        )}
        <div className={`${className}__contentContainer`}>
          <div className={`${className}__content`}>{contentText}</div>
          <PhoneButton phoneNumber={phoneNumber}>Call Us</PhoneButton>
          <div className={`${className}__buttonContainer`}>
            <Link to={routes.wallet.url}>
              <Button fluid={true}>{backToHome}</Button>
            </Link>
          </div>
        </div>
      </CardWrapper>
    </div>
  );
};

export default CallUsPage;
