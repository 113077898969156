/* eslint-disable complexity */
import { HOME_COVER_TYPE, MODE_OF_CONTACT_ONLINE, OTHER_TITLE_CONSTANT, QUOTE_REFERENCE, STAFF_CONSTANT } from '../../constants/home';
import { getObjectFromSessionStorage } from '../../helpers';
import { rebuildPartOfQuote } from '../../helpers/BuildingCostHelper';
import { getValuesDependingOnCoverType } from '../../helpers/CoverTypeObject';
import { addZeroHoursToMakeCorrectFormat, getCurrentDateAndTime, irishToRawFormat } from '../../helpers/DateHelper';

const isNotEmptyValues = (aboutYouObj, yourHomeObj, yourCoverObj) =>
  aboutYouObj !== undefined &&
  yourHomeObj !== undefined &&
  yourCoverObj !== undefined;

export const mapGetQuote = (aboutYouObj, yourHomeObj, yourCoverObj, assumptions, extraQuestionsValues) => {
  if (isNotEmptyValues(aboutYouObj, yourHomeObj, yourCoverObj)) {
    const {
      title,
      otherTitle,
      firstName,
      lastName,
      dob,
      email,
      phoneNo,
      addressLine1,
      addressLine2,
      town,
      county,
      eircode,
      correspondence_addressLine1,
      correspondence_addressLine2,
      correspondence_town,
      correspondence_county,
      correspondence_eircode,
      correspondence_geo_code,
      correspondence_match_level,
      correspondence_match_type,
      correspondence_ecad_identifier,
      correspondence_ecad_match_result,
      correspondence_street_name,
      correspondence_reference,
      correspondence_lookup_response,
      is_correspondence_address,
      // employmentStatus,
      mortgageProvider,
      mortgageApplicationNumber,
      promoCode,
      staffNumber,
      boiMortgage,
      boiPersonalLoan,
      boiMotorInsurance,
      boiPension,
      bankOfIrelandCustomer,
      firstTimeBuyer,
      geo_code,
      match_level,
      match_type,
      ecad_identifier,
      ecad_match_level,
      ecad_match_result,
      street_name,
      reference,
      lookup_response,
      propertyJointlyOwned,
      jointlyOwnedDetails,
      userName                          //honeypot hidden field for bots
    } = aboutYouObj;

    const {
      coverType,
      isFamilyUnit,
      numTenants,
      tenantType,
      tenancyAgreement,
      numBedrooms,
      numBathrooms,
      heatingSource,
      yearOfConstruction,
      hasBeenReroofed,
      hasBeenRewired,
      alarmType,
      numberOfSmokeDetectors,
      homeType,
      claims,
      isInUse,
      holidayHomeIsRented,
      isLetMoreThan5PerYear,
      occupiedByMember,
      noClaimYears,
      no_claim_discount_cover = false,
      contents_accidental_damage_cover  = false,
      home_emergency_assist_cover = false,
      privateLiving
    } = yourHomeObj;

    const {
      policyStart,
      rebuildCost,
      contentsCoverOf,
      acceptedTermsAndConditions,
      userAgreement,
      unspecifiedItems,
      specifiedItems,
      specifiedItemsSwitch,
      unspecifiedItemsSwitch,
      marketingConsent = false,
      marketingConsentEmail,
      marketingConsentPost,
      marketingConsentPhone,
      marketingConsentText,
      consent
    } = yourCoverObj;


    const {
      roof_tile,
      listed_or_protected,
      operational_locks,
      un_occupied
    } = extraQuestionsValues

    let { dwelling_type, residence_type } = getValuesDependingOnCoverType(yourHomeObj);

    const getBuildingCost = () => {
      if (
        rebuildPartOfQuote(homeType, coverType) &&
        rebuildCost &&
        rebuildCost !== ''
      ) {
        return {
          buildings_amount: Number(rebuildCost.replace(/,/gi, ''))
        };
      }

      return {};
    };

    const getCoverCost = () => {
      if (
        contentsCoverOf &&
        contentsCoverOf !== ''
      ) {
        return {
          contents_amount: Number(contentsCoverOf.replace(/,/gi, ''))
        };
      }

      return {};
    };

    const formatClaims = claims => {
      const formattedClaims = [];
      claims.forEach(claim => {
        const amount = claim.claim_amount ? Number(claim.claim_amount.replace(/,/gi, '')) : 0;
        const newClaim = {
          claim_type: claim.claim_type,
          claim_date: addZeroHoursToMakeCorrectFormat(claim.claim_date),
          claim_status: claim.claim_status,
          claim_amount: amount,
          occurred_at: claim.occurred_at
        };
        formattedClaims.push(newClaim);
      });
      return formattedClaims;
    };

    const getHolidayHome = () => {
      if (residence_type === 'Holiday') {
        return {
          holiday_home: {
            furnished: isInUse,
            rented: holidayHomeIsRented,
            rented_num_times: isLetMoreThan5PerYear === "" ? "false" : isLetMoreThan5PerYear
          }
        };
      }

      return {};
    };

    const getTenantedProperty = () => {
      if (residence_type === "Landlord") {
        return {
          tenanted_property: {
            family_unit: isFamilyUnit,
            number_of_tenants: isFamilyUnit === false ? Number(numTenants) : null,
            tenant_type: isFamilyUnit === true ? "" : tenantType || 'NonStudents',
            tenancy_agreement: tenancyAgreement,
            occupied_by_member: occupiedByMember
          }
        };
      }

      return {};
    };

    const getYearOfBuildRelated = () => {
      let result = {}
      if (yearOfConstruction <= 1970 && yearOfConstruction > 1500) {
        result = { has_been_re_wired: hasBeenRewired }
      }
      if (yearOfConstruction <= 1920 && yearOfConstruction > 1500) {
        result = { ...result, has_been_re_roofed: hasBeenReroofed }
      }
      return result
    }

    const claimDetailsArray = claims ? formatClaims(claims) : [];
    const hasClaims = claimDetailsArray.length > 0;

    const additionalPolicyHoldersDetails =
      propertyJointlyOwned === 'true'
        ? [
          {
            title: jointlyOwnedDetails.title,
            other_title: jointlyOwnedDetails.title=== OTHER_TITLE_CONSTANT ? jointlyOwnedDetails.otherTitle : "",
            first_name: jointlyOwnedDetails.firstName,
            surname: jointlyOwnedDetails.lastName,
            date_of_birth: addZeroHoursToMakeCorrectFormat(
              irishToRawFormat(jointlyOwnedDetails.dateOfBirth)
            )
          }
        ]
        : [];

    const addAssumptionIds = () => {
      switch(true) {
        case coverType === HOME_COVER_TYPE.OWN_LIVE || coverType === HOME_COVER_TYPE.RENT :
          assumptions.unshift({code: 23, response: occupiedByMember});
          return assumptions;
        case coverType === HOME_COVER_TYPE.OWN_RENT:
          assumptions.unshift({code: 25, response: privateLiving});
          assumptions.unshift({code: 30, response: tenancyAgreement});
          return assumptions;
        default:
          return assumptions;
      }
    }

    const removeElementsFromPayload = () =>{
      if(is_correspondence_address) {
        delete mapQuoteObj.home_quote.policy_holder_details.correspondence_address
      }
      if(dwelling_type !== '') {
        const coverTypeObj = { coverType, isFamilyUnit, tenantType, holidayHomeIsRented}
        dwelling_type = getValuesDependingOnCoverType(coverTypeObj).dwelling_type;
      }
       if(!(coverType === HOME_COVER_TYPE.OWN_LIVE || coverType === HOME_COVER_TYPE.RENT)){
        delete mapQuoteObj.home_quote.cover_details.specified_items
        delete mapQuoteObj.home_quote.cover_details.unspecified_personal_items_amount
      }
    }

    const getPolicyStart = () => addZeroHoursToMakeCorrectFormat(policyStart ? policyStart : getCurrentDateAndTime());

    const getValue = code => code || "";


    const getSpecifiedItemValueAsNumber = (itemValue) => {
      if (typeof itemValue === 'string') {
        const strippedValue = itemValue !== '' ? itemValue.replace(/,/g, ``) : '0';
        return parseInt(strippedValue, 10);
      }
      return itemValue;
    };

    const formatSpecifiedItems = (specifiedItems) => {
      if (specifiedItems.length === 0) return []
      if (specifiedItems[0].item === '') return [];
      return specifiedItems.map((item) => {
        item.value = getSpecifiedItemValueAsNumber(item.value);
        return item;
      });
    };

    const getQuoteReference = () => {
      const referenceData = getObjectFromSessionStorage(QUOTE_REFERENCE)
      if(Object.keys(referenceData).length > 0) {
          return {
            quote_reference: referenceData
          } 
      }
    }


    const mapQuoteObj = {
      product: 'Blue Grizzly Object Model',
      version: '0.1',
      honeypot_value: userName,
      marketing_consent: marketingConsent,
        marketings: {
          email_consent: marketingConsent ? marketingConsentEmail === "" ? false : marketingConsentEmail : false,
          post_consent: marketingConsent ? marketingConsentPost === "" ? false : marketingConsentPost : false,
          mobile_sms_consent: marketingConsent ? marketingConsentText === "" ? false : marketingConsentText : false,
          phone_consent: marketingConsent ? marketingConsentPhone === "" ? false : marketingConsentPhone : false
        },
      analyse_transactions: marketingConsent ? consent : false,
      home_quote: {
        carrier_id: '0012J',
        created_by: '',
        channel: 'web',
        promotion_code: getValue(promoCode),
        staff_number: promoCode.toLowerCase() === STAFF_CONSTANT ? getValue(staffNumber) : "",
        policy_holder_details: {
          title: title,
          other_title: title === OTHER_TITLE_CONSTANT ? getValue(otherTitle) : "",
          first_name: firstName,
          last_name: lastName,
          email: email,
          date_of_birth: irishToRawFormat(dob),
          mobile_phone: phoneNo.toString(),
          home_phone: phoneNo.toString(),
          work_phone: phoneNo.toString(),
          // employment_status: employmentStatus,
          previous_insurer: 'Other', //other defaulted after speaking to Mandy
          primary_address: {
            is_correspondence_address: false,
            address_line1: addressLine1,
            address_line2: addressLine2,
            address_line3: town,
            town: town,
            post_code: eircode,
            county,
            eircode,
            geo_code,
            match_level,
            match_type,
            ecad_identifier,
            ecad_match_level,
            ecad_match_result,
            street_name,
            reference,
            lookup_response: !lookup_response ? '' : lookup_response
              .replace(/&#60;/gi, '<')
              .replace(/&#62;/gi, '>'),
          },
          correspondence_address: {
            is_correspondence_address: !is_correspondence_address,
            address_line1: correspondence_addressLine1,
            address_line2: correspondence_addressLine2,
            town: correspondence_town,
            post_code: correspondence_eircode,
            county: correspondence_county,
            eircode: correspondence_eircode,
            geo_code: correspondence_geo_code,
            match_level: correspondence_match_level,
            match_type: correspondence_match_type,
            ecad_identifier: correspondence_ecad_identifier,
            ecad_match_level: correspondence_match_level,
            ecad_match_result: correspondence_ecad_match_result,
            street_name: correspondence_street_name,
            reference: correspondence_reference,
            lookup_response: !correspondence_lookup_response  ? '' : correspondence_lookup_response
              .replace(/&#60;/gi, '<')
              .replace(/&#62;/gi, '>'),
          },
          user_agreement: userAgreement,
          data_protection_consent: acceptedTermsAndConditions,
          assumption_confirmation: acceptedTermsAndConditions,
          mortgage_reference: mortgageApplicationNumber,
          has_boi_mortgage: boiMortgage === '' ? false : boiMortgage,
          has_boi_personal_loan: boiPersonalLoan === '' ? false : boiPersonalLoan,
          has_boi_motor_insurance: boiMotorInsurance === '' ? false : boiMotorInsurance,
          has_boi_pension: boiPension === '' ? false : boiPension,
          is_boi_customer: bankOfIrelandCustomer === '' ? false : bankOfIrelandCustomer,
          first_time_buyer: firstTimeBuyer,
          mortgage_provider: mortgageProvider !== '1' ? mortgageProvider : null
        },
        cover_details: {
          start_date: getPolicyStart(),
          premium: 270,
          ...getBuildingCost(),
          ...getCoverCost(),
          excess_amount: 500,
          contact_preference: MODE_OF_CONTACT_ONLINE,
          contents_accidental_damage_cover: contents_accidental_damage_cover,
          no_claim_discount_cover : no_claim_discount_cover,
          home_emergency_assist_cover: home_emergency_assist_cover,
          ncd_num_years: noClaimYears >= 0 ? noClaimYears : undefined,
          additional_policy_holders_details: additionalPolicyHoldersDetails,
          unspecified_personal_items_amount: unspecifiedItemsSwitch === true ? unspecifiedItems  : 0,
          specified_items: specifiedItemsSwitch === true ? formatSpecifiedItems(specifiedItems) : [],
          mortice_locks: 'yes', //Mandy confirmed hardcoded
          risk_address: {
            address_line1: addressLine1,
            address_line2: addressLine2,
            address_line4: county,
            town: town,
            post_code: eircode,
            num_bedrooms: Number(numBedrooms),
            num_bathrooms: Number(numBathrooms),
            year_of_construction: yearOfConstruction,
            construction_type: 'Standard Construction', //default always expected
            is_occupied: 'yes', //makes no difference on premium value - amanda
            alarm_type: alarmType,
            // BMIW-926 Neighbourhood watch should not be displayed to users
            neighbourhood_watch: numberOfSmokeDetectors >= 2 ? 'Yes' : 'No',
            num_smoke_alarms: numberOfSmokeDetectors,
            home_type: homeType,
            dwelling_type: dwelling_type,
            mortgage_discount: 'false',
            roof_tile: roof_tile,
            listed_or_protected: listed_or_protected,
            operational_locks: operational_locks,
            un_occupied: un_occupied,
            heating_type: heatingSource,
            ...getTenantedProperty(),
            ...getHolidayHome(),
            ...getYearOfBuildRelated()
          },
          has_claims: hasClaims.toString(),
          claims_details: claimDetailsArray
        },
        assumptions: addAssumptionIds(),
        ...getQuoteReference()
      }
    };

    removeElementsFromPayload();
    return mapQuoteObj
  }
  return {};
};
