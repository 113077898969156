// @flow
import './PaymentFrequency.scss';

import { ButtonGroup } from "@boi/core/lib";
import { FormikProps } from 'formik';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants/styleConstants';

const className = 'c-PaymentsFrequency'; // TODO refactor class name

// eslint-disable-next-line complexity
const PaymentFrequency = (props: FormikProps<{ paymentFrequency: string, renewalRoute: boolean }>) => {
  const { setFieldValue, paymentOptions, values } = props;


  const paymentView = () => {
    return (<>
      <div className={`${className}__buttonGroupLabelMargin`}>
        <label htmlFor="paymentFrequency" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Choose your payment type
        </label>
      </div>
      <span className={`${className}__input`}>
              <ButtonGroup
                name="paymentFrequency"
                onClick={(e: string) =>
                  setFieldValue('paymentFrequency', e)
                }
                options={paymentOptions}
                // eslint-disable-next-line react/prop-types
                selected={values.paymentFrequency}
              />
      </span>     
    </>)
  }

  return (
    <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__paddedContainer`}>
      {paymentView()}
    </div>
  );
};

export default PaymentFrequency;
