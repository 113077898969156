/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import * as yup from "yup";

export const yourCarFormYupSchema = {
  isManualSearch: yup
    .boolean()
    .required(),
  carReg: yup
    .string()
    .when('isManualSearch', {
      is: false,
      then: yup.string()
        .required('Car registration is required')
    }),
    carRisk: yup
    .string(),
  carMake: yup
    .string()
    .required('Car make is required'),
  carModel: yup
    .string()
    .required('Car model is required'),
  carYear: yup
    .string()
    .required('Car year is required'),
  carFuelType: yup
    .string()
    .required('Car fuel type is required'),
  carEngineSize: yup
    .string()
    .required('Car engine size is required'),
  carVersion: yup
    .string()
    .required('Car version is required'),
  carABICode: yup
    .string()
    .when('isManualSearch', {
      is: true,
      then: yup.string()
        .required('Car registration is required')
    }),
  carPurchased: yup
    .string()
    .required('Car purchased date is required'),
  vehicleValue: yup
    .string()
    .required('Vehicle value is required'),
  carUsedForWork: yup
    .string()
    .required('If car used for work required'),
  annualKm: yup
    .string()
    .required('Annual mileage is required'),
  drivingExpType: yup
    .string()
    .required('Driving experience is required'),
  noClaimsBonus: yup
    .string()
    .required('No claims bonus is required')
};

export default yourCarFormYupSchema;
