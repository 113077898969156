/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './QuoteOverview.scss';

import { Button } from '@boi/core/lib';
import { Text } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import React from 'react';

import ViewQuoteLogo from '../../pages/ViewQuote/ViewQuoteLogo';
import QuoteInfo from '../QuoteInfo';

interface Props {
  quoteTitle: string;
  quoteAmount: number;
  onMoreInfoClick: noop;
  quoteFeatures: Array<{ label: () => Node, tick: boolean, header: boolean }>;
  showUpToFeature: number;
  showBoi: boolean;
  underwritten: string;
  underwrittenClass: string;
}

const className = 'c-QuoteOverview';

const QuoteOverview = (props: Props) => {
  const {
    quoteFeatures,
    showUpToFeature,
    quoteTitle,
    quoteAmount = 0,
    onMoreInfoClick,
    showBoi = false,
    underwritten = '',
    underwrittenClass
  } = props;
  return (
    <div className={className}>
      <div className={`${className}__quoteTitle`}>
        <Text className={`${className}__quoteTitleFont`}>{quoteTitle}</Text>
      </div>
      {showBoi && (
        <div className={`${className}__quoteLogo`}>
          <ViewQuoteLogo />
        </div>
      )}
      {underwritten && (
        <span className={`${className}__underwrittenText`}>
          Underwritten by{' '}
          <img
            className={`${className}__${underwrittenClass}`}
            src={underwritten}
          />
        </span>
      )}
      <div className={`${className}__featureListContainer`}>
        <QuoteInfo
          quoteFeatures={quoteFeatures}
          showUpToFeature={showUpToFeature}
          showReadMoreOption={true}
        />
      </div>
      <div className={`${className}__moreInfoContainer`}>
        <div className={`${className}__quoteAmountContainer`}>
          <div>Your Quote is</div>
          <div className={`${className}__totalAmountText`}>
            <span className={`${className}__euroSymbolText`}>€</span>
            {quoteAmount.toFixed(2)}
          </div>
        </div>
        <div className={`${className}__moreInfoButtonContainer`}>
          <Button
            onClick={onMoreInfoClick}
            style={{ minWidth: '10px', minHeight: '10px', height: '40px' }}
            id={`QuoteOverview__${underwrittenClass}__moreInfoButton`}
            data-testid={`QuoteOverview__${quoteTitle}__moreInfoButton`}
          >
            Select
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuoteOverview;
