"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _colors = require("../../../constants/colors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CarIcon = function CarIcon(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? _colors.fontColorSecondary : _ref$color,
      _ref$solid = _ref.solid,
      solid = _ref$solid === void 0 ? true : _ref$solid;
  var style = solid ? {
    fill: color,
    enableBackground: 'new 0 0 63 63'
  } : {
    fill: 'none',
    stroke: color,
    strokeWidth: '1.5px'
  };
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 47.473 35.604",
    style: style
  }, _react2.default.createElement("path", {
    className: "a",
    d: "M46.363,74.385H40.812l-1.543-3.857A10.332,10.332,0,0,0,29.628,64H17.852A10.335,10.335,0,0,0,8.21,70.528L6.667,74.385H1.117A1.112,1.112,0,0,0,.038,75.767l.556,2.225a1.112,1.112,0,0,0,1.079.843H3.534a5.893,5.893,0,0,0-2.047,4.451v4.451A5.886,5.886,0,0,0,2.97,91.624v5.013A2.967,2.967,0,0,0,5.937,99.6H8.9a2.967,2.967,0,0,0,2.967-2.967V93.671H35.608v2.967A2.967,2.967,0,0,0,38.575,99.6h2.967a2.967,2.967,0,0,0,2.967-2.967V91.624a5.882,5.882,0,0,0,1.484-3.888V83.286a5.9,5.9,0,0,0-2.046-4.451h1.861a1.112,1.112,0,0,0,1.079-.843l.556-2.225a1.113,1.113,0,0,0-1.08-1.382ZM13.719,72.732a4.451,4.451,0,0,1,4.133-2.8H29.628a4.451,4.451,0,0,1,4.133,2.8l1.848,4.62H11.872ZM8.9,87.718a2.958,2.958,0,1,1,0-5.916c1.78,0,4.451,2.662,4.451,4.437S10.685,87.718,8.9,87.718Zm29.671,0c-1.78,0-4.451.3-4.451-1.479s2.67-4.437,4.451-4.437a2.958,2.958,0,1,1,0,5.916Z",
    transform: "translate(-0.004 -64)"
  }));
};

exports.default = CarIcon;