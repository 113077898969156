import Auth from '@aws-amplify/auth';

import { isAgent } from './helpers';
import { isLocalhost } from './utils/utils';

const cognitoLocalEndpoint = process.env.REACT_APP_COGNITO_LOCAL_ENDPOINT;

export const config = {
  region: process.env.REACT_APP_COGNITO_REGION,
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  identityPoolRegion: process.env.REACT_APP_COGNITO_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,
  storage: window.sessionStorage,
  oauth: {
    domain: process.env.REACT_APP_DOMAIN_NAME,
    redirectSignIn: isLocalhost ? "http://localhost:3000/" : process.env.PUBLIC_URL,
    redirectSignOut: isLocalhost ? "http://localhost:3000/" : process.env.PUBLIC_URL,
    responseType: 'token'
  },
  authenticationFlowType: cognitoLocalEndpoint ? 'USER_PASSWORD_AUTH' : undefined,
  aws_mobile_analytics_app_id: process.env.REACT_APP_PINPOINT_ID,
  aws_mobile_analytics_app_region: process.env.REACT_APP_COGNITO_REGION,
  endpoint: cognitoLocalEndpoint,
  Analytics: {
    disabled: false,
    AWSPinpoint: {
      appId: process.env.REACT_APP_PINPOINT_ID,
      region: process.env.REACT_APP_COGNITO_REGION,
      mandatorySignIn: false,
    }
  }
};

export const agent_config = {
  region: process.env.REACT_APP_COGNITO_REGION,
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  identityPoolRegion: process.env.REACT_APP_COGNITO_REGION,
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,
  storage: window.sessionStorage,
  oauth: {
    domain: process.env.REACT_APP_DOMAIN_NAME,
    redirectSignIn: isLocalhost ? "http://localhost:3000/" : process.env.PUBLIC_URL,
    redirectSignOut: isLocalhost ? "http://localhost:3000/" : process.env.PUBLIC_URL,
    responseType: 'token'
  },
  Analytics: {
    disabled: true,
  }
};


export const customer_config = {
  ...config,
  region: process.env.REACT_APP_COGNITO_CUSTOMER_REGION,
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_COGNITO_CUSTOMER_USER_POOL_ID,

  identityPoolRegion: process.env.REACT_APP_COGNITO_CUSTOMER_REGION,
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CUSTOMER_CLIENT_ID
  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
}

isAgent() ? Auth.configure(agent_config) : Auth.configure(config)
