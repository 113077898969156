/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import FormikAboutYouForm from './AboutYouForm';
import AboutYouComponent from './AboutYouFormWrapper';



export default FormikAboutYouForm;

export { AboutYouComponent };
