/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './LegalText.scss';

import type { StatelessFunctionalComponent } from 'react';
import React from 'react';

import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { legalTextHomeContent, legalTextMotorContent, legalTextTravelContent } from './legalTextContent';

interface Props {
  insuranceType: string;
}

const className = 'c-LegalText';

const legalText = {
  [HOME_INSURANCE_TYPE]: legalTextHomeContent,
  [CAR_INSURANCE_TYPE]: legalTextMotorContent,
  [TRAVEL_INSURANCE_TYPE]: legalTextTravelContent
};

const LegalText: StatelessFunctionalComponent<{ insuranceType: string; }> = (props: Props) => (
  legalText[props.insuranceType] ? <p className={className}>{legalText[props.insuranceType]}</p> : ''
);

export default LegalText;
