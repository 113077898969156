import { endOfToday, subYears } from 'date-fns';
import * as yup from "yup";

import {
  irishToISOFormat,
  ISODateConvertable
} from './DateHelper';

export const dateOfBirthYup = yup.mixed()
.test('doneFilling', 'Please enter a valid date', (dob) => {
  const dobISO = irishToISOFormat(dob)
  if (!ISODateConvertable(dobISO)) return false
  return true
})
.test('minDate', 'Please enter a date after 1900', (dob) => {
  const dobISO = irishToISOFormat(dob)
  if (!ISODateConvertable(dobISO)) return true // Don't test invalid date
  const dobDate = new Date(dobISO)
  const minDate = new Date('1900-01-01')
  return +dobDate >= +minDate
})
.test('maxDate', 'You must be over 18 to take out a policy', (dob) => {
  const dobISO = irishToISOFormat(dob)
  if (!ISODateConvertable(dobISO)) return true // Don't test invalid date
  const dobDate = new Date(dobISO)
  const maxDate = subYears(endOfToday(), 18)
  return +dobDate <= +maxDate
})
.required('Date of birth is required')