/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './Loading.scss';
import '../../styles/commonPageStyles.scss';

import {
  DesktopCard,
  Title,
  TitleWithUnderLine
} from '@boi/core/lib';
import React from 'react';

import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';
import { commonPageStylesIdentifier } from '../../constants';

const className = 'c-Loading';

type Props = {
  text: string;
};

const Loading = (props: Props) => {

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <TitleWithUnderLine>Get a Quote</TitleWithUnderLine>
        </div>
      </div>
      <DesktopCard>
        <div className={`${className}__icon`}>
          <AnimatedSpinner size="large"></AnimatedSpinner>
        </div>

        <Title type="h1" weight="light" align="center">
          {props.text}
        </Title>
      </DesktopCard>
    </div>
  );
};

export default Loading;
