/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import './ViewMultiplePoliciesPage.scss';

import { TitleWithUnderLine } from '@boi/core/lib';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';

import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import { MobileBackButton } from '../../components/MobileBackButton';
import {
  commonPageStylesIdentifier,
  iconsAndTitles,
  POLICY_NUMBER_STORAGE,
  policyTypeIdentifierLookup
} from '../../constants';
import { dustyGrayColor } from '../../constants/colors';
import { noPurchasePolicy } from '../../constants/commonConstants';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { localPhoneNumber } from '../../constants/phoneNumbers';
import { POLICY_RENEWAL } from '../../constants/sessionStorage/genericStorageIdentifiers';
import { saveObjectInSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import type {
  UserProfilePolicyType,
  UserProfileType
} from '../../types/profile';

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match;
  insuranceType: string;
  userProfile: UserProfileType;
}

const RightArrowIcon = () => (
  <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />
);

const ViewMultiplePoliciesPage = (props: Props) => {
  const className = 'c-ViewMultiplePoliciesPage';
  const { insuranceType = '', userProfile = {}, history } = props;

  const getPolicies = () => {
    const noPoliciesMessage = (
      <div className={`${className}__noPoliciesMessage`}>
        {noPurchasePolicy} Please call{' '}
        <a href={`tel:${localPhoneNumber}`}>{localPhoneNumber}</a> for
        assistance
      </div>
    );
    if (!userProfile.policies || userProfile.policies.length === 0) {
      return noPoliciesMessage;
    }
    const insuranceTypePolicies = userProfile.policies.filter(
      (policyDetails: UserProfilePolicyType) => {
        return (
          policyDetails.policy_type ===
          policyTypeIdentifierLookup[insuranceType]
        );
      }
    );
    return insuranceTypePolicies.length > 0
      ? getPoliciesLinks(insuranceTypePolicies)
      : noPoliciesMessage;
  };

  const goToPolicyPage = (policyDetails: UserProfilePolicyType) => {
    saveObjectInSessionStorage(POLICY_RENEWAL, policyDetails);
    saveObjectInSessionStorage(`${insuranceType}${POLICY_NUMBER_STORAGE}`, {
      referenceNumber: policyDetails.reference_number,
      policyId: policyDetails.policy_id
    });
    history.push(`${routes.manageMyInsuranceProduct.url}${insuranceType}`);
  };

  const renderPolicyDetails = (policyDetails: UserProfilePolicyType) => {
    return (
      <div className={`${className}__policyNumberDiv`}>
        <div className={`${className}__policyNumber`}>
          {insuranceType === HOME_INSURANCE_TYPE ? (
            <span>Policy Number: {policyDetails.policy_number}</span>
          ) : (
            <span>Policy Code: {policyDetails.reference_number}</span>
          )}
        </div>
        {/* TODO might want to add this code when existing policy date fixed */}
        {/* <div className={`${className}__date`}>
      <div className={`${className}__startDate`}>Start Date: {format(policyDetails.start_date, 'DD/MM/YYYY')}</div>
      <div>End Date: {format(policyDetails.end_date, 'DD/MM/YYYY')}</div>
      </div> */}
      </div>
    );
  };

  const getPoliciesLinks = (
    insuranceTypePolicies: Array<UserProfilePolicyType>
  ) => {
    insuranceTypePolicies.sort(
      (a: UserProfilePolicyType, b: UserProfilePolicyType) =>
        new Date(b.start_date) - new Date(a.start_date)
    );
    return insuranceTypePolicies.map((policyDetails: UserProfilePolicyType) => {
      return (
        <div
          key={policyDetails.reference_number}
          className={`${className}__buttonContainer`}
        >
          <ButtonWithIcons
            id={'ViewMultiplePoliciesPage__openPolicyButton'}
            onClick={() => {
              goToPolicyPage(policyDetails);
            }}
            IconRight={RightArrowIcon}
          >
            {renderPolicyDetails(policyDetails)}
          </ButtonWithIcons>
        </div>
      );
    });
  };

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      {insuranceType && iconsAndTitles[insuranceType] ? (
        <div className={`${className}__iconContainer`}>
          <div className={`${className}__icon`}>
            {iconsAndTitles[insuranceType].icon}
          </div>
          <div className={`${className}__titleText`}>
            <TitleWithUnderLine>
              {iconsAndTitles[insuranceType].title}
            </TitleWithUnderLine>
          </div>
        </div>
      ) : (
        <></>
      )}
      {getPolicies()}
    </div>
  );
};

export default ViewMultiplePoliciesPage;
