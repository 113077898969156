/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './Banner.scss';

import { noop } from '@boi/core/lib/utils';
import React from 'react';

interface Props {
  children: noop;
}

const className = 'c-Banner';
const Banner = ({ children }: Props) => {
  return (
    <div className={className}>
      <div className={`${className}__banner`}>{children}</div>
    </div>
  );
};
export default Banner;
