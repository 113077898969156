/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import '../../styles/commonFormStyles.scss';
import './ViewQuote.scss';

import Auth from '@aws-amplify/auth';
import {
  AccordionText,
  ButtonGroup,
  TitleWithUnderLine,
  WideDivider
} from '@boi/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import type { Location, RouterHistory } from 'react-router-dom';

import { config, customer_config } from '../../amplify.config';
import { CognitoContext } from '../../CognitoUtils';
import TermsAndConditionsCheckbox from '../../components/HomeTermsAndConditions/TermsAndConditionsCheckbox/TermsAndConditionsCheckbox';
import { MobileBackButton } from '../../components/MobileBackButton';
import { HomePremiumBreakdown } from '../../components/PremiumBreakdowns';
import { PropertyMapper } from '../../components/PremiumBreakdowns/HomePremiumBreakdown/AmountMapper';
import ProgressBar from '../../components/ProgressBar';
import QuoteDisplay from '../../components/QuoteDisplay';
import QuoteExtras from '../../components/QuoteExtras';
import QuoteInfo from '../../components/QuoteInfo';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier,
  DIRECT_DEBIT_STORAGE,
  HOME_QUOTE_RECALCULATED_VALUES_STORAGE,
  HOME_QUOTE_STORAGE,
  HOME_QUOTE_THANK_YOU_STORAGE,
  REQUEST_FAILURE,
  REQUEST_LOADING,
  REQUEST_SUCCESS,
  SELECTED_HOME_QUOTE_STORAGE,
  TOGGLE_OPTIONAL_EXTRAS
} from '../../constants';
import { dividerColor } from '../../constants/colors';
import { FORCE_LOGIN } from '../../constants/home';
import {
  DISPLAY_NUM_OF_FEATURES_VIEW_QUOTE,
  EXCESS,
  EXCESS_AMOUNT_MAPPER,
  HOME_COVER_TYPE
} from '../../constants/home';
import { INSURER_TYPE } from '../../constants/home';
import {
  ABOUT_YOU,
  CODE_VALUES,
  HOME_INSURANCE_TYPE,
  premiumBreakDownConstants
} from '../../constants/insuranceTypeConstants';
import {
  HOME_JOURNEY,
  JOURNEY_YOUR_QUOTE,
  RENEWAL_JOURNEY_LABEL_TEXT,
  STAGES_HOME_JOURNEY,
  STAGES_RENEWAL_JOURNEY
} from '../../constants/journey';
import {
  ALTERNATIVE_POLICY,
  POLICY_RENEWAL
} from '../../constants/sessionStorage/genericStorageIdentifiers';
import { CommonPageStylesDesktopBackButton } from '../../constants/styleConstants/commonClassNames';
import {
  getObjectFromSessionStorage,
  isAgent,
  redirectOnBack,
  removeFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { mapGetQuote } from '../../mappings/home/MapGetQuote';
import { routes } from '../../routes';
import { postHomeQuote } from '../../services/homeServices';
import { getUsers } from '../../services/retriveUser';
import type { PayloadType, RequestStateType } from '../../types';
import InsuranceCodes from './InsuranceCodes';
import ViewQuoteLogo from './ViewQuoteLogo';
import { viewQuoteYupSchema } from './viewQuoteYupSchema';

interface Props {
  location: Location;
  history: RouterHistory;
}

export interface ViewQuoteValues {
  accidentalDamage: boolean;
  ncdProtectionPlus: boolean;
  homeEmergencyAssist: boolean;
  excess: number;
}

type QuoteData = {
  yourHomeObj?: {
    coverType: string
  }
};

// eslint-disable-next-line complexity
const ViewQuote = (props: Props & FormikProps<ViewQuoteValues>) => {
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    history,
    setFieldValue,
    setTouched,
    touched,
    values,
    match
  } = props;

  const className = 'c-ViewQuote';
  const [buttonType, setButtonType] = useState('proceed');
  const [price, setPrice] = useState(0);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [quoteReference, setQuoteReference] = useState(0);
  const [premiumDetails, setPremiumDetails] = useState([]);
  const [insurerType, setInsurerType] = useState('');
  const [alternativePolicy, setAlternativePolicy] = useState();
  const [togglesState, setToggleState] = useState({
    accidentalDamage: props.values.accidentalDamage,
    ncdProtectionPlus: props.values.ncdProtectionPlus,
    homeEmergencyAssist: props.values.homeEmergencyAssist,
    excess: props.values.excess
  });
  const [addonDetails, setAddonDetails] = useState([]);
  const [coverDetails, setCoverDetails] = useState({
    rebuildCost: 0,
    contentsCoverOf: 0,
    specifiedItems: [],
    unspecifiedItemsValue: 0,
    excess: '',
    coverType: '',
    start_date: ''
  });
  const [claims, setClaims] = useState();
  const isMounted = useRef(false);
  const FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER = `${HOME_INSURANCE_TYPE}${DIRECT_DEBIT_STORAGE}`;
  const insuranceType = match.params.type;
  const bindingQuote = getObjectFromSessionStorage(`${insuranceType}Quote`);

  const [requestStatus, setRequestStatus] = useState<RequestStateType>(
    REQUEST_LOADING
  );

  const { feature, cognitoUser } = useContext(CognitoContext);

  removeFromSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER);

  let payload: PayloadType = {};

  const checkToggleState = () => {
    return (
      togglesState.accidentalDamage === props.values.accidentalDamage &&
      togglesState.ncdProtectionPlus === props.values.ncdProtectionPlus &&
      togglesState.homeEmergencyAssist === props.values.homeEmergencyAssist &&
      togglesState.excess === props.values.excess
    );
  };

  useEffect(() => {
    redirectOnBack(
      history,
      routes.getQuoteThankYouPage.url,
      `${routes.getQuotePage.url}${ABOUT_YOU}`
    );
    isMounted.current = true;
  }, []);

  // eslint-disable-next-line complexity
  useEffect(() => {
    const quoteData = getObjectFromSessionStorage(SELECTED_HOME_QUOTE_STORAGE);
    const renewalFlag =
      getObjectFromSessionStorage(POLICY_RENEWAL)?.renewal || false;
    setFieldValue('renewal', renewalFlag);
    const stringOnly = (val: any) => (val ? val : '');
    let element = {};
    if (quoteData) {
      element = getObjectFromSessionStorage(TOGGLE_OPTIONAL_EXTRAS) || {};
      if (element && Object.keys(element).length > 0) {
        setToggleState(element);
      }
      setPremiumDetails(quoteData.premium_details);
      setQuoteReference(quoteData.quote_reference);
      setInsurerType(stringOnly(quoteData.insurer));
      setAlternativePolicy(quoteData.alternativePolicy);
      saveInSessionStorage(ALTERNATIVE_POLICY, quoteData.alternativePolicy);
      setAddonDetails(quoteData.addon_details || []);
      if (renewalFlag) {
        setCoverDetails({
          rebuildCost: quoteData.cover_details_information.buildings_amount,
          contentsCoverOf: quoteData.cover_details_information.contents_amount,
          specifiedItems: quoteData.cover_details_information.specified_items,
          unspecifiedItemsValue:
            quoteData.cover_details_information
              .unspecified_personal_items_amount,
          excess: quoteData.cover_details_information.excess_amount,
          coverType:
            quoteData.cover_details_information.risk_address.dwelling_type,
          start_date: quoteData.cover_details_information.start_date
        });
        setClaims(quoteData?.cover_details_information?.claims_details);
      }
      const inclevyPrice = getQuoteTotal(
        quoteData.premium_details,
        quoteData.insurer
      );
      setPrice(inclevyPrice);
      setMonthlyPrice(inclevyPrice / 12);
      //  @TODO Zoltan: Add response values to setSpecifiedItems and setUnspecifiedItems
    } else {
      setPrice(0);
      setMonthlyPrice(0);
      setPremiumDetails([]);
      setQuoteReference(0);
      setInsurerType('');
    }
    if (element?.insurerType === quoteData.insurer) {
      Object.entries(element).forEach(([key, value]: [string, mixed]) => {
        setFieldValue(key, value);
      });
    }
    if (
      [HOME_COVER_TYPE.OWN_LIVE, HOME_COVER_TYPE.RENT].includes(
        yourHomeObj.coverType
      ) &&
      element?.accidentalDamage === undefined
    )
      setFieldValue('accidentalDamage', false);
  }, []);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isMounted.current) {
      if (checkToggleState()) {
        setButtonType('proceed');
      } else {
        setButtonType('recalculate');
      }
    } else {
      isMounted.current = true;
    }
  }, [
    props.values.accidentalDamage,
    props.values.ncdProtectionPlus,
    props.values.homeEmergencyAssist,
    props.values.excess
  ]);

  useEffect(() => {
    if (requestStatus === REQUEST_FAILURE) {
      props.history.push(
        `${routes.genericErrorPage.url}${HOME_INSURANCE_TYPE}`
      );
    }
  }, [requestStatus, props.history]);

  const getYourHomeObject = (quoteData: QuoteData) =>
    quoteData.yourHomeObj || {};

  let yourCoverObj = {};
  const allData = getObjectFromSessionStorage(HOME_QUOTE_STORAGE);
  allData.yourCoverObj
    ? (yourCoverObj = allData.yourCoverObj)
    : (yourCoverObj = {});
  const yourHomeObj = getYourHomeObject(allData);

  const resHomeIPIDLink =
    '//personalbanking.bankofireland.com/insure-and-protect/insurance/home-insurance-quote/important-documents/';

  const rentPropIPIDLink = '//boi.com/insurancehomeipidhhrip';

  const summaryOfCoverHomeTypeOwnRentOROwnHoliday =
    '//boi.com/insurancehomeipidhhrip';
  const policyBookletHomeTypeOwnRentOROwnHoliday =
    '//boi.com/insurancehomepolicybooklet';
  const policyDocumentDefault =
    'https://personalbanking.bankofireland.com/insure-and-protect/insurance/home-insurance-quote/important-documents/';

  const renderPolicyDocuments = () =>
    // eslint-disable-next-line no-constant-condition
    [
      HOME_COVER_TYPE.OWN_HOLIDAY,
      HOME_COVER_TYPE.OWN_LIVE,
      HOME_COVER_TYPE.OWN_RENT,
      HOME_COVER_TYPE.RENT
    ] ? (
      <>
        <a
          href={policyDocumentDefault}
          target="_blank"
          rel="noopener noreferrer"
        >
          Policy Documents
        </a>
      </>
    ) : (
      <></>
    );

  const renderHomeTypeOwnRentOROwnHolidayExcessLinks = () =>
    [HOME_COVER_TYPE.OWN_RENT, HOME_COVER_TYPE.OWN_HOLIDAY].includes(
      yourHomeObj.coverType
    ) ? (
      <>
        <a
          href={summaryOfCoverHomeTypeOwnRentOROwnHoliday}
          target="_blank"
          rel="noopener noreferrer"
        >
          Summary of Cover
        </a>
        {', '}{' '}
        <a
          href={policyBookletHomeTypeOwnRentOROwnHoliday}
          target="_blank"
          rel="noopener noreferrer"
        >
          Policy booklet
        </a>
      </>
    ) : (
      <></>
    );

  const renderHomeTypeOwnLiveORRent = () =>
    [HOME_COVER_TYPE.OWN_LIVE, HOME_COVER_TYPE.RENT].includes(
      yourHomeObj.coverType || coverDetails.coverType
    ) ? (
      <React.Fragment>
        Important Documents{' '}
        <a href={resHomeIPIDLink} target="_blank" rel="noopener noreferrer">
          Click here
        </a>
      </React.Fragment>
    ) : (
      <></>
    );

  const renderHomeTypeOwnRentOROwnHoliday = () =>
    [HOME_COVER_TYPE.OWN_RENT, HOME_COVER_TYPE.OWN_HOLIDAY].includes(
      yourHomeObj.coverType || coverDetails.coverType
    ) ? (
      <React.Fragment>
        Important Documents{' '}
        <a href={rentPropIPIDLink} target="_blank" rel="noopener noreferrer">
          Click here
        </a>
      </React.Fragment>
    ) : (
      <></>
    );

  const renderHomeTypeLinks = () => (
    <>
      {renderHomeTypeOwnLiveORRent()}
      {renderHomeTypeOwnRentOROwnHoliday()}
    </>
  );

  const fbdAndRsaFeatures = [
    { label: () => 'Pay by direct debit at no extra cost', tick: true },
    { label: () => '24 hour helpline in a home emergency ', tick: true },
    { label: () => 'Loss of keys/lock replacement', tick: true },
    { label: () => 'Fire brigade charges, up to €2,500', tick: true },
    {
      label: () => renderHomeTypeLinks(),
      tick: true
    }
  ];

  const fbdAndRsaFeaturesRenewal = [
    { label: () => 'Review your policy summary below', tick: false },
    { label: () => 'Review and accept all Terms & Conditions', tick: false },
    { label: () => 'Select your preferred Payment Method', tick: false },
    {
      label: () => renderHomeTypeLinks(),
      tick: false
    }
  ];

  // eslint-disable-next-line complexity
  const checkResponse = (payload: PayloadType[]) => {
    try {
      if (Array.isArray(payload) && payload.length > 0) {
        setRequestStatus(REQUEST_SUCCESS);
        setPremiumDetails(payload[0].premium_details);
        setQuoteReference(payload[0].quote_reference);
        setAddonDetails(payload[0].addon_details || []);
        setButtonType('proceed');
        const inclevyPrice = getQuoteTotal(
          payload[0].premium_details,
          payload[0].insurer
        );
        setPrice(inclevyPrice);
        setMonthlyPrice(inclevyPrice / 12);
        const quoteData = payload[0];
        if (quoteData) {
          saveInSessionStorage(
            SELECTED_HOME_QUOTE_STORAGE,
            JSON.stringify(quoteData)
          );
        }
        const tempToggleState = {
          accidentalDamage: props.values.accidentalDamage,
          ncdProtectionPlus: props.values.ncdProtectionPlus,
          homeEmergencyAssist: props.values.homeEmergencyAssist,
          excess: props.values.excess,
          insurerType: payload[0].insurer
        };
        setToggleState(tempToggleState);
        saveInSessionStorage(
          TOGGLE_OPTIONAL_EXTRAS,
          JSON.stringify(tempToggleState)
        );
      } else {
        setRequestStatus(REQUEST_FAILURE);
      }
    } catch {
      setRequestStatus(REQUEST_FAILURE);
    }
  };
  const recalculateHomeQuote = async () => {
    const quoteData = getObjectFromSessionStorage(SELECTED_HOME_QUOTE_STORAGE);
    const quoteValues = getObjectFromSessionStorage(
      HOME_QUOTE_THANK_YOU_STORAGE
    ); // previous mapping of payload

    const previousMapping = mapGetQuote(
      quoteValues.aboutYouObj,
      quoteValues.yourHomeObj,
      quoteValues.yourCoverObj,
      quoteValues.assumptions,
      quoteValues.extraQuestionsValues
    );
    const homeQuoteValues = props.values;
    setRequestStatus(REQUEST_LOADING);

    const newCoverDetails = Object.assign(
      {},
      previousMapping.home_quote.cover_details,
      {
        excess_amount: Number(EXCESS_AMOUNT_MAPPER[homeQuoteValues.excess]),
        contents_accidental_damage_cover: homeQuoteValues.accidentalDamage,
        no_claim_discount_cover: homeQuoteValues.ncdProtectionPlus,
        home_emergency_assist_cover: homeQuoteValues.homeEmergencyAssist,
        premium: price
      }
    );

    const newMapping = { ...previousMapping };
    newMapping.home_quote.cover_details = newCoverDetails;
    newMapping.home_quote['product_selected'] = quoteData.scheme_code;
    newMapping.home_quote['quote_reference'] = quoteData.quote_reference;
    payload = await postHomeQuote(newMapping, props);

    if (payload) {
      checkResponse(payload);
    } else {
      setRequestStatus(REQUEST_FAILURE);
    }
  };

  const handleChangeWRecalculate = (
    fieldName: string,
    value: string | number
  ) => {
    setFieldValue(fieldName, value);
    const data = getObjectFromSessionStorage(
      HOME_QUOTE_RECALCULATED_VALUES_STORAGE
    );
    data[fieldName] = value;
    saveInSessionStorage(
      HOME_QUOTE_RECALCULATED_VALUES_STORAGE,
      JSON.stringify(data)
    );
  };

  const isFieldError = (status: string) =>
    touched[status] && errors[status] !== undefined;

  const recalculatedExcess = getObjectFromSessionStorage(
    HOME_QUOTE_RECALCULATED_VALUES_STORAGE
  ).excess;

  const journey = (
    quoteData: any,
    userExistOrLoggedIn: any,
    isAgent: boolean
  ) => {
    if (isAgent) {
      Auth.configure(config);
    }
    if (userExistOrLoggedIn) {
      history.push(`${routes.makePayment.url}${insuranceType}`);
    } else {
      saveInSessionStorage(
        FORCE_LOGIN,
        JSON.stringify({ forceLogIn: true, insuranceType: insuranceType })
      );
      history.push(
        !isAgent
          ? `${routes.forceLogin.url}${insuranceType}`
          : `${routes.signUp.url}`
      );
    }
  };

  const goToNextPage = (quoteData: any) => {
    if (isAgent()) {
      Auth.configure(customer_config);
      getUsers({
        dropdownValue: 'email',
        searchInput: bindingQuote?.aboutYouObj?.email || ''
      })
        .then((res: any) => {
          if (res?.user_list?.length > 0) {
            journey(quoteData, true, true);
          } else {
            journey(quoteData, false, true);
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      journey(quoteData, cognitoUser, false);
    }
  };

  // eslint-disable-next-line complexity
  const onProceedClicked = () => {
    const quoteData = { price, quoteReference, monthlyPrice, insurerType };
    const selectedQuoteData = getObjectFromSessionStorage(
      SELECTED_HOME_QUOTE_STORAGE
    );
    saveInSessionStorage(
      FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER,
      JSON.stringify(quoteData)
    );
    switch (true) {
      case alternativePolicy === true:
        history.push({
          pathname: `${routes.renewalMakePayment.url}${HOME_INSURANCE_TYPE}`,
          state: quoteData
        });
        break;
      case values.autoRenewal === false ||
        selectedQuoteData?.direct_debit_policy === false:
        history.push({
          pathname: `${routes.renewalMakePayment.url}${HOME_INSURANCE_TYPE}`,
          state: quoteData
        });
        break;
      case values.autoRenewal === true:
        history.push({
          pathname: `${routes.paymentThankYou.url}${HOME_INSURANCE_TYPE}`,
          state: {
            insuranceType: HOME_INSURANCE_TYPE
          }
        });
        break;
      case feature?.flags?.marketing_enabled === false:
        goToNextPage(quoteData);
        break;
      default:
        history.push({
          pathname: `${routes.marketingConsent.url}${HOME_INSURANCE_TYPE}`,
          state: quoteData
        });
        break;
    }
  };

  // eslint-disable-next-line complexity
  const getQuoteExtras = () => {
    return (
      <div>
        <QuoteExtras
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          handleChangeWRecalculate={handleChangeWRecalculate}
          touched={touched}
          values={values}
          handleChange={handleChange}
          isFieldError={isFieldError}
          addonDetails={addonDetails || []}
        />
      </div>
    );
  };

  const checkIfPolicyExcessIsAllowed = () => {
    return [
      HOME_COVER_TYPE.OWNUNOCCUPIED,
      HOME_COVER_TYPE.OWNCONSTRUCTION,
      HOME_COVER_TYPE.OWNRENOVATION
    ].includes(yourHomeObj.coverType);
  };

  const getCompanyName = () =>
    insurerType === INSURER_TYPE.FBD
      ? 'FBD Insurance plc'
      : 'RSA Insurance Ireland DAC';

  // eslint-disable-next-line complexity
  const getQuoteTotal = useCallback((quoteData: any[], insurer: string) => {
    const insuranceProvider = insurerType !== '' ? insurerType : insurer;
    const premDetails = quoteData;
    if (premDetails && premDetails.length > 0) {
      const element = premDetails.find((item: any) => {
        if (item.code === CODE_VALUES[insuranceProvider]) {
          return item;
        }
      });
      if (element) {
        const propertyIdentifier =
          PropertyMapper[insuranceProvider][
            premiumBreakDownConstants.INCLEVY
          ] === 'AMT'
            ? 'amount'
            : 'running_total';
        return element[propertyIdentifier] ? element[propertyIdentifier] : 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, []);

  const getQuoteInfoProps = () => {
    if (values.renewal) {
      return {
        showReadMoreOption: false,
        showUpToFeature: DISPLAY_NUM_OF_FEATURES_VIEW_QUOTE,
        quoteFeatures: fbdAndRsaFeaturesRenewal
      };
    } else {
      return {
        quoteFeatures: fbdAndRsaFeatures
      };
    }
  };

  const onDesktopClick = () => {
    history.push('/wallet/terms-and-conditions/home');
  };

  return (
    <div className={`${className}`}>
      <div
        className={`${commonPageStylesIdentifier} ${CommonPageStylesDesktopBackButton}`}
      >
        <MobileBackButton history={history} onDesktopClick={onDesktopClick} />
      </div>
      <ProgressBar
        stage={JOURNEY_YOUR_QUOTE}
        labelText={values.renewal ? RENEWAL_JOURNEY_LABEL_TEXT : HOME_JOURNEY}
        stages={values.renewal ? STAGES_RENEWAL_JOURNEY : STAGES_HOME_JOURNEY}
      />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>
          {values.renewal ? 'Your Summary' : 'Your Quote'}
        </TitleWithUnderLine>
      </div>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          <div
            className={`${className}__quoteInfoContainer ${className}__paddedContainer`}
          >
            <div className={`${className}__logo`}>
              <ViewQuoteLogo />
            </div>
            <div className={`${className}__underwrittenText`}>
              Underwritten by{' '}
              <img
                className={`${className}__${insurerType.toLowerCase()}Logo`}
                alt={`${insurerType.toLowerCase()} logo`}
                src={`/asset/${insurerType.toLowerCase()}_logo.jpg`}
              />
            </div>
            <div className={`${className}__quoteText`}>
              {alternativePolicy
                ? 'Complete your quote in 3 easy steps'
                : values.renewal
                ? 'Complete your renewal in 3 easy steps'
                : 'You do not have to purchase an optional extra in order to take out a policy'}
            </div>
            <div>
              <QuoteInfo {...getQuoteInfoProps()} />
            </div>
          </div>
          <Form>
            {!values.renewal && (
              <>
                {getQuoteExtras()}
                <div
                  className={`${commonFormStylesIdentifier}__fieldContainer ${className}__paddedContainer`}
                >
                  <label
                    htmlFor="excess"
                    className={`${commonFormStylesIdentifier}__fieldLabel`}
                  >
                    <AccordionText
                      label="Select your excess to suit your needs and requirements"
                      icon="info"
                      iconAlign="right"
                      customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
                    >
                      <div
                        className={`${commonFormStylesIdentifier}__infoText`}
                      >
                        <ul className={`${className}__bulletPoints`}>
                          <li>
                            An excess is the part of the claim cost that you
                            must pay. You are responsible for paying the excess
                            amounts shown in your schedule for certain claim
                            types under this policy.
                          </li>
                          <li>
                            If your policy is subject to any other form of
                            excess, this will be detailed in your Policy
                            Schedule or in the endorsements section of the
                            Policy Schedule.
                          </li>
                          <li>
                            You may also have the option of choosing a higher
                            excess for a lower premium, or a lower excess for a
                            higher premium. If you choose a higher excess, this
                            will increase the amount you need to pay if you have
                            a claim.
                          </li>
                          <li>
                            Terms and Conditions apply. See your{' '}
                            {renderPolicyDocuments()}{' '}
                            {renderHomeTypeOwnRentOROwnHolidayExcessLinks()}
                            {'  '}for full details.
                          </li>
                        </ul>
                      </div>
                    </AccordionText>
                  </label>

                  <div
                    className={`${className}--fieldLabelSpacing ${className}--flexGroup`}
                  >
                    <ButtonGroup
                      error={isFieldError('excess')}
                      errorMessage={errors.excess}
                      name="excess"
                      disabled={checkIfPolicyExcessIsAllowed()}
                      onClick={(e: string) => {
                        handleChangeWRecalculate('excess', e);
                      }}
                      options={EXCESS}
                      spaceBetween={true}
                      selected={recalculatedExcess || values.excess}
                      onBlur={handleBlur}
                      value={recalculatedExcess || values.excess}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
            <div className={`${className}__premiumBreakdownContainer`}>
              <HomePremiumBreakdown
                premiumDetails={premiumDetails}
                yourCoverObj={values.renewal ? coverDetails : yourCoverObj}
                coverType={
                  values.renewal
                    ? coverDetails?.coverType
                    : yourHomeObj.coverType
                }
                unspecifiedItemsValue={
                  values.renewal
                    ? coverDetails?.unspecifiedItemsValue
                    : allData?.yourCoverObj?.unspecifiedItems
                }
                specifiedItemsValue={
                  values.renewal
                    ? coverDetails?.specifiedItems
                    : allData?.yourCoverObj?.specifiedItems
                }
                totalPrice={price}
                monthlyPrice={monthlyPrice}
                insurer={InsuranceCodes[insurerType]}
                insurerType={insurerType}
                renewal={values.renewal}
                alternativePolicy={alternativePolicy}
                addonDetails={addonDetails}
                setFieldValue={setFieldValue}
                values={values}
                claims={claims}
                start_date={coverDetails.start_date}
                home_premium_discounts_enabled={
                  feature?.flags?.home_premium_discounts_enabled
                }
              />
            </div>
          </Form>
        </div>
        <div className={`${className}__paddedContainer`}>
          {values.renewal ? (
            <>
              {(values.autoRenewal === false ||
                alternativePolicy ||
                !getObjectFromSessionStorage(SELECTED_HOME_QUOTE_STORAGE)
                  ?.direct_debit_policy) && (
                <TermsAndConditionsCheckbox
                  setFieldValue={setFieldValue}
                  values={values}
                  showStatementOfFact={true}
                />
              )}
            </>
          ) : (
            <>
              <div className={`${className}__eligibilityWarningTitle`}>
                Before You Proceed
              </div>
              <div className={`${className}__eligibilityWarningText`}>
                <p>
                  {`You are responsible for confirming your eligibility for our cover and that the cover you have chosen meets your particular needs. Before deciding to
                      purchase it is important that you have  read and understood your premium breakdown above.`}
                  <br />
                  {`This quote is underwritten by ${getCompanyName()}. Quotes are valid for 90 days only provided all information is correct and does not change.`}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <QuoteDisplay
        quoteReference={quoteReference}
        price={getQuoteTotal(premiumDetails, insurerType)}
        onClickFunction={onProceedClicked}
        sticky
        disabled={isValid}
        buttonType={buttonType}
        recalculate={recalculateHomeQuote}
        callStatus={requestStatus}
        insuranceType={HOME_INSURANCE_TYPE}
        title="Annual Premium"
        insurerType={insurerType}
      />
    </div>
  );
};

const initialFormValues = {
  excess: '3',
  accidentalDamage: false,
  ncdProtectionPlus: false,
  homeEmergencyAssist: false,
  acceptedTermsAndConditions: undefined,
  claimsConditionTwo: undefined,
  claimsConditionOne: undefined,
  autoRenewal: undefined,
  renewal: false
};

const isInitialFormValid = () => {
  return viewQuoteYupSchema.isValidSync(initialFormValues);
};

const FormikViewQuote = withFormik<Props, ViewQuoteValues>({
  mapPropsToValues(): {} {
    return initialFormValues;
  },
  handleSubmit(values: ViewQuoteValues, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  isInitialValid: isInitialFormValid(),
  validationSchema: () => {
    return viewQuoteYupSchema;
  },
  displayName: 'ViewQuote'
})(ViewQuote);

export default FormikViewQuote;
