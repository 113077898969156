import { basePath } from '../../constants/commonConstants';
import { makeRequest } from '../../helpers';

const defaultProfileResponse = {
  title:"",
  first_name: "",
  last_name: "",
  date_of_birth: "",
  phone: "",
  address: {},
  policies: [],
  isDefaultResponse: true
};

export const getUserProfile = async () => {
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const url = `${basePath}/profile/profile`;
  return await makeRequest({
    method: 'GET',
    url: url,
  }).then(
    response => new Promise(resolve => resolve(response.error ? defaultProfileResponse : response)),
    () => new Promise(resolve => resolve(defaultProfileResponse))
  );

};

export const updateUserProfileBranch = async (updatedBranch) => {
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const quoteRequest = makeRequest({
    method: 'PUT',
    url: `${basePath}/profile/profile/branch`,
    payload: {
      id: updatedBranch.branchName.id,
      branch_name: updatedBranch.branchName.value
    }
  });

  return await quoteRequest;
};

export const getBranchData = async (typeofRequest) => {
  const quoteRequest = makeRequest({
    method: 'GET',
    url: `${basePath}/profile/profile/${typeofRequest}`,
  });
  return await quoteRequest;
};
