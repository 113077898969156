/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { addDays, endOfToday, parse, startOfToday } from 'date-fns';
import * as yup from 'yup';

import {
  COMMON_ERROR_TEXT,
  HOME_COVER_TYPE,
  HOME_TYPE,
  marketingConsentInForm
} from '../../../constants/home';
import { HOME_RESUME_QUOTE } from '../../../constants/sessionStorage/homeStorageIdentifiers';
import { getItemFromSessionStorage } from '../../../helpers';
import { specifiedItemsMaxLengthErrorMsg } from '../../../utils/utils';

const oneOfCostRequired = 'Cost must be an whole number';

const yourCoverFormYupSchema = {
  policyStart: yup
    .date()
    .transform((_, dateString: string) => parse(dateString))
    .when([], {
      is: () =>
        JSON.parse(getItemFromSessionStorage(HOME_RESUME_QUOTE)) !== true,
      then: yup
        .date()
        .min(startOfToday(), 'Start date must be today or in the future.'),
      otherwise: yup.date()
    })
    .max(
      addDays(endOfToday(), 90),
      'Please enter a date 90 or less days in the future'
    )
    .required('Policy start date is required'),
  rebuildCost: yup
    .number()
    .transform((_: number, ov: string) => {
      const strippedValue = ov.replace(/,/g, ``);
      return strippedValue !== 'null' ? parseInt(strippedValue, 10) : null;
    })
    .nullable()
    // Need a limit on number size to keep integer validation working
    // Not sure why, but above 100 million seems like a good limit
    .test(
      'MinValue',
      // eslint-disable-next-line complexity
      function(val: number): any {
        const { county } = this.parent;
        if (county && county.toLocaleLowerCase().includes('dublin')) {
          return val < 175000
            ? this.createError({
                message: 'Requires minimum value 175,000'
              })
            : true;
        } else {
          return val < 130000
            ? this.createError({
                message: 'Requires minimum value 130,000'
              })
            : true;
        }
      }
    )
    .integer(oneOfCostRequired)
    .test(
      'rebuildOrContents',
      COMMON_ERROR_TEXT,
      // eslint-disable-next-line complexity
      function(val: number): any {
        const {
          checkRebuildCost,
          coverTypeValidation,
          homeTypeValidation
        } = this.parent;
        if (
          coverTypeValidation === HOME_COVER_TYPE.OWN_RENT &&
          homeTypeValidation === HOME_TYPE.APARTMENT
        ) {
          return true;
        }
        if (!checkRebuildCost) {
          return true;
        }
        return val != undefined && val !== 0 && val != null;
      },
      COMMON_ERROR_TEXT
    ),

  // eslint-disable-next-line complexity
  contentsCoverOf: yup
    .number()
    .transform((_: number, ov: string) => {
      const strippedValue = ov.replace(/,/gi, ``);
      return parseInt(strippedValue, 10);
    })
    .min(15000, 'Requires minimum value of €15,000')
    .integer(oneOfCostRequired)
    .test(
      'contentsOrRebuild',
      COMMON_ERROR_TEXT,
      // eslint-disable-next-line complexity
      function(val: number): boolean {
        const { coverTypeValidation, homeTypeValidation } = this.parent;
        if (
          coverTypeValidation === HOME_COVER_TYPE.OWNUNOCCUPIED ||
          coverTypeValidation === HOME_COVER_TYPE.OWNCONSTRUCTION
        ) {
          return true;
        }
        if (
          coverTypeValidation === HOME_COVER_TYPE.RENT ||
          homeTypeValidation === HOME_TYPE.APARTMENT
        ) {
          return val != undefined && val !== '';
        } else {
          return true;
        }
      },
      COMMON_ERROR_TEXT
    ),
  unspecifiedItems: yup.string().when('unspecifiedItemsSwitch', {
    is: true,
    then: yup.string().required('Unspecified Items value is required')
  }),

  specifiedItems: yup.array().when('specifiedItemsSwitch', {
    is: true,
    then: yup.array().of(
      yup.object().shape({
        item: yup.string().required('Please select an item type'),
        value: yup.string().required('Amount is Required'),
        description: yup
          .string()
          .max(80, specifiedItemsMaxLengthErrorMsg(80))
          .required('Please enter an item description')
      })
    )
  }),

  getInTouch: yup.boolean().test(
    'getInTouch',
    // eslint-disable-next-line complexity
    function(val: boolean): any {
      const { marketingConsent } = this.parent;
      if (marketingConsentInForm && marketingConsent && val === true) {
        return false;
      }
      return true;
    }
  ),

  marketingConsent:
    marketingConsentInForm &&
    yup
      .boolean()
      .oneOf([true], '')
      .required(COMMON_ERROR_TEXT),

  consent: yup.boolean().test(
    'consent',
    COMMON_ERROR_TEXT,
    // eslint-disable-next-line complexity
    function(val: boolean): any {
      const { marketingConsent } = this.parent;
      if (marketingConsentInForm && marketingConsent && val === undefined) {
        return false;
      }
      return true;
    }
  )
};

export default yourCoverFormYupSchema;
