const yearsHeldValues = [
  {
    displayName: '0',
    dropdownValue: 0
  },
  {
    displayName: '1',
    dropdownValue: 1
  },
  {
    displayName: '2',
    dropdownValue: 2
  },
  {
    displayName: '3',
    dropdownValue: 3
  },
  {
    displayName: '4',
    dropdownValue: 4
  },
  {
    displayName: '5',
    dropdownValue: 5
  },
  {
    displayName: '6 or more',
    dropdownValue: 6
  }
];

export default yearsHeldValues;
