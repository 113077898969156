const relationshipToProposerValues = [
  {
    displayName: 'Spouse',
    dropdownValue: 'SPOUSE'
  },
  {
    displayName: 'Child',
    dropdownValue: 'CHILD'
  },
  {
    displayName: 'Parent',
    dropdownValue: 'PARENT'
  },
  {
    displayName: 'Other',
    dropdownValue: 'OTHER'
  }
];

export default relationshipToProposerValues;
