/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './PaymentLoading.scss';
import '../../styles/commonPageStyles.scss';

import { CardWrapper, Title, TitleWithUnderLine } from '@boi/core/lib';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';
import { commonPageStylesIdentifier } from '../../constants';
import { HOME_PAYMENT_BACK_PRESSED } from '../../constants/sessionStorage/genericStorageIdentifiers';
import {
  removeHomeJourneyStorage,
  saveInSessionStorage
} from '../../helpers/sessionStorageHelper';

const className = 'c-Paymentloading';

const PaymentLoading = () => {
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((location: any, action: any) => {
      if (action === 'POP') {
        const confirmationMessage =
          'Policy generation is in progress. Leaving this page may result in payment being taken and no policy in place.';
        const userConfirmed = window.confirm(confirmationMessage);
        if (userConfirmed) {
          saveInSessionStorage(HOME_PAYMENT_BACK_PRESSED, true);
          removeHomeJourneyStorage();
        }
        return userConfirmed;
      }
      return true;
    });
    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      const confirmationMessage =
        'Are you sure you want to reload this page? Your changes may not be saved.';
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}`}>
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <TitleWithUnderLine>Get a Quote</TitleWithUnderLine>
        </div>
      </div>
      <CardWrapper>
        <div className={`${className}__icon`}>
          <AnimatedSpinner size="large" />
        </div>
        <>
          <Title type="h1" weight="bold" align="center">
            Generating Policy
          </Title>
        </>
        <p className={`${className}__text`}>
          This process may take several minutes, so please be patient{' '}
          <span className={`${className}__line-break`} />
          and do not select back, refresh or close this page.
        </p>
        <p className={`${className}__footer`}>Wait for policy confirmation</p>
      </CardWrapper>
    </div>
  );
};

export default PaymentLoading;
