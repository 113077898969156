/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { useReducer } from 'react';

import { rebuildPartOfQuote } from '../../helpers/BuildingCostHelper'

export const updateHomeType = 'showRebuildCost/UPDATE_HOME_TYPE';
export const updateCoverType = 'showRebuildCost/UPDATE_COVER_TYPE';

type RebuildCostStatusState = {
  homeType: string,
  coverType: string,
  isDisplayed: boolean;
};

type RebuildCostStatusAction = {
  type: typeof updateCoverType | typeof updateHomeType;
  payload: string;
};

const rebuildCostStatusReducer = (
  state: RebuildCostStatusState,
  action: RebuildCostStatusAction
) => {

  const actionResolvers = {
    [updateHomeType]: () => {
      const newHomeType = action.payload
      return {
        ...state,
        homeType: newHomeType,
        isDisplayed: rebuildPartOfQuote(newHomeType, state.coverType)
      }
    },
    [updateCoverType]: () => {
      const newCoverType = action.payload
      return {
        ...state,
        coverType: newCoverType,
        isDisplayed: rebuildPartOfQuote(state.homeType, newCoverType)
      }
    }
  }

  if(actionResolvers.hasOwnProperty(action.type)) {
    return actionResolvers[action.type]();
  }

  return state;
};

const initalState: RebuildCostStatusState = {
  homeType: '',
  coverType: '',
  isDisplayed: true
}

const useRebuildCostStatus = () => {
  const [internalStatus, dispatch] = useReducer<
    RebuildCostStatusState,
    RebuildCostStatusAction
  >(rebuildCostStatusReducer, initalState);

  return [internalStatus.isDisplayed, dispatch];
}

export default useRebuildCostStatus;
