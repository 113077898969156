// @flow
import '../../../../styles/commonFormStyles.scss';
import './CoverTypeDependantFields.scss';

import {
  ButtonGroup,
  Dropdown,
} from '@boi/core/lib';
import { FormikProps } from 'formik';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import { isFieldError } from '../../../../helpers';

type Props = {};

const CoverTypeDependantFields = (props: Props & FormikProps) => {
  const className = 'c-CoverTypeDependantFields';
  const {
    errors,
    handleChange,
    handleCustomBlur,
    setFieldValue,
    updateButtonGroupValue,
    values,
    touched
  } = props;

  const showTenantTypeDiv = () => {
    return (
    <div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="tenantType" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Are any of the tenants students?
        </label>
        <span className={`${className}__input`}>
          <div className={`${className}--fieldLabelSpacing`}>
            <ButtonGroup
              key="tenantType"
              name="tenantType"
              onClick={(e: string) => {
                updateButtonGroupValue('tenantType', e);
                setFieldValue('tenantType', e);
              }}
              options={[
                { label: 'Yes', value: 'Students' },
                { label: 'No', value: 'NonStudents' }
              ]}
              error={isFieldError('tenantType', touched, errors)}
              errorMessage={errors.tenantType}
              selected={values.tenantType}
              onBlur={handleCustomBlur.bind(this, 'tenantType')}
            />
          </div>
        </span>
      </div>
    {values.tenantType === 'NonStudents' ? (
        <div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor="numTenants" className={`${commonFormStylesIdentifier}__fieldLabel`}>
              How many tenants live in this property?
            </label>
            <span className={`${className}__input`}>
              <Dropdown
                key="numTenants"
                placeholder="Select here"
                name="numTenants"
                value={values.numTenants}
                onChange={handleChange}
                onBlur={handleCustomBlur.bind(this, 'numTenants')}
                error={isFieldError('numTenants', touched, errors)}
                errorMessage={errors.numTenants}
              >
                <option value="">Select a number</option>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((k: number) => (
                  <option key={`${k}-tenants`} value={k}>{k === 8 ? '8+' : k}</option>
                ))}
              </Dropdown>
            </span>
          </div>
        </div>
      ) : null
    }
    </div>
    )
  }

  const renderOwnRentFields = () => {
    return (
      <div
        className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`} >
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="privateLiving"
            className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Is this property solely occupied as let private living accommodation?
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                key="privateLiving"
                name="privateLiving"
                onClick={(e: string) => {
                  updateButtonGroupValue('privateLiving', e);
                  setFieldValue('privateLiving', e);
                }}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
                error={isFieldError('privateLiving', touched, errors)}
                errorMessage={errors.privateLiving}
                valueType='boolean'
                selected={values.privateLiving}
                onBlur={handleCustomBlur.bind(this, 'privateLiving')}
              />
            </div>
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="isFamilyUnit"
            className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Is this property let to a family or couple?
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                key="isFamilyUnit"
                name="isFamilyUnit"
                onClick={(e: string) => {
                  updateButtonGroupValue('isFamilyUnit', e);
                  setFieldValue('isFamilyUnit', e);
                  if(values.isFamilyUnit=== false){
                    setFieldValue('tenantType', '')
                  }
                }}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
                error={isFieldError('isFamilyUnit', touched, errors)}
                errorMessage={errors.isFamilyUnit}
                valueType='boolean'
                selected={values.isFamilyUnit}
                onBlur={handleCustomBlur.bind(this, 'isFamilyUnit')}
              />
            </div>
          </span>
        </div>
        {values.isFamilyUnit === false ? showTenantTypeDiv() : null}
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="tenancyAgreement"
            className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Are you registered with the Residential Tenancies Board (RTB) and have a minimum tenancy agreement of 6 months, including tenant references?
            Further information on residential tenancies is available on <a target="_blank" rel="noopener noreferrer" href='//www.rtb.ie/'>Home | Residential Tenancies Board </a>
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                key="tenancyAgreement"
                name="tenancyAgreement"
                onClick={(e: string) => {
                  updateButtonGroupValue('tenancyAgreement', e);
                  setFieldValue('tenancyAgreement', e);
                }}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
                error={isFieldError('tenancyAgreement', touched, errors)}
                errorMessage={errors.tenancyAgreement}
                valueType='boolean'
                selected={values.tenancyAgreement}
                onBlur={handleCustomBlur.bind(this, 'tenancyAgreement')}
              />
            </div>
          </span>
        </div>
      </div>
    );
  };

  const renderHolidayFields = () => {
    return (
      <div
        className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`} >
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="isInUse" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Is the property furnished and used on a regular basis as a holiday
            home? By &apos;regular basis&apos;, we mean that this property is occupied as a holiday home at least 30 days a year.
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                key="isInUse"
                name="isInUse"
                onClick={(e: string) => {
                  updateButtonGroupValue('isInUse', e);
                  setFieldValue('isInUse', e);
                }}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
                error={isFieldError('isInUse', touched, errors)}
                errorMessage={errors.isInUse}
                selected={values.isInUse}
                onBlur={handleCustomBlur.bind(this, 'isInUse')}
              />
            </div>
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="holidayHomeIsRented" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Do you rent or let out your holiday home?
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                key="holidayHomeIsRented"
                name="holidayHomeIsRented"
                onClick={(e: string) => {
                  updateButtonGroupValue('holidayHomeIsRented', e);
                  setFieldValue('holidayHomeIsRented', e);
                }}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
                error={isFieldError('holidayHomeIsRented', touched, errors)}
                errorMessage={errors.holidayHomeIsRented}
                selected={values.holidayHomeIsRented}
                onBlur={handleCustomBlur.bind(this, 'holidayHomeIsRented')}
              />
            </div>
          </span>
        </div>
        {values.holidayHomeIsRented.toString() === 'true' ? (
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor="isLetMoreThan5PerYear" className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Is the property let out more than 5 times a year?
            </label>
            <span className={`${className}__input`}>
              <div className={`${className}--fieldLabelSpacing`}>
                <ButtonGroup
                  key="isLetMoreThan5PerYear"
                  name="isLetMoreThan5PerYear"
                  onClick={(e: string) => {
                    updateButtonGroupValue('isLetMoreThan5PerYear', e);
                    setFieldValue('isLetMoreThan5PerYear', e);
                  }}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  error={isFieldError('isLetMoreThan5PerYear', touched, errors)}
                  errorMessage={errors.isLetMoreThan5PerYear}
                  selected={values.isLetMoreThan5PerYear}
                  onBlur={handleCustomBlur.bind(this, 'isLetMoreThan5PerYear')}
                />
              </div>
            </span>
          </div>
        ) : null}
      </div>
    );
  };

  const renderCoverTypeDependantFields = () => {
    switch (values.coverType) {
      case 'ownRent':
        return renderOwnRentFields();
      case 'ownHoliday':
        return renderHolidayFields();
      case 'rent':
      default:
        return null;
    }
  };

  return renderCoverTypeDependantFields();
};

export default CoverTypeDependantFields;
