/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import type { Node } from 'react';
import React, { createContext,useReducer } from 'react';

import { BOI_WALLET_USER_NAME_STORAGE } from './constants'
import { BOI_WALLET_USER_STORAGE, USER_BRANCH } from './constants/sessionStorage/userStorageIdentifiers';
import { getItemFromSessionStorage, saveInSessionStorage } from './helpers';

type ActionTypes = 'setFirstName' | 'setBranchName' | 'default';

type State = {
  firstName?: string,
  branchName?: any
};

type Action = {
  type: ActionTypes,
  payload: {
    firstName?: string,
    branchName?: any
  }
};

type ActionsObject = {
  [key: ActionTypes]: () => State
};

type Props = { 
  children: Node,
  initialValue?: {
    firstName?: string,
    branchName?: any
  };
};

const emptyFirstName: State = { firstName: '' };
const emptyBranchName: State = { branchName: {} };

const UserContext = createContext<any>([
  emptyFirstName,
  (a: Action) => {
    reducer(emptyFirstName, a);
  },
  emptyBranchName,
  (a: Action) => {
    reducer(emptyBranchName, a);
  }
]);

const setBranchNameData = (action: Action, state: State) => {
  saveInSessionStorage(USER_BRANCH, JSON.stringify(action.payload.branchName));
  return {
    ...state,
    branchName: action.payload.branchName
  }
}

const reducer = (state: State, action: Action) => {
  const actions: ActionsObject = {
    setFirstName: () => ({
      ...state,
      firstName: action.payload.firstName
    }),
    setBranchName: () => setBranchNameData(action, state),
    default: () => state
  };

  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type]();
  }

  return actions.default();
};

export const UserContextProvider = ({ children, initialValue }: Props) => {
  const initalState = initialValue ? initialValue : { firstName: '', branchName: {} };

  const [user, userDispatch] = useReducer(reducer, initalState);

  const getNameFromStorage = () => {
    const userName = getItemFromSessionStorage(BOI_WALLET_USER_NAME_STORAGE) === null ? 
    JSON.parse(getItemFromSessionStorage(BOI_WALLET_USER_STORAGE))?.attributes?.given_name: 
    getItemFromSessionStorage(BOI_WALLET_USER_NAME_STORAGE);

    return userName;
  };

  const getBranchNameFromStorage = () => {
    return JSON.parse(getItemFromSessionStorage(USER_BRANCH));
  };

  const getUserName = () => {
    if (user.firstName) return user.firstName;
    const storageName = getNameFromStorage();
    if (storageName) {
      userDispatch({
        type: 'setFirstName',
        payload: { firstName: storageName }
      });
      return storageName;
    }
    return '';
  };

  const getBranchName = () => {
    if (user.branchName) return user.branchName;
    const storageBranchName = getBranchNameFromStorage();
    if (storageBranchName) {
      userDispatch({
        type: 'setBranchName',
        payload: { branchName: storageBranchName }
      });
      return storageBranchName;
    }
    return '';
  };

  const value = {
    getUserName,
    getBranchName,
    user,
    userDispatch,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
