export const docHelper = () => {
  const newBusiness = policyDocs => {
    return (
      policyDocs?.NEW_BUSINESS?.length > 0 &&
      policyDocs?.RENEWAL_CONFIRMATION === undefined &&
      policyDocs?.LAPSE === undefined
    );
  };

  const renewalConfirmationAndNewBusiness = policyDocs => {
    return (
      policyDocs?.NEW_BUSINESS?.length > 0 &&
      policyDocs?.RENEWAL_CONFIRMATION?.length > 0 &&
      policyDocs?.LAPSE === undefined
    );
  };

  const renewalTransfer = policyDocs => {
    return (
      policyDocs?.NEW_BUSINESS === undefined &&
      policyDocs?.RENEWAL_CONFIRMATION === undefined &&
      policyDocs?.RENEWAL_TRANSFER?.length > 0 &&
      policyDocs?.LAPSE === undefined
    );
  };

  const newBusinessAndLapse = policyDocs => {
    return (
      policyDocs?.NEW_BUSINESS?.length > 0 &&
      policyDocs?.RENEWAL_CONFIRMATION === undefined &&
      policyDocs?.LAPSE?.length > 0
    );
  };

  return {
    newBusiness,
    renewalConfirmationAndNewBusiness,
    renewalTransfer,
    newBusinessAndLapse
  };
};
