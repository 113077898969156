// @flow
import { Dropdown, InputField } from "@boi/core/lib";
import { FormikProps } from "formik";
import React from 'react';

import { daysOfTheMonthValues } from '../../constants/dropdowns';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import { isFieldError, mapDropdownOptions } from '../../helpers';

const className = 'c-PaymentsPage';

interface Props {
  disablePolicyStart: boolean
}

const DirectDebit = (props: FormikProps & Props) => {
  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    disablePolicyStart,
    customDayOfTheMonthValues
  } = props;

  const ibanAndBicChangeHandler = (ev: SyntheticEvent<HTMLInputElement>) => {
    const value = ev.currentTarget.value;
    if (value === '') return setFieldValue(ev.currentTarget.name, '');
    if (value.match(/^[A-Za-z0-9]+$/) !== null) return setFieldValue(ev.currentTarget.name, value.toUpperCase());
  };

  return (
    <div className={`${className}__paddedContainer`}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="bic" className={`${commonFormStylesIdentifier}__fieldLabel`}>BIC</label>
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('bic', touched, errors)}
            errorMessage={errors.bic}
            placeholder="Type here"
            name="bic"
            value={values.bic.toUpperCase()}
            onChange={ibanAndBicChangeHandler}
            onBlur={handleBlur}
            autoComplete="off"
          />
        </span>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor="iban" className={`${commonFormStylesIdentifier}__fieldLabel`}>IBAN</label>
        <span className={`${className}__input`}>
          <InputField
            name="iban"
            placeholder="Type here"
            value={values.iban.toUpperCase()}
            error={isFieldError('iban', touched, errors)}
            errorMessage={errors.iban}
            onChange={ibanAndBicChangeHandler}
            onBlur={handleBlur}
            autoComplete="off"
          />
        </span>
      </div>
      {!disablePolicyStart ? 
        <div className={`${commonFormStylesIdentifier}__fieldContainerWithNoMargin`}>
          <label htmlFor="directDebitPaymentDate" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Payment collected each month on day?
          </label>
          <span className={`${className}__input`}>
            <Dropdown
              error={isFieldError('directDebitPaymentDate', touched, errors)}
              errorMessage={errors.directDebitPaymentDate}
              placeholder="Type here"
              name="directDebitPaymentDate"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select here</option>
              {mapDropdownOptions(customDayOfTheMonthValues ? customDayOfTheMonthValues : daysOfTheMonthValues, 'day_of_the_month')}
            </Dropdown>
          </span>
        </div> : <></>  
      }
      
    </div>
  );
};

export default DirectDebit;
