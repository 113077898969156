import './HomeTermsAndConditions.scss'

import PropTypes from 'prop-types';
import React from 'react';

import { QUOTE_REFERENCE } from '../../constants/home';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { HOME_QUOTE_STORAGE,HOME_QUOTE_THANK_YOU_STORAGE, SUBMITTED_GET_QUOTE_STORAGE } from '../../constants/sessionStorage';
import { getObjectFromSessionStorage, removeFromSessionStorage,saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import MainResidence from './MainResidence';

const HomeTermsAndConditions = ({history}) => {
  const className = 'c-HomeTermsAndConditions';

  const quoteData = getObjectFromSessionStorage(
    HOME_QUOTE_THANK_YOU_STORAGE
  );

  const isQuoteDataDifferent = (sessionStorageQuote, quoteData) => {
    const { aboutYouObj: sessionAboutYou } = sessionStorageQuote;
    const { aboutYouObj: currentAboutYou } = quoteData;
  
    return (
      sessionAboutYou?.firstName !== currentAboutYou?.firstName ||
      sessionAboutYou?.lastName !== currentAboutYou?.lastName ||
      sessionAboutYou?.ecad_identifier !== currentAboutYou?.ecad_identifier
    );
  };

  const isSessionStorageQuoteEmpty = (sessionStorageQuote) => {
    return Object.keys(sessionStorageQuote).length === 0;
  };

  const checkAndRemoveQuoteReference = () => {
    const sessionStorageQuote = getObjectFromSessionStorage(`${HOME_QUOTE_STORAGE}`);

    if (isSessionStorageQuoteEmpty(sessionStorageQuote)) return;

    if (isQuoteDataDifferent(sessionStorageQuote, quoteData)) {
      removeFromSessionStorage(`${QUOTE_REFERENCE}`);
    }
  }

  const goToThankYouPage = (assumptions) => {
    const linkAddress = `${routes.getQuoteThankYouPage.url}${HOME_INSURANCE_TYPE}`;
    const extraQuestionsValues = {
        roof_tile: assumptions.roof_tile,
        listed_or_protected: assumptions.listed_or_protected,
        operational_locks: assumptions.operational_locks,
        un_occupied: assumptions.un_occupied
    }
    const assumptionsPayload = Object.keys(assumptions).filter(key => !isNaN(parseInt(key))).map(key => ({ code: parseInt(key), response: assumptions[key] }))
    const payload = {...quoteData, assumptions: assumptionsPayload, extraQuestionsValues };
    checkAndRemoveQuoteReference();
    
    saveInSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE, JSON.stringify(payload))
    // Used to ensure a call to the backend is only made going forward through the journey
    saveInSessionStorage(`${HOME_INSURANCE_TYPE}${SUBMITTED_GET_QUOTE_STORAGE}`, true);
    history.push({
      pathname: linkAddress,
      state: payload
    });
  }

  return (
    <div className={className}>
      <MainResidence goToThankYouPage={goToThankYouPage} className={className} />
    </div>
  )
}

HomeTermsAndConditions.propTypes = {
  history: PropTypes.object
}

export default HomeTermsAndConditions;
