/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const NO_PREPOPULATION = 'Home/NO_PREPOPULATION';
export const PREPOPULATION_PREFORMED = 'Home/PREPOPULATION_PREFORMED';

export type HomePrepoulationCheck =
  | typeof NO_PREPOPULATION
  | typeof PREPOPULATION_PREFORMED;

export const HOME_COVER_TYPE = {
  OWN_LIVE: 'ownLive',
  OWN_RENT: 'ownRent',
  OWN_HOLIDAY: 'ownHoliday',
  RENT: 'rent',
  OWNUNOCCUPIED: 'ownUnoccupied',
  OWNCONSTRUCTION: 'ownConstruction',
  OWNRENOVATION: 'ownRenovation'
};

export const HOME_TYPE = {
  APARTMENT: 'Purpose built apartment'
};

export const EXCESS_AMOUNT_MAPPER = {
  '1': '1000',
  '2': '750',
  '3': '500',
  '4': '250'
};

export const EXCESS_AMOUNT_MAPPER_REVERSE = {
  '1000': '1',
  '750': '2',
  '500': '3',
  '250': '4'
};

export const INSURER_TYPE = {
  RSA: 'RSA',
  FBD: 'FBD'
};
export const FUTURE_ERROR = 'future_error';
export const GENERIC_FUTURE_ERROR_MESSAGE =
  'Please enter a year not in the future';
export const GENERIC_REQUIRED_MESSAGE = 'Field is required';
export const FIRST_NAME_ERROR_TEXT = 'First name is required';
export const LAST_NAME_ERROR_TEXT = 'Last name is required';

export const OTHER_TITLE_CONSTANT = 'OTHER';
export const FORCE_LOGIN = 'FORCE_LOGIN';

export const TITLES_CONSTANT = [
  'Select',
  'Mr.',
  'Mrs.',
  'Ms.',
  'Miss.',
  'Other.'
];
export const OPTION_TITLE_VALUES_CONSTANT = [
  '',
  'MR',
  'MRS',
  'MS',
  'MISS',
  'OTHER'
];
export const STAFF_CONSTANT = 'staff';
export const TITLES_TO_SHOW = TITLES_CONSTANT.length - 1;

export const PROMO_CODES = [
  'staff',
  'bawcode2250',
  'bawcode2260',
  'mortdms007',
  'boilxnb2525',
  'premier000',
  'bawdub2210'
];
export const COMMON_ERROR_TEXT = 'Answer is required';
export const ADDRESS_TOOLTIP_MSG_PRIMARY =
  'An Eircode is a seven-character alpha-numeric code. For example, A65 F4E2. You can find your Eircode by entering your home address at ';
export const ADDRESS_TOOLTIP_MSG_SECONDARY =
  'Or alternatively you can call us at 01 4884062 if you cannot provide an exact Eircode or full address.';

export const MORE_THAN_TWO = 'Please enter more than two characters';
export const LESS_THAN_FIFTY = 'Please enter less than fifty characters';

export const marketingConsentInForm = false;
export const QUOTE_REFERENCE = 'quoteReference';

export const REQUIRED_DOCUMENTS = 'reqdDocs';

export const HOME_TYPE_VALUE = {
  ownLive: 'I own and live in this property',
  ownRent: 'I own and rent out this property',
  ownHoliday: 'I own this property and use it as a holiday home',
  rent: 'I rent from someone',
  ownUnoccupied: 'I own this property and it is unoccupied',
  ownConstruction: 'I own this property and it is under construction',
  ownRenovation: 'I own this property and it is under renovation'
};

export const EXCESS = [
  {
    label:
      'Policy Excess €250 / Water Damage Excess €600 / Subsidence Excess €1,000',
    value: '4'
  },
  {
    label:
      'Policy Excess €500 / Water Damage Excess €850 / Subsidence Excess €1,000',
    value: '3'
  },
  {
    label:
      'Policy Excess €750 / Water Damage Excess €1,100 / Subsidence Excess €1,000',
    value: '2'
  },
  {
    label:
      'Policy Excess €1,000 / Water Damage Excess €1,350 / Subsidence Excess €1,000',
    value: '1'
  }
];

export const MODE_OF_CONTACT_ONLINE = 'CUSTOMER_PORTAL';

export const HOME_COVER_TYPE_VALUE = {
  'Owner Occupied': 'ownLive',
  'Rented - Family': 'rent',
  Unoccupied: 'ownUnoccupied',
  'Own Rented - Family': 'ownRent',
  'Rented - Students': 'ownRent',
  'Rented - One Person': 'ownRent',
  'Rented - Two People': 'ownRent',
  'Rented - Three People': 'ownRent',
  'Rented - Four People': 'ownRent',
  'Rented - Five People': 'ownRent',
  'Rented - Six People': 'ownRent',
  'Rented - Seven People': 'ownRent',
  'Rented - Eight People': 'ownRent',
  'Holiday Home - Let Out': 'ownHoliday',
  'Holiday Home - Family Use': 'ownHoliday',
  'Course of Construction': 'ownConstruction',
  'Under Renovation': 'ownRenovation'
};

export const GET_HOME_DOCUMENT = '/home/applied_home_documents/document';

export const DISPLAY_NUM_OF_FEATURES_VIEW_RENEWAL = 4;

export const DISPLAY_NUM_OF_FEATURES_VIEW_QUOTE = 3;

export const CHECKBOX_LABEL_FONT_SIZE = 16;

export const ABOUT_YOU_INITIAL_VALUES = {
  title: '',
  otherTitle: '',
  firstName: '',
  lastName: '',
  displayAddress: '',
  correspondence_displayAddress: '',
  addressLine1: '',
  addressLine2: '',
  town: '',
  county: '',
  eircode: '',
  is_correspondence_address: '',
  correspondence_addressLine1: '',
  correspondence_addressLine2: '',
  correspondence_county: '',
  correspondence_eircode: '',
  correspondence_town: '',
  phoneNo: '',
  email: '',
  dob: '',
  // employmentStatus: '',
  bankOfIrelandCustomer: '',
  boiMortgage: '',
  boiPersonalLoan: '',
  boiMotorInsurance: '',
  boiPension: '',
  mortgageProvider: '',
  mortgageApplicationNumber: '',
  promoCode: '',
  staffNumber: '',
  propertyJointlyOwned: '',
  jointlyOwnedDetails: {
    title: '',
    otherTitle: '',
    firstName: '',
    lastName: '',
    dateOfBirth: ''
  },
  firstTimeBuyer: '',
  modeOfContactPost: '',
  modeOfContactEmail: '',
  modeOfContactOnline: '',
  modeOfContact: '',
  selectedAppliedAddressIndex: '',
  correspondence_selectedAppliedAddressIndex: '',
  findMyAddress: '',
  correspondence_findMyAddress: '',
  lookup_response: '',
  geo_code: '',
  match_level: '',
  match_type: '',
  ecad_identifier: '',
  ecad_match_level: '',
  ecad_match_result: '',
  street_name: '',
  reference: '',
  policyLength: '',
  userName: ''
};

export const RENEWAL_POLICY_DOCS_SORT_ORDER = [
  'acceptance criteria',
  'renewal cover letter',
  'statement of fact',
  'policy schedule',
  'statement of claims',
  'letter of idemnity',
  'insurance product information document',
  'policy document',
  'terms of business'
];

export const SORT_DOCS_KEY = 'generated_by_business_process';

export const RENEWAL_QUOTES_DOCS = ['RENEWAL_OFFER', ''];

export const RSA_DOCS_SORT_ORDER = [
  'cover letter',
  'statement of fact',
  'statement of claims',
  'renewal cover letter',
  'schedule',
  'insurance product information document',
  'terms of business',
  'data privacy notice',
  'biis terms of business',
  'biis suitability statement',
  'policy book'
];

export const FBD_DOCS_SORT_ORDER = [
  'cover letter',
  'renewal notice',
  'important notice',
  'premium breakdown',
  'statement of claims',
  'premium and claims summary',
  'statement of fact',
  'insurance product information document',
  'data protection details',
  'biis suitability statement',
  'biis terms of business',
  'data privacy notice',
  'policy booklet'
];

export const BUSINESS_PROCESS = {
  RENEWAL_OFFER: 'RENEWAL_OFFER',
  QUOTATION: 'QUOTATION',
  LAPSE: 'LAPSE',
  RENEWAL_TRANSFER: 'RENEWAL_TRANSFER',
  RENEWAL_CONFIRMATION: 'RENEWAL_CONFIRMATION',
  NEW_BUSINESS: 'NEW_BUSINESS'
};

export const TYPE_OF_CLAIM_OR_LOSS_MAPING = {
  ACCIDENTAL_DAMAGE: 'Accidental Damage',
  BREAKAGE_OR_COLLAPSE_OF_TV_AERIALS: 'Breakage / Collapse Of Tv Aerials',
  COLLISION_AIRCRAFT_OR_VEHICLES_OR_LIVESTOCK:
    'Collision - Aircraft/ Vehicles / Livestock',
  ESCAPE_OF_OIL: 'Escape Of Oil',
  ESCAPE_OF_WATER: 'Escape Of Water',
  FALLING_TREES: 'Falling Trees',
  FIRE: 'Fire',
  LIABILITY: 'Liability',
  MALICIOUS_DAMAGE_OR_RIOT: 'Malicious Damage / Riot',
  STORM_DAMAGE: 'Storm Damage',
  THEFT: 'Theft',
  FLOOD: 'Flood',
  BURST_PIPES: 'Burst Pipes',
  FREEZER_BREAKDOWN: 'Freezer Breakdown',
  LIGHTNING: 'Lightning',
  AIRCRAFT: 'Aircraft',
  ALL_RISK_IF_A_TYPE_NOT_ALREADY_LISTED:
    'All Risks - If A Type Not Already Listed',
  EARTHQUAKE: 'Earthquake',
  EMPLOYERS_LIABILITY: 'Employers Liability',
  EXPLOSION: 'Explosion',
  EXPLOSION_CAUSED_BY_TERRORISM: 'Explosion - Caused By Terrorism',
  FIRE_CAUSED_BY_TERRORISM: 'Fire - Caused By Terrorism',
  FREEZER_CONTENTS: 'Freezer Contents',
  GLASS_OR_MIRRORS: 'Glass / Mirrors',
  MALICIOUS_DAMAGE: 'Malicious Damage',
  PUBLIC_LIABILITY_BUILDINGS: 'Public Liability - Buildings',
  PUBLIC_LIABILITY_CONTENTS: 'Public Liability - Contents',
  REMOVAL_OR_TRANSIT: 'Removal / Transit',
  REPAIR_OR_BLOCKED_SEWER: 'Repair Of Blocked Sewer',
  RIOT_CIVIL_COMMOTION: 'Riot Civil Commotion',
  SANITARY_WARE: 'Sanitary Ware',
  UNDERGROUND_SERVICE_PIPE: 'Underground Service Pipe',
  THEFT_FROM_CAR: 'Theft From Car',
  ACCIDENTAL_LOSS_OF_CONTENTS: 'Accidental Loss Of Contents',
  SUBSIDENCE: 'Subsidence',
  GROUND_HEAVE: 'Ground Heave',
  LANDSLIDE: 'Landslide',
  LANDSLIP: 'Landslip',
  IMPACT: 'Impact',
  ACCIDENTAL_LOSS: 'Accidental Loss',
  ACCIDENTAL_DAMAGE_TO_AUDIO_OR_VISUAL_EQUIPMENT:
    'Accidental Damage To Audio / Visual Equipment',
  LOSS_OF_CURRENCY_OR_CREDIT_CARDS: 'Loss Of Currency / Credit Cards',
  COASTAL_FLOOD: 'Coastal Flood',
  RIVERINE_FLOOD: 'Riverine Flood',
  FIRE_CAUSED_BY_ARSON: 'Fire - Caused By Arson'
};

export const TYPE_OF_CLAIM_OR_LOSS_VALUES: string[] = Object.keys(
  TYPE_OF_CLAIM_OR_LOSS_MAPING
);

export const TYPE_OF_CLAIM_OR_LOSS_OPTIONS = Object.values(
  TYPE_OF_CLAIM_OR_LOSS_MAPING
);

export const TYPE_OF_CLAIM_OR_LOSS_TO_SHOW =
  TYPE_OF_CLAIM_OR_LOSS_OPTIONS.length;

export const CONTENTS_COVER_TYPE_MAPING = {
  '€ 15,000': '15000',
  '€ 16,000': '16000',
  '€ 17,000': '17000',
  '€ 18,000': '18000',
  '€ 19,000': '19000',
  '€ 20,000': '20000',
  '€ 21,000': '21000',
  '€ 22,000': '22000',
  '€ 23,000': '23000',
  '€ 24,000': '24000',
  '€ 25,000': '25000',
  '€ 26,000': '26000',
  '€ 27,000': '27000',
  '€ 28,000': '28000',
  '€ 29,000': '29000',
  '€ 30,000': '30000',
  '€ 35,000': '35000',
  '€ 40,000': '40000',
  '€ 45,000': '45000',
  '€ 50,000': '50000',
  '€ 55,000': '55000',
  '€ 60,000': '60000',
  '€ 65,000': '65000',
  '€ 70,000': '70000',
  '€ 75,000': '75000',
  '€ 80,000': '80000',
  '€ 85,000': '85000',
  '€ 90,000': '90000',
  '€ 95,000': '95000',
  '€ 100,000': '100000',
  '€ 110,000': '110000',
  '€ 120,000': '120000',
  '€ 130,000': '130000',
  '€ 140,000': '140000',
  '€ 150,000': '150000',
  '€ 160,000': '160000',
  '€ 170,000': '170000',
  '€ 180,000': '180000',
  '€ 190,000': '190000',
  '€ 200,000': '200000',
  '€ 210,000': '210000',
  '€ 220,000': '220000',
  '€ 230,000': '230000',
  '€ 240,000': '240000',
  '€ 250,000': '250000',
  '€ 260,000': '260000'
};

export const CONTENTS_COVER_TYPE_VALUES: string[] = Object.keys(
  CONTENTS_COVER_TYPE_MAPING
);

export const CONTENTS_COVER_TYPE_OPTIONS = Object.values(
  CONTENTS_COVER_TYPE_MAPING
);
export const CONTENTS_TO_SHOW = CONTENTS_COVER_TYPE_VALUES.length - 1;

export const LATEST_QUOTE_SEARCH_USER_CONSTANT = ['Email', 'Branch'];
export const LATEST_QUOTE_OPTION_SEARCH_USER_CONSTANT = ['email', 'branch'];
export const LATEST_QUOTE_TITLES_TO_SHOW =
  LATEST_QUOTE_SEARCH_USER_CONSTANT.length - 1;

export const LATEST_QUOTE_LABELMAPING = {
  branch: 'BRANCH'
};

export const LOGIN_FEATURES = [
  'Alternative quote at your fingertips',
  'Pay your renewal premium via Card or Direct Debit',
  'No extra cost to pay by Direct Debit',
  'Secure access to your documents',
  'Buy Home, Travel and Motor Insurance'
];

export const BOLD_COUNT_WORDS = [2, 1, 3, 1, 1];
