// @flow

import { AccordionText } from "@boi/core/lib";
import React from 'react';

import { commonFormStylesIdentifier } from "../../constants";
import { marketingPhoneNumber } from '../../constants/phoneNumbers';

const className = "c-MarketingConsentForm";

const ConsentText = () => {

  const getControlDataLink = (str: string) => <a href='https://www.bankofireland.com/privacy/data-protection-notice/' target="_blank" rel="noopener noreferrer">{str}</a>

  return (
    <div className={`${className}__readMoreContainer`}>
      <div className={`${className}__visibleText`}>
        We{"'"}d like to contact you occasionally with news of products, services and offers 
        from Bank of Ireland Group and select trusted partners.
      </div>
      <AccordionText
        label="Find out more"
        labelColor="inherit"
        openLabel="Read less"
        underline
        inverted
      >
        <div className={`${className}__readMoreContent ${commonFormStylesIdentifier}__infoText`}>
          <div className={`${className}--spaceBellow`}>
            <span className={`${className}--bold`}>
            When you give us permission to get in touch, 
            you are allowing members of Bank of Ireland Group to send you information about relevant products, services and offers. 
            </span>
          </div>

          <div className={`${className}--spaceBellow`}>
          We will only send you information that we believe will be of interest to you, 
          can make your life easier, or offer you value for money. 
          </div>

          <div className={`${className}--spaceBellow`}>
            <span className={`${className}--bold`}>You are always in control of your data.</span> <br />
            You can change your marketing preferences at any time {getControlDataLink('online')} or by calling us on <a href={`tel:${marketingPhoneNumber}`}>{marketingPhoneNumber}</a>. 
            You can also tell us to stop sending you marketing emails by using the unsubscribe option at the end of each marketing email. 
            To find out more about how we manage your marketing preferences and personal data, {getControlDataLink('click here')}
          </div>

          <div className={`${className}--spaceBellow`}>
            <span className={`${className}--bold`}>You choose the way we contact you.</span> <br />
            This can be through any combination of email, phone, post, text, digital or other electronic messages. 
            Digital messaging includes displaying relevant messages through social media in secure format.
          </div>

          <div className={`${className}--spaceBellow`}>
            <span className={`${className}--bold`}>Bank of Ireland Group includes the following companies.</span> <br />
            Bank of Ireland, Bank of Ireland Mortgage Bank, Bank of Ireland Insurance Services Limited, Bank of Ireland Leasing Limited, 
            J&E Davy trading as Davy and New Ireland Assurance Company plc.
          </div>
        </div>
      </AccordionText>
    </div>
  );
};

export default ConsentText;

