/* eslint-disable flowtype/no-types-missing-file-annotation */
import { ButtonGroup, Checkbox } from '@boi/core/lib';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import { CHECKBOX_LABEL_FONT_SIZE } from '../../../../constants/home';
import { isFieldError } from '../../../../helpers';
import { saveData } from '../../../../helpers/HomeCommonMethods';
import { ChildComponentProps } from '../models/AboutYouFormModels';

const AboutYouFormBOIQuestions = (props: ChildComponentProps) => {
  const {
    className,
    setFieldValue,
    setFieldTouched,
    setAboutYouObjForUpdatedFields,
    errors,
    values,
    handleCustomBlurFunc,
    touched
  } = props;
  return (
    <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
      <div
        className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
      >
        <label
          htmlFor="bankOfIrelandCustomer"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Are you a Bank of Ireland Group Customer? If you hold any BOI product,
          such as a Current Account or Credit Card, please select Yes as your
          response.
          <div className={`${className}__infoText`}>
            If you select yes, you may need your bank account details prior to
            purchase
          </div>
        </label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            key="bankOfIrelandCustomer"
            name="bankOfIrelandCustomer"
            onClick={(value: boolean) => {
              setFieldValue('bankOfIrelandCustomer', value === 'true');
              setFieldTouched('bankOfIrelandCustomer', value);
              setAboutYouObjForUpdatedFields(
                'bankOfIrelandCustomer',
                value === 'true'
              );
              saveData('aboutYouObj','bankOfIrelandCustomer', value === 'true')
            }}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
            error={isFieldError('bankOfIrelandCustomer', touched, errors)}
            errorMessage={errors?.bankOfIrelandCustomer}
            selected={values?.bankOfIrelandCustomer}
            onBlur={e => {
              handleCustomBlurFunc(e, 'bankOfIrelandCustomer');
            }}
          />
        </div>
      </div>
      <div
        className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
      >
        <label
          htmlFor="bankOfIrelandCustomer"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Do you currently have any of the following products from Bank of
          Ireland Group?
        </label>
      </div>
      <div className={`${className}__fieldIndent`}>
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
        >
          <label
            htmlFor="boiMortgage"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          />
          <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
            <Checkbox
              id="boiMortgage"
              label="Mortgage"
              labelFontSize={CHECKBOX_LABEL_FONT_SIZE}
              name="boiMortgage"
              isChecked={values?.boiMortgage}
              onChange={() => {
                setFieldValue('boiMortgage', !values?.boiMortgage);
                saveData('aboutYouObj','boiMortgage', !values?.boiMortgage)
                setAboutYouObjForUpdatedFields(
                  'boiMortgage',
                  !values?.boiMortgage
                );
              }}
              value={values?.boiMortgage}
              onBlur={e => {
                handleCustomBlurFunc(e, 'boiMortgage');
              }}
            />
          </div>
        </div>
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
        >
          <label
            htmlFor="boiPersonalLoan"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          />
          <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
            <Checkbox
              id="boiPersonalLoan"
              label="Personal Loan"
              labelFontSize={CHECKBOX_LABEL_FONT_SIZE}
              name="boiPersonalLoan"
              isChecked={values?.boiPersonalLoan}
              onChange={() => {
                setFieldValue('boiPersonalLoan', !values?.boiPersonalLoan);
                saveData('aboutYouObj','boiPersonalLoan', !values?.boiPersonalLoan)
                setAboutYouObjForUpdatedFields(
                  'boiPersonalLoan',
                  !values?.boiPersonalLoan
                );
              }}
              value={values?.boiPersonalLoan}
              onBlur={e => {
                handleCustomBlurFunc(e, 'boiPersonalLoan');
              }}
            />
          </div>
        </div>
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
        >
          <label
            htmlFor="boiMotorInsurance"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          />
          <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
            <Checkbox
              id="boiMotorInsurance"
              label="Motor Insurance"
              labelFontSize={CHECKBOX_LABEL_FONT_SIZE}
              name="boiMotorInsurance"
              isChecked={values?.boiMotorInsurance}
              onChange={() => {
                setFieldValue('boiMotorInsurance', !values?.boiMotorInsurance);
                saveData('aboutYouObj','boiMotorInsurance', !values?.boiMotorInsurance)
                setAboutYouObjForUpdatedFields(
                  'boiMotorInsurance',
                  !values?.boiMotorInsurance
                );
              }}
              value={values?.boiMotorInsurance}
              onBlur={e => {
                handleCustomBlurFunc(e, 'boiMotorInsurance');
              }}
            />
          </div>
        </div>
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}
        >
          <label
            htmlFor="boiPension "
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          />
          <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
            <Checkbox
              id="boiPension"
              label="New Ireland Pension or Saving"
              labelFontSize={CHECKBOX_LABEL_FONT_SIZE}
              name="New Ireland Pension or Saving"
              isChecked={values?.boiPension}
              onChange={() => {
                setFieldValue('boiPension', !values?.boiPension);
                saveData('aboutYouObj','boiPension', !values?.boiPension)
                setAboutYouObjForUpdatedFields(
                  'boiPension',
                  !values?.boiPension
                );
              }}
              value={values?.boiPension}
              onBlur={e => {
                handleCustomBlurFunc(e, 'boiPension');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutYouFormBOIQuestions;
