/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { HOME_COVER_TYPE } from "../constants/home";
import { parseBool } from "../utils/utils";

//due to four types of cover types and many values changing
//this function creates an object with the relevant details depending on cover

type CoverTypeInfo = {
  dwelling_type: string;
  proposer_type: string;
  residence_type: string;
};

type CoverTypeValueType = {
  [key: string]: CoverTypeInfo
};

type YourHomeObjType = {
  tenantType: string;
  isFamilyUnit: string;
  holidayHomeIsRented: string;
};
const coverTypeValue: CoverTypeValueType = {
  ownLive: {
    dwelling_type: 'Owner Occupied',
    residence_type: 'Owner Occupied',
    proposer_type: 'Not applicable'
  },
  ownRent: {
    residence_type: 'Landlord',
    proposer_type: 'Landlord',
    dwelling_type: ''
    // dwelling_type calculated in getValuesDependingOnCoverType()
  },
  ownHoliday: {
    residence_type: 'Holiday',
    proposer_type: 'Holiday',
    dwelling_type: ''
    // dwelling_type calculated in getValuesDependingOnCoverType()
  },
  rent: {
    dwelling_type: 'Rented - Family',
    residence_type: 'Rented - Family',
    proposer_type: 'Not applicable'
  },
  ownUnoccupied: {
    dwelling_type: 'Unoccupied',
    residence_type: 'Unoccupied',
    proposer_type: 'Not applicable'
  },
  ownConstruction: {
    dwelling_type: 'Course of Construction',
    residence_type: 'Course of Construction',
    proposer_type: 'Not applicable'
  },
  ownRenovation: {
    dwelling_type: 'Under Renovation',
    residence_type: 'Under Renovation',
    proposer_type: 'Not applicable'
  }
};


// eslint-disable-next-line complexity
export const getValuesDependingOnCoverType = (yourHomeObj: any) => {
  if (yourHomeObj.coverType === 'ownRent') {
    let dwelling_type
    if (yourHomeObj.isFamilyUnit) {
      dwelling_type = 'Own Rented - Family'
    }
    else if (yourHomeObj.tenantType === 'Students') {
      dwelling_type = 'Rented - Students'
    }
    else {
        const numbers = { '1': 'One', '2': 'Two', '3': 'Three', '4': 'Four', '5': 'Five', '6': 'Six', '7': 'Seven', '8': 'Eight' }
        dwelling_type = (yourHomeObj.numTenants==='1') ? `Rented - ${numbers[`${yourHomeObj.numTenants}`]} Person` : `Rented - ${numbers[`${yourHomeObj.numTenants}`]} People`
    }
    return {
      ...coverTypeValue.ownRent,
      dwelling_type
    }
  }

  if(yourHomeObj.coverType === 'ownHoliday'){
    switch(parseBool(yourHomeObj.holidayHomeIsRented)) {
      case true :
        return {
          ...coverTypeValue.ownHoliday,
          dwelling_type: 'Holiday Home - Let Out'
        }
      case false: 
        return {
          ...coverTypeValue.ownHoliday,
          dwelling_type: 'Holiday Home - Family Use'
        } 
      default: 
        return {
          ...coverTypeValue.ownHoliday,
          dwelling_type: ''
        }
    }
  }
  return coverTypeValue[yourHomeObj.coverType];
};



// eslint-disable-next-line complexity
const getCoverType = (dwellingType: string) => {
  return Object
    .entries(coverTypeValue)
    /* eslint-disable-next-line flowtype/no-weak-types */
    .find((coverType: [string, any]) => coverType[1].dwelling_type === dwellingType);
}
// eslint-disable-next-line complexity
export const getCoverTypeForDwellingType = (dwellingType: string, value?: number, yourHomeObj: YourHomeObjType) => {

  let coverTypes = getCoverType(dwellingType)


  if (coverTypes) {
    return coverTypes[0];
  } else {
    switch(true) {
      case yourHomeObj.holidayHomeIsRented !== '': 
        return HOME_COVER_TYPE.OWN_HOLIDAY;
      case yourHomeObj.isFamilyUnit || yourHomeObj.tenantType === 'Students' || dwellingType.includes('Rented'):
        return HOME_COVER_TYPE.OWN_RENT;
      default: 
        return '';
    }
  }
};
  
