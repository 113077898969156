/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import { useState } from 'react';

export type FormStatus = {
  aboutYou: boolean,
  yourHome: boolean,
  yourCover: boolean
};

/**
 * Hook for the stateful logic of Form Details
 * Returns the Opened status of the three forms and of all the forms overall,
 * and a submit handler to pass down to the child forms
 * @returns an Array with the opened state as the first element,
 *  the submit handler as the second
 *
 */
const useSectionOpenedStatus = () => {
  const [baseOpened, setBaseOpened] = useState<FormStatus>({
    aboutYou: true,
    yourHome: true,
    yourCover: true
  });

  const handleFormOpen = (formName: string) => {
    setBaseOpened((baseOpened: FormStatus) => ({
      ...baseOpened,
      [formName]: true
    }));
  };

  const opened = {
    ...baseOpened,
    allForms: !Object.values(baseOpened).includes(false)
  };

  return [opened, handleFormOpen];
};

export default useSectionOpenedStatus;
