import '../PremiumBreakdowns/HomePremiumBreakdown/HomePremiumBreakdown.scss'

import { WideDivider } from '@boi/core/lib';
import { dividerColor } from '@boi/core/lib/constants/colors';
import { format } from 'date-fns';
import React from 'react'

import { TYPE_OF_CLAIM_OR_LOSS_MAPING } from '../../constants/home';

interface Props {
  claims: any[]
}
// eslint-disable-next-line flowtype/no-types-missing-file-annotation
const ClaimsSummary = ({ claims = [] }: Props) => {
  return (
    <div className='claims_section'>
      {claims?.length > 0 ? claims.map((item, index, array) => {
        const lastIndex = array.length - 1;
        return (
          <div key={item.claim_type} className='claim-container'>
            <div className="claim-title">Claim {index + 1}</div>
            <div className="detail-label">Type of claim/loss</div>
            <div className="claim-type">{TYPE_OF_CLAIM_OR_LOSS_MAPING[item.claim_type]}</div>
            <div className="detail-label">Date of claim</div>
            <div className="claim-date">{format(item.claim_date, 'DD/MM/YYYY')}</div>
            {index !== lastIndex && <WideDivider color={dividerColor} height={1} />}
          </div>
        )
      })
        :
        <div>
          <table className="table">
            <thead className="table-head">
              <tr className="row">
                <td className="cell">Type of claim/loss</td>
                <td className="cell">Date of claim</td>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="row">
                <td className="cell">No Claim</td>
                <td className="cell">N/A</td>
              </tr>
            </tbody>
          </table>
        </div>}
    </div>

  )
}
export default ClaimsSummary;
