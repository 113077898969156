import { CarIcon, HomeIcon, PlaneIcon } from '@boi/core/lib';
import React from 'react';

import { blueMagenta } from './colors';

export const iconsAndTitles = {
  home: {
    icon: <HomeIcon color={blueMagenta} />,
    title: 'Home'
  },
  car: {
    icon: <CarIcon color={blueMagenta} />,
    title: 'Car'
  },
  travel: {
    icon: <PlaneIcon color={blueMagenta} />,
    title: 'Travel'
  }
};
