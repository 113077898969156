/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import CallUsPage from '../../components/CallUsPage/CallUsPage';
import { iconsAndTitles } from '../../constants';
import { breakdownPhoneNumber } from '../../constants/phoneNumbers';


const BreakdownAssistance = ({ match, history }: { match: Match, history: RouterHistory }) => {
  const type = match.params.type || '';

  return <CallUsPage
    history={history}
    icon={type && iconsAndTitles[type].icon}
    title="Breakdown Assistance"
    contentText="For breakdown assistance please call us on"
    phoneNumber={breakdownPhoneNumber}
  />
};

export default withRouter(BreakdownAssistance);
