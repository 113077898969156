/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import '../../styles/commonPageStyles.scss';

import { TitleWithUnderLine } from '@boi/core/lib';
import React from 'react';

import {
    commonPageStylesIdentifier
  } from '../../constants';

interface Props {
  titleText: string;
  className: string;
  classes: string;
}

const FormHeader = (props: Props) => {
  const { titleText, className='', classes='' } = props;
  
  return (
    <>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop ${classes}`}>
        <div className={`${className}__mobileTitle`}>
          <TitleWithUnderLine>{titleText}</TitleWithUnderLine>
        </div>
      </div>
      <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly ${classes}`}>
        <div className={`${commonPageStylesIdentifier}__desktopTitle`}>
          {titleText}
        </div>
      </div>
    </>
  );
};

export default FormHeader;
