/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
export const yourCarCoverFormInitialValues = {
  coverType: '',
  policyStart: '',
  motorClaims: [],
  claimsCount: "",
  mainDriverPenaltyPointOffenceCount: "",
  mainDriverPenaltyPointOffences: [],
  mainDriverDisqualifiedOrConvicted: null,
  hasAdditionalDrivers: null,
  additionalDrivers: []
};
