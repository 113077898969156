/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './PolicyLinksPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  Button,
  CardWrapper,
  TitleWithUnderLine
} from '@boi/core/lib';
import PropTypes from 'prop-types';
import React, { useContext }  from 'react';
import { Link, withRouter } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, iconsAndTitles } from '../../constants';
import { backToHome } from '../../constants/buttonLabelConstants';
import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { getAccessToken } from '../../helpers';
import { routes } from '../../routes';
import { LinksPage }from './PolicyInsuranceLinksPages';
import { homeLinks, motorLinks, travelLinks } from './PolicyInsuranceLinksPages/policyLinks';

const className = 'c-PolicyLinksPage';

const PolicyLinksPage = (props) => {

  const { cognitoUser } = useContext(CognitoContext);
  const { match, history } = props;
  const insuranceType = match.params.type || '';

  const staticPolicyLinksPage = {
    [HOME_INSURANCE_TYPE]: homeLinks,
    [CAR_INSURANCE_TYPE]: motorLinks,
    [TRAVEL_INSURANCE_TYPE]: travelLinks
  };

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history}/>
      </div>
      <CardWrapper alignItemsCenter={false}>
      {insuranceType ?
        <div className={`${className}__iconContainer`}>
          <div className={`${className}__icon`}>{iconsAndTitles[insuranceType].icon}</div>
          <div className={`${className}__titleText`}>
            <TitleWithUnderLine>{`${iconsAndTitles[insuranceType].title} Documents`}</TitleWithUnderLine>
          </div>
        </div> : <></>}
      <div className={`${className}__contentContainer`}>
        <LinksPage
          cognitoAccessTokenKey={getAccessToken(cognitoUser)}
          history={history}
          insuranceType={insuranceType}
          staticLinks={staticPolicyLinksPage[insuranceType]}
        />
        <div className={`${className}__buttonContainer`}>
          <Link to={routes.wallet.url}>
            <Button fluid={true}>{backToHome}</Button>
          </Link>
        </div>
      </div>
      </CardWrapper>
    </div>
  );
};

PolicyLinksPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(PolicyLinksPage);
