/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorComponent.scss';

import { Card, TitleWithUnderLine } from '@boi/core/lib'
import type { Node } from 'react';
import React from 'react'
import type { RouterHistory } from 'react-router-dom';

import { commonPageStylesIdentifier } from '../../constants';
import { MobileBackButton } from '../MobileBackButton';


type ErrorComponentProps = {
    children: Node,
    title: string,
    history: RouterHistory,
    showMobileBtn: boolean
};

const className = 'c-Generic-Error-Component'

const GenericErrorComponent = ({children, title, history, showMobileBtn=true}: ErrorComponentProps) => {
    
  return (
        <div className={className}>
                {showMobileBtn && (
                    <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
                        <MobileBackButton history={history} />
                    </div>
                )}
            <Card>
                {title && (
                    <div className={`${commonPageStylesIdentifier}__pageTitle ${className}__title`}>
                        <TitleWithUnderLine>{title}</TitleWithUnderLine>
                    </div>
                )}
                <div className={`${className}__mainChild`}>
                    {children}
                </div>
            </Card>
        </div>
  )
}

export default GenericErrorComponent
