export const JOURNEY_GET_QUOTE = 'GET_QUOTE';
export const JOURNEY_YOUR_QUOTE = 'YOUR_QUOTE';
export const JOURNEY_YOUR_PRODUCT = 'YOUR_PRODUCT';
export const JOURNEY_PAYMENT = 'PAYMENT';
export const JOURNEY_ABOUT_YOU = 'ABOUT YOU';
export const JOURNEY_YOUR_HOME = 'YOUR HOME';
export const JOURNEY_YOUR_COVER = 'YOUR COVER';
export const RENEWAL_JOURNEY_LABEL_TEXT = ['Your Renewal', 'Your Summary', 'Payment'];
export const STAGES_RENEWAL_JOURNEY = [JOURNEY_GET_QUOTE, JOURNEY_YOUR_QUOTE, JOURNEY_PAYMENT]
export const HOME_JOURNEY = ['About You', 'Your Home', 'Your Cover', 'Your Quote', 'Payment']
export const STAGES_HOME_JOURNEY = [JOURNEY_ABOUT_YOU, JOURNEY_YOUR_HOME, JOURNEY_YOUR_COVER, JOURNEY_YOUR_QUOTE, JOURNEY_PAYMENT]
export const MOTOR_TRAVEL_JOURNEY = ['Your Details', 'Your Quote', 'Payment']
export const STAGES_MOTOR_TRAVEL_JOURNEY = [JOURNEY_GET_QUOTE, JOURNEY_YOUR_QUOTE, JOURNEY_PAYMENT]

