import {
  AccordionCard,
  Button,
  Title,
  TitleWithUnderLine
} from '@boi/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  commonPageStylesIdentifier,
  HOME_QUOTE_THANK_YOU_STORAGE
} from '../../../constants';
import {
  NO_PREPOPULATION,
  PREPOPULATION_PREFORMED
} from '../../../constants/home';
import {
  ABOUT_YOU,
  HOME_INSURANCE_TYPE
} from '../../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_HOME } from '../../../constants/journey';
import {
  GET_QUOTE_VALUES_STORAGE,
  SUBMITTED_GET_QUOTE_STORAGE
} from '../../../constants/sessionStorage';
import { HOME_LATEST_QUOTE_STORAGE } from '../../../constants/sessionStorage/homeStorageIdentifiers';
import {
  AGENT_CLICKED_REFERENCE_QUOTES,
  AGENT_SELECTED_QUOTE
} from '../../../constants/sessionStorage/userStorageIdentifiers';
import {
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  isAgent,
  removeFromSessionStorage,
  saveInSessionStorage
} from '../../../helpers';
import {
  saveDataInLatestQuote,
  scrollToFirstError
} from '../../../helpers/HomeCommonMethods';
import { yourHomeObjInitialValues } from '../../../pages/GetQuotePage/InitalValues';
import useGetQuotePageValidity from '../../../pages/GetQuotePage/useGetQuotePageValidity';
import useSectionOpenedStatus from '../../../pages/GetQuotePage/useSectionOpenedStatus';
import { routes } from '../../../routes';
import LegalText from '../../LegalText';
import { MobileBackButton } from '../../MobileBackButton';
import ProgressBar from '../../ProgressBar';
import FormikYourHomeForm from './YourHomeForm';

const className = 'c-GetQuotePage';
const YourHomeComponent = ({ history, location }) => {
  const [prepopulationStatus, setPrepopulationStatus] = useState(
    NO_PREPOPULATION
  );

  const [yourHomeObj, setYourHome] = useState(
    Object.assign({}, yourHomeObjInitialValues)
  );

  const [yourHomeFormTrackCount, setyourHomeFormTrackCount] = useState(0);

  const [opened, handleFormOpen] = useSectionOpenedStatus();
  const [validity, handleFormSubmit] = useGetQuotePageValidity();

  const setYourHomeObjForUpdatedFields = (fieldName, value) => {
    const newValues = { ...yourHomeObj };
    newValues[fieldName] = value;
    setYourHome(newValues);
  };

  const setUpdatedYourHomeValues = values => setYourHome(values);

  const renderSubmitButton = () => (
    <div className={`${className}__button`}>
      <a
        data-ga
        id={`GetQuote__getCoveredButton`}
        data-testid={`GetQuote__getCoveredButton`}
      >
        <Button
          quaternary
          fluid
          onClick={() => {
            setyourHomeFormTrackCount(yourHomeFormTrackCount + 1);
            if (validity.yourHome) {
              goToYourCoverPage();
            } else setTimeout(scrollToFirstError, 1);
          }}
        >
          Next
        </Button>
      </a>
    </div>
  );

  // eslint-disable-next-line complexity
  const goToYourCoverPage = () => {
    const linkAddress = `${routes.getQuotePage.url}yourCover`;

    const quoteData = {
      aboutYouObj:
        location?.state?.aboutYouObj ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.aboutYouObj,
      yourHomeObj: yourHomeObj,
      yourCoverObj:
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.yourCoverObj || null,
      assumptions:
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.assumptions ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.assumptions ||
        null,
      extraQuestionsValues:
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.extraQuestionsValues ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.assumptions ||
        null
    };
    saveInSessionStorage(
      HOME_QUOTE_THANK_YOU_STORAGE,
      JSON.stringify(quoteData)
    );

    const sessionStorageLatestQuote = getObjectFromSessionStorage(
      `${HOME_LATEST_QUOTE_STORAGE}`
    );
    if (Object.keys(sessionStorageLatestQuote).length !== 0) {
      saveDataInLatestQuote(
        sessionStorageLatestQuote,
        'yourHome',
        quoteData.yourHomeObj
      );
    }

    if (
      isAgent() &&
      getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)
    ) {
      removeFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES);
    }
    history.push({
      pathname: linkAddress,
      state: quoteData
    });
  };

  const getFormStatus = formName => {
    if (opened[formName]) {
      return validity[formName] ? 'success' : 'warning';
    }
    return 'default';
  };

  const sessionStoredValues = getObjectFromSessionStorage(
    `${HOME_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`
  );

  const loadYourHome = (object, override) => {
    const replaceOrKeepValue = key =>
      key in object && object[key] !== '' ? object[key] : yourHomeObj[key];
    setYourHome(
      Object.keys(yourHomeObj).reduce((newObj, key) => {
        newObj[key] = override ? object[key] : replaceOrKeepValue(key);
        return newObj;
      }, {})
    );
  };

  // eslint-disable-next-line complexity
  const preloadForm = () => {
    const objMaker = (oldObj, newObj) =>
      Object.assign({}, oldObj, newObj || {});
    const hasValues = obj =>
      typeof obj === 'object' && Object.keys(obj).length > 0;
    const submitted = getItemFromSessionStorage(
      `${HOME_INSURANCE_TYPE}${SUBMITTED_GET_QUOTE_STORAGE}`
    );
    const values = getObjectFromSessionStorage(
      `${HOME_QUOTE_THANK_YOU_STORAGE}`
    );
    const unSubmitted = () => !submitted && hasValues(values);
    const latestQuote = getObjectFromSessionStorage(
      `${HOME_LATEST_QUOTE_STORAGE}`
    );
    const submittedLatest = () =>
      hasValues(latestQuote) &&
      (!isAgent() || getObjectFromSessionStorage(AGENT_SELECTED_QUOTE));
    const submittedSession = () => hasValues(sessionStoredValues) && !isAgent();
    if (submittedLatest()) {
      setYourHome(oldYourHome =>
        objMaker(oldYourHome, values?.yourHomeObj || latestQuote?.yourHome)
      );
      if (values?.yourHomeObj === null) {
        saveInSessionStorage(
          HOME_QUOTE_THANK_YOU_STORAGE,
          JSON.stringify({ ...values, yourHomeObj: latestQuote?.yourHome })
        );
      }
      setPrepopulationStatus(PREPOPULATION_PREFORMED);
    } else if (unSubmitted()) {
      setYourHome(oldYourHome => objMaker(oldYourHome, values.yourHomeObj));
      if (values.yourHomeObj) {
        setPrepopulationStatus(PREPOPULATION_PREFORMED);
      }
    } else if (submittedSession()) {
      loadYourHome(sessionStoredValues, false);
      setPrepopulationStatus(PREPOPULATION_PREFORMED);
    }
  };

  useEffect(() => {
    preloadForm();
  }, []);

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_HOME} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Get Quotes</TitleWithUnderLine>
      </div>
      <div className={`${className}__subHeading`}>
        <Title type="h4" align="left" variant="black">
          Please complete the following:
        </Title>
      </div>
      <div className={`${className}__accordions`}>
        <AccordionCard
          id={`GetQuote__yourHome`}
          status={getFormStatus('yourHome')}
          label="Your home"
          childContainerClass={`${className}__noPaddingFormContainer`}
          isOnState={true}
        >
          <FormikYourHomeForm
            YourHomeValid={validity.yourHome}
            validForm={handleFormSubmit}
            formOpened={handleFormOpen}
            setUpdatedYourHomeValues={setUpdatedYourHomeValues}
            yourHomeValues={yourHomeObj}
            setYourHomeObjForUpdatedFields={setYourHomeObjForUpdatedFields}
            yourHomeFormTrackCount={yourHomeFormTrackCount}
            prepopulationStatus={prepopulationStatus}
            aboutYouObj={
              location?.state?.aboutYouObj ||
              getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
                ?.aboutYouObj
            }
          />
        </AccordionCard>
      </div>
      <div className={`${className}__buttonContainer`}>
        {renderSubmitButton()}
        <div className={`${className}__backButton`}>
          <Link to={`${routes.getQuotePage.url}${ABOUT_YOU}`}>
            <Button fluid={true}>Back</Button>
          </Link>
        </div>
      </div>
      <div className={`${className}__legalTextContainer`}>
        <LegalText insuranceType="home" />
      </div>
    </div>
  );
};

YourHomeComponent.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any
};

export default YourHomeComponent;
