/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './SubmitClaimPage.scss';

import {
  AccordionCard,
  Button,
  CardWrapper,
  TitleWithUnderLine,
} from '@boi/core/lib';
import { faLaptop, faMapMarkedAlt, faMobileAlt, faMousePointer } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import type { RouterHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { MobileBackButton } from '../../components/MobileBackButton';
import { iconsAndTitles } from '../../constants';
import { backToHome } from '../../constants/buttonLabelConstants';
import { fbdPhoneNumber, productPhoneNumber, rsaPhoneNumber } from '../../constants/phoneNumbers';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { CALL_TEXT, CLAIM_TEXT, EMAIL_TEXT, EMERGENCY_TEXT, POST_TEXT } from '../../constants/submitClaimConstants';
import { routes } from '../../routes';
import ContactCard from './ContactCard';

interface Props {
  history: RouterHistory,
  type: string
}

// eslint-disable-next-line complexity
const HomeClaimPage = ({ history, type }: Props) => {
  const className = 'c-SubmitClaimPage';

  const [accordion1Open, setAccordion1Open] = useState(false);
  const [accordion2Open, setAccordion2Open] = useState(false);

  const CALL_INFO = (
    <div>
      Line Opening Hours:
      <br />
      Mon – Fri: 9am-5pm
    </div>
  );

  const RSA_POST_INFO = (
    <div>
      For Policies Underwritten by RSA:
      <br />
      Bank Of Ireland Insurance Services (A)
      <br />
      P.O Box 13474 Business Reply Dublin 17
    </div>
  );

  const FBD_POST_INFO = (
    <div>
      For Policies Underwritten by FBD:
      <br />
      Bank Of Ireland Insurance Services (B)
      <br />
      P.O Box 13475 Business Reply Dublin 17
    </div>
  );

  const handleToggleAccordion1 = () => {
    setAccordion1Open(!accordion1Open);
    if (accordion2Open) {
      setAccordion2Open(false);
    }
  };

  const handleToggleAccordion2 = () => {
    setAccordion2Open(!accordion2Open);
    if (accordion1Open) {
      setAccordion1Open(false);
    }
  };

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <CardWrapper alignItemsCenter={false} className={`${className}__CardWrapper`}>
        {type && iconsAndTitles[type] ? (
          <div className={`${className}__homeiconContainer`}>
            <div className={`${className}__titleText`}>
              <TitleWithUnderLine>Submit a claim</TitleWithUnderLine>
            </div>
            <div className={`${className}__homeicon`}>
              {iconsAndTitles[type].icon}
            </div>
          </div>) : <></>}
        <div className={`${className}__textContainer`}>
          <div className={`${className}__infoTextHeading`}>
            How to make a claim or if you have further queries on policy
          </div>
          Use the option below to contact us about your claim or policy:
        </div>
        <div className={`${className}__homecontentContainer`}>
          <div className={`${className}__Accordion`}>
            <AccordionCard
              id="accordionCard"
              label={"For Policies Underwritten by RSA"}
              status="none"
              labelStyle="black"
              childContainerClass={`${className}__noPaddingAccordion`}
              customLabelClass={`${className}__accordionLabel`}
              labelImage={true}
              labelImageLink={'/asset/rsa_logo.jpg'}
              labelImageStyle={'rsa'}
              isOnState={accordion1Open}
              handleToggle={handleToggleAccordion1}
            >
              <ContactCard className={className} label={CALL_TEXT} contactData={productPhoneNumber} info={CALL_INFO} icon={faMobileAlt} />
              <ContactCard className={className} label={POST_TEXT} contactData={''} info={RSA_POST_INFO} icon={faMapMarkedAlt} />
              <ContactCard className={className} label={EMAIL_TEXT} contactData={'bankofirelandinsurancea@mailmetrics.com'} info={''} icon={faMousePointer} />
              <ContactCard className={className} label={CLAIM_TEXT} contactData={'https://www.rsagroup.ie/household-claims?_ga=2.20800632.1564579007.1692286057-1445629840.1666272979'} info={''} icon={faLaptop} />
              <ContactCard className={className} label={EMERGENCY_TEXT} contactData={rsaPhoneNumber} info={''} icon={faMobileAlt} />
            </AccordionCard>
          </div>
          <div className={`${className}__Accordion`}>
            <AccordionCard
              id="accordionCard"
              label={"For Policies Underwritten by FBD"}
              status="none"
              labelStyle="black"
              childContainerClass={`${className}__noPaddingAccordion`}
              customLabelClass={`${className}__accordionLabel`}
              labelImage={true}
              labelImageLink={'/asset/fbd_logo.jpg'}
              labelImageStyle={'fbd'}
              isOnState={accordion2Open}
              handleToggle={handleToggleAccordion2}
            >
              <ContactCard className={className} label={CALL_TEXT} contactData={productPhoneNumber} info={CALL_INFO} icon={faMobileAlt} />
              <ContactCard className={className} label={POST_TEXT} contactData={''} info={FBD_POST_INFO} icon={faMapMarkedAlt} />
              <ContactCard className={className} label={EMAIL_TEXT} contactData={'bankofirelandinsurancea@mailmetrics.com'} info={''} icon={faMousePointer} />
              <ContactCard className={className} label={CLAIM_TEXT} contactData={'https://www.fbd.ie/claims/home/'} info={''} icon={faLaptop} />
              <ContactCard className={className} label={EMERGENCY_TEXT} contactData={fbdPhoneNumber} info={''} icon={faMobileAlt} />
            </AccordionCard>
          </div>

          <div className={`${className}__buttonContainer`}>
            <Link to={routes.wallet.url}>
              <Button fluid={true}>{backToHome}</Button>
            </Link>
          </div>
        </div>
      </CardWrapper>
    </div>
  );
};

export default HomeClaimPage;
