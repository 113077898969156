import './MotorTermsAndConditionsPage.scss';

import { TitleWithUnderLine } from "@boi/core/lib";
import PropTypes from 'prop-types';
import React from 'react';

import { MobileBackButton } from '../../components/MobileBackButton';
import MotorTermsAndConditions from '../../components/MotorTermsAndConditions';
import ProgressBar from '../../components/ProgressBar';
import { JOURNEY_GET_QUOTE, MOTOR_TRAVEL_JOURNEY, STAGES_MOTOR_TRAVEL_JOURNEY } from '../../constants/journey';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';

const MotorTermsAndConditionsPage = ({history, location}) => {
  const className = 'p-MotorTermsAndConditionsPage'
  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history}/>
      </div>
      <ProgressBar stage={JOURNEY_GET_QUOTE} stages={STAGES_MOTOR_TRAVEL_JOURNEY} labelText={MOTOR_TRAVEL_JOURNEY}/>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Acceptance of our Terms and conditions</TitleWithUnderLine>
      </div>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          {/*{insuranceType && paymentTypeContent[insuranceType] ? paymentTypeContent[insuranceType]() : (<></>)}*/}
          <MotorTermsAndConditions history={history} location={location} />
        </div>
      </div>
    </div>
  )
}

MotorTermsAndConditionsPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

export default MotorTermsAndConditionsPage;
