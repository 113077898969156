(function() {
  if (
    process.env.REACT_APP_MANIFEST_DIR !== 'ci' &&
    process.env.REACT_APP_MANIFEST_DIR !== 'dev'
  ) {
    const getUrlPath = () => window.location.hash.replace(/(\/)?#\//, '');

    window.dataLayer.push({
      event: 'virtualPageview',
      urlPath: `/${getUrlPath()}`
    });

    window.addEventListener('hashchange', () => {
      window.dataLayer.push({
        event: 'virtualPageview',
        urlPath: `/${getUrlPath()}`
      });
    });
  }
})();
