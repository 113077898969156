/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GetQuoteThankYouGenericPage.scss';
import '../../styles/commonPageStyles.scss';

import React  from 'react';
import { Redirect } from 'react-router-dom';

import { routes } from '../../routes';
import type { AboutYouFormType, PayloadType, YourCarCoverFormTypes, YourCarFormTypes, YourTripFormType } from '../../types';

interface Props {
  values: AboutYouFormType & YourCarFormTypes & YourCarCoverFormTypes & YourTripFormType;
  payload: PayloadType;
  insuranceType: string;
}

const Success = ({ insuranceType }: Props) => {

  const getLinkAddress = () => {
    return `${routes.viewMultipleQuotes.url}${insuranceType}`;

  };

  return <Redirect push to={`${getLinkAddress()}`} />;
};

export default Success;
