/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../styles/commonFormStyles.scss';
import './MotorClaims.scss';

import {
  ButtonGroup,
  Dropdown,
  InputField,
  WideDivider
} from '@boi/core/lib';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { commonFormStylesIdentifier } from '../../constants';
import { dividerColor } from '../../constants/colors';
import motorClaimsValues from '../../constants/dropdowns/motorClaimsValues';
import { isFieldError } from '../../helpers';
import { decimalNumberMask } from '../../helpers/NumberMask';

const emptyClaim = {
  claim_type: undefined,
  claim_status: undefined,
  claim_amount: undefined
};

const untouchedEmptyClaim = {
  claim_type: false,
  claim_status: false,
  claim_amount: false
};

const MotorClaims = (props) => {
  const className = 'c-MotorClaims';
  const { name, errors, setFieldValue, setTouched, touched, values, onBlur } = props;

  const componentFieldName = name

  useEffect(() => {
    if (values.motorClaims.length > 0) {
      updateTouchedClaims();
      setFieldValue('claimsCount', values.motorClaims.length);
    }
  }, [setFieldValue, setTouched]);

  const updateTouchedClaims = () => {
    const touchedArraySize = touched.motorClaims ? touched.motorClaims.length : 0;
    const newTouchedClaims = touched.motorClaims ? [...touched.motorClaims] : [];
    const arraysSizeDiff = values.motorClaims.length - touchedArraySize;
    for (let i = 0; i < arraysSizeDiff; ++i) {
      newTouchedClaims.push({ ...untouchedEmptyClaim });
    }
    setTouched({ ...touched, claims: newTouchedClaims });
  };

  const getClaimTypeOptions = () => {
    return motorClaimsValues.map((motorClaimsValue, index) => {
      return <option key={`motor_claims_option_${index}`} value={motorClaimsValue.dropdownValue}>{motorClaimsValue.displayName}</option>
    });
  };

  const handleIndexChange = (value, fieldName, index) => {
    if (values.motorClaims && values.motorClaims[index]) {
      const newValues = [...values.motorClaims];
      const claim = { ...values.motorClaims[index] };
      claim[fieldName] = value;
      newValues[index] = claim;
      setFieldValue(componentFieldName, newValues);
    }
  };

  const handleBlurCustom = (fieldName, claimNumber) => {
    const newTouched = touched[componentFieldName] ? [ ...touched[componentFieldName]] : [untouchedEmptyClaim];
    if (claimNumber < newTouched.length) {
      newTouched[claimNumber][fieldName] = true;
      setTouched({ ...touched, motorClaims: newTouched });
    }
  };

  
  const handleCountChange = (newCount) => {
    setFieldValue('claimsCount', newCount);
    if (newCount !== "") {
      let i = 0, max = Number(newCount)
      const newValues = []
      while (i !== max) {
        if (values.motorClaims[i]) {
          newValues.push(values.motorClaims[i])
        } else {
          newValues.push(Object.assign({}, emptyClaim));
          touched.motorClaims.push(Object.assign({}, untouchedEmptyClaim));
        }
        ++i
      }
      setFieldValue('motorClaims', newValues)
    } else setFieldValue('motorClaims', [])
  };

  const checkClaimExists = (claimNumber) => {
    return (
      errors[componentFieldName] !== undefined &&
      errors[componentFieldName][claimNumber] &&
      touched[componentFieldName] !== undefined &&
      touched[componentFieldName][claimNumber]
    );
  };

  const checkFieldError = (fieldName, index) => {
    if (checkClaimExists(index)) {
      return (
        errors[componentFieldName][index][fieldName] !== undefined && touched[componentFieldName][index][fieldName]
      );
    }
  };

  const getErrorMessage = (fieldName, index) => {
    return checkFieldError(fieldName, index) ? errors[componentFieldName][index][fieldName] : null;
  };

  const getClaimValue = (claims, claimId, fieldName) => {
    if (claims.length > claimId && fieldName !== '') {
      return claims[claimId][fieldName];
    }
    return '';
  };

  const checkIfShownLastField = (index) => {
    return index + 1 === values.claimsCount ? `${commonFormStylesIdentifier}__fieldContainerWithNoMargin` : `${commonFormStylesIdentifier}__fieldContainer`;
  };

  const renderClaim = (motorClaims) => {
    if (values.claimsCount === 0) return <></>;
    const claims = [];
    for (let index = 0; index < values.claimsCount; index++) {
      claims.push(
        <div
          key={'Claim_' + (index + 1)}
          className={`${className}__claimSection`}
        >
          {index > 0 ? <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
            <WideDivider height={1} color={dividerColor}/>
          </div> : null}
          <div className={`${commonFormStylesIdentifier}__itemCountContainer`}>
            <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>Motor Claim {index + 1}</label>
          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor={`claim_type[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Type of claim
            </label>
            <Dropdown
              placeholder="Select here"
              error={checkFieldError('claim_type', index)}
              errorMessage={getErrorMessage('claim_type', index)}
              key={`claim_type[${index}]`}
              name={`claim_type[${index}]`}
              value={getClaimValue(motorClaims, index, 'claim_type')}
              onChange={(e) =>
                handleIndexChange(e.currentTarget.value, 'claim_type', index)
              }
              onBlur={() => handleBlurCustom('claim_type', index)}
            >
              <option value="">Select here</option>
              {getClaimTypeOptions()}
            </Dropdown>
          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor={`claim_status[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Claim status
            </label>
            <ButtonGroup
              error={checkFieldError('claim_status', index)}
              errorMessage={getErrorMessage('claim_status', index)}
              key={`claim_status[${index}]`}
              name={`claim_status[${index}]`}
              onClick={(value) => {
                handleIndexChange(value, 'claim_status', index);
                handleBlurCustom('claim_status', index);
              }}
              selected={getClaimValue(motorClaims, index, 'claim_status')}
              options={[
                { label: 'Closed', value: 'CLOSED' },
                { label: 'Open', value: 'OPEN' },
              ]}
            >
            </ButtonGroup>
          </div>
          <div className={checkIfShownLastField(index)}>
            <label htmlFor={`claim_amount[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Estimated claim amount
            </label>
            <InputField
              error={checkFieldError('claim_amount', index)}
              placeholder="Enter an amount"
              prefix={faEuroSign}
              errorMessage={getErrorMessage('claim_amount', index)}
              key={`claim_amount[${index}]`}
              name={`claim_amount[${index}]`}
              value={getClaimValue(motorClaims, index, 'claim_amount')}
              onChange={(e) =>
                handleIndexChange(e.currentTarget.value, 'claim_amount', index)
              }
              onBlur={() => handleBlurCustom('claim_amount', index)}
              masked
              mask={decimalNumberMask}
            />
          </div>
        </div>
      );
    }
    return <div className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`}>
      {claims}
    </div>
  };

  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__counterPadding`}>
        <Dropdown
          name="claimsCount"
          value={values.claimsCount}
          onChange={(e) => handleCountChange(e.target.value)}
          onBlur={(e) => onBlur('claimsCount', e)}
          error={isFieldError('claimsCount', touched, errors)}
          errorMessage={errors.claimsCount}
        >
          <option value="">Select here</option>
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
        </Dropdown>
      </div>
      {values.motorClaims.length > 0 ? renderClaim(values.motorClaims) : <></>}
    </div>
  );
};

MotorClaims.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  setTouched: PropTypes.func,
  touched: PropTypes.any,
  values: PropTypes.object,
  onBlur: PropTypes.func
}

export default MotorClaims;
