import { AccordionText, DatePicker, Label, SimpleSwitch, WideDivider } from '@boi/core/lib';
import PropTypes from "prop-types";
import React from 'react';

import { dividerColor } from '../../constants/colors';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import { MULTI_TRIP, SINGLE_TRIP } from '../../constants/travel';
import { getDateOnly } from '../../helpers/DateHelper';
import { className } from './ViewTravelQuotePage';

const SingleTripExtras = ({ handleChangeWithRecalculate, handleBlur, values,
                            errors, touched, setTripType }) => {

  const getTravelDateValue = (travelDate) => {
    /* If travel date is defined call getDateOnly else return the value that was
       passed to initial values. Whether it be '' or null or undefined */
    return travelDate ? getDateOnly(travelDate) : travelDate;
  };

  const getPolicyStartDate = () => {
    return values.isMultiTrip ? (
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <Label htmlFor="policyStartDate" className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Policy start date
        </Label>
        <DatePicker
          name="policyStartDate"
          error={errors.policyStartDate}
          placeholder="Type here"
          onBlur={handleBlur}
          touched={touched.policyStartDate}
          onChange={(event) => {
            handleChangeWithRecalculate('policyStartDate', event.target.value);
          }}
          value={getTravelDateValue(values.policyStartDate)}
        />
      </div>
    ) : <></>;
  };

  return (<>
    <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
      <WideDivider height={1} color={dividerColor} />
    </div>
    <div className={`${className}__switchContainer ${className}__paddedContainer`}>
      <label htmlFor="isMultiTrip" className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}>
        Upgrade to Annual Multi-Trip
      </label>
      <SimpleSwitch
        name="isMultiTrip"
        onLabel="on"
        onClick={() => {
          const isMultiTripValue = !values.isMultiTrip;
          isMultiTripValue ? setTripType(MULTI_TRIP): setTripType(SINGLE_TRIP);
          handleChangeWithRecalculate('isMultiTrip', isMultiTripValue);
          if (!isMultiTripValue) {
            // policyStartDate needs to be set as undefined as it is a date type input field
            handleChangeWithRecalculate('policyStartDate', undefined);
          }
        }}
        on={values.isMultiTrip}
      />
    </div>
    <div className={`${className}__paddedContainer ${className}__quoteInfoContainer`}>
      <AccordionText label="Read more" icon="default"
                     customLabelClass={`${className}__readMoreLabel ${commonFormStylesIdentifier}__infoText`}
      >
        <div className={`${commonFormStylesIdentifier}__infoText`}>
          An Annual Multi-Trip policy could save you money if you are
          going on holiday more than once this year. It offers cover
          for up to 90 days travel in the year, but no holiday can be
          longer than 31 days. With an Annual Multi-Trip policy, you
          can also be covered for holidays in Ireland provided that
          you spend at least two nights in accommodation you booked
          before your departure.
        </div>
      </AccordionText>
    </div>
    <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
      <WideDivider height={1} color={dividerColor} />
    </div>
    {getPolicyStartDate()}
  </>);
};

SingleTripExtras.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChangeWithRecalculate: PropTypes.func,
  setTripType: PropTypes.func
};

export default SingleTripExtras;
