/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './NewPassword.scss';

import Auth from '@aws-amplify/auth';
import { BOIGroupLogo, Button, InputField } from '@boi/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useContext } from 'react';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import { primaryBlue } from '../../constants/colors';
import { MFA_TYPE, passswordMsg, passwordRegexPattern } from '../../constants/loginRegistrationConstants';
import { routes } from '../../routes';
import type { CognitoUser } from '../../types';

interface Props {
  email: string;
}

interface Values {
  email: string;
  password: string;
}

const NewPasswordForm = (props: Props & FormikProps<Values>) => {
  const className = 'c-NewPassword';
  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    isValid,
    setFieldError,
    touched,
    values
  } = props;
  const { cognitoUser, setCognitoUser } = useContext(CognitoContext);
  const isFieldError = (status: string) => {
    return dirty && touched[status] && errors[status] !== undefined;
  };

  if (!cognitoUser && (!props.location.state || !props.location.state.user)) props.history.push(routes.loginPage.url);

  const completeNewPassword = async (newPassword: string) => {
    Auth.completeNewPassword(cognitoUser || props.location.state.user, newPassword)
      .then((user: CognitoUser) => {
        // at this time the user is logged in if no MFA required
        if(user.challengeName === MFA_TYPE) {
          props.history.push(routes.loginPage.url);
        } else {
          setCognitoUser(user);
          props.history.push(routes.wallet.url);
        }
      })
      .catch((error: Error) => {
        setFieldError('confirmNewPassword', error.message);
      });
  };

  return (
    <Form autoComplete={"off"}>
      <div className={`${className}`}>
        <div className={`${className}__container`}>
          <BOIGroupLogo color={primaryBlue} />
          <div className={`${className}__title`}>{'Reset your password'}</div>

          <div className={`${className}__fieldLabel`}>
            <label htmlFor="newPassword" className={`${className}__label`}>
              New Password
            </label>
            <span className={`${className}__input`}>
            <InputField
              error={isFieldError('newPassword')}
              errorMessage={errors.newPassword}
              name="newPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter new password"
              touched={touched.newPassword}
              type="password"
              autoComplete={"off"}
              values={values.newPassword}
            />
          </span>
          </div>
          <div className={`${className}__fieldLabel`}>
            <label htmlFor="confirmNewPassword" className={`${className}__label`}>
              Confirm New Password
            </label>
            <span className={`${className}__input`}>
            <InputField
              error={isFieldError('confirmNewPassword')}
              errorMessage={errors.confirmNewPassword}
              name="confirmNewPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Confirm new password"
              touched={touched.confirmNewPassword}
              type="password"
              autoComplete={"off"}
              values={values.confirmNewPassword}
            />
          </span>
          </div>
          <div className={`${className}__button`}>
            <Button
              id='CompleteNewPassword__button'
              fluid={true}
              quaternary
              disabled={!isValid}
              onClick={async () => {
                await completeNewPassword(values.newPassword);
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

const NewPassword = withFormik<Props, Values>({
  mapPropsToValues(): {} {
    return {
      newPassword: '',
      confirmNewPassword: ''
    };
  },
  handleSubmit(
    values: Values,
    { setSubmitting }: FormikProps
  ) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    newPassword: yup.string()
      .required('Please enter a new password')
      .matches(passwordRegexPattern, passswordMsg),
    confirmNewPassword: yup.string()
      .required('Please confirm new password')
      .matches(passwordRegexPattern, passswordMsg)
      .test('passwords-match', 'Passwords must match', function(
        value: string
      ): boolean {
        return this.parent.newPassword === value;
      })
  }),
  displayName: 'NewPasswordForm'
})(NewPasswordForm);

export default NewPassword;
