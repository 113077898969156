/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import type { InsuranceType } from '../types/commonTypes';
import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from './insuranceTypeConstants';

export const HOME_POLICY_TYPE = 'HOME';
export const MOTOR_POLICY_TYPE = 'MOTOR';
export const TRAVEL_POLICY_TYPE = 'TRAVEL';

export const COMPREHENSIVE_TYPE = 'COMPREHENSIVE';
export const THIRD_PARTY_AND_THEFT_TYPE = 'THIRD_PARTY_AND_THEFT';

export const policyTypeIdentifierLookup: { [InsuranceType]: string } = {
  [HOME_INSURANCE_TYPE]: HOME_POLICY_TYPE,
  [CAR_INSURANCE_TYPE]: MOTOR_POLICY_TYPE,
  [TRAVEL_INSURANCE_TYPE]: TRAVEL_POLICY_TYPE
};

export const policyTypeToInsuranceTypeLookup: { [string]: string } = {
  [HOME_POLICY_TYPE]: HOME_INSURANCE_TYPE,
  [MOTOR_POLICY_TYPE]: CAR_INSURANCE_TYPE,
  [TRAVEL_POLICY_TYPE]: TRAVEL_INSURANCE_TYPE
};
