import { Card, TitleWithUnderLine } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import React from 'react'

import { commonPageStylesIdentifier } from '../../constants';
import { DISPLAY_NUM_OF_FEATURES_VIEW_RENEWAL, HOME_COVER_TYPE_VALUE, HOME_TYPE_VALUE } from '../../constants/home';
import QuoteRenewalOverview from '../QuoteRenewalOverview/QuoteRenewalOverview'


interface PROPS {
    classes: string;
    goToViewQuotePage: noop;
    quotesFinal: any;
    benefits: any;
}
// eslint-disable-next-line flowtype/no-types-missing-file-annotation, complexity
const QuoteRenewalOverviewContainer = ({classes, goToViewQuotePage, quotesFinal, benefits}: PROPS) => {

    return (  
        <div className={`${classes}__renewalCards`}>
                {quotesFinal?.currentRenewal && (
                        <Card>
                            <QuoteRenewalOverview
                                quoteTitle={<div className={`${commonPageStylesIdentifier}__pageTitle`}>
                                <TitleWithUnderLine>Your Renewal</TitleWithUnderLine>
                              </div>}
                                quoteType={HOME_TYPE_VALUE[HOME_COVER_TYPE_VALUE[quotesFinal.currentRenewal.cover_details_information.risk_address.dwelling_type]]}
                                differentStyle={false}
                                eircode={quotesFinal.currentRenewal.cover_details_information.risk_address.post_code}
                                policy_code={quotesFinal.currentRenewal.policy_code}
                                policy_id={quotesFinal.currentRenewal.policy_id}
                                scheme_code={quotesFinal.currentRenewal.scheme_code}
                                dates={{
                                    startDate: quotesFinal.currentRenewal.cover_details_information.start_date,
                                    endDate: quotesFinal.currentRenewal.cover_details_information.end_date
                                }}
                                direct_debit_policy={quotesFinal.currentRenewal.direct_debit_policy}
                                showBoi
                                insurer={quotesFinal.currentRenewal.insurer}
                                alternativeQuote={false}
                                underwritten={`${process.env.PUBLIC_URL}/asset/${(quotesFinal.currentRenewal.insurer).toLowerCase()}_logo.jpg`}
                                underwrittenClass={`${(quotesFinal.currentRenewal.insurer).toLowerCase()}Logo`}
                                quoteAmount={quotesFinal.currentRenewal.price}
                                quoteFeatures={benefits}
                                showUpToFeature={DISPLAY_NUM_OF_FEATURES_VIEW_RENEWAL}
                                onMoreInfoClick={() =>
                                    goToViewQuotePage(quotesFinal.currentRenewal.insurer,  {alternativePolicy: false})
                                }
                            />
                        </Card>
                        )
                }

                {quotesFinal?.alternativeQuote?.length > 0 && quotesFinal.alternativeQuote.map((item, idx) => {
                    return (
                            <>
                                <br/>
                                <Card key={`${item.insurer}${idx}`}>
                                    <QuoteRenewalOverview
                                        quoteTitle={<div className={`${commonPageStylesIdentifier}__pageTitle`}>
                                        <TitleWithUnderLine>Your Alternative Quote</TitleWithUnderLine>
                                        </div>}
                                        quoteType={HOME_TYPE_VALUE[HOME_COVER_TYPE_VALUE[item.cover_details_information.risk_address.dwelling_type]]}
                                        differentStyle={false}
                                        eircode={item.cover_details_information.risk_address.post_code}
                                        policy_code={item.policy_code}
                                        insurer={item.insurer}
                                        scheme_code={item.scheme_code}
                                        policy_id={item.policy_id}
                                        dates={{
                                            startDate: item.cover_details_information.start_date,
                                            endDate: item.cover_details_information.end_date
                                        }}
                                        direct_debit_policy={false}
                                        showBoi
                                        alternativeQuote={true}
                                        underwritten={`${process.env.PUBLIC_URL}/asset/${(item.insurer).toLowerCase()}_logo.jpg`}
                                        underwrittenClass={`${(item.insurer).toLowerCase()}Logo`}
                                        quoteAmount={item.price}
                                        quoteFeatures={benefits}
                                        showUpToFeature={DISPLAY_NUM_OF_FEATURES_VIEW_RENEWAL}
                                        onMoreInfoClick={() =>
                                            goToViewQuotePage(item.insurer,  {alternativePolicy: true})
                                        }
                                    />
                                </Card>
                            </>

                        
                        )
                })}
    </div>
  )
}

export default QuoteRenewalOverviewContainer;
