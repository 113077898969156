/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './BoiNotificationCard.scss';

import { BOIWhiteLogo, CardWrapper, CarIcon, HomeIcon, PlaneIcon, XIcon } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import type { Node } from 'react';
import React, { PureComponent } from 'react';

import { blueNotification } from '../../constants/colors';

interface Props {
  color: string;
  children: Node;
  height: string;
  icon?: string;
  heading: noop;
  text: string;
}

interface State {
  isDisplayed: boolean;
}

class BoiNotificationCard extends PureComponent<Props, State> {
  state = {
    isDisplayed: true
  };

  static defaultProps = {
    color: blueNotification,
    height: '100%',
    heading: '',
    text: ''
  };

  hideCard = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.setState({ isDisplayed: false });
  };

  render() {
    const className = 'c-BoiNotificationCard';

    const { color, icon, heading, children, height } = this.props;

    const icons = {
      boi: <BOIWhiteLogo color={color} />,
      home: <HomeIcon color={color} solid={false} />,
      car: <CarIcon color={color} solid={false} />,
      travel: <PlaneIcon color={color} solid={false} />
    };

    const iconSection = icon ? (<div className={`${className}__icon`}>{icons[icon]}</div>) : null;

    return (
      <div>
        {this.state.isDisplayed ? (
          <div className={className} style={{ height: height }}>
            <CardWrapper>
              <div className={`${className}__cardContents`}>
                {iconSection}
                <div className={`${className}__cardText`}>
                  {heading()}
                  {children}
                </div>

                <div
                  className={`${className}__closeIcon`}
                  onClick={this.hideCard}
                >
                  <XIcon />
                </div>
              </div>
            </CardWrapper>
          </div>
        ) : (
            ''
          )}
      </div>
    );
  }
}

export default BoiNotificationCard;
