import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { amplifyAnalytics } from './amplifyAnalytics';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    //Send an event to AWS Pinpoint when the route changes
    amplifyAnalytics({
      name: 'PAGE_VIEW_TRACKER',
      attributes: { route: location.pathname }
    })
  }, [location.pathname]);

  return null;
};

export default RouteChangeTracker;
