/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../../styles/commonFormStyles.scss';

import { AccordionText, AutoAddressAutocomplete, ButtonGroup } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import { connect, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CAR_INSURANCE_TYPE } from '../../../../../constants/insuranceTypeConstants';
import { commonFormStylesIdentifier } from '../../../../../constants/styleConstants';
import { isFieldError } from '../../../../../helpers';
import { inFormStructure, InsuranceTypeContext } from '../../../../../pages/GetGenericQuotePage';
import { aboutYouFormIdentifier } from '../../../../Forms/CommonFiles/commonFormConstants';
import AddressInfo from './AddressInfo';
import { Address } from './AddressInterface';

interface Props { }

export const BaseAddressDetails = (props: Props & FormikProps) => {
  const className = 'c-AddressDetails';
  const defaultAddressFieldPrefix = 'address';
  const postalAddressFieldPrefix = 'postalAddress';
  const {
    setFieldValue,
    setFieldTouched,
    setTouched,
    touched,
    values,
    errors,
    handleBlur,
    handleChange,
    submitCount
  } = props.formik;
  const insuranceType = useContext(InsuranceTypeContext);
  const [releaseAddressError, setReleaseAddressError] = useState(false);
  useEffect(() => {
    if (submitCount > 0)
      setReleaseAddressError(true)
  }, [submitCount])

  const setSelectedAddress = (addressFieldNamesPrefix: string, address: Address) => {
    const addressWithUpdatedFieldNames = {
      [`${addressFieldNamesPrefix}Line1`]: address.addressLine1,
      [`${addressFieldNamesPrefix}Line2`]: address.addressLine2,
      [`${addressFieldNamesPrefix}Line3`]: address.addressLine3,
      [`${addressFieldNamesPrefix}Town`]: address.town,
      [`${addressFieldNamesPrefix}County`]: address.county,
      [`${addressFieldNamesPrefix}Eircode`]: address.eircode,
    };
    Object.entries(addressWithUpdatedFieldNames)
      .forEach(([k, v]: [string, mixed]) => {
        setFieldValue(k, v || '');
      });
    /* You only need to check if one of the read only
       fields has been touched to know if they all have */
    if (!touched[`${addressFieldNamesPrefix}Line1`]) {
      setReadOnlyAddressFieldsAsTouched(addressFieldNamesPrefix);
    }
  };

  const emptyAddress = (addressFieldNamesPrefix: string) => {
    const addressWithUpdatedFieldNames = {
      [`${addressFieldNamesPrefix}Line1`]: '',
      [`${addressFieldNamesPrefix}Line2`]: '',
      [`${addressFieldNamesPrefix}Line3`]: '',
      [`${addressFieldNamesPrefix}Town`]: '',
      [`${addressFieldNamesPrefix}County`]: '',
      [`${addressFieldNamesPrefix}Eircode`]: '',
    };
    Object.entries(addressWithUpdatedFieldNames)
      .forEach(([k, v]: [string, mixed]) => {
        setFieldValue(k, v || '');
      });
  };

  const setReadOnlyAddressFieldsAsTouched = (addressFieldNamesPrefix: string) => {
    const newTouched = { ...touched };
    newTouched[`${addressFieldNamesPrefix}Line1`] = true;
    newTouched[`${addressFieldNamesPrefix}Line2`] = true;
    newTouched[`${addressFieldNamesPrefix}Line3`] = true;
    newTouched[`${addressFieldNamesPrefix}Town`] = true;
    newTouched[`${addressFieldNamesPrefix}County`] = true;
    newTouched[`${addressFieldNamesPrefix}Eircode`] = true;
    setTouched(newTouched);
  };

  const getAddressFields = (addressFieldNamesPrefix: string) => {
    return (
      <AddressInfo
        fieldNamePrefix={addressFieldNamesPrefix}
        className={className}
        addressLine1={values[`${addressFieldNamesPrefix}Line1`]}
        addressLine2={values[`${addressFieldNamesPrefix}Line2`]}
        addressLine3={values[`${addressFieldNamesPrefix}Line3`]}
        town={values[`${addressFieldNamesPrefix}Town`]}
        county={values[`${addressFieldNamesPrefix}County`]}
        eircode={values[`${addressFieldNamesPrefix}Eircode`]}
      />
    );
  };

  const clearAddressFields = (addressFieldNamesPrefix: string) => {
    setFieldValue(`${addressFieldNamesPrefix}Line1`, '');
    setFieldValue(`${addressFieldNamesPrefix}Line2`, '');
    setFieldValue(`${addressFieldNamesPrefix}Line3`, '');
    setFieldValue(`${addressFieldNamesPrefix}Town`, '');
    setFieldValue(`${addressFieldNamesPrefix}County`, '');
    setFieldValue(`${addressFieldNamesPrefix}Eircode`, '');
  };

  const getDefaultAddressValue = (addressFieldNamesPrefix: string) => {
    return [
      values[`${addressFieldNamesPrefix}Line1`], values[`${addressFieldNamesPrefix}Line2`], values[`${addressFieldNamesPrefix}Line3`], values[`${addressFieldNamesPrefix}Line4`]
    ].filter((e?: string) => !!e).join(', ');
  };

  const getCollectiveErrorStatus = (prefix: string) => {
    return releaseAddressError &&
      (isFieldError(`${prefix}Eircode`, touched, errors) ||
        isFieldError(`${prefix}Line1`, touched, errors) ||
        isFieldError(`${prefix}County`, touched, errors))
  }

  const getCollectiveErrorMessage = (prefix: string) => {
    if (errors[`${prefix}Eircode`])
      return errors[`${prefix}Eircode`]
    if (errors[`${prefix}Line1`])
      return errors[`${prefix}Line1`]
    if (errors[`${prefix}County`])
      return errors[`${prefix}County`]
  }

  const getPostalAddress = () => {
    const postalAddressFieldName = `${postalAddressFieldPrefix}Eircode`;
    return values.isThisYourPostalAddress === false ?
      <>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor={postalAddressFieldName} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            <AccordionText
              label="Enter full postal address or Eircode (and select your address from the drop-down list)"
              icon="info"
              iconAlign="right"
              customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
            >
              <div className={`${commonFormStylesIdentifier}__infoText`}>
                {`An Eircode is a seven character
                  alpha-numeric code. For example A65 F4E2.
                  You can find your Eircode by entering your
                  home address at `}
                <a href={'https://finder.eircode.ie'} target="_blank" rel="noopener noreferrer">
                  {'https://finder.eircode.ie'}
                </a>
              </div>
            </AccordionText>
          </label>
          <AutoAddressAutocomplete
            onSelect={(address: Address) => setSelectedAddress(postalAddressFieldPrefix, address)}
            value={getDefaultAddressValue(postalAddressFieldPrefix)}
            name={postalAddressFieldName}
            errorMessage={getCollectiveErrorMessage(postalAddressFieldPrefix)}
            handleBlur={(event: SyntheticInputEvent<HTMLInputElement>) => {
              setTimeout(() => {
                setFieldTouched(`${postalAddressFieldPrefix}Line1`)
                setFieldTouched(`${postalAddressFieldPrefix}County`)
                setReleaseAddressError(true)
              }, 1500)
              handleBlur(event)
            }}
            handleKeyDown={(event: SyntheticKeyboardEvent<HTMLInputElement>) => {
              if (event.keyCode === 8 && !!values[`${postalAddressFieldPrefix}Line1`]) {
                emptyAddress(postalAddressFieldPrefix)
              }
            }}
            handleChange={handleChange}
            hasError={getCollectiveErrorStatus(postalAddressFieldPrefix)}
          />
        </div>
        {values[postalAddressFieldName] !== '' && !errors[postalAddressFieldName] ? getAddressFields(postalAddressFieldPrefix) : null}
      </>
      : null;
  };

  const getMainAddress = () => {
    const mainAddressFieldName = `${defaultAddressFieldPrefix}Eircode`;
    return <>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`${mainAddressFieldName}_displayAddress`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          <AccordionText
            label={insuranceType === CAR_INSURANCE_TYPE ? "Enter full address or Eircode where you park your car overnight (and select your address from the drop-down list)" : "Enter full address or Eircode  (and select your address from the drop-down list)"}
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${commonFormStylesIdentifier}__infoText`}>
              {`An Eircode is a seven character
                alpha-numeric code. For example A65 F4E2.
                You can find your Eircode by entering your
                home address at `}
              <a href={'https://finder.eircode.ie'} target={'_blank'} rel="noopener noreferrer">
                {'https://finder.eircode.ie'}
              </a>
            </div>
          </AccordionText>
        </label>
        <AutoAddressAutocomplete
          onSelect={(address: Address) => setSelectedAddress(defaultAddressFieldPrefix, address)}
          value={getDefaultAddressValue(defaultAddressFieldPrefix)}
          name={mainAddressFieldName}
          errorMessage={getCollectiveErrorMessage(defaultAddressFieldPrefix)}
          handleBlur={(event: SyntheticInputEvent<HTMLInputElement>) => {
            setTimeout(() => {
              setFieldTouched(`${defaultAddressFieldPrefix}Line1`)
              setFieldTouched(`${defaultAddressFieldPrefix}County`)
              setReleaseAddressError(true)
            }, 1500)
            handleBlur(event)
          }}
          handleChange={handleChange}
          handleKeyDown={(event: SyntheticKeyboardEvent<HTMLInputElement>) => {
            if (event.keyCode === 8 && !!values[`${defaultAddressFieldPrefix}Line1`]) {
              emptyAddress(defaultAddressFieldPrefix)
            }
          }}
          hasError={getCollectiveErrorStatus(defaultAddressFieldPrefix)}
          showEirCodeError={noop}
          getEirCodeErrorStatus={noop}
        />
      </div>
      {values[mainAddressFieldName] !== '' && !errors[mainAddressFieldName] ? getAddressFields(defaultAddressFieldPrefix) : null}
    </>
  };

  return (
    <div className={className}>
      {getMainAddress()}
      {inFormStructure('isThisYourPostalAddress', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="isThisYourPostalAddress" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Is this your postal address
          </label>
          <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
            <ButtonGroup
              name="isThisYourPostalAddress"
              onClick={(value: string) => {
                if (value === 'true') {
                  clearAddressFields(postalAddressFieldPrefix);
                }
                setFieldValue('isThisYourPostalAddress', value === 'true');
                if (!touched.isThisYourPostalAddress) {
                  setFieldTouched('isThisYourPostalAddress', true);
                }
              }}
              options={[
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ]}
              value={values.isThisYourPostalAddress}
              touched={touched.isThisYourPostalAddress}
              selected={values.isThisYourPostalAddress}
              error={isFieldError('isThisYourPostalAddress', touched, errors)}
              errorMessage={errors.isThisYourPostalAddress}
            />
          </div>
        </div>
      ) : null}
      {getPostalAddress()}
    </div>
  );
};

BaseAddressDetails.propTypes = {
  formik: PropTypes.object
};

const AddressDetails = connect(BaseAddressDetails);

export default AddressDetails;
