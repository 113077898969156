import { addDays, endOfDay, startOfDay } from 'date-fns';
import * as yup from 'yup';

import { createDateTransformer } from '../../helpers/DateHelper';

const dateTransformer = createDateTransformer({ nullable: true });

export const viewTravelQuoteYupSchema = yup.object().shape({
  policyStartDate: yup
    .date()
    .transform(dateTransformer)
    .when('isMultiTrip', (isMultiTrip, schema) => {
      if (isMultiTrip) {
        return schema
          .nullable()
          .required('Please select your travel date')
          .min(
            startOfDay(new Date()),
            'Start date must be today or in the future.'
          )
          .max(
            endOfDay(addDays(new Date(), 45)),
            `The date selected must be within 45 days of today's date`
          );
      }
      return schema.nullable();
    })
});
