/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import '../../styles/commonFormStyles.scss';

import { InputField } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { EMAIL_TO_REGISTER_STORAGE } from '../../constants';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { AGENT_LATEST_QUOTE_LOADED } from '../../constants/sessionStorage/genericStorageIdentifiers.js';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import {
  getItemFromSessionStorage,
  removeFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { isAgent } from '../../helpers';
import { isFieldError } from '../../helpers/FieldErrorHelper';
import { saveData } from '../../helpers/HomeCommonMethods.js';
import { mapAboutYouProfileValues } from '../../pages/GetGenericQuotePage/helpers.js';
import { mapAboutYouProfileValues as mapAboutYouProfileValuesForHome } from '../../pages/GetQuotePage/GetQuotePageWrapper';
import { routes } from '../../routes';
import { getUserProfileFromEmail } from '../../services/agent';
import { checkUserExistsByEmail } from '../../services/checkExistingEmail';
import { getLatestUnpurchasedHomeQuote } from '../../services/latestHomeQuoteService';
import { getLatestUnpurchasedMotorQuote } from '../../services/motor/latestMotorQuoteService';
import AnimatedSpinner from '../AnimatedSpinner/AnimatedSpinner';

const REQUEST_LOADING = 'AgentEmail/LOADING';
const REQUEST_SUCCESS = 'AgentEmail/SUCCESS';
const REQUEST_FAILURE = 'AgentEmail/FAILURE';
const EMAIL_PLACEHOLDER = 'E.g. John@domain.com';

type RequestState =
  | typeof REQUEST_LOADING
  | typeof REQUEST_SUCCESS
  | typeof REQUEST_FAILURE;

interface Props {
  insuranceType: string;
  handleCustomBlur?: noop;
  height: string;
  icon?: string;
  heading: noop;
  text: string;
}

const EmailInput = (props: FormikProps & Props) => {
  const className = 'c-EmailInput';

  const {
    touched,
    errors,
    handleChange,
    values,
    setValues,
    setFieldError,
    handleBlur
  } = props.formik;
  const {
    insuranceType,
    handleCustomBlur,
    loadAgentFormValues,
    emailParam
  } = props;

  const [requestStatus, setRequestStatus] = useState<?RequestState>();
  const [emailExisting, setEmailExisting] = useState(false);
  const { cognitoUser } = useContext(CognitoContext);
  const isLoggedIn = !!cognitoUser;

  useEffect(() => {
    const load = getItemFromSessionStorage(AGENT_LATEST_QUOTE_LOADED);
    if (isAgent() && emailParam && load !== 'true') {
      getQuoteDataForAgent(emailParam);
    }

    const handleBeforeUnload = () => {
      removeFromSessionStorage(AGENT_LATEST_QUOTE_LOADED);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const getQuoteDataForAgent = async (emailParam: string = '') => {
    setRequestStatus(REQUEST_LOADING);
    const email = emailParam || values.email;
    let response;
    if (insuranceType === CAR_INSURANCE_TYPE)
      response = await getLatestUnpurchasedMotorQuote(props, email);
    else response = await getLatestUnpurchasedHomeQuote(props, email);

    if (Object.keys(response).length) {
      loadAgentFormValues(response);
      saveInSessionStorage(AGENT_LATEST_QUOTE_LOADED, true);
      setRequestStatus(REQUEST_SUCCESS);
    } else {
      getUserProfileForAgent(values);
    }
  };

  const getUserProfileForAgent = async (values: any) => {
    const profileResponse = await getUserProfileFromEmail(values.email);
    if (profileResponse.user_id) {
      //motor and travel
      const profileValues =
        insuranceType === HOME_INSURANCE_TYPE
          ? mapAboutYouProfileValuesForHome(profileResponse)
          : mapAboutYouProfileValues(profileResponse, insuranceType);

      const newValues = {
        ...values,
        ...profileValues
      };
      setValues(newValues);
      setRequestStatus(REQUEST_SUCCESS);
    } else {
      setFieldError('email', `Email address has not been found`);
      setRequestStatus(REQUEST_FAILURE);
    }
    setRequestStatus(REQUEST_FAILURE);
  };

  const callSetExistingEmail = (e: SyntheticEvent<HTMLInputElement>) => {
    const { target } = e;
    if (!(target instanceof window.HTMLInputElement)) return;
    if (!isLoggedIn) {
      const email = target.value;
      checkUserExistsByEmail(email).then((res: boolean) => {
        if (res) removeFromSessionStorage(EMAIL_TO_REGISTER_STORAGE);
        else saveInSessionStorage(EMAIL_TO_REGISTER_STORAGE, email);
        setEmailExisting(res);
      });
    }
  };

  const handleEmailCustomBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    if (!isAgent()) {
      callSetExistingEmail(e);
    }

    insuranceType === HOME_INSURANCE_TYPE
      ? handleCustomBlur('email', e)
      : handleBlur(e);
  };

  const renderEmail = () => {
    return emailExisting && !isAgent() ? (
      <div className={`${commonFormStylesIdentifier}__infoText`}>
        That email address is already registered with us.
        <Link
          to={{ pathname: routes.loginPage.url }}
          className={`${className}__link`}
        >
          {' Please click here to login.'}
        </Link>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      {renderEmail()}
      {isAgent() ? (
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('email', touched, errors)}
            errorMessage={errors.email}
            name="email"
            placeholder={EMAIL_PLACEHOLDER}
            type="email"
            onBlur={handleEmailCustomBlur}
            value={values.email}
            onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
              handleChange(e);
              saveData('aboutYouObj', 'email', e.target.value);
            }}
            suffix={faSearch}
            onClickIcon={getQuoteDataForAgent}
          />
        </span>
      ) : (
        <span className={`${className}__input`}>
          <InputField
            error={isFieldError('email', touched, errors)}
            errorMessage={errors.email}
            name="email"
            placeholder={EMAIL_PLACEHOLDER}
            type="email"
            onBlur={handleEmailCustomBlur}
            value={values.email}
            onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
              handleChange(e);
              saveData('aboutYouObj', 'email', e.target.value);
            }}
          />
        </span>
      )}
      {requestStatus === REQUEST_LOADING ? (
        <AnimatedSpinner size="medium" />
      ) : null}
    </>
  );
};

export default EmailInput;
