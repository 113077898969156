/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { noop } from '@boi/core/lib/utils';
import * as yup from 'yup';
import { Schema } from 'yup';

import {
  carTitleValues,
  driverLicenseTypeValues,
  employmentStatusValues,
  genderValues,
  lastFiveYearsDrivingExperienceValues,
  motorEmploymentStatusValues
} from '../../../../constants/dropdowns';
import {
  FIRST_NAME_ERROR_TEXT,
  LAST_NAME_ERROR_TEXT
} from '../../../../constants/home';
import { CAR_INSURANCE_TYPE } from '../../../../constants/insuranceTypeConstants';
import {
  nameErrorMsg,
  nameRegexPattern,
  nameWithNoSpecialChar,
  nameWithNoSpecialCharMsg
} from '../../../../constants/loginRegistrationConstants';
import {
  dateOfBirthYup,
  getValuesFromDropdownDetailsType
} from '../../../../helpers';
import { getOccupationOptions } from '../../../../mocks/MainOccupationValues';

// Regex to match Irish phone numbers in order of
// 1. International Code if provided (otherwise leading zero)
// 2. Area Code (1-3 numbers)
// 3. Local number (7 numbers usually, can be 6 or 5)
// See https://en.wikipedia.org/wiki/Telephone_numbers_in_the_Republic_of_Ireland for details

const phoneNoRegex = /^(08)\d{8}$$/;
const moreThanTwo = 'Please enter in more than two characters';
const lessThanFifty = 'Please enter in less than fifty characters';

const shouldHaveOccupation = (status: string) => {
  const occupationStatuses: Array<string> = [
    'EMP',
    'SEF',
    'EMPLOYED',
    'SELF_EMPLOYED'
  ];
  return occupationStatuses.includes(status);
};

const checkIfYearsHeldWithinRange = (
  value: number,
  path: string,
  createError: noop
) => {
  if (value < 0) {
    return createError({ path, message: 'Please enter a larger number' });
  } else if (value > 100) {
    return createError({ path, message: 'Please enter a smaller number' });
  }
};

const mainOccupationOptions = getOccupationOptions();

const personalDetailsYupSchema = {
  title: yup
    .string()
    .oneOf(getValuesFromDropdownDetailsType(carTitleValues))
    .required('Title is required'),
  //First Name and Last Name: can't be empty, less than 50 chars
  firstName: yup.string().when('insuranceType', {
    is: CAR_INSURANCE_TYPE,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty)
      .matches(nameWithNoSpecialChar, nameWithNoSpecialCharMsg)
      .required(FIRST_NAME_ERROR_TEXT),
    otherwise: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty)
      .matches(nameRegexPattern, nameErrorMsg)
      .required(FIRST_NAME_ERROR_TEXT)
  }),
  lastName: yup.string().when('insuranceType', {
    is: CAR_INSURANCE_TYPE,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty)
      .matches(nameWithNoSpecialChar, nameWithNoSpecialCharMsg)
      .required(LAST_NAME_ERROR_TEXT),
    otherwise: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty)
      .matches(nameRegexPattern, nameErrorMsg)
      .required(LAST_NAME_ERROR_TEXT)
  }),
  gender: yup
    .string()
    .oneOf(getValuesFromDropdownDetailsType(genderValues))
    .required('Gender is required.'),
  dob: dateOfBirthYup
};

const addressDetailsYupSchema = {
  addressLine1: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .required('Address line is required'),
  addressLine2: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty),
  addressLine3: yup
    .string()
    .min(0, moreThanTwo)
    .max(50, lessThanFifty),
  addressTown: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty),
  addressCounty: yup
    .string()
    .min(2, moreThanTwo)
    .max(50, lessThanFifty)
    .required('County is required'),
  addressEircode: yup
    .string()
    .matches(
      // Matches eircode:
      /^[A-Z]{1}[0-9W]{2}[ ]?[0-9A-Z]{4}$/,
      'Please enter a valid Eircode or address'
    )
    .required('Eircode or address is required'),
  isThisYourPostalAddress: yup
    .boolean()
    .test(
      'isThisYourPostalAddress',
      'You must answer is this your postal address',
      function(value: boolean): boolean {
        return value !== null;
      }
    )
    .nullable(true),
  postalAddressLine1: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty)
      .required('Address line is required'),
    otherwise: yup.string().notRequired()
  }),
  postalAddressLine2: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty),
    otherwise: yup.string().notRequired()
  }),
  postalAddressLine3: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty),
    otherwise: yup.string().notRequired()
  }),
  postalAddressTown: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty),
    otherwise: yup.string().notRequired()
  }),
  postalAddressCounty: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .min(2, moreThanTwo)
      .max(50, lessThanFifty)
      .required('County is required'),
    otherwise: yup.string().notRequired()
  }),
  postalAddressEircode: yup.string().when('isThisYourPostalAddress', {
    is: false,
    then: yup
      .string()
      .matches(
        // Matches eircode:
        /^[A-Z]{1}[0-9W]{2}[ ]?[0-9A-Z]{4}$/,
        'Please enter a valid Eircode or address'
      )
      .required('Eircode or address is required'),
    otherwise: yup.string().notRequired()
  })
};

const contactDetailsYupSchema = {
  phoneNo: yup
    .string()
    .matches(phoneNoRegex, 'Must be a valid Mobile number')
    .max(10, 'Mobile Number Must be 10 digit')
    .required('Mobile number is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required')
};

const employmentDetailsYupSchema = {
  employmentStatus: yup
    .string()
    .oneOf([
      ...getValuesFromDropdownDetailsType(employmentStatusValues),
      ...getValuesFromDropdownDetailsType(motorEmploymentStatusValues)
    ])
    .required('Employment status is required'),
  mainOccupation: yup
    .string()
    .when(
      'employmentStatus',
      (employmentStatus: string, schema: Schema<string>) =>
        shouldHaveOccupation(employmentStatus)
          ? schema
              .oneOf(
                mainOccupationOptions.map(
                  (occupation: { value: string }) => occupation.value
                )
              )
              .required('Occupation is required')
          : schema
    )
};

const drivingDetailsYupSchema = {
  driversLicenseType: yup
    .string()
    .oneOf(getValuesFromDropdownDetailsType(driverLicenseTypeValues))
    .required('Drivers licence type is required'),
  passedTestInIrelandOrUk: yup.boolean().when('driversLicenseType', {
    is: (value: string) => value === 'FULL_UK' || value === 'FULL_IRISH',
    then: yup
      .boolean()
      .test(
        'passedTestInIrelandOrUkValueSet',
        'Did you pass your test in Ireland or the UK',
        function(value: boolean): boolean {
          return value !== null;
        }
      )
      .nullable(true),
    otherwise: yup
      .boolean()
      .notRequired()
      .nullable(true)
  }),
  lastFiveYearsExperience: yup
    .string()
    .oneOf(
      getValuesFromDropdownDetailsType(lastFiveYearsDrivingExperienceValues)
    )
    .required('Last five years experience required.'),
  yearsHeld: yup
    .string()
    .test('yearsHeldDrivingLicense', 'Years Held is required', function(
      value: string
    ): boolean {
      if (!value) return false;
      const { path, createError } = this;
      const strippedValue = value.replace(/,/g, ``);
      const integerValue = parseInt(strippedValue, 10);
      const error = checkIfYearsHeldWithinRange(
        integerValue,
        path,
        createError
      );
      if (error) return error;
      return true;
    })
    .nullable(true)
};

const boiCustomerAndPromoYupSchema = {
  bankOfIrelandCustomer: yup
    .boolean()
    .oneOf([false, true])
    .required('Bank Of Ireland Customer answer is required'),
  bankOfIrelandHomeInsurancePolicy: yup
    .boolean()
    .oneOf([false, true])
    .required('Bank of Ireland Home Insurance policy answer is required')
};

const insuranceDetailsYupSchema = {
  coverAbroad: yup
    .string()
    .oneOf(['yes', 'no'])
    .required('Please provide an answer.'),
  provider: yup.string().when('coverAbroad', {
    is: 'yes',
    then: yup
      .string()
      .required('Private medical insurance provider is required'),
    otherwise: yup.string().notRequired()
  })
};

const aboutYouFormYupSchema = {
  ...personalDetailsYupSchema,
  ...addressDetailsYupSchema,
  ...contactDetailsYupSchema,
  ...employmentDetailsYupSchema,
  ...drivingDetailsYupSchema,
  ...insuranceDetailsYupSchema,
  ...boiCustomerAndPromoYupSchema
};

export default aboutYouFormYupSchema;
