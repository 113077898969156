const carUse = [
  {
    displayName: 'Nothing else',
    dropdownValue: 'PERSONAL_ONLY',
  },
  {
    displayName: 'Own business',
    dropdownValue: 'OWN_BUSINESS',
  },
  {
    displayName: 'Employers business',
    dropdownValue: 'EMPLOYERS_BUSINESS',
  },
  {
    displayName: 'Commercial use',
    dropdownValue: 'COMMERCIAL_USE',
  },
];

export default carUse;