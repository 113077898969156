/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import '../QuoteOverview/QuoteOverview.scss';

import { Button, Text, WideDivider } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import {
  faDownload,
  faFile,
  faHome,
  faKey,
  faPhone
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { commonFormStylesIdentifier } from '../../constants';
import { dividerColor, primaryBlue } from '../../constants/colors';
import {
  BUSINESS_PROCESS,
  FBD_DOCS_SORT_ORDER,
  GET_HOME_DOCUMENT,
  REQUIRED_DOCUMENTS,
  RSA_DOCS_SORT_ORDER
} from '../../constants/home';
import { insurancePhoneNumber } from '../../constants/phoneNumbers';
import { saveObjectInSessionStorage } from '../../helpers';
import ViewQuoteLogo from '../../pages/ViewQuote/ViewQuoteLogo';
import { getDocumentLinks } from '../../services/common/commonServices';
import { getDownloadLink } from '../../utils/utils';
import Panel from '../Panel/Panel';
import QuoteInfo from '../QuoteInfo';
import Tabs from '../Tabs/Tabs';

interface Props {
  quoteTitle: string;
  quoteAmount: number;
  onMoreInfoClick: noop;
  quoteFeatures: Array<{ label: () => Node, tick: boolean, header: boolean }>;
  showUpToFeature: number;
  showBoi: boolean;
  underwritten: string;
  underwrittenClass: string;
  quoteType: string;
  differentStyle: boolean;
  eircode: string;
  policy_code: string;
  policy_id: string;
  dates: {
    startDate: string,
    endDate: string
  };
  direct_debit_policy: boolean;
  alternativeQuote: boolean;
  insurer: string;
  scheme_code: string;
}

interface Document {
  association_code: string;
  association_id: string;
  association_level: string;
  created_date: string;
  data: null;
  description: string;
  display_name: string;
  document_id: string;
  document_pack_id: string;
  document_type: string;
  file_name: string;
  generated_by_business_process: string;
  was_imported: boolean;
}
const POLICY_RENEWAL_URI = '/home/applied_home_documents/summary';

const className = 'c-QuoteOverview';

const INSURER = {
  FBD: FBD_DOCS_SORT_ORDER,
  RSA: RSA_DOCS_SORT_ORDER
};

const currentPhoneIconText = () => (
  <>
    If you wish to opt out of Automatic Renewal on Direct debit or Amend a
    detail of your renewal policy, Please Call us on{' '}
    <a href={`tel:${insurancePhoneNumber}`}>{insurancePhoneNumber}</a>
  </>
);

const alternatePhoneIconText = () => (
  <>
    If you wish to amend any detail of your alternate quote before choosing it,
    please contact{' '}
    <a href={`tel:${insurancePhoneNumber}`}>{insurancePhoneNumber}</a>
  </>
);

const phoneIconTextCurrentInsurer = () => (
  <>
    If you wish to amend any detail of your quote before choosing it, please
    contact <a href={`tel:${insurancePhoneNumber}`}>{insurancePhoneNumber}</a>
  </>
);

// eslint-disable-next-line complexity
const QuoteRenewalOverview = (props: Props) => {
  const {
    quoteFeatures,
    insurer,
    scheme_code = '',
    alternativeQuote,
    direct_debit_policy = false,
    policy_id = '',
    eircode = '',
    policy_code = '',
    showUpToFeature,
    quoteTitle,
    quoteType,
    quoteAmount = 0,
    onMoreInfoClick,
    showBoi = false,
    underwritten = '',
    differentStyle = true,
    underwrittenClass,
    dates = {}
  } = props;
  const [showDocuments, setShowDocuments] = useState(false);
  const [groupDocs, setGroupDocs] = useState({});
  const [normalCardElements, setNormalCardElements] = useState([]);

  const tableRenderer = (elements: any) => {
    return (
      <table className={`${className}__info_points`}>
        <tbody>
          {elements.map((item: any, idx: number) => {
            return (
              <tr key={idx}>
                <td>
                  <FontAwesomeIcon icon={item.icon} color={primaryBlue} />
                </td>
                <td>{item.text}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  // eslint-disable-next-line complexity
  const generalInfo = () => {
    return (
      <div className={`${className}__generalInfo`}>
        {tableRenderer(normalCardElements)}
        {direct_debit_policy && !alternativeQuote && (
          <>
            <div className={`${className}__cardNoteBlock`}>
              <p>** Please Note **</p>
              <div
                className={`${commonFormStylesIdentifier}__infoText ${className}__noteText`}
              >
                Your policy is currently set to Automatically Renew on Direct
                Debit on the{' '}
                <strong>
                  {format(dates?.startDate, 'Do MMMM YYYY') || ''}
                </strong>
                . If you are happy to proceed,{' '}
                <strong>No further action is required from you.</strong>
              </div>
            </div>
            {tableRenderer([{ text: currentPhoneIconText(), icon: faPhone }])}
          </>
        )}
        {!direct_debit_policy && !alternativeQuote && (
          <>
            {tableRenderer([
              { text: phoneIconTextCurrentInsurer(), icon: faPhone }
            ])}
          </>
        )}
        {alternativeQuote && (
          <>
            {tableRenderer([{ text: alternatePhoneIconText(), icon: faPhone }])}
          </>
        )}
        <div className={`${className}__dateContainer`}>
          <div className={`${className}__startDateSection`}>
            <WideDivider color={dividerColor} height={1} />
            <div>
              <span>Start Date</span>
              <span>{format(dates?.startDate, 'DD MMMM YYYY') || ''}</span>
            </div>
            <WideDivider color={dividerColor} height={1} />
          </div>
          <div className={`${className}__endDateSection`}>
            <WideDivider color={dividerColor} height={1} />
            <div>
              <span>End Date</span>
              <span>{format(dates?.endDate, 'DD MMMM YYYY') || ''}</span>
            </div>
            <WideDivider color={dividerColor} height={1} />
          </div>
        </div>
      </div>
    );
  };

  const loadDocs = useCallback(
    (groupDocs: any) => {
      return (
        <>
          {groupDocs &&
            groupDocs.map((document: Document, idx: number) => {
              return (
                <li key={idx}>
                  <FontAwesomeIcon
                    className={`${className}__linkIcon`}
                    icon={faDownload}
                    onClick={(e: any) => {
                      getDownloadLink(
                        e,
                        document?.document_id,
                        document?.display_name,
                        GET_HOME_DOCUMENT
                      );
                    }}
                  />
                  <a
                    href="#"
                    className={`${className}__link`}
                    onClick={(e: any) => {
                      getDownloadLink(
                        e,
                        document?.document_id,
                        document?.display_name,
                        GET_HOME_DOCUMENT
                      );
                    }}
                  >
                    {document?.display_name || ''}
                  </a>
                  <div
                    className={`${commonFormStylesIdentifier}__infoText ${className}__policy-document-text`}
                  >
                    {document?.description || ''}
                  </div>
                </li>
              );
            })}
        </>
      );
    },
    [groupDocs]
  );

  const noPolicyDocs = () => {
    return (
      <>
        {showDocuments && (
          <div className={`${className}__noPolicyDocs`}>
            <h5>No Policy Documents Found</h5>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const elements = [
      {
        icon: faFile,
        text: policy_code
      },
      {
        icon: faHome,
        text: eircode
      },
      {
        icon: faKey,
        text: quoteType
      }
    ];
    setNormalCardElements(elements);
  }, [policy_code, eircode, quoteType]);

  useEffect(() => {
    getDocumentLinks(
      POLICY_RENEWAL_URI,
      {
        policy_id: policy_id,
        business_process: !alternativeQuote
          ? [BUSINESS_PROCESS.RENEWAL_OFFER]
          : [BUSINESS_PROCESS.RENEWAL_TRANSFER],
        display_name_sort_order: INSURER[insurer],
        scheme_code: scheme_code
      },
      {},
      'POST'
    )
      .then((response: any) => {
        if (response.length > 0) {
          setGroupDocs(response);
          const statementOfFact = response.find(
            (item: any) => item.display_name === 'Statement Of Fact'
          );
          const reqdDocs = {
            statement_of_fact: {
              display_name: statementOfFact.display_name,
              document_id: statementOfFact.document_id
            }
          };
          saveObjectInSessionStorage(REQUIRED_DOCUMENTS, reqdDocs);
        }
      })
      .catch((e: any) => {
        console.error(e);
        setGroupDocs({});
      });
  }, []);

  return (
    <div className={className}>
      <div className={`${className}__quoteTitle`}>
        <Text className={`${className}__quoteTitleFont`}>{quoteTitle}</Text>
      </div>
      {showBoi && (
        <div className={`${className}__quoteLogo`}>
          <ViewQuoteLogo />
        </div>
      )}
      {underwritten && (
        <span className={`${className}__underwrittenText`}>
          Underwritten by{' '}
          <img
            className={`${className}__${underwrittenClass}`}
            src={underwritten}
          />
        </span>
      )}

      <Tabs>
        <Panel title="Information">
          <>{generalInfo()}</>
        </Panel>
        <Panel title="Features">
          <div className={`${className}__featureListContainer`}>
            <QuoteInfo
              quoteFeatures={quoteFeatures}
              showUpToFeature={showUpToFeature}
              showReadMoreOption={false}
            />
          </div>
        </Panel>
        {differentStyle && (
          <Panel title="Policy Documents">
            {groupDocs.length === 0 ? (
              noPolicyDocs()
            ) : (
              <>{loadDocs(groupDocs)}</>
            )}
          </Panel>
        )}
      </Tabs>
      {groupDocs.length === 0 ? (
        noPolicyDocs()
      ) : (
        <>
          {showDocuments && !differentStyle && groupDocs.length > 1 && (
            <div>
              <h4>Policy Documents</h4>
              <div className={`${className}__accordion-container`}>
                <ul className={`${className}__list-style-none`}>
                  {loadDocs(groupDocs)}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
      <div
        className={`${className}__moreInfoContainer ${className}__infoContainerRenewal`}
        style={
          isMobile && !differentStyle
            ? { flexDirection: 'column' }
            : { flexDirection: 'row' }
        }
      >
        <div className={`${className}__quoteAmountContainer`}>
          <div>Your Quote is</div>
          <div className={`${className}__totalAmountText`}>
            <span className={`${className}__euroSymbolText`}>€</span>
            {quoteAmount.toFixed(2)}
          </div>
        </div>
        {differentStyle && (
          <Button
            onClick={onMoreInfoClick}
            style={{ minWidth: '10px', minHeight: '10px', height: '40px' }}
            id={`QuoteOverview__${underwrittenClass}__moreInfoButton`}
            data-testid={`QuoteOverview__${underwrittenClass}__moreInfoButton`}
          >
            Select
          </Button>
        )}

        {!differentStyle && (
          <div className={`${className}__moreInfoButtonContainer`}>
            <Button
              secondary
              onClick={() => {
                setShowDocuments(!showDocuments);
              }}
              style={{ minWidth: '10px', minHeight: '10px', height: '40px' }}
              id={`QuoteOverview__${underwrittenClass}__moreInfoButton`}
              data-testid={`QuoteOverview__${underwrittenClass}__moreInfoButton`}
            >
              {showDocuments ? 'Hide Documents' : 'View Documents'}
            </Button>
            <Button
              onClick={onMoreInfoClick}
              style={{ minWidth: '10px', minHeight: '10px', height: '40px' }}
              id={`QuoteOverview__${underwrittenClass}__moreInfoButton`}
              data-testid={`QuoteOverview__${underwrittenClass}__moreInfoButton`}
            >
              Select
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteRenewalOverview;
