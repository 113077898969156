const conditions = [
  {
    name: 'disability_reported',
    label: 'Is it correct that neither you or any of the named drivers suffer from any disability or medical condition that may impair Your/Their ability to drive, and if You/They do, it has been reported to the relevant driver licensing authority and they have issued a licence to drive?'
  },
  { name: 'no_refusals', label: 'Is it correct that neither you or any of the named drivers, have ever had a proposal for car insurance refused, a car insurance policy cancelled or any additional terms applied?' },
  {
    name: 'registered_to_you_or_spouse',
    label: 'Is the vehicle registered in your name or your spouse’s name?'
  },
  {
    name: 'no_modifications',
    label: 'Is it correct that the vehicle has not been modified from the manufacturer\'s standard specifications?'
  }
]

export default conditions
