import './GetQuotePage.scss';

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';
import { getAccessToken, isAgent, rawToIrishFormat } from '../../helpers';
import { cognitoAttributeFieldMappings } from '../../helpers/CognitorHelper';
import { getLatestUnpurchasedHomeQuote } from '../../services/latestHomeQuoteService';
import { getUserProfile } from '../../services/profile';
import GetQuotePage from './GetQuotePage';

const getEmploymentStatusCode = (occupationCode) => {
  if (['OCC143',
    'OCC234',
    'OCC260',
    'SEF',
    'UAC'].includes(occupationCode)) return occupationCode;
  return occupationCode ? 'EMP' : "";
};
const getOrEmpty = (fieldValue) => fieldValue || "";

/*const homeTitleLookup = {
  'MR': 'Mr.',
  'MRS': 'Mrs.',
  'MS': 'Ms.',
  'MISS': 'Miss.'
};

const getTitleOrEmpty = (fieldValue) => {
  return fieldValue && homeTitleLookup[fieldValue] ? homeTitleLookup[fieldValue] : '';
};*/

const getAddressFieldValue = (address, fieldName) => address && address[fieldName] ? address[fieldName] : '';

const getDisplayAddressValue = addressValues => {
  return ["address_line1", "address_line2", "address_line3", "county"]
    .map(addressField => getAddressFieldValue(addressValues, addressField))
    .filter(e => !!e)
    .join(', ');
};

export const mapAboutYouProfileValues = (values) => ({
  title: getOrEmpty(values.title),
  otherTitle: getOrEmpty(values.otherTitle),
  firstName: getOrEmpty(values.first_name),
  lastName: getOrEmpty(values.last_name),
  email: getOrEmpty(values.email),
  displayAddress: getDisplayAddressValue(values.address),
  addressLine1: getAddressFieldValue(values.address, 'address_line1'),
  addressLine2: getAddressFieldValue(values.address, 'address_line2'),
  addressLine3: getAddressFieldValue(values.address, 'address_line3'),
  town: getAddressFieldValue(values.address, 'town'),
  county: getAddressFieldValue(values.address, 'county'),
  eircode: getAddressFieldValue(values.address, 'eircode'),
  phoneNo: getOrEmpty(values.phone),
  dob: rawToIrishFormat(getOrEmpty(values.date_of_birth)),
  employmentStatus: getEmploymentStatusCode(values.occupation),
  mainOccupation: values.occupation,
  promoCode: getOrEmpty(values.promotion_code),
  staffNumber: getOrEmpty(values.staff_number),
  policyLength: values.policies.length,
});

const GetQuotePageWrapper = (props) => {
  const className = 'c-GetQuotePage';
  const [quoteDetails, setQuoteDetails] = useState(null);
  const { cognitoUser } = useContext(CognitoContext);

  const setCognitoAttributes = (profile = {}) => {
    const cognitorUserValues = {};
    if (cognitoUser && cognitoUser.attributes) {
      Object.keys(cognitoUser.attributes).forEach(
        (key) => {
          const cognitoAttributeField = cognitoAttributeFieldMappings[key];
          if (
            cognitoAttributeField
            && !cognitorUserValues[cognitoAttributeField]
          ) {
            cognitorUserValues[cognitoAttributeFieldMappings[key]] = cognitoUser.attributes[key];
          }
        }
      );
      const aboutYouValues = profile.isDefaultResponse ? { ...profile, ...cognitorUserValues } : cognitorUserValues;
      setQuoteDetails({ aboutYou: aboutYouValues });
    }
  };

  const getLatestUnpurchasedQuote = () => {
    const accessToken = getAccessToken(cognitoUser);
    if (accessToken && !isAgent()) {
      return getLatestUnpurchasedHomeQuote(props).then(
        latestHomeQuote => {
          if (Object.keys(latestHomeQuote).length === 0) {
            getUserProfile().then(
              res => {
                if (!res || res.isDefaultResponse) {
                  // If no profile or the default response is returned then the user has no profile
                  setCognitoAttributes(res);
                  return;
                }
                setQuoteDetails({
                  aboutYou: mapAboutYouProfileValues(res)
                });
              }
            );
          } else {
            setQuoteDetails(latestHomeQuote);
          }
        }
      ).catch((error)=>{
        console.error(error)
      });
    }
    // If no getLatestQuoteFunction found just set Latest Quote as an empty object
    setQuoteDetails({});
  };

  useEffect(() => {
    if (!quoteDetails) {
      getLatestUnpurchasedQuote();
    }
  });

  return quoteDetails ? <GetQuotePage latestQuote={quoteDetails} history={props.history} /> :
    (<div className={`${className}__spinnerContainer`}>
      <AnimatedSpinner size="large"></AnimatedSpinner>
    </div>);
};

GetQuotePageWrapper.propTypes = {
  history: PropTypes.any
};

export default GetQuotePageWrapper;
