// @flow
import { InputField } from "@boi/core/lib";
import React from 'react';

import { commonFormStylesIdentifier } from '../../constants';

const className = "c-CardDetailsInfo";

const fields = ["carMake",
  "carModel",
  "carYear",
  "carFuelType",
  "carEngineSize",
  "carVersion"];

const fieldLabels = {
  carMake: "Your car make",
  carModel: "Your car model",
  carYear: "Your car year",
  carFuelType: "Your car fuel type",
  carEngineSize: "Your car engine size",
  carVersion: "Your car version"
};

const CarDetailsInfo = (props: {
  carMake: string,
  carModel: string,
  carYear: string,
  carFuelType: string,
  carEngineSize: string,
  carVersion: string
}) => {
  const getCarFields = () => {
    return fields.map((field: string) => {
      return (
        <div key={`carDetailField-${field}`}
             className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor={field} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            {fieldLabels[field]}
          </label>
          <InputField
            type="text"
            name={field}
            value={props[field]}
            readOnly={true}
          />
        </div>);
    });
  };

  return (
    <div className={className}>
      {getCarFields()}
    </div>
  );
};

export default CarDetailsInfo;
