/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './ManageInsuranceProductPage.scss';

import { TitleWithUnderLine } from '@boi/core/lib';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, HOME_QUOTE_STORAGE, iconsAndTitles } from '../../constants';
import { dustyGrayColor } from '../../constants/colors';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { POLICY_RENEWAL } from '../../constants/sessionStorage/genericStorageIdentifiers';
import { getObjectFromSessionStorage, removeFromSessionStorage } from '../../helpers';
import { routes } from '../../routes';

const RightArrowIcon = () => (
  <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />
);

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match;
}

// eslint-disable-next-line complexity
const ManageInsuranceProductPage = (props: Props) => {
  const className = 'c-ManageInsuranceProductPage';
  const { match, history } = props;
  const insuranceType = match.params.type || '';
  const policyRenewal = getObjectFromSessionStorage(POLICY_RENEWAL);
  const { feature } = useContext(CognitoContext);

  const renderBreakdownAssistanceButton = () => {
    return insuranceType === CAR_INSURANCE_TYPE ? (
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.breakdownAssistance.url}${insuranceType}`}>
          <ButtonWithIcons
            id={'ManageInsuranceProductPage__breakdownAssistanceButton'}
            IconRight={RightArrowIcon}
          >
            Breakdown assistance
          </ButtonWithIcons>
        </Link>
      </div>
    ) : (
      <></>
    );
  };

  const renderMedicalAssistanceButton = () => {
    return insuranceType === TRAVEL_INSURANCE_TYPE ? (
      <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.medicalAssistance.url}${insuranceType}`}>
          <ButtonWithIcons
            id={'ManageInsuranceProductPage__medicalAssistanceButton'}
            IconRight={RightArrowIcon}
          >
            Medical assistance
          </ButtonWithIcons>
        </Link>
      </div>
    ) : (
      <></>
    );
  };


  const renderViewDocumentsButton = () => (
    <div className={`${className}__buttonSpacing`}>
      <Link to={`${routes.policyLinks.url}${insuranceType}`}>
        <ButtonWithIcons
          id={'ManageInsuranceProductPage__viewDocumentsButton'}
          IconRight={RightArrowIcon}
        >
          View documents
        </ButtonWithIcons>
      </Link>
    </div>
  )


  const renderViewRenewalsButton = () =>
    insuranceType == HOME_INSURANCE_TYPE ?
      (<div className={`${className}__buttonSpacing`}>
        {Object.keys(getObjectFromSessionStorage(HOME_QUOTE_STORAGE)).length !== 0 && (
            removeFromSessionStorage(HOME_QUOTE_STORAGE)
          )}
        <Link to={`${routes.viewRenewalQuote.url}${insuranceType}`}>
          <ButtonWithIcons
            id={'ManageInsuranceProductPage__viewDocumentsButton'}
            IconRight={RightArrowIcon}
          >
            View Renewals
          </ButtonWithIcons>
        </Link>
      </div>) : <></>

  const showRenewalButton = () => policyRenewal?.renewal && feature?.flags?.home_renewal_enabled;

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      {insuranceType && iconsAndTitles[insuranceType] ? (
        <div className={`${className}__iconContainer`}>
          <div className={`${className}__icon`}>
            {iconsAndTitles[insuranceType].icon}
          </div>
          <div className={`${className}__titleText`}>
            <TitleWithUnderLine>
              {iconsAndTitles[insuranceType].title}
            </TitleWithUnderLine>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={`${className}__buttonContainer`}>
        {showRenewalButton() && (renderViewRenewalsButton())}
        {renderViewDocumentsButton()}
        {renderBreakdownAssistanceButton()}
        {renderMedicalAssistanceButton()}
        <div className={`${className}__buttonSpacing`}>
          <Link to={`${routes.submitClaimPage.url}${insuranceType}`}>
            <ButtonWithIcons
              id={'ManageInsuranceProductPage__submitClaimButton'}
              IconRight={RightArrowIcon}
            >
              Submit a claim
            </ButtonWithIcons>
          </Link>
        </div>
        <div className={`${className}__buttonSpacing`}>
          <Link to={`${routes.amendPolicyPage.url}${insuranceType}`}>
            <ButtonWithIcons
              id="ManageInsuranceProductPage__amendMyPolicyButton"
              IconRight={RightArrowIcon}
            >
              Amend my policy
            </ButtonWithIcons>
          </Link>
        </div>
        {/* TODO: @amanda - this part will be needed */}
        {/* <div className={`${className}__buttonSpacing`}>
          <Link to='#'>
            <ButtonWithIcons 
              id="ManageInsuranceProductPage__myInsuranceProfileButton"
              iconRight={RightArrowIcon}
            >
              My insurance profile
            </ButtonWithIcons>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default withRouter(ManageInsuranceProductPage);
