/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonFormStyles.scss';
import './SpecifiedItems.scss';

import { Button, Dropdown, InputField, Text, WideDivider, XIcon } from '@boi/core/lib';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { createNumberMask } from 'text-mask-addons';

import { dividerColor } from '../../constants/colors';
import specifiedItemsDetailsList from '../../constants/specifiedItemsDetailsList';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import type { SpecifiedItem, SpecifiedItemDetails } from '../../types';

interface Props {
}

const emptySpecifiedItem = {
  item: '',
  value: '',
  description: ''
};

const untouchedSpecifiedItem = {
  item: false,
  value: false,
  description: false
};

const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: false
});

const SpecifiedItems = (props: Props & FormikProps) => {
  const className = 'c-SpecifiedItems';
  const { errors, handleItemsChange, setFieldValue, setTouched, touched, values } = props;

  useEffect(() => {
    setFieldValue('specifiedItems', values.specifiedItems || [{ ...emptySpecifiedItem }]);
    setTouched({ specifiedItems: [{ ...untouchedSpecifiedItem }] });
  }, [setFieldValue, setTouched]);

  const checkItemExists = (itemNumber: number) => {
    return (
      errors.specifiedItems !== undefined &&
      errors.specifiedItems[itemNumber] !== undefined &&
      touched !== undefined &&
      touched[itemNumber] !== undefined
    );
  };

  const checkFieldError = (fieldName: string, index: number) => {
    if (checkItemExists(index)) {
      return (
        errors.specifiedItems[index][fieldName] !== undefined && touched[index][fieldName]
      );
    }
  };

  const getErrorMessage = (fieldName: string, index: number) => {
    return checkFieldError(fieldName, index) ? errors.specifiedItems[index][fieldName] : null;
  };

  const handleBlur = (fieldName: string, itemNumber: number) => {
    const newTouched = [...touched];
    newTouched[itemNumber][fieldName] = true;
    setTouched({ specifiedItems: newTouched });
  };

  const handleChange = (value: string, fieldName: string, index: number) => {
    const newValues = [...values.specifiedItems];
    newValues[index][fieldName] = value;
    handleItemsChange('specifiedItems', newValues);
  };

  const addItem = () => {
    const newValues = [...values.specifiedItems];
    const newTouched = [...touched];
    newValues.push({ ...emptySpecifiedItem });
    newTouched.push({ ...untouchedSpecifiedItem });
    setTouched({ specifiedItems: newTouched });
    setFieldValue('specifiedItems', newValues);
  };

  const removeItem = (index: number) => {
    const newValues = [...values.specifiedItems];
    const newTouched = [...touched];
    newValues.splice(index, 1);
    newTouched.splice(index, 1);
    setTouched({ specifiedItems: newTouched });
    handleItemsChange('specifiedItems', newValues);
  };

  const getSpecifiedItemsOptions = () => {
    return specifiedItemsDetailsList.map((specItemsDetails: SpecifiedItemDetails, index: number) => {
      return <option key={`specified_item_option_${index}`}
        value={specItemsDetails.dropdownValue}>{specItemsDetails.displayName}</option>;
    });
  };

  const getDescriptionPrompt = (selectedItem: string) => {
    const specItemDetails = specifiedItemsDetailsList.find(
      (itemDetails: SpecifiedItemDetails) => itemDetails.dropdownValue === selectedItem);
    return specItemDetails ?
      <div className={`${className}__itemPromptContainer`}><Text>{specItemDetails.descriptionPrompt}</Text>
      </div> : null;
  };

  const renderSpecItem = () => {
    const specItemsElements = [];
    const specifiedItems = values.specifiedItems || [];
    const specItemsCount = specifiedItems.length;
    specifiedItems.forEach((specifiedItem: SpecifiedItem, index: number) => {
      specItemsElements.push(
        <div key={`SpecifiedItem_${index}`}>
          <div className={`${className}__itemPromptAndNumberContainer`}>
            <div className={`${className}__itemNumberContainer`}>
              <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>
                Item {index + 1}
              </label>
              {specItemsCount > 1 &&
                <div
                  className={`${className}__closeIcon`}
                  onClick={() => removeItem(index)}
                  id={`SpecifiedItems__closeIcon${index + 1}`}
                  data-testid={`SpecifiedItems__closeIcon${index + 1}`}
                >
                  <XIcon />
                </div>
              }
            </div>
            {getDescriptionPrompt(specifiedItem.item)}
          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor='item' className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Item type
            </label>
            <Dropdown
              placeholder="Select here"
              error={checkFieldError('item', index)}
              errorMessage={getErrorMessage('item', index)}
              name="item"
              value={specifiedItem.item}
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                handleChange(e.currentTarget.value, 'item', index)
              }
              onBlur={() => handleBlur('item', index)}
            >
              <option value="">Select here</option>
              {getSpecifiedItemsOptions()}
            </Dropdown>
          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor='value' className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Item value
            </label>
            <InputField
              error={checkFieldError('value', index)}
              errorMessage={getErrorMessage('value', index)}
              prefix={faEuroSign}
              name="value"
              value={specifiedItem.value}
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                handleChange(e.currentTarget.value, 'value', index)
              }
              onBlur={() => handleBlur('value', index)}
              masked
              mask={numberMask}
              placeholder="Enter an amount"
            />
          </div>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label htmlFor='description' className={`${commonFormStylesIdentifier}__fieldLabel`}>
              Item description
            </label>
            {index === 0 && (<div className={`${className}__infoText`}>
              Please insert a full description of the item to be insured (e.g. 18ct Gold 0.5ct Solitaire Diamond Ring)
            </div>)}
            <InputField
              error={checkFieldError('description', index)}
              errorMessage={getErrorMessage('description', index)}
              placeholder="Type here"
              name="description"
              value={specifiedItem.description}
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                handleChange(e.currentTarget.value, 'description', index)
              }
              onBlur={() => handleBlur('description', index)}
            />
          </div>
          {index < specItemsCount - 1 &&
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
          }
        </div>
      );
    });
    return specItemsElements;
  };

  return (
    <div className={className}>
      {renderSpecItem()}
      <Button
        fluid={true}
        onClick={() => {
          addItem();
        }}
        id='SpecifiedItems__addAnotherItemButton'
        data-testid='SpecifiedItems__addAnotherItemButton'
      >
        Add another item
      </Button>
    </div>
  );
};

export default SpecifiedItems;
