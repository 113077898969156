export const localPhoneNumber = "01 4884062";
export const carinsurancePhoneNumber = "01 2901958";
export const travelInsurancePhoneNumber = "1890 719 428";
export const insurancePhoneNumber = "01 488 4062"
export const localTravelInsurancePhoneNumber = "01 440 2787";
export const internationalTravelInsurancePhoneNumber = "+353 1 4402787";
export const travelMedicalAssistancePhoneNumber = "+353 1 440 1704";
export const productPhoneNumber = "01 488 4062";
export const breakdownPhoneNumber = "1800 806 809";
export const marketingPhoneNumber = "01 688 3674";
export const emergencyPhoneNumber = "1800 213 111";
export const rsaPhoneNumber = "1800 213 111";
export const fbdPhoneNumber = "0818 432 323";
