/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';

import { Title } from "@boi/core/lib";
import React from 'react';

const className = 'c-GenericErrorPage';

interface Props {
  firstName?: string;
  redTextMessage?: string;
  quoteReferenceLine?: string;
  isCardError?: boolean;
}

const HomeErrorPage = (props: Props) => {
  const { firstName, redTextMessage, quoteReferenceLine, isCardError } = props;

  const firstNameMessage = () =>
    firstName ? `We're sorry ${firstName}` : 'We\'re sorry';

  const getQuoteFirstNameMessage = () =>
    firstName ? `Thank you for providing your details, ${firstName}` : `Thank you for providing your details.`

  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__primaryMessage`}>
        <Title type="h1" weight="light">
          {isCardError ? firstNameMessage() : getQuoteFirstNameMessage()}
        </Title>
      </div>
      {isCardError ?
        (<>
        <div className={`${className}__warningMessage`}
        id='HomeErrorPage__warningMessage'
        data-testid='HomeErrorPage__warningMessage'>{redTextMessage}</div>
        <div>We have been unable to connect to our payment service. You have not been charged. Please try
          again.</div></>) : (
          <div className={`${className}__referralNotice`}>
            <div>
            We need additional information to finalise your quote please call us on 01 488 4062
            </div>
            <div>Our lines are open Monday to Friday 9am-5pm.
            </div>
          </div>)}

      <div className={`${className}__referenceTextContainer`}>
        {quoteReferenceLine}
      </div>
    </div>
  );
};

// Set default props
HomeErrorPage.defaultProps = {
  firstName: "",
  redTextMessage: '',
  quoteReferenceLine: ''
};

export default HomeErrorPage;
