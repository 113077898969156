import { Card } from '@boi/core/lib';
import React from 'react';

import QuoteOverview from '../../components/QuoteOverview';

interface PROPS {
  classes: string;
  goToViewQuotePage: any;
  quotesFinal: any;
  benefits: any;
}
// eslint-disable-next-line flowtype/no-types-missing-file-annotation
const  QuoteOverviewContainer = ({classes, goToViewQuotePage, quotesFinal, benefits}: PROPS) => {
  return (
    <div className={`${classes}__cards`}>
      <Card margin={'0 60px 20px 0'}>
        <QuoteOverview
          showBoi
          underwritten={quotesFinal[0].logo}
          underwrittenClass={quotesFinal[0].underwrittenClass}
          quoteAmount={quotesFinal[0].price}
          quoteFeatures={benefits}
          showUpToFeature={5}
          onMoreInfoClick={() => goToViewQuotePage(quotesFinal[0].INSURER_TYPE)}
        />
      </Card>

      <Card margin={'0'}>
        <QuoteOverview
          showBoi
          underwritten={quotesFinal[1].logo}
          underwrittenClass={quotesFinal[1].underwrittenClass}
          quoteAmount={quotesFinal[1].price}
          quoteFeatures={benefits}
          showUpToFeature={5}
          onMoreInfoClick={() => goToViewQuotePage(quotesFinal[1].INSURER_TYPE)}
        />
      </Card>
    </div>
  );
};

export default QuoteOverviewContainer;
