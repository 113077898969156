/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './PaymentsThankYouPage.scss';

import { Button, CardWrapper, Title } from '@boi/core/lib';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import { backToHome } from '../../constants/buttonLabelConstants';
import { exclamationColor } from '../../constants/colors';
import { localPhoneNumber } from '../../constants/phoneNumbers';
import { routes } from '../../routes';

const className = 'c-PaymentsThankYouPage';
const classes = 'c-ThankYouPageError';

const HomePaymentErrorPage = () => {
  const Home_FAILED_TEXT = (
    <>
      Unfortunately, we have been unable to confirm your cover at this time. We
      need additional information to finalise your policy please call us on{' '}
      <a href={`tel:${localPhoneNumber}`}>{localPhoneNumber}</a>. Our lines are
      open Monday to Friday 9am-5pm. If any payment has been collected, it
      will be refunded within 5-10 working days.
    </>
  );

  return (
    <div className={`${classes}`}>
      <CardWrapper>
        <div className={className}>
          <div className={`${className}__thankYouPageTextContainer`}>
            <div className={`${className}__primaryTextContainer`}>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className={`${className}--buttonIcon fa-lg`}
                color={exclamationColor}
                size="3x"
              />
            </div>
            <div className={`${className}__primaryTextContainerOneFailed`}>
              <Title type="h1" weight="light">
                <span id="PaymentsThankYouPage__thankYouText">
                  Policy Generation Failed
                </span>
              </Title>
            </div>
            <div className={`${className}__contentFailureTexthome`}>
              {Home_FAILED_TEXT}
            </div>
          </div>
          <div className={`${className}__buttonContainer`}>
            <div className={`${className}__buttonSpacing`}>
              <Link to={routes.wallet.url}>
                <Button secondary fluid data-ga id="PaymentsThankYouPage__home">
                  {backToHome}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </CardWrapper>
    </div>
  );
};

export default HomePaymentErrorPage;
