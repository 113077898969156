export const dividerColor = '#c0c1c3';
export const whiteColor = '#FFFFFF';
export const midGrayColor = '#a0a1a3';
export const primaryBlue = '#0404fc';
export const dustyGrayColor = '#979797';
export const blueNotification = '#00f';
export const darkOrange = '#ef6c00';
export const offWhite = '#fafbfc';
export const darkBlue = '#0000ff';
export const greyDark = '#161616';
export const black = '#000000';
export const blueMagenta = '#006';
export const errorRed = '#8b0000';
export const green = '#00930f';
export const exclamationColor = '#C23934';
