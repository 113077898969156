/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './QuoteInfo.scss';

import { AccordionText, Text } from '@boi/core/lib';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Node } from 'react';
import React, { Component } from 'react';

import { primaryBlue } from '../../constants/colors';

interface Props {
  quoteTitle?: string;
  quoteFeatures: Array<{ label: () => Node, tick: boolean, header: boolean }>;
  showUpToFeature: number;
  showReadMoreOption?: boolean;
}

const className = 'c-QuoteInfo';

class QuoteInfo extends Component<Props> {
  static defaultProps = {
    showUpToFeature: 5,
    showReadMoreOption: true
  };

  readMore = (additionalFeatures: Array<Node>) => {
    if (additionalFeatures.length > 0) {
      return (
        <AccordionText
          label="Read more"
          labelColor={primaryBlue}
          openLabel="Read less"
          iconColor={primaryBlue}
          inverted
          marginTop
          marginLeft
          customLabelClass={`${className}__readMoreLabel`}
        >
          <div className={`${className}__readMoreContent`}>
            {additionalFeatures}
          </div>
        </AccordionText>
      );
    }
  };

  render() {
    const {
      quoteTitle,
      quoteFeatures,
      showReadMoreOption,
      showUpToFeature
    } = this.props;

    const featuresList = [];
    const additionalFeatures = [];
    if (quoteFeatures) {
      quoteFeatures.forEach(
        (
          feature: { label: () => Node, tick: boolean, header: boolean },
          idx: number
        ) => {
          const listItem = (
            <div
              key={`quote-feature-${idx}`}
              className={`${className}__feature`}
            >
              <div className={`${className}__featureIconContainer`}>
                {feature.tick ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={`${className}__featureIcon`}
                  />
                ) : (
                  <div className={`${className}__featureIcon`}>{idx + 1}.</div>
                )}
              </div>
              {feature.header ? (
                <div className={`${className}__featureHeader`}>
                  {' '}
                  {feature.label()}
                </div>
              ) : (
                <div className={`${className}__featureText`}>
                  {' '}
                  {feature.label()}
                </div>
              )}
            </div>
          );
          idx < showUpToFeature
            ? featuresList.push(listItem)
            : additionalFeatures.push(listItem);
        }
      );
    }

    return (
      <div className={className}>
        {quoteTitle ? (
          <div className={`${className}__quoteTitle`}>
            <Text weight="bold">{quoteTitle}</Text>
          </div>
        ) : null}
        <div className={`${className}__featureList`}>
          {featuresList}
          {showReadMoreOption ? this.readMore(additionalFeatures) : null}
        </div>
      </div>
    );
  }
}

export default QuoteInfo;
