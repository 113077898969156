/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import * as yup from "yup";

import { COMMON_ERROR_TEXT } from "../../constants/home";
import { parseBool } from "../../utils/utils";

// @TODO: Move to get quote or delete
// import specifiedItemsDetails from '../../constants/specifiedItemsDetailsList';
// import type { SpecifiedItemDetails } from '../../types/Home';

// const specifiedItemValueSchema = yup.string().test(
//   'checkIfValueValidForSpecifiedItem',
//   'The value you have entered is below the minimum amount for this item',
//   function (itemValueAsString: string): boolean {
//     const { item } = this.parent;
//     const { path, createError } = this;
//     const itemValue = itemValueAsString !== '' ? parseInt(itemValueAsString.replace(/,/g, ``), 10) : 0;
//     const specItemDetails = specifiedItemsDetails.find(
//       (itemDetails: SpecifiedItemDetails) => itemDetails.dropdownValue === item);
//     if (specItemDetails && itemValue < specItemDetails.minRequiredItemValue)
//       return createError({
//         path,
//         message: `You cannot specify this item if less than €${specItemDetails.minRequiredItemValue} in value!`
//       });
//     return true;
//   }
// ).required('Please enter an item value');

// const specifiedItemsSchema = yup.array().when('specifiedItemsSwitch', {
//   is: true,
//   then: yup.array().of(
//     yup.object().shape({
//       item: yup.string().required('Please select an item type'),
//       value: specifiedItemValueSchema,
//       description: yup.string().required('Please enter an item description')
//     })
//   ).required('Specified Items required'),
//   otherwise: yup.array().notRequired()
// });

export const viewQuoteYupSchema = yup.object().shape({
  excess: yup.number().required(),
  accidentalDamage: yup.boolean().required(),
  acceptedTermsAndConditions: yup
    .string()
    // eslint-disable-next-line complexity
    .test('acceptedTermsAndConditions', COMMON_ERROR_TEXT, function (
      value: string
    ): boolean {
      if (this.parent.renewal) {
        if (this.parent.autoRenewal) {
          return !parseBool(this.parent.claimsConditionTwo) || !parseBool(this.parent.claimsConditionOne);
        } else {
          return !parseBool(value) || !parseBool(this.parent.claimsConditionTwo) || !parseBool(this.parent.claimsConditionOne);
        }
      }
      return false;
    }),
  claimsConditionTwo: yup
    .string()
    // eslint-disable-next-line complexity
    .test('claimsConditionTwo', COMMON_ERROR_TEXT, function (
      value: string
    ): boolean {
      if (this.parent.renewal) {
        if (!parseBool(value) || !parseBool(this.parent.acceptedTermsAndConditions) || !parseBool(this.parent.claimsConditionOne)) {
          return true
        }
      }
      return false;
    }),
  claimsConditionOne: yup
    .string()
    // eslint-disable-next-line complexity
    .test('claimsConditionOne', COMMON_ERROR_TEXT, function (
      value: string
    ): boolean {
      if (this.parent.renewal) {
        if (!parseBool(value) || !parseBool(this.parent.claimsConditionTwo) || !parseBool(this.parent.acceptedTermsAndConditions)) {
          return true
        }
      }
      return false;
    })
});
