/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './SubmitClaimPage.scss';

import {
  Button,
  PhoneButton,
  TitleWithUnderLine
} from '@boi/core/lib';
import React from 'react';
import type { RouterHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { MobileBackButton } from '../../components/MobileBackButton';
import { iconsAndTitles } from '../../constants';
import { backToHome } from '../../constants/buttonLabelConstants';
import { carinsurancePhoneNumber, emergencyPhoneNumber } from '../../constants/phoneNumbers';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { routes } from '../../routes';

interface Props {
  history: RouterHistory,
  type: string
}

// eslint-disable-next-line complexity
const CarClaimPage = ({ history, type }: Props) => {
  const className = 'c-SubmitClaimPage';

  const HELPLINE_LINK_CAR = 'https://personalbanking.bankofireland.com/insure-and-protect/insurance/car-insurance/important-documents/'
  const CONTENT_TEXT = 'or';
  const CONTENT_TEXT_TWO = 'Call your insurers 24 hour Emergency Helpline';

  const buttonText = () => {
    return (
      <>
        Emergency helpline see your Policy Booklet for full details <a style={{ 'color': '#fff', 'text-decoration': 'underline' }}
          href={HELPLINE_LINK_CAR} target="_blank"
          rel="noopener noreferrer"> here </a>
      </>
    )
  }
  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      {type && iconsAndTitles[type] ? (
        <div className={`${className}__iconContainer`}>
          <div className={`${className}__titleText`}>
            <TitleWithUnderLine>Submit a claim</TitleWithUnderLine>
          </div>
          <div className={`${className}__icon`}>{iconsAndTitles[type].icon}</div>
        </div>) : <></>}
      <div className={`${className}__contentContainer`}>
        <div className={`${className}__content`}>
          <PhoneButton phoneNumber={carinsurancePhoneNumber}>Call Us</PhoneButton>
        </div>
        <div className={`${className}--alignText`}>{CONTENT_TEXT}</div>
        <div className={`${className}--alignText`}>{CONTENT_TEXT_TWO}</div>
        <div className={`${className}__content`}>
          <PhoneButton phoneNumber={emergencyPhoneNumber} label={buttonText()}>
            Emergency helpline
          </PhoneButton>
        </div>
        <div className={`${className}__buttonContainer`}>
          <Link to={routes.wallet.url}>
            <Button fluid={true}>{backToHome}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CarClaimPage;
