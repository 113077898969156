/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import '../../../../styles/commonFormStyles.scss';
import './InsuredPerson.scss';

import {
  AccordionText,
  ButtonGroup, Dropdown,
  Label
} from '@boi/core/lib';
import { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import { mapDropdownOptions } from '../../../../helpers';
import { isFieldError } from '../../../../helpers';
import { INSURED_PERSON_TYPE } from '../values';
import type { TravelFormPropsType } from '../YourTravelTypes';
import InsuredPersonDetails from './InsuredPersonDetails';

const childrenValues = [1, 2, 3, 4, 5, 6, 7].map((value: number) => ({
  displayName: value,
  dropdownValue: value
}));

const InsuredPerson = (props: FormikProps & TravelFormPropsType) => {
  const className = 'c-TravelInsuredPerson';
  const { values, touched, errors, setFieldValue, handleBlur, handleChange } = props;

  const getNumberOfChildren = () => (
    <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__lastRepeatingField`}>
      <Label htmlFor='numOfChildren' className={`${commonFormStylesIdentifier}__fieldLabel `}>
        <AccordionText
          label="Number of children"
          icon="info"
          iconAlign="right"
          customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
        >
          <div className={`${commonFormStylesIdentifier}__infoText`}>
            Your children and the children of your Partner, extending to include a step child or children
            and a legally adopted child or children, each of whom must be:<br/><br/>
            - Under 18 years old (or under 23 years old if still in full-time education) on the date you purchase cover;<br/>
            <span className={`${className}__numChildrenTextSeparator`}>and<br/></span>
            - Dependent on you or your Partner even if he or she does not live with either of you;<br/>
            <span className={`${className}__numChildrenTextSeparator`}>and<br/></span>
            - Unmarried or not living with a Partner.
          </div>
        </AccordionText>
      </Label>
      <Dropdown
        name="numOfChildren"
        placeholder="Type here"
        error={isFieldError('numOfChildren', touched, errors)}
        errorMessage={errors.numOfChildren}
        touched={touched}
        onBlur={handleBlur}
        value={values.numOfChildren}
        onChange={handleChange}
      >
        <option value="">Select here</option>
        {mapDropdownOptions(childrenValues, 'numOfChildren')}
      </Dropdown>
    </div>
  );

  const hasPartnerFields = (insuredPersonType: string) => [INSURED_PERSON_TYPE.YOU_AND_PARTNER, INSURED_PERSON_TYPE.YOU_AND_FAMILY].indexOf(insuredPersonType) > -1;
  const hasChildrenFields = (insuredPersonType: string) => [INSURED_PERSON_TYPE.YOU_AND_CHILDREN, INSURED_PERSON_TYPE.YOU_AND_FAMILY].indexOf(insuredPersonType) > -1;

  const clearNumberOfChildren = () => setFieldValue('numOfChildren', '');
  const clearPartnerDetails = () => {
    ['partnerFirstName', 'partnerLastName', 'partnerDOB']
      .forEach((field: string) =>
        setFieldValue(field, '')
      );
  };

  const handleInsuredPersonTypeChange = (e: string) => {
    if (['1', '2'].indexOf(e) >= -1) clearNumberOfChildren();
    if (['1', '4'].indexOf(e) >= -1) clearPartnerDetails();
    setFieldValue('insuredPerson', e);
  };

  const checkIfUserHasPartnerOrChildren = () => {
    return hasPartnerFields(values.insuredPerson) || hasChildrenFields(values.insuredPerson);
  };

  return (
    <div className={`${className}__container`}>
      <div className={`${className}__formPaddingContainer`}>
        <div className={`${commonFormStylesIdentifier}__fieldContainer ${commonFormStylesIdentifier}--withNoMargin`}>
          <Label htmlFor="insuredPerson" className={`${commonFormStylesIdentifier}__fieldLabel`}>Insured persons</Label>
          <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
            <ButtonGroup
              name="insuredPerson"
              options={[
                { label: 'You', value: '1' },
                { label: 'You and your partner', value: '2' },
                { label: 'You and your family', value: '3' },
                { label: 'You and your children', value: '4' }
              ]}
              onClick={handleInsuredPersonTypeChange}
              error={isFieldError('insuredPerson', touched, errors)}
              errorMessage={errors.insuredPerson}
              touched={touched.insuredPerson}
              spaceBetween={true}
              selected={values.insuredPerson}
            />
          </div>
        </div>
      </div>
      {checkIfUserHasPartnerOrChildren() ?
        <div
          className={`${commonFormStylesIdentifier}__dynamicFieldsContainer ${commonFormStylesIdentifier}--withNoMargin ${commonFormStylesIdentifier}--withMarginTop`}>
          {hasPartnerFields(values.insuredPerson) ? <InsuredPersonDetails {...props} /> : null}
          {hasChildrenFields(values.insuredPerson) ? getNumberOfChildren() : null}
        </div> : <></>}
    </div>
  );
};

InsuredPerson.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object
};

export default InsuredPerson;
