/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';

import { Title } from '@boi/core/lib';
import React from 'react';

const className = 'c-GenericErrorPage';

interface Props {
  firstName?: string;
  isCardError?: boolean;
}

const MotorErrorPage = (props: Props) => {
  const { firstName, isCardError } = props;

  const firstNameMessage = () =>
    firstName
      ? `Thank you for providing your details, ${firstName}`
      : 'Thank you for providing your details';

  const quoteReferenceLine =
    'Based on the information provided, the risk you have submitted has fallen outside our acceptance criteria.';
  const cardErrorMessage =
    'We have been unable to connect to our payment service. You have not been charged. Please try again.';

  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__primaryMessage`}>
        <Title type="h1" weight="light">
          {firstNameMessage()}
        </Title>
      </div>
      <div className={`${className}__referenceTextContainer`}>
        {isCardError ? (
          <div className={`${className}__referenceTextContainer`}>
            {isCardError ? cardErrorMessage : quoteReferenceLine}
          </div>
        ) : (
          <div className={`${className}__referralNotice`}>
            <div>
              We need additional information to finalise your quote please call
              us on 01 488 4062
            </div>
            <div>Our lines are open Monday to Friday 9am-5pm.</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MotorErrorPage;
