const mortgageProviders = [
  {
    dropdownValue: 1,
    displayName: 'No Mortgage'
  },
  {
    dropdownValue: 122,
    displayName: 'AIB'
  },
  {
    dropdownValue: 2021,
    displayName: 'Avantcard DAC t/a Avant Money'
  },
  {
    dropdownValue: 10045,
    displayName: 'Bank of Ireland Mortgages'
  },
  {
    dropdownValue: 10051,
    displayName: 'Bank of Ireland (Broker Mortgage Only)'
  },
  {
    dropdownValue: 1905,
    displayName: 'Bank of Scotland Ireland'
  },
  {
    dropdownValue: 497,
    displayName: 'Danske Bank'
  },
  {
    dropdownValue: 10055,
    displayName: 'Dilosk DAC t/a ICS Mortgages'
  },
  {
    dropdownValue: 970,
    displayName: 'EBS'
  },
  {
    dropdownValue: 1977,
    displayName: 'Finance Ireland'
  },
  {
    dropdownValue: 1110,
    displayName: 'First Active PLC'
  },
  {
    dropdownValue: 1797,
    displayName: 'Haven Mortgages'
  },
  {
    dropdownValue: 1790,
    displayName: 'Halifax'
  },
  {
    dropdownValue: 9921,
    displayName: 'KBC Bank Ireland'
  },
  {
    dropdownValue: 1390,
    displayName: 'Permanent TSB'
  },
  {
    dropdownValue: 1563,
    displayName: 'RaboBank (ACC Bank)'
  },
  {
    dropdownValue: 9938,
    displayName: 'Start Mortgages'
  },
  {
    dropdownValue: 1675,
    displayName: 'Ulster Bank Ireland DAC'
  }
];

export default mortgageProviders;
