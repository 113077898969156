const insuranceProviders = [
  {
    dropdownValue: 2,
    displayName: 'VHI'
  },
  {
    dropdownValue: 1,
    displayName: 'Laya'
  },
  {
    dropdownValue: 3,
    displayName: 'Irish Life Health'
  }
];

export default insuranceProviders;
