const kmPerYearValues = [
  {
    displayName: '0-5000',
    dropdownValue: 'LOW',
  },
  {
    displayName: '5001 - 10000',
    dropdownValue: 'BELOW_AVERAGE',
  },
  {
    displayName: '10001 - 15000',
    dropdownValue: 'AVERAGE',
  },
  {
    displayName: '15001 - 20000',
    dropdownValue: 'ABOVE_AVERAGE',
  },
  {
    displayName: '20001 - 25000',
    dropdownValue: 'HIGH_UP_TO_25000',
  },
  {
    displayName: '25001 - 30000',
    dropdownValue: 'HIGH_UP_TO_30000',
  },
  {
    displayName: '30001 - 35000',
    dropdownValue: 'VERY_HIGH_UP_TO_35000',
  },
  {
    displayName: '35001 - 40000',
    dropdownValue: 'VERY_HIGH_UP_TO_40000',
  },
  {
    displayName: '40001 - or more',
    dropdownValue: 'PEAK',
  },
];

export default kmPerYearValues;
