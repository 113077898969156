/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GetGenericQuotePage.scss';

import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { getAccessToken, isAgent } from '../../helpers';
import { cognitoAttributeFieldMappings } from '../../helpers/CognitorHelper';
import { getLatestUnpurchasedHomeQuote } from '../../services/latestHomeQuoteService';
import { getCarRegLookup } from '../../services/motor/carRegLookupService';
import { getLatestUnpurchasedMotorQuote } from '../../services/motor/latestMotorQuoteService';
import { getUserProfile } from '../../services/profile';
import { getLatestUnpurchasedTravelQuote } from '../../services/travel/latestTravelQuoteService';
import type {
  CarRegistrationResponseType,
  LatestQuoteType,
  RouterPropsType,
  UserProfileType
} from '../../types';
import GetGenericQuotePage from './GetGenericQuotePage';
import { mapAboutYouProfileValues } from './helpers';

type LatestHomeQuoteType = {
  quote_reference: string,
  policy_reference: string,
  aboutYou?: {
    firstName?: string
  },
  yourHome?: {},
  yourCover?: {}
};

const GetGenericQuotePageWrapper = (props: RouterPropsType) => {
  const className = 'c-GetGenericQuotePageForm';
  const [latestQuote, setLatestQuote] = useState(null);
  const [cognitoUserData, setCognitoUserData] = useState({});
  const { cognitoUser } = useContext(CognitoContext);

  const { match = {}, history } = props;

  const insuranceType =
    match.params && match.params.type ? match.params.type : '';

  const formatLatestHomeQuoteForGenericForm = (
    latestHomeQuote: LatestHomeQuoteType
  ) => {
    return {
      quote_reference: latestHomeQuote.quote_reference,
      policy_reference: latestHomeQuote.policy_reference,
      values: {
        ...latestHomeQuote.aboutYou,
        ...latestHomeQuote.yourHome,
        ...latestHomeQuote.yourCover
      }
    };
  };

  const setCognitoAttributes = () => {
    let cognitorUserValues = {};
    if (cognitoUser && cognitoUser.attributes) {
      Object.keys(cognitoUser.attributes).forEach((key: string) => {
        const cognitoAttributeField = cognitoAttributeFieldMappings[key];
        if (
          cognitoAttributeField &&
          !cognitorUserValues[cognitoAttributeField]
        ) {
          cognitorUserValues[cognitoAttributeFieldMappings[key]] =
            cognitoUser.attributes[key];
        }
      });

      setCognitoUserData(cognitorUserValues);
      setLatestQuote({});
    }
  };

  //@amanda complexity error here
  // eslint-disable-next-line complexity
  const getLatestUnpurchasedQuote = () => {
    const accessToken = getAccessToken(cognitoUser);
    if (insuranceType === HOME_INSURANCE_TYPE) {
      return getLatestUnpurchasedHomeQuote(props)
        .then((latestHomeQuote: LatestHomeQuoteType) => {
          setLatestQuote(formatLatestHomeQuoteForGenericForm(latestHomeQuote));
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
    const getLatestQuoteFunction = getLatestQuoteFunctionForInsuranceType();

    if (getLatestQuoteFunction && accessToken && !isAgent()) {
      return getLatestQuoteFunction(props).then(
        (latestQuote: LatestQuoteType) => {
          if (Object.keys(latestQuote).length > 0) {
            if (latestQuote.values && latestQuote.values.carReg) {
              getCarRegLookup(latestQuote.values.carReg, props).then((carData: CarRegistrationResponseType) => {
                if (carData.hasOwnProperty('model')) {
                  setLatestQuote({
                    ...latestQuote,
                    carMake: carData.make.toString(),
                    carModel: carData.model.toString(),
                    carYear: carData.year_manufacture.toString(),
                    carFuelType: carData.fuel_type.toString(),
                    carEngineSize: carData.engine_cc.toString(),
                    carVersion: carData.version.toString(),
                    carRisk: carData.risk.toString()
                  });
                } else setLatestQuote(latestQuote);
              });
            } else {
              setLatestQuote(latestQuote);
            }
          } else {
            getUserProfile(accessToken).then((res: UserProfileType) => {
              if (!res || res.isDefaultResponse) {
                // If no profile or the default response is returned then the user has no profile
                setCognitoAttributes();
                return;
              }
              const profileValues = mapAboutYouProfileValues(
                res,
                insuranceType
              );
              setLatestQuote({
                values: { ...profileValues }
              });
            });
          }
        }
      );
    }
    // If no getLatestQuoteFunction found just set Latest Quote as an empty object
    setLatestQuote({});
  };

  const getLatestQuoteFunctionForInsuranceType = () => {
    switch (insuranceType) {
      case CAR_INSURANCE_TYPE:
        return getLatestUnpurchasedMotorQuote;
      case TRAVEL_INSURANCE_TYPE:
        return getLatestUnpurchasedTravelQuote;
    }
  };

  useEffect(() => {
    if (!latestQuote) {
      getLatestUnpurchasedQuote();
    }
  }, [latestQuote]);

  return latestQuote ? (
    <GetGenericQuotePage
      history={history}
      insuranceType={insuranceType}
      latestQuote={latestQuote}
      cognitoUserData={cognitoUserData}
    />
  ) : (
    <div className={`${className}__spinnerContainer`}>
      <AnimatedSpinner size="large" />
    </div>
  );
};

export default GetGenericQuotePageWrapper;
