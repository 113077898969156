/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const yourTravelFormInitialValues = {
  tripType: '',
  travelFromDate: null,
  travelToDate: null,
  destinationZone: '',
  destinationCountry: '',
  insuredPerson: '',
  numOfChildren: 0,
  partnerFirstName: '',
  partnerLastName: '',
  partnerDOB: null
};

export default yourTravelFormInitialValues;
