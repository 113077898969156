// @flow
import './PremiumBreakdownRow.scss';

import PropTypes from 'prop-types';
import React from 'react';

interface PremiumBreakdownRowProps {
  label: string;
  price?: number;
  prefix?: string;
  suffix?: string;
  bold?: boolean;
  textValue?: string;
  showSecondColumn?: boolean;
}



const PremiumBreakdownRow = (props: PremiumBreakdownRowProps) => {
  const className = 'c-PremiumBreakdownRow';
  const { 
    label,
    price,
    prefix = '€',
    suffix,
    bold = false,
    textValue = '',
    showSecondColumn = false
  } = props;

  const britishNumberFormatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const britishNumberFormatterWithoutDecimal = new Intl.NumberFormat("en-GB");

  const subHeadingClassName = [
    `${className}__label`,
    bold && `${className}__label--bold`
  ].filter(Boolean).join(' ');

  const getPrice = () => {
    return price !== undefined ? (
      <div
        className={`${className}__price`}
        id={`PremiumBreakdownRow__${label}--price`}
      >
        {prefix}
        {
          Number.isInteger(price) ? britishNumberFormatterWithoutDecimal.format(price) : britishNumberFormatter.format(parseFloat(price))
        }
        {suffix}
      </div>
    ) : null;
  };

  const getText = () => {
    return textValue !== '' ? (
      <div
        className={`${className}__price`}
        id={`PremiumBreakdownRow__${label}--price`}
      >
        {textValue}
      </div>
    ) : null;
  };
    return (
      <div
        className={`${className}__rowContainer`}
        id={`PremiumBreakdownRow__${label}`}
      >
        <div
          className={subHeadingClassName}
          id={`PremiumBreakdownRow__${label}--label`}
          style={showSecondColumn ? {
            width: '100%'
          }: {}}
        >
          {label}
        </div>
        {!showSecondColumn && 
          <>
            {getPrice()}
            {getText()}
          </>
        }
      </div>
    );
};

PremiumBreakdownRow.propTypes = {
  price: PropTypes.number
};

export default PremiumBreakdownRow;
