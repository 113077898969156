export const findElementsByIds = (ids) => {
  let elements = []
  for (let id of ids) {
    if (document.getElementById(id)) elements.push(document.getElementById(id))
  }
  return elements
}

export const findElementsByClasses = (classes) => {
  let elements = []
  for (let c of classes) {
    if (document.querySelector(`.${c}`)) elements.push(document.querySelector(`.${c}`))
  }
  return elements
}