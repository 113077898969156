const driverLicenseTypeValues = [
  {
    displayName: 'Full Irish',
    dropdownValue: 'FULL_IRISH',
  },
  {
    displayName: 'Full UK',
    dropdownValue: 'FULL_UK',
  },
  {
    displayName: 'Full EU',
    dropdownValue: 'FULL_EU',
  },
  {
    displayName: 'Provisional Irish',
    dropdownValue: 'PROVISIONAL_IRISH',
  },
  {
    displayName: 'Provisional UK',
    dropdownValue: 'PROVISIONAL_UK',
  },
  {
    displayName: 'Provisional EU',
    dropdownValue: 'PROVISIONAL_EU',
  },
  {
    displayName: 'Non EU',
    dropdownValue: 'NON_EU',
  },
];

export default driverLicenseTypeValues;
