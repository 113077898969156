// Home Specific Storage Identifiers
export const HOME_QUOTE_STORAGE = 'homeQuote';
export const SELECTED_HOME_QUOTE_STORAGE = 'selectedHomeQuote';
export const HOME_QUOTE_THANK_YOU_STORAGE = 'homeQuoteThankYouPage';
export const HOME_QUOTE_RECALCULATED_VALUES_STORAGE =
  'homeQuoteRecalculateData';
export const TOGGLE_OPTIONAL_EXTRAS = 'toggleOptionalExtras';
export const RENEWAL_DIRECT_DEBIT_POLICY = 'renewalDirectDebitPolicy';
export const HOME_LATEST_QUOTE_STORAGE = 'homeLatestQuote';
export const HOME_RESUME_QUOTE = 'homeResumeQuote';
export const HOME_RESUME_QUOTE_OBJ = 'homeResumeQuoteObj';
