/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

const isHomeTypeValid = (homeType: string) => {
  const validHomeTypes = {
    'Purpose built apartment': false
  };

  if (homeType && validHomeTypes.hasOwnProperty(homeType)) {
    return validHomeTypes[homeType];
  }

  return true;
};

const isCoverTypeValid = (coverType: string) => {
  const validCoverTypes = {
    ownLive: true,
    rent: false
  };

  if (coverType && validCoverTypes.hasOwnProperty(coverType)) {
    return validCoverTypes[coverType];
  }

  return true;
};

export const rebuildPartOfQuote = (homeType: string, coverType: string) => {
  return isHomeTypeValid(homeType) && isCoverTypeValid(coverType);
};
