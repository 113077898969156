export const aboutYouObjInitialValues = {
  title: '',
  otherTitle: '',
  firstName: '',
  userName: '',      //honeypot hidden field for bots
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  town: '',
  county: '',
  correspondence_addressLine1: '',
  correspondence_addressLine2: '',
  correspondence_addressLine3: '',
  correspondence_town: '',
  correspondence_post_code: '',
  correspondence_county: '',
  correspondence_eircode: '',
  correspondence_geo_code: '',
  correspondence_match_level: '',
  correspondence_match_type: '',
  correspondence_ecad_identifier: '',
  correspondence_ecad_match_result: '',
  correspondence_street_name: '',
  correspondence_reference: '',
  correspondence_lookup_response: '',
  geo_code: '',
  match_level: '',
  match_type: '',
  ecad_identifier: '',
  ecad_match_level: '',
  ecad_match_result: '',
  street_name: '',
  reference: '',
  lookup_response: '',
  eircode: '',
  phoneNo: '',
  email: '',
  dob: '',
  employmentStatus: '',
  promoCode: '',
  staffNumber: '',
  bankOfIrelandCustomer: '',
  mortgageProvider: '',
  mortgageApplicationNumber: '',
  is_correspondence_address: '',
  modeOfContact: '',
  modeOfContactEmail: '',
  modeOfContactPost: '',
  propertyJointlyOwned: '',
  boiMortgage: '',
  boiPersonalLoan: '',
  boiMotorInsurance: '',
  boiPension: '',
  firstTimeBuyer: '',
  jointlyOwnedDetails: {
    title: '',
    firstName: '',
    lastName: '',
    dateOfBirth: ''
  }
};

export const yourHomeObjInitialValues = {
  coverType: '',
  homeType: '',
  yearOfConstruction: '',
  hasBeenReroofed: '',
  hasBeenRewired: '',
  numBedrooms: '',
  numBathrooms: '',
  alarmType: '',
  numberOfSmokeDetectors: '',
  neighbourhoodWatch: '',
  claims: [
    {
      claim_type: '',
      occurred_at: '',
      claim_date: '',
      claim_status: '',
      claim_amount: ''
    }
  ],
  heatingSource: '',
  claimsCount: '',
  claimsValue: '',
  noClaimYears: '',
  claimsFreeDuration: '',
  jointlyOwnedDetails: {
    title: '',
    otherTitle: '',
    firstName: '',
    lastName: '',
    dateOfBirth: ''
  },
  occupiedByMember: '',
  // ownRent fields
  isFamilyUnit: '',
  numTenants: '',
  tenantType: '',
  tenancyAgreement: '',
  privateLiving: '',

  // ownHolidayFields
  isInUse: '',
  holidayHomeIsRented: '',
  isLetMoreThan5PerYear: ''
};

export const yourCoverObjInitialValues = {
  policyStart: '',
  rebuildCost: '',
  contentsCoverOf: '',
  unspecifiedItems: '',
  specifiedItems: [
    {
      item: '',
      value: '',
      description: ''
    }
  ],
  coverTypeValidation: '',
  homeTypeValidation: '',
  unspecifiedItemsSwitch: '',
  specifiedItemsSwitch: '',
  marketingConsent: '',
  marketingConsentEmail: '',
  marketingConsentPost: '',
  marketingConsentPhone: '',
  marketingConsentText: '',
  consent: ''
};
