/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from 'react'

import AnimatedSpinner from '../../AnimatedSpinner/AnimatedSpinner';
import { AboutYouFormValues, Address }  from './models/AboutYouFormModels';

export const getDefaultCorrespondenceAddressValue = (addressValues: AboutYouFormValues) => {
    const {
      correspondence_addressLine1,
      correspondence_addressLine2,
      correspondence_county
    } = addressValues;
    return [correspondence_addressLine1,
      correspondence_addressLine2,
      correspondence_county].filter((e?: string) => !!e)
      .join(', ');
  };
  
export const getDefaultDisplayAddressValue = (addressValues: AboutYouFormValues) => {
    const {
      addressLine1,
      addressLine2,
      county,
    } = addressValues;
    return [addressLine1,
      addressLine2,
      county].filter((e?: string) => !!e)
      .join(', ');
  };

  // eslint-disable-next-line complexity
  export const getAddressErrorMessage = (referenceAddr: boolean, errors) => {
    const addressDetails = referenceAddr ? {
      displayAddress: errors['displayAddress'],
      addressLine1: errors['addressLine1'],
      county: errors['county']
    } : {
      displayAddress: errors['correspondence_displayAddress'],
      addressLine1: errors['correspondence_addressLine1'],
      county: errors['county']
    }
    if (addressDetails.displayAddress)
      return addressDetails.displayAddress
    if (addressDetails.addressLine1)
      return addressDetails.addressLine1
    if (addressDetails.county)
      return addressDetails.county
  }

  export const modifyManualAddressData = (address: Address) => {
    if(address?.addressLine1 === null && address?.addressLine2 !== null) {
      address.addressLine1 = address?.addressLine2 || ''
    }
    return address;
  }

  // eslint-disable-next-line complexity
  export const checkCheckboxError = (triggerSubmitButton, values, touched, errors) => {
    let flag = false;
    if (triggerSubmitButton) {
      if (
        !values.modeOfContactEmail &&
        !values.modeOfContactPost &&
        !values.modeOfContactOnline
      ) {
        return true;
      }
      if (
        values.modeOfContactEmail ||
        values.modeOfContactPost ||
        values.modeOfContactOnline
      ) {
        flag = false;
      }
      return flag;
    } else {
      const fieldTouched =
        touched.modeOfContactEmail ||
        touched.modeOfContactPost ||
        touched.modeOfContactOnline;
      if (fieldTouched) {
        return fieldTouched && errors.modeOfContact;
      } else {
        return false;
      }
    }
  };
  
  export const showSpinner = () => {
    return <AnimatedSpinner size="medium" />;
  };