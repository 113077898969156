const scrollToError = (id) => {
  const element = document.getElementById(`c-ThreeStateToggle__${id}`);
  let topPosition = 10000;
  const currentY = element && element.getBoundingClientRect().top;
  if (currentY && currentY < topPosition) {
    topPosition = currentY;
  }
  const scrollPosition = window.pageYOffset;
  window.scrollTo(0, topPosition - 80 + scrollPosition);
};

const scrollToFirstError = (conditions, values) => {
  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i];
    const { name } = condition;
    if (values[name] !== true && values[name] !== false) {
      scrollToError(name);
      break;
    }
  }
};

export { scrollToFirstError };
