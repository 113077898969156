import './MotorTermsAndConditions.scss'

import { Button, Checkbox } from '@boi/core/lib';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import * as yup from 'yup';

import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { GET_QUOTE_VALUES_STORAGE, SUBMITTED_GET_QUOTE_STORAGE } from '../../constants/sessionStorage';
import { commonFormStylesIdentifier } from '../../constants/styleConstants/commonClassNames';
import { getObjectFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { mapMotorQuote } from '../../mappings/motor/MapMotorQuote';
import { routes } from '../../routes';
import { scrollToFirstError } from '../HomeTermsAndConditions/errorHelper';
import ThreeStateToggle from '../ThreeStateToggle';
import conditions from './motorConditions';

const termsOfBusinessURL = '//boi.com/insurancehometob';
const termsOfUseURL = '//boi.com/insurancehometou';
const dataPrivacyURL = '//boi.com/insurancemotordpn';
const commissionStatementSummaryURL =
  '//boi.com/insurancecss';

const checkboxLabelFontSize = 16;

const MotorTermsAndConditions = (props) => {
  const className = 'c-MotorTermsAndConditions';
  const { setFieldValue, errors, touched, values, isValid, history, submitCount, submitForm } = props;

  const quoteDataWithoutAssumptions = getObjectFromSessionStorage(
    `${CAR_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`
  );

  useEffect(() => {
    if(submitCount > 0) scrollToFirstError(conditions, values)
  }, [submitCount]);

  const goToThankYouPage = (assumptions) => {
    const linkAddress = `${routes.getQuoteThankYouPage.url}${CAR_INSURANCE_TYPE}`;
    const quoteData = { ...quoteDataWithoutAssumptions, assumptions };
    saveInSessionStorage(`${CAR_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`, JSON.stringify(values));
    // Used to ensure a call to the backend is only made going forward through the journey
    saveInSessionStorage(`${CAR_INSURANCE_TYPE}${SUBMITTED_GET_QUOTE_STORAGE}`, true);

    history.push({
      pathname: linkAddress,
      state: {
        values:  quoteData,
        insuranceType: CAR_INSURANCE_TYPE,
        mappingsData: mapMotorQuote(quoteData)
      } 
    });
  }

  const getError = (name) => errors[name] && touched[name] ? errors[name] : ''

  return (
    <div className={className}>

      <div className={`${className}__subtitle`}>Additional questions about the car and you and/or named drivers of this car</div>

      <hr />
      <>
        {conditions.map((condition, index) => {
          return (
            <>
              <div key={`tnx${index}`}>
                <ThreeStateToggle label={condition.label} error={getError(condition.name)}
                  value={values[condition.name]}
                  name={condition.name} onChange={(val) => setFieldValue(condition.name, val)} />
              </div>
              <hr />
            </>);
        })}

        <div>
          <div className={`${className}__selectAllButton`}>If the answer is Yes to all of the above please click here</div>
          <Button
            quaternary
            fluid
            onClick={() => {
              conditions.forEach(({ name }) => {
                setFieldValue(name, true);
              })
            }}
          >
            Click here
          </Button>
        </div>

        <hr />
        <h4>Terms and conditions</h4>

        <div style={{padding: '5px 15px'}}>
          You are under a duty to answer all questions that have been asked, honestly and with reasonable care and attention. These questions are material in determining if RSA can accept this risk, what terms are applied and what premium is charged. Failure to do so could result in RSA cancelling the contract, rejecting a claim or limiting the claim payment.
        </div>
        
        <div className={`${commonFormStylesIdentifier}__checkboxContainer`}>
          <Checkbox
            id="acceptedTermsAndConditions"
            name="acceptedTermsAndConditions"
            label={
              <>
                Please tick to confirm that you understand the above information and to confirm acceptance of our
                <a
                  href={termsOfBusinessURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Terms of Business
                </a>
                ,
                <a
                  href={commissionStatementSummaryURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Commission Summary Document
                </a>
                ,
                <a
                  href={termsOfUseURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{' '}
                and
                <a
                  href={dataPrivacyURL}
                  className={`${commonFormStylesIdentifier}__checkboxLink`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Data Privacy Notice
                </a>{' '}
                before proceeding.
              </>
            }
            labelFontSize={checkboxLabelFontSize}
            isChecked={values.acceptedTermsAndConditions}
            onChange={() =>
              setFieldValue(
                'acceptedTermsAndConditions',
                !values.acceptedTermsAndConditions
              )
            }
            value={values.acceptedTermsAndConditions}
          />
        </div>

        <hr />

        <div>
          <a
            data-ga
            id={`GetQuote__getCoveredButton`}
            data-testid={`GetQuote__getCoveredButton`}
          >
            <Button
              quaternary
              fluid
              onClick={() => {
                if (!isValid) {
                  submitForm()
                } else goToThankYouPage(values);
              }}
            >
              Get your quote
            </Button>
          </a>
        </div>
      </>
    </div >
  )
}


const errorMessage = "Please select";

const FormikMotorTermsAndConditions = withFormik({
  mapPropsToValues: () => ({
    disability_reported: undefined,
    no_refusals: undefined,
    registered_to_you_or_spouse: undefined,
    no_modifications: undefined,
    acceptedTermsAndConditions: undefined
  }),
  validationSchema: yup.object().shape({
    disability_reported: yup.boolean().oneOf([true, false], errorMessage).required(errorMessage),
    no_refusals: yup.boolean().oneOf([true, false], errorMessage).required(errorMessage),
    registered_to_you_or_spouse: yup.boolean().oneOf([true, false], errorMessage).required(errorMessage),
    no_modifications: yup.boolean().oneOf([true, false], errorMessage).required(errorMessage),
    acceptedTermsAndConditions: yup.boolean().oneOf([true], errorMessage).required(errorMessage)
  }),
  displayName: 'Terms and Conditions'
})(MotorTermsAndConditions);

MotorTermsAndConditions.propTypes = {
  goToThankYouPage: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  isValid: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  submitForm: PropTypes.func,
  submitCount: PropTypes.number,
  touched: PropTypes.object
};


export default FormikMotorTermsAndConditions;
