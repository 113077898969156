/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonFormStyles.scss'
import './UnspecifiedItems.scss';

import {
  Dropdown
} from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import { FormikProps } from 'formik';
import React from 'react';

import { commonFormStylesIdentifier } from '../../constants/styleConstants';

interface Props {
  isFieldError: noop;
  handleChange: noop;
  handleBlur: noop;
}

const UnspecifiedItems = (props: Props & FormikProps) => {
  const className = 'c-UnspecifiedItems';
  const { isFieldError, handleChange, handleBlur, errors, value } = props;
  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainerWithNoMargin`}>
        <label htmlFor='unspecifiedItems' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Please Select Amount?
        </label>
        <Dropdown
          error={isFieldError('unspecifiedItems')}
          errorMessage={errors.unspecifiedItems}
          placeholder="Type here"
          name="unspecifiedItems"
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          id="unspecifiedItems"
          test-id="unspecifiedItems"
        >
          <option value="">Select here</option>
          {['2500', '3000', '3500', '4000', '4500', '5000', '5500', '6000', '6500', '7000', '7500', '8000', '8500', '9000', '9500', '10000'].map((k: string) => (
            <option
              key={`${k}-unspecifiedItems`}
              value={`${k}`} id={`unspecifiedItems${k}`}
              test-id={`unspecifiedItems${k}`}>{`€${k}`}</option>
          ))}
        </Dropdown>
      </div>
    </div>
  );

};

export default UnspecifiedItems;
