const noClaimsBonusValues = [
  {
    displayName: '5+',
    dropdownValue: 5,
  },
  {
    displayName: '4',
    dropdownValue: 4,
  },
  {
    displayName: '3',
    dropdownValue: 3,
  },
  {
    displayName: '2',
    dropdownValue: 2,
  },
  {
    displayName: '1',
    dropdownValue: 1,
  },
  {
    displayName: '0',
    dropdownValue: 0,
  },
];

export default noClaimsBonusValues;
