import React from 'react';

const getTravelIPIDLink = () => {
  return <a rel="noopener noreferrer" target="_blank" href="https://boi.com/insurancetravelipid">
    See Insurance Product Information Documents for full benefits here
  </a>;
};

export const features = [
  { label: () => "Medical Expenses - Unlimited", tick: true },
  { label: () => "Cancellation up to €4,000", tick: true },
  { label: () => "24/7 Worldwide Emergency Assistance", tick: true },
  { label: () => "Personal Property up to €1,500", tick: true },
  { label: () => "Personal Accident up to €30,000", tick: true },
  { label: () => "Personal Liability up to €2,500,000", tick: true },
  { label: () => "Overseas Legal Expenses up to €30,000", tick: true },
  {
    label: getTravelIPIDLink,
    tick: true,
    header: true
  }
];

export const STATUS = {
  DISABLED: 1,
  ENABLED: 2,
  RECALCULATE: 3
};

export const TITLE = {
  SingleTrip: "Your single trip quote.",
  AnnualMultiTrip: "Your multi-trip quote."
};

export const EXCESS_TYPE = {
  CAR_HIRE: "3",
  BUSINESS: "4",
  WINTER_SPORTS: "1"
};

export const TRIP_TYPE_CODES = {
  "1": "SingleTrip",
  "2": "AnnualMultiTrip"
};
