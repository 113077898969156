/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './ProductLandingPage.scss';

import { Button, PhoneButton, Text } from '@boi/core/lib';
import React, { useContext, useEffect, useState } from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';

import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, iconsAndTitles } from '../../constants';
import {
  ABOUT_YOU,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import { productPhoneNumber } from '../../constants/phoneNumbers';
import { routes } from '../../routes';
import { UserProfileContext } from '../../UserProfile';

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match;
}

export const ProductLandingPage = (props: Props) => {
  const className = 'c-ProductLandingPage';
  const { history, match } = props;
  const type = match.params.type;
  const [hasPurchasedInsurance, setHasPurchasedInsurance] = useState(false);
  const { userProfile } = useContext(UserProfileContext);

  useEffect(() => {
    if (userProfile && userProfile.purchasedInsuranceTypes) {
      const purchasedInsuranceTypes = userProfile.purchasedInsuranceTypes;
      setHasPurchasedInsurance(purchasedInsuranceTypes[type]);
    }
  }, [userProfile]);

  const contact = `${productPhoneNumber}`;

  if (type) {
    return (
      <div className={className}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <div className={`${className}__titleContainer`}>
          <div className={`${className}__titleText`}>
            <Text size="inherit">{iconsAndTitles[type].title}</Text>
          </div>
          <div className={`${className}__line`} />
        </div>
        <div className={`${className}__content`}>
          <div className={`${className}__iconContainer`}>
            <div className={`${className}__icon`}>
              {iconsAndTitles[type].icon}
            </div>
          </div>
          <div className={`${className}__mobileTitleText`}>
            <Text size="inherit">{iconsAndTitles[type].title}</Text>
          </div>
          <div className={`${className}__buttonContainer`}>
            {type === HOME_INSURANCE_TYPE ? (
              <Link
                to={`${routes.getQuotePage.url}${ABOUT_YOU}`}
                data-ga
                id={`ProductPage__${type}GetQuoteButton`}
                data-testid={`ProductPage__${type}GetQuoteButton`}
              >
                <Button fluid={true}>Get Quotes</Button>
              </Link>
            ) : (
              <Link
                to={`${routes.getGenericQuotePage.url}${type}`}
                data-ga
                id={`ProductPage__${type}GetQuoteButton`}
                data-testid={`ProductPage__${type}GetQuoteButton`}
              >
                <Button fluid={true}>Get Quotes</Button>
              </Link>
            )}

            <div className={`${className}__buttonSpacing`}>
              <Link to={`${routes.viewMultiplePolicies.url}${type}`}>
                <Button
                  secondary
                  fluid
                  disabled={!hasPurchasedInsurance}
                  data-ga
                  id={`ProductPage__${type}ManageMyInsurance`}
                  data-testid={`ProductPage__${type}ManageMyInsurance`}
                >
                  Manage My Insurance
                </Button>
              </Link>
            </div>

            <div
              className={`${className}__phoneIconContainer ${className}__buttonSpacing`}
            >
              {type === TRAVEL_INSURANCE_TYPE ? (
                <div className={`${className}__travelInsurancePhone`}>
                  <div className={`${className}__textContainer`}>
                    <span className={`${className}__text`}>
                      Please note that Travel Insurance is only available to
                      purchase online.
                    </span>
                  </div>
                </div>
              ) : (
                <PhoneButton
                  label={`You can call us at ${contact}.`}
                  phoneNumber={contact}
                  isWhite
                >
                  Need any help?
                </PhoneButton>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default withRouter(ProductLandingPage);
