

/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import { POPULAR_COVER, PRESTIGE_COVER } from '../../constants/motor'
import { comprehensivePopular, comprehensivePrestige, thirdPartyFireAndTheftPopular, thirdPartyFireAndTheftPrestige } from './InfoContstantsPrestigePopular.js';

const thirdPartyFireAndTheftPopularNumOfFeatures = 3;
const thirdPartyFireAndTheftPrestigeNumOfFeatures = 4;
const comprehensivePopularNumOfFeatures = 3;
const comprehensivePrestigeNumOfFeatures = 5;

const getThirdPartyFeaturesList = (insuranceLevelOfCover: string) => {
    if (insuranceLevelOfCover === POPULAR_COVER) {
        return thirdPartyFireAndTheftPopular;
    } else if (insuranceLevelOfCover === PRESTIGE_COVER) {
        return thirdPartyFireAndTheftPrestige;
    }
    return [];
};

const getComprehensiveFeaturesList = (insuranceLevelOfCover: string) => {
    if (insuranceLevelOfCover === POPULAR_COVER) {
        return comprehensivePopular;
    }
    else if (insuranceLevelOfCover === PRESTIGE_COVER) {
        return comprehensivePrestige;
    }
    return [];
};


const getShowUpToFeatureNumberThirdParty = (insuranceLevelOfCover: string) => {
    if (insuranceLevelOfCover === POPULAR_COVER) {
        return thirdPartyFireAndTheftPopularNumOfFeatures;
    }
    else if (insuranceLevelOfCover === PRESTIGE_COVER) {
        return thirdPartyFireAndTheftPrestigeNumOfFeatures;
    }
};

const getShowUpToFeatureNumberComp = (insuranceLevelOfCover: string) => {
    if (insuranceLevelOfCover === POPULAR_COVER) {
        return comprehensivePopularNumOfFeatures;
    }
    else if (insuranceLevelOfCover === PRESTIGE_COVER) {
        return comprehensivePrestigeNumOfFeatures;
    }
};

export const listOfFeatures = (carInsuranceType: string, insuranceLevelOfCover: string) => {
    if (carInsuranceType === 'THIRD_PARTY_AND_THEFT') {
        return getThirdPartyFeaturesList(insuranceLevelOfCover);
    } else if (carInsuranceType === 'COMPREHENSIVE') {
        return getComprehensiveFeaturesList(insuranceLevelOfCover);
    }
};

export const getShowUpToFeatureNumber = (insuranceLevelOfCover: string, carInsuranceType: string) => {
    if (carInsuranceType === 'THIRD_PARTY_AND_THEFT') {
        return getShowUpToFeatureNumberThirdParty(insuranceLevelOfCover);
    } else if (carInsuranceType === 'COMPREHENSIVE') {
        return getShowUpToFeatureNumberComp(insuranceLevelOfCover);
    }
};
