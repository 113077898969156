/* eslint-disable complexity */
import { CardWrapper } from '@boi/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { HOME_RESUME_QUOTE } from '../../../constants/sessionStorage/homeStorageIdentifiers';
import { saveInSessionStorage } from '../../../helpers';
import ThreeStateToggle from '../../ThreeStateToggle';

const ConditionList = ({
  setFieldValue,
  endQuestionIdx,
  conditions,
  values,
  errors,
  touched,
  triggerEffect
}) => {
  return (
    <CardWrapper alignItemsCenter={false}>
      {conditions.map((condition, index) => {
        return (
          <div
            key={`tnc_${index}`}
            className={`${index === 0 ? '__firstEle' : ''} ${
              index === endQuestionIdx - 1 ? '__lastEle' : ''
            }`}
          >
            <div>
              <ThreeStateToggle
                label={condition.label}
                error={touched[condition.code] && errors[condition.code]}
                value={values[condition.code]}
                tooltip={condition.tooltip}
                name={condition.code}
                onChange={val => {
                  saveInSessionStorage(HOME_RESUME_QUOTE, false);
                  setFieldValue(condition.code, val);
                  triggerEffect(prev => !prev);
                }}
              />
            </div>
            {endQuestionIdx - 1 !== index && <hr />}
          </div>
        );
      })}
    </CardWrapper>
  );
};

ConditionList.propTypes = {
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  endQuestionIdx: PropTypes.number,
  conditions: PropTypes.array,
  triggerEffect: PropTypes.func
};
export default ConditionList;
