import React from 'react';

const conditions = [
  {
    code: 'operational_locks',
    label:
      'Are all external doors and windows of this property, including domestic outbuildings, securely fitted with fully operational locks?',
      tooltip: (
        <>
          You must lock all external doors when you leave your home and at night.
        </>
      )
  },
  {
    code: 47,
    label:
      'Are the external walls of this property built entirely with stone, brick or concrete? ',
      tooltip: (
        <>
        This includes timber frame with concrete or brick external cladding.
        </>
      )
      
  },
  {
    code: 'roof_tile',
    label:
      'Is your roof entirely covered in Tile, Slate, Concrete, Metal (other than corrugated iron) or Asphalt?',
      tooltip:(
        <>
        If your roof is a combination of these materials only, then select Yes.
        </>
      ) 
      
  },
  {
    code: 51,
    label:
      'Is this property structurally sound, weatherproof, free from damage or defect of any kind, and will it continue to be maintained in a good state of repair?'
  },
  {
    code: 'un_occupied',
    label:
      'Will this property be left unoccupied for 45 consecutive days or more in any one year?',
      tooltip: (
        <>
          If the property is currently under the course of construction, this refers to the state the property will be in upon completion.
        </>
      )
  },
  {
    code: 33,
    label:
      'Has this property been divided into separate living units or is this property a flat or a bedsit?'
  },
  {
    code: 'listed_or_protected',
    label: 'Is any part of this property a listed or protected structure?'
  },
  {
    code: 43,
    label:
      'Have you or any member of your household ever been convicted of an offence, or have a prosecution pending, other than speeding or parking offences? You do not need to tell us about any spent convictions.',
    tooltip: (
      <>
        Any conviction considered to be spent under the  terms of the Children Act 2001 or Criminal Justice (Spent Convictions and Certain Disclosures) Act 2016, do not need to be disclosed.
        If you are unsure whether your conviction qualifies as spent please answer &apos;Yes&apos; to this question. 
        Further information on spent convictions is available on <a rel="noopener noreferrer" target="_blank" href="//www.citizensinformation.ie">Citizens Information</a> or at your local Garda station.
      </>
    )
  },
  {
    code: 19,
    label:
      'Have you or any member of your household ever been refused insurance or had insurance cancelled or special terms or conditions imposed by any insurer (for example. Increased excesses or policy loadings)?'
  },
  {
    code: 99,
    label: 'Is more than 30% of your roof flat?'
  },
  {
    code: 52,
    label:
      'Is this property currently under construction, renovation, extension, repair or redecoration (excluding minor internal decoration work such as painting and wallpapering)?'
  },
  {
    code: 53,
    label: 'Is any part of this property, including any outbuildings used for business purposes or to store commercial goods?',
        tooltip:(
        <>
        <br />
        The following are not considered business purposes:{' '}
        <ul>
          <li>
            Office work carried out at your home that does not involve any business visitors to your property.
          </li>
          <li> Child minding of no more than 3 children.</li>
          <li> You have up to two paying guests.</li>
        </ul>
      </>
    )
  },
  {
    code: 54,
    label:
      'Has this property ever been underpinned or required structural support, or has it ever been the subject of a survey which mentions settlement or movement?'
  },
  {
    code: 55,
    label:
      'Are you aware if this property, its site or the surrounding area (within 200 meters) has shown any signs of or been monitored for subsidence, landslip, ground heave, landslide, coastal or river erosion?'
  },
  {
    code: 56,
    label:
      'Has this property ever flooded? You must tell us if this property including any outbuildings, garden or land, has any history of flooding (from external sources such as sea, river, lakes or rainfall).'
  }
];

export default conditions;
