/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './index.scss';

import type { Node } from 'react';
import React, { useEffect, useState } from 'react';

import { policyTypeToInsuranceTypeLookup } from './constants';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from './constants/insuranceTypeConstants';
import { WebsiteLoadingPage } from './pages/WebsiteLoadingPage';
import { getUserProfile } from './services/profile';
import type { CognitoUser } from './types/commonTypes/CognitoUser';
import type { UserProfilePolicyType, UserProfileType } from './types/profile';

export const UserProfileContext: any = React.createContext();

interface Props {
  children: Node;
  cognitoUser?: CognitoUser;
}

const UserProfile = ({ children, cognitoUser }: Props) => {
  const [userProfile, setUserProfile] = useState(null);
  const [loadingUserProfile, setLoadingUserProfile] = useState(true);

  const updateUserProfileValue = (newValue: UserProfileType) => {
    newValue.purchasedInsuranceTypes = checkHasPurchasedInsurance(newValue);
    setUserProfile(newValue);
  };

  const checkHasPurchasedInsurance = (userProfile: UserProfileType) => {
    const purchasedInsuranceTypes = {
      [HOME_INSURANCE_TYPE]: false,
      [CAR_INSURANCE_TYPE]: false,
      [TRAVEL_INSURANCE_TYPE]: false
    };
    if (!userProfile.policies || userProfile.policies.length === 0) {
      return purchasedInsuranceTypes;
    }
    userProfile.policies.forEach((policyDetails: UserProfilePolicyType) => {
      const insuranceType =
        policyTypeToInsuranceTypeLookup[policyDetails.policy_type];
      purchasedInsuranceTypes[insuranceType] = true;
    });
    return purchasedInsuranceTypes;
  };

  const getUsersStoredProfile = (showLoader: boolean = true) => {
    // TODO: this is usually cognito response, but in case of new password it is slightly different  so it needs checking cognitoUser.attributes also
    const email =
      cognitoUser && cognitoUser.attributes ? cognitoUser.attributes.email : '';
    if (email) {
      // setLoading shows spinner above all content which causes everything else to unmount and mount again. Makes
      // problem in pages that make calls on mount because of triggering another call (payment pages),
      // passing flag as false prevents that.
      setLoadingUserProfile(showLoader);
      return getUserProfile()
        .then((userProfile: UserProfileType) => {
          userProfile.purchasedInsuranceTypes = checkHasPurchasedInsurance(
            userProfile
          );
          setUserProfile(userProfile);
          setLoadingUserProfile(false);
        })
        .catch(() => {
          setLoadingUserProfile(false);
          /* Always setUserProfile to have a value once a request is made */
          setUserProfile({});
        });
    } else {
      setLoadingUserProfile(false);
      /* Always setUserProfile to have a value once a request is made */
      setUserProfile({});
    }
  };

  useEffect(() => {
    getUsersStoredProfile();
  }, [cognitoUser]);

  const value = {
    setLoadingUserProfile,
    setUserProfile,
    userProfile,
    getUsersStoredProfile,
    updateUserProfileValue,
    loadingUserProfile
  };

  return loadingUserProfile ? (
    <WebsiteLoadingPage />
  ) : (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfile;
