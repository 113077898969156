import * as yup from 'yup';

import { isBICValidForOurCountries, isIBANValidForOurCountries } from '../../../helpers';
import { PAYMENT_FREQUENCY } from './values';


export default yup.object().shape({
  paymentFrequency: yup.string()
    .oneOf([PAYMENT_FREQUENCY.TOTAL, PAYMENT_FREQUENCY.MONTHLY])
    .required('Please enter a valid payment frequency'),
  bic: yup.string()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.string()
        .max(11, 'Must be 11 characters or less')
        .test('isBIC', 'Must be a valid BIC', isBICValidForOurCountries)
        .required('BIC is required')
    }),
  iban: yup.string()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.string().min(15, 'Minimum of 15 characters')
        .max(32, 'Maximum of 32 characters')
        .test('isIban', 'Must be a valid IBAN', isIBANValidForOurCountries)
        .required('IBAN is required')
    }),
  directDebitLegalText: yup
    .boolean()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.MONTHLY,
      then: yup.boolean().required()
        .oneOf([true], 'You must accept the Terms and Conditions')
    }),
  cardIsValid: yup.boolean().oneOf([true]).required(),
  nameOnCard: yup
    .string()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.TOTAL,
      then: yup
        .string()
        .matches(/^[a-z ]+$/i, 'Only letters are allowed')
        .min(2, "Please enter more than 2 characters")
        .max(50, "Only a maximum of 50 characters")
        .required('Please enter name on card.')
    })
});
