import './HomeTermsAndConditionsPage.scss';

import PropTypes from 'prop-types';
import React from 'react';

import HomeTermsAndConditions from '../../components/HomeTermsAndConditions';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';
import { CommonPageStylesDesktopBackButton } from '../../constants/styleConstants/commonClassNames';

const HomeTermsAndConditionsPage = ({ history }) => {
  const className = 'p-HomeTermsAndConditionsPage';

  const onDesktopClick = () => {
    history.push('/wallet/getquote/aboutYou');
  };

  return (
    <div className={`${className}`}>
      <div
        className={`${commonPageStylesIdentifier} ${CommonPageStylesDesktopBackButton}`}
      >
        <MobileBackButton history={history} onDesktopClick={onDesktopClick} />
      </div>
      <div className={`${className}__card`}>
        <div className={`${className}__contentContainer`}>
          {/*{insuranceType && paymentTypeContent[insuranceType] ? paymentTypeContent[insuranceType]() : (<></>)}*/}
          <HomeTermsAndConditions history={history} />
        </div>
      </div>
    </div>
  );
};

HomeTermsAndConditionsPage.propTypes = {
  history: PropTypes.object
};

export default HomeTermsAndConditionsPage;
