/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './Logout.scss';

import Auth from '@aws-amplify/auth';
import { BOIGroupLogo, Button } from '@boi/core/lib';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { commonPageStylesIdentifier } from '../../constants';
import { backToHome } from '../../constants/buttonLabelConstants';
import {
  clearAllSessionStorage,
  isAgent,
  removeAllSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import { getCognitoUrl, isLocalhost } from '../../utils/utils';

const Logout = () => {
  const className = 'c-Logout';
  const { setCognitoUser } = useContext(CognitoContext);
  const [message, setMessage] = useState('Should not see this');
  const buttonRef = useRef(null);

  useEffect(() => {
    Auth.signOut()
      .then(() => {
        setCognitoUser(null);
        removeAllSessionStorage();
        clearAllSessionStorage();
        setMessage('You have logged out successfully');
        if (isAgent()) {
          const url = getCognitoUrl(isLocalhost);
          window.location = url;
        }
      })
      .catch(() => setMessage('An error occurred while logging out'));
  }, [setCognitoUser]);

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === 'Enter' && buttonRef.current) {
        const baseUrl = process.env.PUBLIC_URL || '/';
        window.location.replace(`${baseUrl}`);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className={className}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo--flex`}>
            <BOIGroupLogo />
          </div>
          <div className={`${className}__text`} id="Logout__text">
            {message}
          </div>
          <div className={`${className}__buttonContainer`}>
            <Link to={{ pathname: routes.wallet.url }}>
              <Button fluid id="Logout__button" ref={buttonRef}>
                {backToHome}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
