import { AccordionText } from '@boi/core/lib'
import React from 'react'

import { commonFormStylesIdentifier } from '../../constants'

const className = 'c-MarketingConsentForm'
const AnalyzeTransaction = () => {
  return (
    <>
    <AccordionText
        label="Show me examples"
        labelColor="inherit"
        openLabel="Show less"
        underline
        inverted
      >
        <div className={`${className}__readMoreContent ${commonFormStylesIdentifier}__infoText`}>
          <div className={`${className}--spaceBellow`}>
            <span>
            Examples for instance could include regular monthly deposits. 
            These may identify you as someone who could be interested in a particular savings product. 
            Similarly, when you draw down your Bank of Ireland mortgage, home insurance is an important consideration. 
            </span>
          </div>

          <div className={`${className}--spaceBellow`}>
            Read our <a href='//boi.com/insurancehomedpn' target='_blank' rel='noopener noreferrer'>Data Privacy Notice</a> which describes how we process personal information, and why.
          </div>
        </div>
      </AccordionText>
    </>
  )
}

export default AnalyzeTransaction
