/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../PolicyLinksPage.scss';

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import AnimatedSpinner from '../../../components/AnimatedSpinner/AnimatedSpinner';
import { BUSINESS_PROCESS, GET_HOME_DOCUMENT, SORT_DOCS_KEY } from '../../../constants/home';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../../constants/insuranceTypeConstants';
import { localPhoneNumber } from '../../../constants/phoneNumbers';
import { POLICY_RENEWAL } from '../../../constants/sessionStorage/genericStorageIdentifiers';
import { getItemFromSessionStorage } from '../../../helpers';
import { routes } from '../../../routes';
import { getDocumentLinks } from '../../../services/common/commonServices';
import { checkObectKeysHasData, getDownloadLink, groupBy } from '../../../utils/utils';
import DocumentsCard from './DocumentsCard';

const className = 'c-PolicyLinksPage';

// eslint-disable-next-line complexity
const LinksPage = ({ cognitoAccessTokenKey, history, insuranceType, staticLinks = [] }) => {

  const getDocumentsLookup = {
    [HOME_INSURANCE_TYPE]: '/home/applied_home_documents/summary',
    [CAR_INSURANCE_TYPE]: '/motor/motor_quotes/documents?policyReference=',
    [TRAVEL_INSURANCE_TYPE]: '/travel/travel_quotes/documents?policyReference='
  };

  const [documentLinks, setDocumentLinks] = useState([]);
  const [loadingDocumentLinks, setLoadingDocumentLinks] = useState(true);
  const [policyDocs, setPolicyDocs] = useState()
  const [policy, setPolicy] = useState()
  
  // eslint-disable-next-line complexity
  const getPolicyDocuments = async () => {
    let documentsResponse
    let policyNumber = JSON.parse(getItemFromSessionStorage(POLICY_RENEWAL)) || JSON.parse(getItemFromSessionStorage(`${insuranceType}PolicyNumber`));
      setPolicy(policyNumber)
    if(insuranceType === HOME_INSURANCE_TYPE) {
      const documentsResponse =  await getCustomerPolicyUserDetails(policyNumber?.policy_id, getDocumentsLookup[insuranceType])
      setPolicyDocs(documentsResponse)
    } else {
      documentsResponse = await getDocumentLinks(`${getDocumentsLookup[insuranceType]}${policyNumber?.reference_number || policyNumber?.referenceNumber}`, {}, { history });
      setDocumentLinks(getPolicyDocumentsFromResponse(policyNumber.reference_number || policyNumber?.referenceNumber, documentsResponse));
    }
    setLoadingDocumentLinks(false);
    return <></>
  };

  const getCustomerPolicyUserDetails = async (policyId, url) => {
    try {
        const res = await getDocumentLinks(url, {
          business_process: [
            BUSINESS_PROCESS.NEW_BUSINESS, 
            BUSINESS_PROCESS.RENEWAL_CONFIRMATION, 
            BUSINESS_PROCESS.RENEWAL_TRANSFER, 
            BUSINESS_PROCESS.LAPSE
          ],
          policy_id: policyId   
      },
      {},
      'POST'
      )
        return groupBy(res, SORT_DOCS_KEY)
      } catch (error) {
        console.error(error);
      }
  }

  useEffect(() => {
    if (cognitoAccessTokenKey) {
      getPolicyDocuments();
    }
  }, [cognitoAccessTokenKey]);

  const getPolicyDocumentsFromResponse = (policyNumber, documentsResponse = {}) => {
    if (documentsResponse.policies && documentsResponse.policies.length > 0) {
      // We're querying for a single policy so there should only ever be one
      const selectedPolicy = documentsResponse.policies[0];
      return selectedPolicy.documents ? selectedPolicy.documents : [];
    }
    return [];
  };



    const getStaticLinks = () => {
    return staticLinks.map((labelsAndUrl, index) => (
      <div
        className={`${className}__linksContainer`}
        key={`StaticLinks_${index}`}
      >
        {labelsAndUrl.url ? (
          <a
            href={labelsAndUrl.url}
            className={`${className}__link`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {labelsAndUrl.label}
            <FontAwesomeIcon
              className={`${className}__linkIcon`}
              icon={faDownload}
            />
          </a>
        ) : (
          <Link
            to={`${routes.comingSoon.url}`}
            className={`${className}__link`}
          >
            {labelsAndUrl.label}
            <FontAwesomeIcon
              className={`${className}__linkIcon`}
              icon={faDownload}
            />
          </Link>
        )}
      </div>
    ));
  };

      return (
        <>
        {loadingDocumentLinks && (
          <div className={`${className}__spinnerContainer`}>
            <AnimatedSpinner size="large"></AnimatedSpinner>
          </div>
        )}
          {!loadingDocumentLinks && insuranceType !== HOME_INSURANCE_TYPE && 
          <>
                  {getStaticLinks()}
                  {documentLinks.length > 0 &&
                    documentLinks.map((document, index) => {
                      return (
                        <div
                          className={`${className}__linksContainer`}
                          key={`travel_document_${index}`}
                        >
                          {insuranceType !== HOME_INSURANCE_TYPE ? (
                            <>
                              <a
                                href={document.url}
                                className={`${className}__link`}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {document.name}
                                <FontAwesomeIcon
                                  className={`${className}__linkIcon`}
                                  icon={faDownload}
                                />
                              </a>
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                className={`${className}__linkIcon`}
                                icon={faDownload}
                                onClick={e => {
                                  getDownloadLink(
                                    e,
                                    document.document_id,
                                    document.display_name,
                                    GET_HOME_DOCUMENT
                                  );
                                }}
                              />
                              <a
                                href="#"
                                className={`${className}__link`}
                                onClick={e => {
                                  getDownloadLink(
                                    e,
                                    document.document_id,
                                    document.display_name,
                                    GET_HOME_DOCUMENT
                                  );
                                }}
                              >
                                {document.display_name}
                              </a>
                            </>
                          )}
                        </div>
                      );
                  })}
          </>
          }
          {!loadingDocumentLinks && checkObectKeysHasData(policyDocs) && insuranceType === HOME_INSURANCE_TYPE && (
            <>
              <DocumentsCard
                policyDocs={policyDocs} 
                className={className} 
                startDate={policy.start_date}
                insuranceType={HOME_INSURANCE_TYPE}
              />
            </>
          )}
          {insuranceType === HOME_INSURANCE_TYPE && !loadingDocumentLinks && documentLinks.length === 0 && !checkObectKeysHasData(policyDocs) && (
            <div className={`${className}__noDocumentsMessage`}>
              Your purchased policy is still being processed. If your documents are not made available to you within the next 24 hours please call us on  
              <a href={`tel:${localPhoneNumber}`}></a>
              {' '}{localPhoneNumber}
            </div>
          )}

        </>
      )

  };

LinksPage.propTypes = {
  cognitoAccessTokenKey: PropTypes.string,
  history: PropTypes.object,
  insuranceType: PropTypes.string,
  staticLinks: PropTypes.array
};

export default LinksPage;
