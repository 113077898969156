/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import { basePath } from '../../constants/commonConstants';
import { makeRequest } from '../../helpers';


export const getCarRegLookup = async (carReg, props = {}) => {
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/motor/resources/vehicle/lookup?registrationNumber=${carReg}`,
    history: props.history
  });
};
