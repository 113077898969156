/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './TravelPayment.scss';

import { Form, FormikProps, withFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import { CognitoContext } from '../../../CognitoUtils';
import QuoteDisplay from '../../../components/QuoteDisplay';
import { DIRECT_DEBIT_STORAGE } from '../../../constants';
import { TRAVEL_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { TOTAL } from '../../../constants/paymentTypes';
import { getObjectFromSessionStorage, isAgent } from '../../../helpers';
import { routes } from '../../../routes';
import { payTravel, payTravel3DS } from '../../../services/paysafeService';
import type { DirectDebitType } from '../../../types';
import CardPayment from '../CardPayment';
import travelPaymentSchema from './travelPaymentSchema';

interface DirectDebitProps {
  history: RouterHistory;
  location: Location;
  match: Match;
  insuranceType: string;
}

type TravelPaymentsDetails = {
  payment_details: {
    quote_reference?: string,
    card_type?: string,
    status?: string,
    payment_type?: string,
    purchaser?: string,
    payment_date?: string,
    bank_account_number?: string,
    bank_sort_code?: string,
    collection_day?: string,
    installments_number?: string,
    bank_identifier_code?: string,
    international_bank_account_number?: string,
    payment_id?: string
  }
};

const className = 'c-TravelPayment';

const TravelPaymentForm = (
  props: DirectDebitProps & FormikProps<DirectDebitType>
) => {
  const { isValid, values, history, setFieldValue, insuranceType } = props;

  const [quoteReference, setQuoteReference] = useState('');
  const [price, setPrice] = useState(0);
  const [premiumId, setPremiumId] = useState('');
  const [consentData, setConsentData] = useState({});
  const [tripType, setTripType] = useState('');
  const redirectToHome = () => {
    history.push({ pathname: routes.wallet.url });
  };
  const [paysafeError, setPaysafeError] = useState('');
  const { feature } = useContext(CognitoContext);

  const readQuoteData = () => {
    let paymentData = getObjectFromSessionStorage(
      `${TRAVEL_INSURANCE_TYPE}${DIRECT_DEBIT_STORAGE}`
    );
    if (!paymentData.quoteReference) {
      redirectToHome();
    } else {
      setQuoteReference(paymentData.quoteReference);
      setPrice(paymentData.price);
      setPremiumId(paymentData.premiumId);
      setTripType(paymentData.tripType);
    }
    if (feature.flags.marketing_enabled) {
      if (!paymentData.consent) {
        redirectToHome();
      } else {
        const { email, phone, post, text } = paymentData.consent;
        setConsentData({
          marketing_consent: [email, phone, post, text].includes(true),
          marketing: {
            email_consent: email,
            mailing_consent: post,
            mobile_sms_consent: text,
            telephone_consent: phone
          }
        });
      }
    } else {
      setConsentData({});
    }
  };

  useEffect(() => {
    readQuoteData();
  }, []);

  useEffect(() => {
    setPaysafeError('');
  }, [values]);

  const getPaymentDetails = (paymentToken: string) => {
    return {
      quote_reference: quoteReference.toString(),
      declaration_consent: true,
      selected_premium_id: premiumId,
      payment_details: {
        payment_token: paymentToken,
        product_selected: tripType
      },
      ...consentData
    };
  };

  const goToPaymentsThankYouPage = (paymentDetails: TravelPaymentsDetails) => {
    props.history.push({
      pathname: `${routes.paymentThankYou.url}${TRAVEL_INSURANCE_TYPE}`,
      state: {
        paymentDetails: paymentDetails,
        insuranceType: insuranceType
      }
    });
  };

  const submitPayment = () => {
    const successFn = (paymentToken: string) => {
      const paymentDetails = getPaymentDetails(paymentToken);
      goToPaymentsThankYouPage(paymentDetails);
    };
    const errorFn = (code: string, message: string) =>
      setPaysafeError(`${message} (Code: ${code})`);
    const priceWithDecimals = Math.round(100.0 * price);
    const pay3DSObj = {
      amount: priceWithDecimals,
      cardHolder: values.nameOnCard
    };
    isAgent()
      ? payTravel(successFn, errorFn)
      : payTravel3DS(pay3DSObj, successFn, errorFn);
  };

  const setCardValidity = (isValidCard: boolean) => {
    setFieldValue('cardIsValid', isValidCard);
  };

  const getPaysafeError = () =>
    paysafeError ? (
      <>
        <div className={`${className}__paysafeError`}>{paysafeError}</div>
      </>
    ) : (
      <></>
    );

  return (
    <Form>
      <CardPayment
        setIsValid={setCardValidity}
        {...props}
        insuranceType={TRAVEL_INSURANCE_TYPE}
      />
      {getPaysafeError()}
      <QuoteDisplay
        sticky
        price={price}
        paymentType={TOTAL}
        title={'Total'}
        buttonType="Buy"
        disabled={!isValid}
        onClickFunction={submitPayment}
        quoteReference={quoteReference}
        insurerType={null}
      />
    </Form>
  );
};

const TravelPayments = withFormik<DirectDebitProps, DirectDebitType>({
  mapPropsToValues(): {} {
    return {
      paymentFrequency: '',
      bankRequireOneSignature: '',
      bankAccountYoursWillAcceptDirectDebit: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      county: '',
      bic: '',
      iban: '',
      directDebitPaymentDate: '',
      directDebitLegalText: false,
      nameOnCard: ''
    };
  },
  handleSubmit(values: DirectDebitType, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: travelPaymentSchema,
  displayName: 'TravelPaymentForm'
})(TravelPaymentForm);

export default TravelPayments;
