import { basePath } from '../../constants/commonConstants';
import { LATEST_QUOTE_LABELMAPING } from '../../constants/home';
import { PAYLOADVALUE } from '../../constants/retriveUserConstant';
import {
  AGENT_SELECTED_QUOTE,
  USER_QUOTE_LIST
} from '../../constants/sessionStorage/userStorageIdentifiers';
import {
  getObjectFromSessionStorage,
  makeRequest,
  removeFromSessionStorage,
  saveObjectInSessionStorage
} from '../../helpers';

export const getDocumentLinks = async (
  url,
  payload,
  props = {},
  type = 'GET'
) => {
  // Make request and get links from Chubb here
  const getPayload = type => {
    const getAddonPayload = () => {
      if (payload.business_process) {
        return {
          business_processes: payload.business_process,
          scheme_code: payload?.scheme_code || '',
          display_name_sort_order: payload?.display_name_sort_order || []
        };
      } else {
        return {};
      }
    };

    if (type === 'POST') {
      // TODO: policy ID based on profile currently hard coded.
      return {
        payload: {
          policy_id: payload.policy_id,
          ...getAddonPayload()
        }
      };
    } else {
      return {};
    }
  };

  return makeRequest({
    method: type,
    url: `${basePath}${url}`,
    ...getPayload(type),
    history: props.history
  });
};

export const updateUserAttribute = async (
  phoneNumber,
  userName,
  props = {}
) => {
  return await makeRequest({
    method: 'PUT',
    url: `${basePath}/update_phone_number`,
    history: props.history,
    payload: {
      user_name: userName,
      phone_number: phoneNumber
    }
  });
};

export const getDocument = async (payload, url, props = {}) => {
  return makeRequest({
    method: 'POST',
    url: `${basePath}${url}`,
    payload: {
      document_id: payload
    },
    history: props.history
  });
};

export const getFeatureFlags = async () => {
  return makeRequest({
    method: 'GET',
    url: `${basePath}/feature`
  });
};

export const isVerifiedUser = async email => {
  try {
    return await makeRequest({
      method: 'POST',
      url: `${basePath}/verified`,
      payload: {
        email: email
      }
    });
  } catch {
    return {
      mfa_enable: false,
      number_verified: false
    };
  }
};

export const collateUserSearchResults = async (values, userList) => {
  if (userList.pagination_token && userList.pagination_token.length > 0) {
    let continuedRequest = await makeRequest({
      method: 'POST',
      url: `${basePath}/home/applied_home_quotes/user_latest_quotes`,
      payload: {
        [PAYLOADVALUE[`${values.dropdownValue}`]]: values.searchInput,
        starts_with: true,
        limit: 10,
        pagination_token: userList.pagination_token
      }
    });
    // eslint-disable-next-line require-atomic-updates
    userList.user_quote_map = {
      ...userList.user_quote_map,
      ...continuedRequest.user_quote_map
    };
    // eslint-disable-next-line require-atomic-updates
    userList.pagination_token = continuedRequest.pagination_token;
    await collateUserSearchResults(values, userList);
  } else {
    if (getObjectFromSessionStorage(USER_QUOTE_LIST)) {
      removeFromSessionStorage(USER_QUOTE_LIST);
    }
    saveObjectInSessionStorage(USER_QUOTE_LIST, userList);
  }
};

export const getLatestQuotes = async values => {
  try {
    return await makeRequest({
      method: 'POST',
      url: `${basePath}/home/applied_home_quotes/latest_quotes_users`,
      payload: values.searchInput
    });
  } catch {
    return [];
  }
};

export const getLatestQuoteForPolicyCode = async policyCode => {
  try {
    const response = await makeRequest({
      method: 'GET',
      url: `${basePath}/home/applied_home_quotes/latest/policy_code?policyCode=${policyCode}`
    });
    saveObjectInSessionStorage(AGENT_SELECTED_QUOTE, response);
  } catch {
    return [];
  }
};

export const getLatestQuotesForEmail = email => {
  return makeRequest({
    method: 'POST',
    url: `${basePath}/home/applied_home_quotes/latest_quotes`,
    payload: {
      email: email
    }
  });
};

export const getLatestHomeQuotes = async (
  request,
  type,
  page_number,
  page_size
) => {
  try {
    return await makeRequest({
      method: 'POST',
      url: `${basePath}/home/applied_home_quotes/latest_quotes/${type}`,
      payload: {
        search_criteria: request,
        search_type: LATEST_QUOTE_LABELMAPING[type],
        page_number: page_number,
        page_size: page_size
      }
    });
  } catch {
    return [];
  }
};
