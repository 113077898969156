import {
  AccordionCard,
  Button,
  Title,
  TitleWithUnderLine
} from '@boi/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  commonPageStylesIdentifier,
  GET_QUOTE_VALUES_STORAGE,
  HOME_QUOTE_THANK_YOU_STORAGE
} from '../../../constants';
import {
  HOME_COVER_TYPE,
  HOME_TYPE,
  NO_PREPOPULATION,
  PREPOPULATION_PREFORMED
} from '../../../constants/home';
import { HOME_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_COVER } from '../../../constants/journey';
import { SUBMITTED_GET_QUOTE_STORAGE } from '../../../constants/sessionStorage';
import {
  HOME_LATEST_QUOTE_STORAGE,
  HOME_RESUME_QUOTE
} from '../../../constants/sessionStorage/homeStorageIdentifiers';
import {
  AGENT_CLICKED_REFERENCE_QUOTES,
  AGENT_SELECTED_QUOTE
} from '../../../constants/sessionStorage/userStorageIdentifiers';
import {
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  isAgent,
  removeFromSessionStorage,
  saveInSessionStorage
} from '../../../helpers';
import {
  saveDataInLatestQuote,
  scrollToFirstError
} from '../../../helpers/HomeCommonMethods';
import { yourCoverObjInitialValues } from '../../../pages/GetQuotePage/InitalValues';
import useGetQuotePageValidity from '../../../pages/GetQuotePage/useGetQuotePageValidity';
import useSectionOpenedStatus from '../../../pages/GetQuotePage/useSectionOpenedStatus';
import { routes } from '../../../routes';
import LegalText from '../../LegalText';
import { MobileBackButton } from '../../MobileBackButton';
import ProgressBar from '../../ProgressBar';
import FormikYourCoverForm from './YourCoverForm';

const className = 'c-GetQuotePage';
const YourCoverComponent = ({ history, location }) => {
  const [prepopulationStatus, setPrepopulationStatus] = useState(
    NO_PREPOPULATION
  );

  const [yourCoverObj, setYourCover] = useState(
    Object.assign({}, yourCoverObjInitialValues)
  );

  const [yourCoverFormTrackCount, setyourCoverFormTrackCount] = useState(0);

  const [opened, handleFormOpen] = useSectionOpenedStatus();
  const [validity, handleFormSubmit] = useGetQuotePageValidity();
  const [nextButton, setNextButton] = useState(false);

  const setYourCoverObjForUpdatedFields = (fieldName, value) => {
    const newValues = { ...yourCoverObj };
    newValues[fieldName] = value;
    setYourCover(newValues);
  };

  const setUpdatedYourCoverValues = values => setYourCover(values);

  const modifyYourCover = obj => {
    if (!obj['specifiedItemsSwitch']) {
      obj['specifiedItems'] = [
        {
          item: '',
          value: '',
          description: ''
        }
      ];
    }
    if (!obj['unspecifiedItemsSwitch']) {
      obj['unspecifiedItems'] = '0';
    }
    return obj;
  };

  const renderSubmitButton = () => (
    <div className={`${className}__button`}>
      <a
        data-ga
        id={`GetQuote__getCoveredButton`}
        data-testid={`GetQuote__getCoveredButton`}
      >
        <Button
          quaternary
          fluid
          onClick={() => {
            setyourCoverFormTrackCount(yourCoverFormTrackCount + 1);
            if (validity.yourCover) {
              goToThankYouPage();
            } else setTimeout(scrollToFirstError, 1);
          }}
        >
          {!nextButton ? 'Get your quote' : 'Next'}
        </Button>
      </a>
    </div>
  );

  // eslint-disable-next-line complexity
  const goToThankYouPage = () => {
    const linkAddress = `${
      routes.termsAndConditions.url
    }${HOME_INSURANCE_TYPE}`;

    const quoteData = {
      aboutYouObj:
        location?.state?.aboutYouObj ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.aboutYouObj,
      yourHomeObj:
        location?.state?.yourHomeObj ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.yourHomeObj,
      yourCoverObj: modifyYourCover(yourCoverObj),
      assumptions:
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.assumptions ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.assumptions ||
        null,
      extraQuestionsValues:
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.extraQuestionsValues ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.assumptions ||
        null
    };
    saveInSessionStorage(
      HOME_QUOTE_THANK_YOU_STORAGE,
      JSON.stringify(quoteData)
    );
    const sessionStorageLatestQuote = getObjectFromSessionStorage(
      `${HOME_LATEST_QUOTE_STORAGE}`
    );
    if (Object.keys(sessionStorageLatestQuote).length !== 0) {
      saveDataInLatestQuote(
        sessionStorageLatestQuote,
        'yourCover',
        quoteData.yourCoverObj
      );
    }
    if (
      isAgent() &&
      getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)
    ) {
      removeFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES);
    }
    history.push({
      pathname: linkAddress,
      state: quoteData
    });
  };

  const getFormStatus = formName => {
    if (opened[formName]) {
      return validity[formName] ? 'success' : 'warning';
    }
    return 'default';
  };

  const sessionStoredValues = getObjectFromSessionStorage(
    `${HOME_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`
  );

  const loadYourCover = (object, override) => {
    const replaceOrKeepValue = key =>
      key in object && object[key] !== '' ? object[key] : yourCoverObj[key];
    setYourCover(
      Object.keys(yourCoverObj).reduce((newObj, key) => {
        newObj[key] = override ? object[key] : replaceOrKeepValue(key);
        return newObj;
      }, {})
    );
  };

  // eslint-disable-next-line complexity
  const preloadForm = () => {
    const objMaker = (oldObj, newObj) =>
      Object.assign({}, oldObj, newObj || {});
    const hasValues = obj =>
      typeof obj === 'object' && Object.keys(obj).length > 0;
    const submitted = getItemFromSessionStorage(
      `${HOME_INSURANCE_TYPE}${SUBMITTED_GET_QUOTE_STORAGE}`
    );
    if (JSON.parse(getItemFromSessionStorage(HOME_RESUME_QUOTE)) === false) {
      const values = getObjectFromSessionStorage(
        `${HOME_QUOTE_THANK_YOU_STORAGE}`
      );
      if (values && values.yourCoverObj) {
        values.yourCoverObj.policyStart = '';
      }
      saveInSessionStorage(
        `${HOME_QUOTE_THANK_YOU_STORAGE}`,
        JSON.stringify(values)
      );
    }
    const values = getObjectFromSessionStorage(
      `${HOME_QUOTE_THANK_YOU_STORAGE}`
    );
    const latestQuote = getObjectFromSessionStorage(
      `${HOME_LATEST_QUOTE_STORAGE}`
    );
    const unSubmitted = () => !submitted && hasValues(values);
    const submittedLatest = () =>
      hasValues(latestQuote) &&
      (!isAgent() || getObjectFromSessionStorage(AGENT_SELECTED_QUOTE));
    const submittedSession = () => hasValues(sessionStoredValues) && !isAgent();
    if (submittedLatest()) {
      setYourCover(oldYourCover =>
        objMaker(oldYourCover, values?.yourCoverObj || latestQuote.yourCover)
      );
      if (values?.yourCoverObj === null) {
        saveInSessionStorage(
          HOME_QUOTE_THANK_YOU_STORAGE,
          JSON.stringify({ ...values, yourCoverObj: latestQuote?.yourCoverObj })
        );
      }
      setPrepopulationStatus(PREPOPULATION_PREFORMED);
    } else if (unSubmitted()) {
      setYourCover(oldYourCover => objMaker(oldYourCover, values.yourCoverObj));
      if (values.yourCoverObj) {
        setPrepopulationStatus(PREPOPULATION_PREFORMED);
      }
    } else if (submittedSession()) {
      loadYourCover(sessionStoredValues, false);
      setPrepopulationStatus(PREPOPULATION_PREFORMED);
    }
  };

  useEffect(() => {
    preloadForm();
  }, []);

  useEffect(() => {
    setNextButton(JSON.parse(getItemFromSessionStorage(HOME_RESUME_QUOTE)));
  }, [yourCoverObj]);

  const getRebuildCost = () => {
    const homeType =
      getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
        ?.yourHomeObj.coverType === HOME_COVER_TYPE.RENT ||
      getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
        ?.yourHomeObj.homeType === HOME_TYPE.APARTMENT;
    return !homeType;
  };

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_COVER} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Get Quotes</TitleWithUnderLine>
      </div>
      <div className={`${className}__subHeading`}>
        <Title type="h4" align="left" variant="black">
          Please complete the following:
        </Title>
      </div>
      <div className={`${className}__accordions`}>
        <AccordionCard
          id={`GetQuote__yourCover`}
          status={getFormStatus('yourCover')}
          label="Your cover"
          childContainerClass={`${className}__noPaddingFormContainer`}
          isOnState={true}
        >
          <FormikYourCoverForm
            yourCoverValid={validity.yourCover}
            formOpened={handleFormOpen}
            validForm={handleFormSubmit}
            setUpdatedYourCoverValues={setUpdatedYourCoverValues}
            yourCoverFormValues={yourCoverObj}
            setYourCoverObjForUpdatedFields={setYourCoverObjForUpdatedFields}
            showRebuildCost={getRebuildCost()}
            yourCoverFormTrackCount={yourCoverFormTrackCount}
            prepopulationStatus={prepopulationStatus}
            yourHomeObj={
              getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
                ?.yourHomeObj
            }
            aboutYouObj={
              getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
                ?.aboutYouObj
            }
          />
        </AccordionCard>
      </div>
      <div className={`${className}__buttonContainer`}>
        {renderSubmitButton()}
        <div className={`${className}__backButton`}>
          <Link to={`${routes.getQuotePage.url}yourHome`}>
            <Button fluid={true}>Back</Button>
          </Link>
        </div>
      </div>
      <div className={`${className}__legalTextContainer`}>
        <LegalText insuranceType="home" />
      </div>
    </div>
  );
};

YourCoverComponent.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any
};

export default YourCoverComponent;
