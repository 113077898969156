// @flow

import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import type { LatestCallAddressType } from '../../types/commonTypes';
import type { UserProfileType } from '../../types/profile';
import { rawToIrishFormat } from './../../helpers';

const employmentStatusValues = {
  OCC143: 'HOUSEWIFE_HUSBAND',
  OCC234: 'RETIRED',
  OCC260: 'STUDENT',
  SEF: 'SELF_EMPLOYED',
  UAC: 'UNEMPLOYED'
};

const getEmploymentStatusCode = (occupationCode: string) => {
  if (['OCC143', 'OCC234', 'OCC260', 'SEF', 'UAC'].includes(occupationCode))
    return employmentStatusValues[occupationCode];
  return occupationCode ? 'EMPLOYED' : '';
};

const getOrEmpty = (fieldValue?: string) => fieldValue || '';

const getAddressFieldValue = (
  address: LatestCallAddressType,
  fieldName: string
) => (address && address[fieldName] ? address[fieldName] : '');

const getTitleOrEmpty = (insuranceType: string, fieldValue?: string) => {
  if (!fieldValue) {
    return '';
  } else if (fieldValue === 'DR') {
    return insuranceType === CAR_INSURANCE_TYPE ? '' : fieldValue;
  }
  return fieldValue;
};

export const mapAboutYouProfileValues = (
  values: UserProfileType,
  insuranceType: string
) => ({
  title: getTitleOrEmpty(insuranceType, values.title),
  firstName: getOrEmpty(values.first_name),
  lastName: getOrEmpty(values.last_name),
  email: getOrEmpty(values.email),
  addressLine1: getAddressFieldValue(values.address, 'address_line1'),
  addressLine2: getAddressFieldValue(values.address, 'address_line2'),
  addressLine3: getAddressFieldValue(values.address, 'address_line3'),
  addressTown: getAddressFieldValue(values.address, 'town'),
  addressCounty: getAddressFieldValue(values.address, 'county'),
  addressEircode: getAddressFieldValue(values.address, 'eircode'),
  phoneNo: getOrEmpty(values.phone),
  dob: rawToIrishFormat(getOrEmpty(values.date_of_birth)),
  employmentStatus: getEmploymentStatusCode(values.occupation),
  mainOccupation: values.occupation,
  policyLength: values.policies.length,
});
