/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import '../../styles/commonFormStyles.scss';
import './PaymentsPage.scss';

import { TitleWithUnderLine } from '@boi/core/lib';
import React, { useEffect } from 'react';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import { desktopBackButtonClass } from '../../constants/commonConstants';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import {
  HOME_JOURNEY,
  JOURNEY_PAYMENT,
  MOTOR_TRAVEL_JOURNEY,
  RENEWAL_JOURNEY_LABEL_TEXT,
  STAGES_HOME_JOURNEY,
  STAGES_MOTOR_TRAVEL_JOURNEY,
  STAGES_RENEWAL_JOURNEY
} from '../../constants/journey';
import { POLICY_RENEWAL } from '../../constants/sessionStorage/genericStorageIdentifiers';
import { HOME_RESUME_QUOTE } from '../../constants/sessionStorage/homeStorageIdentifiers';
import { commonPageStylesIdentifier } from '../../constants/styleConstants/commonClassNames';
import {
  getObjectFromSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import CarPayment from './CarPayment';
import HomePayment from './HomePayment';
import TravelPayment from './TravelPayment';

const className = 'c-PaymentsPage';

interface PaymentsPageProps {
  history: RouterHistory;
  location: Location;
  match: Match;
}

// eslint-disable-next-line complexity
const PaymentsPage = (props: PaymentsPageProps) => {
  const { match, history } = props;
  const insuranceType = match.params.type;

  const renewalRoute =
    getObjectFromSessionStorage(POLICY_RENEWAL)?.renewal || false;

  const getHomePaymentPage = () => (
    <HomePayment {...props} insuranceType={insuranceType} />
  );
  const getCarPaymentPage = () => (
    <CarPayment {...props} insuranceType={insuranceType} />
  );
  const getTravelPaymentPage = () => (
    <TravelPayment {...props} type={insuranceType} />
  );

  const paymentTypeContent = {
    [CAR_INSURANCE_TYPE]: getCarPaymentPage,
    [HOME_INSURANCE_TYPE]: getHomePaymentPage,
    [TRAVEL_INSURANCE_TYPE]: getTravelPaymentPage
  };

  useEffect(() => {
    if (insuranceType === HOME_INSURANCE_TYPE) {
      saveInSessionStorage(HOME_RESUME_QUOTE, false);
    }
  }, []);

  return (
    <div className={className}>
      <div
        className={`${commonPageStylesIdentifier} ${desktopBackButtonClass(
          insuranceType
        )}`}
      >
        <MobileBackButton history={history} />
      </div>
      <ProgressBar
        stage={JOURNEY_PAYMENT}
        labelText={
          insuranceType === HOME_INSURANCE_TYPE
            ? !renewalRoute
              ? HOME_JOURNEY
              : RENEWAL_JOURNEY_LABEL_TEXT
            : MOTOR_TRAVEL_JOURNEY
        }
        stages={
          insuranceType === HOME_INSURANCE_TYPE
            ? !renewalRoute
              ? STAGES_HOME_JOURNEY
              : STAGES_RENEWAL_JOURNEY
            : STAGES_MOTOR_TRAVEL_JOURNEY
        }
      />
      <div>
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <TitleWithUnderLine>Payment</TitleWithUnderLine>
        </div>
        <div className={`${className}__card`}>
          <div className={`${className}__contentContainer`}>
            {insuranceType && paymentTypeContent[insuranceType] ? (
              paymentTypeContent[insuranceType]()
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsPage;
