/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../../styles/commonFormStyles.scss';

import { Dropdown, Typeahead } from '@boi/core/lib';
import { connect, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import {
  employmentStatusValues,
  motorEmploymentStatusValues
} from '../../../../../constants/dropdowns';
import { CAR_INSURANCE_TYPE } from '../../../../../constants/insuranceTypeConstants';
import {
  getRepeatingFieldName,
  isFieldError,
  mapDropdownOptions
} from '../../../../../helpers';

interface Props {
  occupationOptions: Array<{ value: string, label: string }>;
  insuranceType: string;
  fieldNamePrefix?: string;
  isSubFormComponent?: boolean;
}

export const BaseEmploymentDetails = (props: Props & FormikProps) => {
  const className = 'c-EmploymentDetails';
  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    values
  } = props.formik;

  const { fieldNamePrefix, isSubFormComponent = false } = props;

  const { occupationOptions, insuranceType } = props;

  const [showOccupation, setShowOccupation] = useState(false);

  const employedStatuses: Array<string> = [
    'EMP',
    'SEF',
    'EMPLOYED',
    'SELF_EMPLOYED',
    'UNEMPLOYED',
    'UAC'
  ];

  useEffect(() => {
    const newShowOccupation =
      employedStatuses.indexOf(values.employmentStatus) >= 0;
    setShowOccupation(newShowOccupation);
    if (newShowOccupation === false && values.employmentStatus !== '') {
      setFieldValue(
        getRepeatingFieldName('mainOccupation', fieldNamePrefix),
        ''
      );
      setFieldTouched(
        getRepeatingFieldName('mainOccupation', fieldNamePrefix),
        false
      );
    }
  }, [values.employmentStatus]);

  const shouldHaveOccupation = (status: string) => {
    return employedStatuses.includes(status);
  };

  const getFieldActiveClassNames = (isDynamicField: boolean) => {
    const classNames: Array<string> = [
      `${commonFormStylesIdentifier}__fieldContainer`
    ];
    if (isDynamicField) {
      classNames.push(
        isSubFormComponent
          ? `${commonFormStylesIdentifier}__dynamicFieldsContainer--with-no-padding`
          : `${commonFormStylesIdentifier}__dynamicFieldsContainer`
      );
    } else if (!isSubFormComponent) {
      classNames.push(
        `${commonFormStylesIdentifier}__nonDynamicFieldsContainer`
      );
    }
    return classNames.join(' ');
  };

  const getOccupationLabel = () => {
    if (['UNEMPLOYED', 'UAC'].includes(values.employmentStatus))
      return 'Your last occupation:';
    return 'Occupation';
  };

  const getOccupation = () => {
    if (!showOccupation) return null;

    return shouldHaveOccupation(values['employmentStatus']) ? (
      <div className={getFieldActiveClassNames(true)}>
        <label
          htmlFor={getRepeatingFieldName('mainOccupation', fieldNamePrefix)}
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          {getOccupationLabel()}
          <div className={`${commonFormStylesIdentifier}__infoText`}>
            Select the closest matching occupation
          </div>
        </label>
        <Typeahead
          onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
            if (
              !touched[getRepeatingFieldName('mainOccupation', fieldNamePrefix)]
            ) {
              setFieldTouched(
                getRepeatingFieldName('mainOccupation', fieldNamePrefix),
                true
              );
            }
            handleBlur(e);
          }}
          data={occupationOptions}
          error={isFieldError('mainOccupation', touched, errors)}
          errorMessage={errors.mainOccupation}
          id={getRepeatingFieldName('mainOccupation', fieldNamePrefix)}
          name={getRepeatingFieldName('mainOccupation', fieldNamePrefix)}
          placeholder="Search here"
          touched={
            touched[getRepeatingFieldName('mainOccupation', fieldNamePrefix)]
          }
          onChange={(option: { key: string, value: string, text: string }) => {
            const value = option && option.value ? option.value : '';
            setFieldValue(
              getRepeatingFieldName('mainOccupation', fieldNamePrefix),
              value
            );
            if (
              !touched[getRepeatingFieldName('mainOccupation', fieldNamePrefix)]
            ) {
              setFieldTouched(
                getRepeatingFieldName('mainOccupation', fieldNamePrefix),
                true
              );
            }
          }}
          value={values.mainOccupation}
        />
      </div>
    ) : null;
  };

  const employmentStatusDropdownValues =
    insuranceType === CAR_INSURANCE_TYPE
      ? motorEmploymentStatusValues
      : employmentStatusValues;

  return (
    <div className={className}>
      <div className={getFieldActiveClassNames(false)}>
        <label
          htmlFor={getRepeatingFieldName('employmentStatus', fieldNamePrefix)}
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Employment status
        </label>
        <Dropdown
          placeholder="Select here"
          error={isFieldError('employmentStatus', touched, errors)}
          errorMessage={errors['employmentStatus']}
          name={getRepeatingFieldName('employmentStatus', fieldNamePrefix)}
          value={values['employmentStatus']}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            handleChange(e);
          }}
          onBlur={handleBlur}
        >
          <option value="">Select here</option>
          {mapDropdownOptions(
            employmentStatusDropdownValues,
            'employmentStatus'
          )}
        </Dropdown>
      </div>
      {getOccupation()}
    </div>
  );
};

BaseEmploymentDetails.propTypes = {
  formik: PropTypes.object
};

const EmploymentDetails = connect(BaseEmploymentDetails);

export default EmploymentDetails;
