/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './CreateAccount.scss';
import '../../styles/commonPageStyles.scss';

import Auth from '@aws-amplify/auth';
import {
  BOIGroupLogo,
  Button,
  InputField,
  TitleWithUnderLine
} from '@boi/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';

import { customer_config } from '../../amplify.config';
import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants';
import { primaryBlue } from '../../constants/colors';
import {
  FIRST_NAME_ERROR_TEXT,
  LAST_NAME_ERROR_TEXT
} from '../../constants/home';
import {
  phoneNoRegex
} from '../../constants/loginRegistrationConstants';
import { INVALID_PORTFOLIO_CODE_FORMAT, PORTFOLIO_CODE_REGEX } from '../../constants/retriveUserConstant';
import {
  makeRequest,
} from '../../helpers';
import { isFieldError } from '../../helpers/FieldErrorHelper';
import { getUsers, verifyPortfolioCode } from '../../services/retriveUser';
import { addIrishCountryCode } from '../../utils/utils';

type Props = {};
type Values = {
  email: string,
  firstName: string,
  lastName: string,
  phoneNo: string,
  portfolioCode: string
};
const registrationFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phoneNo: 'phoneNo',
  portfolioCode: 'portfolioCode'
};

// $FlowFixMe
var generator = require('generate-password');

const CreateAccountForm = (props: Props & FormikProps<Values>) => {
  const className = 'c-CreateAccount';
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    touched,
    values,
    resetForm
  } = props;

  const [response, setResponse] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line complexity
  const createAccountFunc = async (data: any, exsistingCustomer: boolean, email: string) => {
    const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
    let profileData = mapData(data);
    const quoteRequest = makeRequest({
      url: `${basePath}/profile/profile/create`,
      method: 'POST',
      payload: profileData
    });

    const response = await quoteRequest;
    setLoading(false);
    if (response.successfully_paired) {
      setIsError(false);
      setResponse(exsistingCustomer ? `Portfolio Code successfully matched to email address ${email}` : "Form has been successfully submitted and email sent. Please advice the Customer that an email with a Login Verification Code will be with them shortly.");
      clearForm();
    } else {
      setIsError(true);
      setResponse(response.failure_reason);
    }
  };

  const mapData = (values: any) => {
    return {
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phoneNo,
      email: values.email,
      portfolio_code: values.portfolioCode
    };
  };

  const generatePassword = useCallback(() => {
    // $FlowFixMe
    var password = generator.generate({
      length: 12,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      strict: true
    });
    return password;
  }, []);

  const clearForm = () => {
    resetForm();
  }

  // eslint-disable-next-line complexity
  const signup = async (fieldValues: Values) => {
    setLoading(true);
    setIsError(false);
    setResponse("");
    const isValidPotfolioCode = await verifyPortfolioCode(fieldValues.portfolioCode);
    if (!isValidPotfolioCode || isValidPotfolioCode?.error) {
      setIsError(true);
      setLoading(false);
      setResponse("Please enter a valid portfolio code.");
      return;
    }
    getUsers({
      dropdownValue: 'email',
      searchInput: fieldValues.email
    }).then((res: any) => {
      if (res?.user_list?.length > 0 && res.user_list.some((user: any) => user.email === fieldValues.email)) {
        createAccountFunc(fieldValues, true, fieldValues.email);
      } else {
        Auth.configure(customer_config);
        const fakePassword = generatePassword();
        Auth.signUp({
          username: fieldValues.email,
          password: fakePassword,
          attributes: {
            given_name: fieldValues.firstName,
            family_name: fieldValues.lastName,
            email: fieldValues.email,
            phone_number: addIrishCountryCode(fieldValues.phoneNo)
          }
        })
          .then(() => {
            createAccountFunc(fieldValues, false, fieldValues.email);
          })
          .catch((e: Error) => {
            setLoading(false);
            setIsError(true);
            setResponse(e.message);
          });
      }
    }).catch((e: Error) => {
      setLoading(false);
      setIsError(true);
      setResponse(e.message);
    });
  };


  return (
    <div className={`${className}`}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo--flex`}>
            <BOIGroupLogo color={primaryBlue} />
          </div>
          <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
            <div className={`${className}__mobileTitle`}>
              <TitleWithUnderLine>Create your account</TitleWithUnderLine>
            </div>
          </div>

          <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
            <div className={`${commonPageStylesIdentifier}__desktopTitle`}>
              Register User
            </div>
          </div>

          <Form>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor={registrationFields.firstName}
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Name
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('firstName', touched, errors)}
                  errorMessage={errors.firstName}
                  name={registrationFields.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  touched={touched.firstName}
                  type="text"
                  value={values.firstName}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor={registrationFields.lastName}
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Surname
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('lastName', touched, errors)}
                  errorMessage={errors.lastName}
                  name={registrationFields.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter surname"
                  touched={touched.lastName}
                  type="text"
                  value={values.lastName}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="phoneNo"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Mobile Number
              </label>
              <div className={`${commonFormStylesIdentifier}__infoText`}>
                In order to complete your account setup, to keep your account
                safe, you must setup Multi Factor Authentication. Multifactor
                Authentication uses an additional verification method at login
                to verify your identity. You can set this up below by entering a
                valid mobile number to receive a code by SMS, which can then be
                entered at login.
              </div>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('phoneNo', touched, errors)}
                  errorMessage={errors.phoneNo}
                  name="phoneNo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter mobile number"
                  touched={touched.phoneNo}
                  type="text"
                  value={values.phoneNo}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor={registrationFields.email}
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Email address
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('email', touched, errors)}
                  errorMessage={errors.email}
                  name={registrationFields.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter email address"
                  touched={touched.email}
                  type="email"
                  value={values.email}
                />
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor={registrationFields.portfolioCode}
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Portfolio code
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('portfolioCode', touched, errors)}
                  errorMessage={errors.portfolioCode}
                  name={registrationFields.portfolioCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter portfolio code"
                  touched={touched.portfolioCode}
                  type="text"
                  value={values.portfolioCode}
                />
              </span>
            </div>

            <div className={`${className}${isError ? '__responseError' : '__responseSuccess'}`}>{response}</div>

            <div className={`${className}__infoText`}>Note: Please ensure you have closed out of this policy in Applied before clicking the Sign Up button</div>
            {!loading ? <div className={`${className}__button`}>
              <Button
                id="CreateAccount__signupButton"
                fluid
                quaternary
                disabled={!isValid}
                onClick={async () => {
                  await signup(values);
                }}
                type="submit"
              >
                Sign up
              </Button>
            </div> : <AnimatedSpinner size="medium"></AnimatedSpinner>}
          </Form>
        </div>
      </div>
    </div>
  );
};

const CreateAccount = withFormik < Props, Values> ({
  mapPropsToValues(): {} {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNo: '',
      portfolioCode: ''
    };
  },
  handleSubmit(values: Values, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    firstName: yup
      .string()
      .required(FIRST_NAME_ERROR_TEXT)
      .matches(
        /^[a-zA-Z ]+$/,
        'Numbers and symbols are not permitted at this time'
      ),
    lastName: yup
      .string()
      .required(LAST_NAME_ERROR_TEXT)
      .matches(
        /^[a-zA-Z ]+$/,
        'Numbers and symbols are not permitted at this time'
      ),
    phoneNo: yup
      .string()
      .matches(phoneNoRegex, 'Must be a valid Mobile number')
      .max(10, 'Mobile Number Must be 10 digit')
      .required('Mobile number is required'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    portfolioCode: yup
      .string()
      .matches(PORTFOLIO_CODE_REGEX, INVALID_PORTFOLIO_CODE_FORMAT)
      .required('Portfolio code is required')
  }),
  displayName: 'CreateAccountForm'
})(CreateAccountForm);

export default CreateAccount;
