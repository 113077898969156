/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
export const yourCarFormInitialValues = {
  carReg: '',
  carRisk: '',
  carMake: '',
  carModel: '',
  carYear: '',
  carFuelType: '',
  carEngineSize: '',
  carVersion: '',
  carPurchased: '',
  vehicleValue: '',
  carUsedForWork: '',
  annualKm: '',
  drivingExpType: '',
  noClaimsBonus: '',
  isManualSearch: false
};

export const yourCarFormFieldsList = [
  'carReg',
  'carRisk',
  'carMake',
  'carModel',
  'carYear',
  'carFuelType',
  'carEngineSize',
  'carVersion',
  'carPurchased',
  'vehicleValue',
  'carUsedForWork',
  'annualKm',
  'drivingExpType',
  'noClaimsBonus',
  'isManualSearch'
];
