const InsuranceCodes = {
  FBD: {
    AFTERLOADDISC: 'AFTERLOADDISC',
    CONAD: 'CONAD',
    PNCD: 'PNCD',
    HEAOPT: 'HEAOPT',
    AFTEROPTEX: 'AFTEROPTEX',
    DISC: 'DISC',
    BOICUS: 'BOICUS',
    BOIMUL: 'BOIMUL',
    BOICAR: 'BOICAR',
    PROMOPCT: 'PROMOPCT',
    AFTERSALES: 'AFTERSALES',
    LEVY: 'LEVY',
    INCLEVY: 'INCLEVY'
  },
  RSA: {
    AFTERLOADDISC: '23',
    CONAD: '17',
    PNCD: '15',
    HEAOPT: '14',
    AFTEROPTEX: '24',
    BOICUS: '20',
    BOIMUL: '22',
    BOICAR: '21',
    PROMOPCT: '2',
    AFTERSALES: '25',
    LEVY: '27',
    INCLEVY: '26'
  }
};

export default InsuranceCodes;
