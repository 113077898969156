/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import * as yup from 'yup';

import mortgageProviders from '../../../constants/dropdowns/mortgageProviders';
import {
  COMMON_ERROR_TEXT,
  FIRST_NAME_ERROR_TEXT,
  LAST_NAME_ERROR_TEXT,
  LESS_THAN_FIFTY,
  MORE_THAN_TWO,
  OPTION_TITLE_VALUES_CONSTANT,
  PROMO_CODES
} from '../../../constants/home';
import {
  nameErrorMsg,
  nameRegexPattern,
  phoneNoRegex
} from '../../../constants/loginRegistrationConstants';
import { dateOfBirthYup } from '../../../helpers';
import { maxLengthErrorMsg } from '../../../utils/utils';

const lowerCasePromoCodes = PROMO_CODES.map((code: any) => code.toLowerCase());

const additionalMortagesValues = ['9959', '9954', '10045', '10046'];

const AboutYouFormYupSchema = {
  title: yup
    .string()
    .oneOf(OPTION_TITLE_VALUES_CONSTANT)
    .required('Title is required'),
  otherTitle: yup.string().when('title', {
    is: 'OTHER',
    then: yup
      .string()
      .max(10, maxLengthErrorMsg(10))
      .required('Other title is required')
  }),
  //First Name and Last Name: can't be empty, less than 50 chars
  firstName: yup
    .string()
    .min(2, MORE_THAN_TWO)
    .max(50, LESS_THAN_FIFTY)
    .matches(nameRegexPattern, nameErrorMsg)
    .required(FIRST_NAME_ERROR_TEXT),
  lastName: yup
    .string()
    .min(2, MORE_THAN_TWO)
    .max(50, LESS_THAN_FIFTY)
    .matches(nameRegexPattern, nameErrorMsg)
    .required(LAST_NAME_ERROR_TEXT),
  is_correspondence_address: yup
    .boolean()
    .oneOf([false, true])
    .required('Correspondence address or not is required'),
  displayAddress: yup.string().required('Eircode or address is required'),
  correspondence_displayAddress: yup
    .string()
    .when('is_correspondence_address', {
      is: false,
      then: yup.string().required('Address line is required')
    }),
  addressLine1: yup
    .string()
    .min(2, MORE_THAN_TWO)
    .max(250, LESS_THAN_FIFTY)
    .test(
      'addressLine1',
      ' ',
      // eslint-disable-next-line complexity
      function(): any {
        const { addressLine1 } = this.parent;
        if (addressLine1 === undefined) {
          return false;
        }
        return true;
      }
    ),
  town: yup
    .string()
    .min(2, MORE_THAN_TWO)
    .max(250, LESS_THAN_FIFTY)
    .test(
      'town',
      'Answer is required',
      // eslint-disable-next-line complexity
      function(): any {
        const { town } = this.parent;
        if (town === undefined) {
          return false;
        }
        return true;
      }
    ),
  county: yup
    .string()
    .min(2, MORE_THAN_TWO)
    .max(250, LESS_THAN_FIFTY)
    .test(
      'county',
      ' ',
      // eslint-disable-next-line complexity
      function(): any {
        const { county } = this.parent;
        if (county === undefined) {
          return false;
        }
        return true;
      }
    ),
  selectedAppliedAddressIndex: yup.string().when('findMyAddress', {
    is: true,
    then: yup.string().test(
      'selectedAppliedAddressIndex',
      ' ',
      // eslint-disable-next-line complexity
      function(): any {
        const { selectedAppliedAddressIndex } = this.parent;
        if (
          selectedAppliedAddressIndex === 'selectHere' ||
          selectedAppliedAddressIndex === undefined
        ) {
          return false;
        }
        return true;
      }
    )
  }),
  correspondence_selectedAppliedAddressIndex: yup
    .string()
    .when('correspondence_findMyAddress', {
      is: true,
      then: yup.string().test(
        'correspondence_selectedAppliedAddressIndex',
        ' ',
        // eslint-disable-next-line complexity
        function(): any {
          const { correspondence_selectedAppliedAddressIndex } = this.parent;
          if (
            correspondence_selectedAppliedAddressIndex === 'selectHere' ||
            correspondence_selectedAppliedAddressIndex === undefined
          ) {
            return false;
          }
          return true;
        }
      )
    }),
  correspondence_addressLine1: yup.string().when('is_correspondence_address', {
    is: false,
    then: yup
      .string()
      .min(2, MORE_THAN_TWO)
      .max(250, LESS_THAN_FIFTY)
      .test(
        'correspondence_addressLine1',
        ' ',
        // eslint-disable-next-line complexity
        function(): any {
          const { correspondence_addressLine1 } = this.parent;
          if (correspondence_addressLine1 === undefined) {
            return false;
          }
          return true;
        }
      )
  }),
  correspondence_town: yup.string().when('is_correspondence_address', {
    is: false,
    then: yup
      .string()
      .min(2, MORE_THAN_TWO)
      .max(250, LESS_THAN_FIFTY)
      .test(
        'correspondence_town',
        ' ',
        // eslint-disable-next-line complexity
        function(): any {
          const { correspondence_town } = this.parent;
          if (correspondence_town === undefined) {
            return false;
          }
          return true;
        }
      )
  }),
  correspondence_county: yup.string().when('is_correspondence_address', {
    is: false,
    then: yup
      .string()
      .min(2, MORE_THAN_TWO)
      .max(250, LESS_THAN_FIFTY)
      .test(
        'correspondence_county',
        ' ',
        // eslint-disable-next-line complexity
        function(): any {
          const { correspondence_county } = this.parent;
          if (correspondence_county === undefined) {
            return false;
          }
          return true;
        }
      )
  }),
  phoneNo: yup
    .string()
    .matches(phoneNoRegex, 'Must be a valid Mobile number')
    .max(10, 'Mobile Number Must be 10 digit')
    .required('Mobile number is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .max(50, 'Email must be less then 50 chars')
    .required('Email is required'),
  dob: dateOfBirthYup,
  // employmentStatus: yup
  //   .string()
  //   .oneOf(['OCC143', 'EMP', 'OCC234', 'OCC260', 'SEF', 'UAC'])
  //   .required('Employment status is required'),
  bankOfIrelandCustomer: yup
    .boolean()
    .oneOf([false, true])
    .required('Bank Of Ireland Group Customer answer is required'),
  propertyJointlyOwned: yup
    .string()
    .oneOf(['false', 'true'])
    .required(COMMON_ERROR_TEXT),
  jointlyOwnedDetails: yup.object().when('propertyJointlyOwned', {
    is: 'true',
    then: yup.object().shape({
      title: yup
        .string()
        .oneOf(OPTION_TITLE_VALUES_CONSTANT)
        .required('Title is required'),
      otherTitle: yup.string().when('title', {
        is: 'OTHER',
        then: yup
          .string()
          .max(10, maxLengthErrorMsg(10))
          .required('Other title is required')
      }),
      //First Name and Last Name: can't be empty, less than 50 chars
      firstName: yup
        .string()
        .min(2, MORE_THAN_TWO)
        .max(50, LESS_THAN_FIFTY)
        .matches(nameRegexPattern, nameErrorMsg)
        .required(FIRST_NAME_ERROR_TEXT),
      lastName: yup
        .string()
        .min(2, MORE_THAN_TWO)
        .max(50, LESS_THAN_FIFTY)
        .matches(nameRegexPattern, nameErrorMsg)
        .required(LAST_NAME_ERROR_TEXT),
      dateOfBirth: dateOfBirthYup
    }),
    else: yup.object().notRequired()
  }),
  mortgageApplicationNumber: yup
    .string()
    .matches(
      /^[0-9]{7,8}$/,
      'Please enter a valid Mortgage Application Number'
    ),
  mortgageProvider: yup
    .string()
    .oneOf([
      ...mortgageProviders.map((o: any) => o.dropdownValue.toString()),
      ...additionalMortagesValues
    ])
    .required('Do you have mortgage answer is required'),
  firstTimeBuyer: yup
    .boolean()
    .oneOf([false, true])
    .required('First time buyer answer is required'),
  promoCode: yup
    .string()
    .lowercase()
    .oneOf(
      lowerCasePromoCodes,
      'This is not a valid promo code, no discount will be provided. Please contact the Bank of Ireland Insurance Operations team on: 01-4884062  if you believe your discount code is correct to proceed.'
    )
    .matches(
      /^\w+$/,
      'This is not a valid promo code, no discount will be provided. Please contact the Bank of Ireland Insurance Operations team on: 01 - 4884062  if you believe your discount code is correct to proceed.'
    )
    .max(
      11,
      'This is not a valid promo code, no discount will be provided. Please contact the Bank of Ireland Insurance Operations team on: 01 - 4884062  if you believe your discount code is correct to proceed.'
    )
    .nullable(),
  staffNumber: yup.string().when('promoCode', (val: string) =>
    val && val.toLocaleLowerCase() === 'staff'
      ? yup
          .string()
          .matches(
            /^[a|c|A|C]{1}[0-9]{6,7}$/,
            'Please enter a valid staff number E.g. A1234567.'
          )
          .max(8, maxLengthErrorMsg(8))
          .required('Staff number is required')
      : yup.string().notRequired()
  )
};

export default AboutYouFormYupSchema;
