/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';

import React from 'react';

import { localPhoneNumber } from '../../constants/phoneNumbers';

const className = 'c-GenericErrorPage';

interface Props {
  firstName?: string;
}

const RSAErrorPage = ({ firstName }: Props) => {

  const errorText =
    `We're sorry ${firstName ? firstName : ''} our system is currently unavailable. Please call us on ${localPhoneNumber} to get your quote today. Our lines are open Monday to Friday 9am-5pm.`;

  return (
    <div className={`${className}__textContentContainer`}>
      <div className={`${className}__warningMessage`}
        id='RSAErrorPage__warningMessage'
        data-testid='RSAErrorPage__warningMessage'>
        {errorText}
      </div>
    </div>
  );
};

export default RSAErrorPage;
