/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './SubmitClaimPage.scss';

import { DesktopCard, PhoneButton, TitleWithUnderLine } from '@boi/core/lib';
import React from 'react';
import type { RouterHistory } from "react-router-dom";

import { MobileBackButton } from '../../components/MobileBackButton';
import { chubbTravelEmail } from '../../constants/emails';
import {
  internationalTravelInsurancePhoneNumber,
  travelInsurancePhoneNumber
} from '../../constants/phoneNumbers';
import { commonPageStylesIdentifier } from '../../constants/styleConstants';

interface Props {
  history: RouterHistory
}

const TravelClaimPage = ({ history }: Props) => {
  const className = 'c-SubmitClaimPage';

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <DesktopCard>
        <TitleWithUnderLine>Submit a claim</TitleWithUnderLine>
        <div className={`${className}__content`}>
          <p>
            To make a Claim please phone or write to travel claims within thirty
            days of the incident, or as soon as possible afterwards and provide
            Your name, address and policy number. We do not recommend you send
            financial or personal sensitive details via email as it may not be
            secure whilst in the public domain.
          </p>
          <p>Address:</p>
          <blockquote>
            Travel Insurance Claims,
            <br />
            Sedgwick,
            <br />
            Merrion Hall,
            <br />
            Strand Road,
            <br />
            Sandymount,
            <br />
            Dublin 4
          </blockquote>
          <p>
            <PhoneButton phoneNumber={travelInsurancePhoneNumber}>
              loCall
            </PhoneButton>
            or{' '}
            <PhoneButton phoneNumber={internationalTravelInsurancePhoneNumber}>
              International
            </PhoneButton>
          </p>
          <p>
            Email:{' '}
            <a href={`mailto:${chubbTravelEmail}`}>
              {chubbTravelEmail}
            </a>
          </p>
        </div>
      </DesktopCard>
    </div>
  );
};

export default TravelClaimPage;
