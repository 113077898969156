/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

export const emptyAdditionalDriver = {
  title: '',
  firstName: '',
  lastName: '',
  dob: '',
  additionalDriverPenaltyPointOffenceCount: "",
  additionalDriverPenaltyPointOffences: [],
  relationshipToProposer: '',
  driversLicenseType: '',
  lastFiveYearsExperience: '',
  employmentStatus: '',
  mainOccupation: '',
  yearsHeld: '',
};

export const untouchedAdditionalDriver = {
  title: false,
  firstName: false,
  lastName: false,
  dob: false,
  additionalDriverPenaltyPointOffenceCount: false,
  additionalDriverPenaltyPointOffences: false,
  relationshipToProposer: false,
  driversLicenseType: false,
  lastFiveYearsExperience: false,
  employmentStatus: false,
  mainOccupation: false,
  yearsHeld: false
};
