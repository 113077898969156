
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

// TODO fix this complexity issue... somehow
// @dogpawhat Ciarán Curley

/* eslint-disable complexity */

import '../../../../../styles/commonFormStyles.scss';

import { DatePicker, Dropdown, InputField } from '@boi/core/lib';
import { connect, FormikProps } from 'formik';
import React, { useContext, useEffect } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import {
  carTitleValues,
  genderValues, travelTitleValues
} from '../../../../../constants/dropdowns';
import { getRepeatingFieldName, isFieldError, mapDropdownOptions } from '../../../../../helpers';
import { inFormStructure, InsuranceTypeContext } from '../../../../../pages/GetGenericQuotePage';
import { aboutYouFormIdentifier } from '../../../../Forms/CommonFiles/commonFormConstants';

interface Props {
  fieldNamePrefix?: string
}

export const BasePersonalDetails = (props: Props & FormikProps) => {
  const insuranceType = useContext(InsuranceTypeContext);

  const className = 'c-PersonalDetails';
  const {
    errors,
    handleChange,
    handleBlur,
    touched,
    values,
    setFieldValue
  } = props.formik;


  const { fieldNamePrefix } = props;

  useEffect(() => {
    setFieldValue('insuranceType', insuranceType)
  },[])
  return (
    <div className={className}>
      {inFormStructure('title', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor={getRepeatingFieldName("title", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Title
          </label>
          <Dropdown
            error={isFieldError('title', touched, errors)}
            errorMessage={errors.title}
            name={getRepeatingFieldName("title", fieldNamePrefix)}
            placeholder="Type here"
            type="text"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Select here</option>
            {insuranceType === 'car' ?
              mapDropdownOptions(carTitleValues, 'title') :
              mapDropdownOptions(travelTitleValues, 'title')
            }
          </Dropdown>
        </div>
      ) : null}
      {inFormStructure('userName', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__username`}>
          <label htmlFor={getRepeatingFieldName("userName", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            User name
          </label>
          <InputField
            name={getRepeatingFieldName("userName", fieldNamePrefix)}
            placeholder="Type here"
            value={values.userName}
            onChange={handleChange}
            type="text"
          />
        </div>) : null}
      {inFormStructure('firstName', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor={getRepeatingFieldName("firstName", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            First name
          </label>
          <InputField
            error={isFieldError('firstName', touched, errors)}
            errorMessage={errors.firstName}
            name={getRepeatingFieldName("firstName", fieldNamePrefix)}
            placeholder="Type here"
            touched={touched.firstName}
            value={values.firstName}
            onChange={handleChange}
            type="text"
            onBlur={handleBlur}
          />
        </div>) : null}
      {inFormStructure('lastName', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor={getRepeatingFieldName("lastName", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Last name
          </label>
          <InputField
            error={isFieldError('lastName', touched, errors)}
            errorMessage={errors.lastName}
            name={getRepeatingFieldName("lastName", fieldNamePrefix)}
            placeholder="Type here"
            touched={touched.lastName}
            type="text"
            onBlur={handleBlur}
            value={values.lastName}
            onChange={handleChange}
          />
        </div>) : null}
      {inFormStructure('gender', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor={getRepeatingFieldName("gender", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Gender
          </label>
          <Dropdown
            error={isFieldError('gender', touched, errors)}
            errorMessage={errors.gender}
            name={getRepeatingFieldName("gender", fieldNamePrefix)}
            placeholder="Type here"
            type="text"
            value={values.gender}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Select here</option>
            {mapDropdownOptions(genderValues, 'gender')}
          </Dropdown>
        </div>
      ) : null}
      {inFormStructure('dob', insuranceType, aboutYouFormIdentifier) ? (
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor={getRepeatingFieldName("dob", fieldNamePrefix)} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Date of birth
          </label>
          <DatePicker
            textType
            error={errors.dob}
            name={getRepeatingFieldName("dob", fieldNamePrefix)}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="DD/MM/YYYY"
            touched={touched.dob}
            value={values.dob}
          />
        </div>) : null}
    </div>
  );
};

const PersonalDetails = connect(BasePersonalDetails);

export default PersonalDetails;
