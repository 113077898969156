/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './HomePayment.scss';

import { Form, FormikProps, withFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import { CognitoContext } from '../../../CognitoUtils';
import LegalTextHomeDirectDebit from '../../../components/LegalTextHomeDirectDebit';
import QuoteDisplay from '../../../components/QuoteDisplay';
import {
  commonFormStylesIdentifier,
  DIRECT_DEBIT_STORAGE,
  HOME_QUOTE_THANK_YOU_STORAGE,
  SELECTED_HOME_QUOTE_STORAGE
} from '../../../constants';
import { INSURER_TYPE } from '../../../constants/home';
import { HOME_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { TOTAL } from '../../../constants/paymentTypes';
import {
  PAYMENT_SUCCESS,
  POLICY_RENEWAL
} from '../../../constants/sessionStorage/genericStorageIdentifiers';
import {
  getCurrentDateAndTime,
  getObjectFromSessionStorage,
  isAgent
} from '../../../helpers';
import { getItemFromSessionStorage } from '../../../helpers/sessionStorageHelper';
import { routes } from '../../../routes';
import {
  payHomeFbd,
  payHomeFbd3DS,
  payHomeRsa,
  payHomeRsa3DS
} from '../../../services/paysafeService';
import type { DirectDebitType } from '../../../types';
import CardPayment from '../CardPayment';
import PaymentFrequency from '../CarPayment/PaymentFrequency';
import DirectDebit from '../DirectDebit';
import homePaymentSchema from './homePaymentSchema';
import { PAYMENT_FREQUENCY } from './values';

interface DirectDebitProps {
  history: RouterHistory;
  location: Location;
  match: Match;
  insuranceType: string;
}

type HomePaymentDetails = {
  payment_details: {
    quote_reference?: string,
    card_type?: string,
    status?: string,
    payment_type?: string,
    purchaser?: string,
    payment_date?: string,
    bank_account_number?: string,
    bank_sort_code?: string,
    collection_day?: string,
    installments_number?: string,
    bank_identifier_code?: string,
    international_bank_account_number?: string,
    payment_id?: string,
    product_selected?: string
  }
};

const className = 'c-HomePayment';

// eslint-disable-next-line complexity
const HomePaymentForm = (
  props: DirectDebitProps & FormikProps<DirectDebitType>
) => {
  const { isValid, values, history, setFieldValue, insuranceType } = props;
  const selectedQuote = getObjectFromSessionStorage(
    `${SELECTED_HOME_QUOTE_STORAGE}`
  );

  const renewalRoute =
    getObjectFromSessionStorage(POLICY_RENEWAL)?.renewal || false;

  const paymentData = getObjectFromSessionStorage(
    `${insuranceType}${DIRECT_DEBIT_STORAGE}`
  );

  const homePaymentBackPressed = getItemFromSessionStorage(
    'homePaymentBackPressed'
  );

  const success = getObjectFromSessionStorage(PAYMENT_SUCCESS)?.success;

  const [cardBrand, setCardBrand] = useState('');
  const [quoteReference, setQuoteReference] = useState('');
  const [price, setPrice] = useState(0);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [paysafeError, setPaysafeError] = useState('');
  const [insurerType, setinsurerType] = useState('');

  const { feature } = useContext(CognitoContext);

  const redirectToHome = () => {
    history.push({ pathname: routes.wallet.url });
  };

  const readQuoteData = () => {
    if (
      !paymentData.quoteReference ||
      homePaymentBackPressed === 'true' ||
      success === false
    ) {
      redirectToHome();
    } else {
      setQuoteReference(paymentData.quoteReference);
      setPrice(paymentData.price);
      setMonthlyPrice(paymentData.monthlyPrice);
      setinsurerType(paymentData.insurerType);
    }
  };

  const getPurchaserName = (): string => {
    if (renewalRoute) {
      return `${selectedQuote.policy_holder_information.first_name} ${
        selectedQuote.policy_holder_information.last_name
      }`;
    } else {
      if (
        Object.keys(getObjectFromSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE))
          .length > 0
      ) {
        const quoteStorage = getObjectFromSessionStorage(
          HOME_QUOTE_THANK_YOU_STORAGE
        );
        const { firstName, lastName } = quoteStorage.aboutYouObj;
        return `${firstName} ${lastName}`;
      } else if (
        Object.keys(getObjectFromSessionStorage(SELECTED_HOME_QUOTE_STORAGE))
          .length > 0
      ) {
        const selectedQuote = getObjectFromSessionStorage(
          SELECTED_HOME_QUOTE_STORAGE
        );
        const firstName = selectedQuote.quote_policy_holder_details.first_name;
        const lastName = selectedQuote.quote_policy_holder_details.last_name;
        return `${firstName} ${lastName}`;
      } else {
        return '';
      }
    }
  };

  useEffect(() => {
    readQuoteData();
  }, []);

  useEffect(() => {
    setPaysafeError('');
  }, [values]);

  // eslint-disable-next-line complexity
  const getMarketingConsent = () => {
    if (!renewalRoute && feature.flags.marketing_enabled) {
      return {
        marketing_consent: Object.values(paymentData.consent).includes(false)
          ? false
          : true,
        marketings: {
          email_consent: paymentData.consent.email,
          post_consent: paymentData.consent.post,
          mobile_sms_consent: paymentData.consent.text,
          phone_consent: paymentData.consent.phone
        },
        analyse_transactions: paymentData.analyzeTransaction
      };
    } else {
      return {};
    }
  };
  const getPaymentDetails = {
    ['MONTHLY']: (): HomePaymentDetails => {
      return {
        payment_details: {
          status: 'NOTPAID',
          purchaser: getPurchaserName(),
          bank_account_number: '',
          bank_sort_code: '',
          payment_type: 'DIRECT_DEBIT_PAYMENT',
          quote_reference:
            quoteReference.toString() || selectedQuote?.quoteReference,
          collection_day: values?.directDebitPaymentDate,
          installments_number: PAYMENT_FREQUENCY.DIRECT_DEBIT,
          payment_date: getCurrentDateAndTime(),
          bank_identifier_code: values?.bic,
          international_bank_account_number: values?.iban,
          card_type: 'DD',
          payment_id: '',
          direct_debit_amount: monthlyPrice,
          product_selected: selectedQuote?.scheme_code,
          total_premium: price
        },
        ...getMarketingConsent()
      };
    },
    // eslint-disable-next-line complexity
    ['TOTAL']: (): HomePaymentDetails => {
      return {
        payment_details: {
          quote_reference:
            quoteReference.toString() || selectedQuote.quoteReference,
          card_type: cardBrand,
          status: 'PAID',
          payment_type: 'CARD_PAYMENT',
          purchaser: values.nameOnCard || getPurchaserName(),
          payment_date: getCurrentDateAndTime(),
          bank_account_number: '',
          bank_sort_code: '',
          collection_day: '',
          installments_number: PAYMENT_FREQUENCY.TOTAL,
          bank_identifier_code: '',
          international_bank_account_number: '',
          payment_id: '',
          product_selected: selectedQuote.scheme_code,
          total_premium: price || selectedQuote.price
        },
        ...getMarketingConsent()
      };
    }
  };

  const goToPaymentsThankYouPage = (paymentDetails: HomePaymentDetails) => {
    props.history.push({
      pathname: `${routes.paymentThankYou.url}${insuranceType}`,
      state: {
        paymentDetails: paymentDetails,
        insuranceType: insuranceType
      }
    });
  };

  const paymentOptions = [
    { label: 'Monthly', value: PAYMENT_FREQUENCY.DIRECT_DEBIT },
    { label: 'Single', value: PAYMENT_FREQUENCY.TOTAL }
  ];

  // eslint-disable-next-line complexity
  const submitPayment = () => {
    const isTotal = values.paymentFrequency === PAYMENT_FREQUENCY.TOTAL;
    const payFreq = isTotal ? 'TOTAL' : 'MONTHLY';
    const paymentDetails = getPaymentDetails[payFreq]();
    if (isTotal) {
      const successFn = (paymentToken: string) => {
        paymentDetails.payment_details.payment_id = paymentToken;
        goToPaymentsThankYouPage(paymentDetails);
      };
      const errorFn = (code: string, message: string) =>
        setPaysafeError(`${message} (Code: ${code})`);
      const priceWithDecimals = Math.round(100.0 * getDisplayPrice());
      const pay3DSObj = {
        amount: priceWithDecimals,
        cardHolder: values.nameOnCard
      };
      if (selectedQuote.insurer === INSURER_TYPE.FBD) {
        isAgent()
          ? payHomeFbd(successFn, errorFn)
          : payHomeFbd3DS(pay3DSObj, successFn, errorFn);
      } else {
        isAgent()
          ? payHomeRsa(successFn, errorFn)
          : payHomeRsa3DS(pay3DSObj, successFn, errorFn);
      }
    } else {
      goToPaymentsThankYouPage(paymentDetails);
    }
  };

  const setCardValidity = (isValidCard: boolean) => {
    setFieldValue('cardIsValid', isValidCard);
  };

  const getDisplayPrice = () =>
    values.paymentFrequency === PAYMENT_FREQUENCY.DIRECT_DEBIT
      ? monthlyPrice
      : price;
  const getDisplayLabel = () =>
    values.paymentFrequency === PAYMENT_FREQUENCY.DIRECT_DEBIT
      ? 'Monthly total'
      : 'Total';

  const createCustomDayOfTheMonth = (dayArray: number[]) => {
    let daysOfTheMonthValues = [];
    dayArray.forEach((day: number) =>
      daysOfTheMonthValues.push({
        displayName: `${day}`,
        dropdownValue: day
      })
    );
    return daysOfTheMonthValues;
  };

  const getDirectDebitFields = (paymentFrequency: string) => {
    return paymentFrequency && paymentFrequency !== PAYMENT_FREQUENCY.TOTAL ? (
      <div>
        <DirectDebit
          {...props}
          customDayOfTheMonthValues={
            /* @TODO Add FBD condition */ createCustomDayOfTheMonth([
              1,
              8,
              15,
              22
            ])
          }
        />
        <div
          className={`${commonFormStylesIdentifier}__fieldContainerWithNoMargin`}
        >
          <LegalTextHomeDirectDebit
            values={values}
            setFieldValue={setFieldValue}
            insurerType={insurerType}
          />
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const getCardPaymentFields = (paymentFrequency: string) => {
    return paymentFrequency &&
      paymentFrequency !== PAYMENT_FREQUENCY.DIRECT_DEBIT ? (
      <CardPayment
        setCardBrand={setCardBrand}
        setIsValid={setCardValidity}
        {...props}
        insuranceType={HOME_INSURANCE_TYPE}
      />
    ) : (
      <></>
    );
  };

  const getPaysafeError = () =>
    paysafeError ? (
      <>
        <div className={`${className}__paysafeError`}>{paysafeError}</div>
      </>
    ) : (
      <></>
    );

  return (
    <Form>
      <PaymentFrequency {...props} paymentOptions={paymentOptions} />
      {getDirectDebitFields(values.paymentFrequency)}
      {getCardPaymentFields(values.paymentFrequency)}

      {getPaysafeError()}

      <QuoteDisplay
        sticky
        price={getDisplayPrice()}
        paymentType={TOTAL}
        title={getDisplayLabel()}
        buttonType="Buy"
        disabled={!isValid}
        onClickFunction={submitPayment}
        quoteReference={quoteReference}
        insurerType={insurerType}
      />
    </Form>
  );
};

const HomePayments = withFormik<DirectDebitProps, DirectDebitType>({
  mapPropsToValues(): {} {
    return {
      paymentFrequency: '',
      bankRequireOneSignature: '',
      bankAccountYoursWillAcceptDirectDebit: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      county: '',
      bic: '',
      iban: '',
      directDebitPaymentDate: '',
      directDebitLegalText: false,
      nameOnCard: ''
    };
  },
  handleSubmit(values: DirectDebitType, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: homePaymentSchema,
  displayName: 'HomePaymentForm'
})(HomePaymentForm);

export default HomePayments;
