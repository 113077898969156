/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import CallUsPage from '../../components/CallUsPage/CallUsPage';
import { iconsAndTitles } from '../../constants';
import { CAR_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { carinsurancePhoneNumber, insurancePhoneNumber, localTravelInsurancePhoneNumber } from '../../constants/phoneNumbers'

const getInsuranceNumber = (type: string) =>
  type === TRAVEL_INSURANCE_TYPE ? localTravelInsurancePhoneNumber : type === CAR_INSURANCE_TYPE ? carinsurancePhoneNumber : insurancePhoneNumber;

// eslint-disable-next-line complexity
const AmendPolicyPage = ({ match, history }: { match: Match, history: RouterHistory }) => {
  const type = match.params.type || '';
  let title = "Amend Policy"
  let subText = "If you wish to amend your policy please call us on"
  if(type === 'renewal') {
    title = "Amend Auto Renewal Policy";
    subText = "If you wish to amend your auto renewal policy please call us on"
  }
  return <CallUsPage
    history={history}
    icon={type === 'renewal' ? 'home' : type && iconsAndTitles[type === 'renewal' ? 'home' : type].icon}
    title={title}
    contentText={subText}
    phoneNumber={getInsuranceNumber(type)}
  />
};


export default withRouter(AmendPolicyPage);
