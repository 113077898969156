/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './ViewMultiplePoliciesPage.scss';

import React, { useEffect, useState } from 'react';
import type { Match, RouterHistory } from 'react-router-dom';

import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';
import { getUserProfile } from '../../services/profile';
import type { UserProfileType } from '../../types/profile';
import ViewMultiplePoliciesPage from './ViewMultiplePoliciesPage';

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match;
}

const ViewMultiplePoliciesPageWrapper = (props: Props) => {
  const className = 'c-ViewMultiplePoliciesPage';

  const [userProfile, setUserProfile] = useState(null);
  const insuranceType = props.match.params.type || '';

  useEffect(() => {
    if (!userProfile) {
      getUserProfile().then(
        (res: UserProfileType) => {
          setUserProfile(res);
        }
      );
    }
  });

  return userProfile ?
    <ViewMultiplePoliciesPage
      {...props}
      insuranceType={insuranceType}
      userProfile={userProfile}
    /> : (
      <div className={`${className}__spinnerContainer`}>
        <AnimatedSpinner size="large"></AnimatedSpinner>
      </div>
    );
};

export default ViewMultiplePoliciesPageWrapper;
