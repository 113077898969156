const specifiedItemsDetailsList = [
  {
    displayName: 'Camcorder',
    dropdownValue: 'CAMCORDER',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide details such as equipment type, make and model'
  },
  {
    displayName: 'Furs',
    dropdownValue: 'FURS',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide details of the item and fur type where possible'
  },
  {
    displayName: 'Hearing Aids',
    dropdownValue: 'HEARING_AID',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide manufacturer, type, serial number and full description including left/right ear'
  },
  {
    displayName: 'Jewellery',
    dropdownValue: 'JEWELLERY',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide full details of jewellery type, metal, stones and carat'
  },
  {
    displayName: 'Laptop',
    dropdownValue: 'LAPTOP',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide details of manufacturer, make, model and serial number'
  },
  {
    displayName: 'Mobile',
    dropdownValue: 'MOBILE',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide details of make, model, serial number and whether it is contract or ready to go'
  },
  {
    displayName: 'Tablet',
    dropdownValue: 'TABLET',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide details of manufacturer, type and full description'
  },
  {
    displayName: 'Musical instrument',
    dropdownValue: 'MUSICAL_INSTRUMENT',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide details such as instrument type, make and model where possible'
  },
  {
    displayName: 'Pedal Cycle',
    dropdownValue: 'PEDAL_CYCLE',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide details of manufacturer, make, model and serial number'
  },
  {
    displayName: 'Other',
    dropdownValue: 'OTHER',
    minRequiredItemValue: 1501,
    descriptionPrompt: 'In the Item Description field, please provide details of manufacturer, type and full description'
  }
];

export default specifiedItemsDetailsList;
