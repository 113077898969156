import './ProgressBar.scss';

import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { commonPageStylesIdentifier } from '../../constants';
import {
  HOME_JOURNEY,
  STAGES_HOME_JOURNEY
} from '../../constants/journey';

const ProgressBar = (props) => {
  const { stage, labelText = HOME_JOURNEY, stages = STAGES_HOME_JOURNEY, vertical = false } = props;
  const [step, setStep] = useState(1)
  const className = 'c-ProgressBar';
  const verticalClass = 'c-ProgressBarVertical';

  const stageBuilder = () => {
    const stepElement = stages.findIndex((item) => stage === item)
    setStep(stepElement + 1)
  }

  const renderLine = (index) =>
    <div className={`${className}__line ${vertical && verticalClass}__line ${index <= step ?
      `${className}__line--active` : ''}`}> </div>


  const renderIcon = (index) => {
    const active = index <= step
    const getActiveClass = () => active ? `${className}__icon--active` : ''

    return (
      <>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <FontAwesomeIcon
            data-testid={`progress-bar-icon-${step}`}
            icon={active ? faCheckCircle : faCircle}
            className={`${className}__icon ${getActiveClass()}`}
          />
        </div>
        <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
          <FontAwesomeIcon
            data-testid={`progress-bar-icon-${step}`}
            icon={active ? faCheckCircle : faCircle}
            className={`${className}__icon ${getActiveClass()}`}
            size="2x"
          />
        </div>
      </>
    )
  }

  const renderLabel = (index, label) =>
    <div className={`${className}__label ${vertical && verticalClass}__label ${index <= step ?
      `${className}__label--active` : ''}`}>{label}</div>


  useEffect(() => {
    stageBuilder()
  }, [step])

  return (
    <div className={`${className} ${vertical ? verticalClass : ''}`}>
      <div className={`${className}__justifyContainer  ${vertical && verticalClass}__justifyContainer  ${className}__progressContainer`}>
        {stages.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              {idx > 0 && renderLine(idx + 1)}
              {renderIcon(idx + 1)}
            </React.Fragment>
          )
        })}
      </div>
      <div className={`${className}__justifyContainer  ${vertical && verticalClass}__justifyContainer ${className}__labelContainer`}>
        {stages.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              {renderLabel(idx + 1, labelText[idx])}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
};

ProgressBar.propTypes = {
  stage: PropTypes.string,
  labelText: PropTypes.array,
  stages: PropTypes.array,
  vertical: PropTypes.bool
}

export default ProgressBar;
