import './ForceLogIn.scss'

import { Button, CardWrapper, TitleWithUnderLine } from '@boi/core/lib';
import React from 'react';

import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier } from '../../constants';
import { routes } from '../../routes';

interface Props {
  location: Location;
}

const signup = 'signup';
const login = 'login';
// eslint-disable-next-line flowtype/no-types-missing-file-annotation
const ForceLogIn = (props: Props) => {
  const { history } = props;

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  const goToPage = (path: string) => {
    history.push(path === login ? routes.loginPage.url : routes.signUp.url);
  };

  const className = 'c-ForceLoginPage';
  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Login/Sign-Up</TitleWithUnderLine>
      </div>
      <CardWrapper alignItemsCenter={false}>
        <>
          <div className={`${commonPageStylesIdentifier} ${className}__titleText`}>
            <div className={`${className}__desktopTitle`}>
              To continue with payment for your policy, you first must register/login for our 
              digital wallet solution to allow you to have the opportunity to review your policy 
              information and documentation at any time. You can perform this action by clicking the 
              buttons below.
            </div>
          </div>
          <div className={`${className}__button`}>
            <Button
              fluid={true}
              id={`${className}__${login}`}
              onClick={() => {
                goToPage(login);
              }}
            >
              Login
            </Button>
          </div>
          <div className={`${className}__button`}>
            <Button
              fluid={true}
              id={`${className}__${signup}`}
              onClick={() => {
                goToPage(signup);
              }}
            >
              Sign Up
            </Button>
          </div>
        </>
      </CardWrapper>
    </div>
  );
};

export default ForceLogIn;
