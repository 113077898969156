const employmentStatusValues = [
  {
    displayName: 'Housewife/Husband',
    dropdownValue: 'HOUSEWIFE_HUSBAND',
  },
  {
    displayName: 'Employed',
    dropdownValue: 'EMPLOYED',
  },
  {
    displayName: 'Retired',
    dropdownValue: 'RETIRED',
  },
  {
    displayName: 'Student',
    dropdownValue: 'STUDENT',
  },
  {
    displayName: 'Self Employed',
    dropdownValue: 'SELF_EMPLOYED',
  },
  {
    displayName: 'Unemployed',
    dropdownValue: 'UNEMPLOYED',
  }
];

export default employmentStatusValues;
