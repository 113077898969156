"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./Checkbox.scss");

var _formik = require("formik");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _InputErrorMessage = require("../InputErrorMessage");

var _InputErrorMessage2 = _interopRequireDefault(_InputErrorMessage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Checkbox = function Checkbox(_ref) {
  var _ref$id = _ref.id,
      id = _ref$id === void 0 ? '' : _ref$id,
      _ref$label = _ref.label,
      label = _ref$label === void 0 ? '' : _ref$label,
      _ref$labelFontSize = _ref.labelFontSize,
      labelFontSize = _ref$labelFontSize === void 0 ? 14 : _ref$labelFontSize,
      _ref$onChange = _ref.onChange,
      onChange = _ref$onChange === void 0 ? function () {} : _ref$onChange,
      _ref$onBlur = _ref.onBlur,
      onBlur = _ref$onBlur === void 0 ? function () {} : _ref$onBlur,
      _ref$name = _ref.name,
      name = _ref$name === void 0 ? '' : _ref$name,
      _ref$value = _ref.value,
      value = _ref$value === void 0 ? false : _ref$value,
      _ref$isChecked = _ref.isChecked,
      isChecked = _ref$isChecked === void 0 ? false : _ref$isChecked;
  var className = 'c-Checkbox';

  var getCheckedCheckbox = function getCheckedCheckbox() {
    return _react2.default.createElement("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: "22",
      height: "22",
      viewBox: "0 0 22 22"
    }, _react2.default.createElement("g", {
      id: "".concat(id, "-Group"),
      transform: "translate(-3 -3)",
      fill: "none",
      stroke: "#0000ff",
      strokeWidth: "2px"
    }, _react2.default.createElement("rect", {
      id: "".concat(id, "-").concat(className, "__checkboxTick"),
      "data-name": "Rectangle 3",
      width: "22",
      height: "22",
      rx: "2",
      transform: "translate(3 3)"
    }), _react2.default.createElement("g", {
      id: "".concat(id, "-tick"),
      transform: "translate(8.654 9.905)"
    }, _react2.default.createElement("path", {
      id: "".concat(id, "-Line"),
      d: "M8,0,.5,7.5",
      transform: "translate(3.5)",
      stroke: "#0000ff",
      strokeLinecap: "square",
      strokeMiterlimit: "10",
      strokeWidth: "2"
    }), _react2.default.createElement("path", {
      id: "".concat(id, "-Line-2"),
      "data-name": "Line",
      d: "M.5.5l4,4",
      transform: "translate(-0.5 3)",
      stroke: "#0000ff",
      strokeLinecap: "square",
      strokeMiterlimit: "10",
      strokeWidth: "2"
    }))));
  };

  var getUncheckedCheckbox = function getUncheckedCheckbox() {
    return _react2.default.createElement("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: "22",
      height: "22",
      viewBox: "0 0 22 22"
    }, _react2.default.createElement("g", {
      id: "".concat(id, "-tick-box"),
      transform: "translate(-3 -3)"
    }, _react2.default.createElement("g", {
      id: "".concat(id, "-").concat(className, "__checkboxOutline"),
      "data-name": "Rectangle 3 Copy",
      transform: "translate(3 3)",
      fill: "#f1f1f1",
      stroke: "#484848",
      strokeMiterlimit: "10",
      strokeWidth: "1"
    }, _react2.default.createElement("rect", {
      width: "22",
      height: "22",
      rx: "2",
      stroke: "none"
    }), _react2.default.createElement("rect", {
      x: "0.5",
      y: "0.5",
      width: "21",
      height: "21",
      rx: "1.5",
      fill: "none"
    }))));
  };

  return _react2.default.createElement("div", {
    "data-testid": "".concat(className, "$").concat(name)
  }, _react2.default.createElement(_formik.ErrorMessage, {
    name: name
  }, function (msg) {
    return _react2.default.createElement(_InputErrorMessage2.default, {
      id: "".concat(name, "ErrorMessage"),
      error: true,
      errorMessage: msg
    });
  }), _react2.default.createElement("div", {
    className: className
  }, _react2.default.createElement("label", {
    "data-testid": "".concat(className, "__label$").concat(name),
    className: "".concat(className, "__label"),
    htmlFor: name
  }, _react2.default.createElement("div", {
    className: "".concat(className, "__inputContainer")
  }, _react2.default.createElement("input", {
    "data-testid": "".concat(className, "__input$").concat(name),
    className: "".concat(className, "__input"),
    id: name,
    value: value,
    name: name,
    type: "checkbox",
    checked: isChecked,
    onChange: onChange,
    onBlur: onBlur
  }), _react2.default.createElement("svg", {
    className: "svg ".concat(className, "__checkbox"),
    xmlns: "http://www.w3.org/2000/svg",
    width: "24",
    height: "24",
    viewBox: "0 0 24 24"
  }, isChecked ? getCheckedCheckbox() : getUncheckedCheckbox())), _react2.default.createElement("span", {
    className: "".concat(className, "__text"),
    style: {
      fontSize: "".concat(labelFontSize, "px")
    }
  }, label))));
};

exports.default = Checkbox;