const drivingExperienceValues = [
  { "displayName": "I have insurance in my own name, on my own policy.", dropdownValue: "1-X" },
  { "displayName": "I have been a named driver on another policy.", dropdownValue: "4-9" },
  { "displayName": "I have been driving a company car and insured under this policy", dropdownValue: "6-A" },
  {
    "displayName": "I do not have any previous driving experience, or at least not in the last 2 years.",
    dropdownValue: "7-X"
  }
];

export default drivingExperienceValues;
