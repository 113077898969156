/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import '../../styles/commonFormStyles.scss';
import './CarRegLookup.scss';

import {
  InputField
} from '@boi/core/lib';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import React, { useState } from 'react';

import { commonFormStylesIdentifier } from '../../constants';
import { getCarRegLookup } from '../../services/motor/carRegLookupService';
import type { RouterPropsType } from '../../types/commonTypes';
import AnimatedSpinner from '../AnimatedSpinner/AnimatedSpinner';
import CarDetailsInfo from './CarDetailsInfo';

const REQUEST_LOADING = 'CarRegLookup/LOADING';
const REQUEST_SUCCESS = 'CarRegLookup/SUCCESS';
const REQUEST_FAILURE = 'CarRegLookup/FAILURE';

type RequestState =
  | typeof REQUEST_LOADING
  | typeof REQUEST_SUCCESS
  | typeof REQUEST_FAILURE;

const CarRegLookup = (props: RouterPropsType & FormikProps) => {
  const className = 'c-CarRegLookup';
  const {
    disabled,
    errors,
    name,
    onBlur,
    onChange,
    placeholder,
    values,
    setFieldValue,
    isFieldError,
    touched
  } = props;
  const [requestStatus, setRequestStatus] = useState<?RequestState>();
  // TODO: @kpajtasev manual search disabled for now
  // const { isManualSearch } = values;
  const setIsManualSearch = (status: boolean) => setFieldValue("isManualSearch", status);

  let carRegLookupResponse = {};

  const clearRegFields = () => {
    setFieldValue('carMake', '');
    setFieldValue('carModel', '');
    setFieldValue('carYear', '');
    setFieldValue('carFuelType', '');
    setFieldValue('carEngineSize', '');
    setFieldValue('carVersion', '');
    setFieldValue('carABICode', '');
  };

  const getCarRegDetails = async (carReg: string) => {
    setIsManualSearch(false);
    setRequestStatus(REQUEST_LOADING);

    if (carReg !== '') {
      carRegLookupResponse = await getCarRegLookup(carReg, props);
      setRequestStatus(REQUEST_SUCCESS);
      if (carRegLookupResponse.hasOwnProperty('model')) {
        setFieldValue('carMake', carRegLookupResponse.make.toString());
        setFieldValue('carModel', carRegLookupResponse.model.toString());
        setFieldValue('carYear', carRegLookupResponse.year_manufacture.toString());
        setFieldValue('carFuelType', carRegLookupResponse.fuel_type.toString());
        setFieldValue('carEngineSize', carRegLookupResponse.engine_cc.toString());
        setFieldValue('carVersion', carRegLookupResponse.version.toString());
        setFieldValue('carRisk', carRegLookupResponse.risk.toString());
        setRequestStatus(REQUEST_SUCCESS);
      } else {
        carRegLookupResponse = {};
        setRequestStatus(REQUEST_FAILURE);
        clearRegFields();
      }
    } else {
      carRegLookupResponse = {};
      setRequestStatus(REQUEST_FAILURE);
      clearRegFields();
    }
  };

  const hasCarRegAndRegMake = () => !!values.carReg && !!values.carMake;

  const getCarDetailsInfo = () => {
    const notInLoadingOrErrorStatus = requestStatus !== REQUEST_LOADING && requestStatus !== REQUEST_FAILURE;
    return hasCarRegAndRegMake() && notInLoadingOrErrorStatus ? <CarDetailsInfo {...values} /> : <></>;
  };

  // TODO: @kpajtasev manual search disabled for now
  // const resetCarValues = () => {
  //   ["carReg",
  //     "carMake",
  //     "carModel",
  //     "carYear",
  //     "carFuelType",
  //     "carEngineSize",
  //     "carVersion"].forEach((field: string) => {
  //     setFieldValue(field, "");
  //     setFieldTouched(field, false);
  //   });
  // };
  //
  // const openManualSearch = () => {
  //   resetCarValues();
  //   setIsManualSearch(true);
  // };

  const hasCarRegFieldError = () => {
    if (touched.carReg && values.carReg && !values.carMake)
      return true;
    return isFieldError('carReg', touched, errors);
  };

  const getCarRegFieldErrorMessage = () => {
    if (touched.carReg && values.carReg && !values.carMake)
      return 'Press the enter key or search icon to get the car details';
    return errors.carReg;
  };

  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor='carReg' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Your car registration
          <div className={`${commonFormStylesIdentifier}__infoText`}>
            Enter car registration and press the search icon
          </div>
        </label>
        <InputField
          touched={touched.carReg}
          disabled={disabled}
          type="string"
          placeholder={placeholder}
          name={name}
          onBlur={onBlur}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            onChange(e);
            setRequestStatus(null);
            clearRegFields();
          }}
          value={values.carReg}
          error={hasCarRegFieldError()}
          errorMessage={getCarRegFieldErrorMessage()}
          onClickIcon={() => {
            getCarRegDetails(values.carReg);
          }}
          onKeyPress={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              // Prevents the form from submitting when you hit the enter key
              e.preventDefault();
              getCarRegDetails(values.carReg);
            }
          }}
          suffix={faSearch}
        />

        {/* TODO: @kpajtasev manual search disabled for now*/}
        {/*<button type="button"*/}
        {/*        className={`${className}__manualSearchButton`}*/}
        {/*        id="OpenCarManualSearchButton"*/}
        {/*        onClick={openManualSearch}>{'Don\'t know your registration number?'}</button>*/}
      </div>
      {(requestStatus === REQUEST_LOADING) ?
        <AnimatedSpinner size="medium" /> :
        null
      }

      {/* TODO: @kpajtasev manual search disabled for now*/}
      {/*<div className={`${commonFormStylesIdentifier}__fieldContainer`}>*/}
      {/*  {isManualSearch ? <ManualSearch {...props} /> : <></>}*/}
      {/*</div>*/}
      {getCarDetailsInfo()}
    </div>
  );
};

export default CarRegLookup;
