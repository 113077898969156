/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { basePath } from '../../constants/commonConstants';
import { makeRequest, sendAnalyticsEvent } from '../../helpers';

export const postTravelQuote = async (travelQuoteData, props = {}) => {
  const quoteRequest = await makeRequest({
    method: 'POST',
    url: `${basePath}/travel/travel_quotes`,
    payload: travelQuoteData,
    history: props.history
  });
  sendAnalyticsEvent(quoteRequest, 'insuranceEvent', 'Travel');
  return quoteRequest;
};
