import 'react-popper-tooltip/dist/styles.css';
import './PopOverWrapper.scss'

import React, {useEffect, useState} from 'react'
import { usePopperTooltip } from 'react-popper-tooltip';

const classes = 'PopOverWrapperContainer'
// eslint-disable-next-line react/prop-types
export default function PopOverWrapper({clickTitle, children, visiblility, updateVisibility}) {
  const [controlledVisible, setControlledVisible] = useState(false);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    interactive: true,
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
    delayHide: 250
  });

  useEffect(() => {
    if(!visiblility) {
      setControlledVisible(visiblility)
      updateVisibility()
    }
  }, [visiblility])

  return (
    <>
      <div className={classes}>
        <p className={`${classes}__title`} data-testid='popover_title_click' ref={setTriggerRef}>
          {clickTitle}
        </p>

        {visible && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ className: 'tooltip-container' })}
          >
            {children}
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          </div>
        )}
      </div>
    </>
  );
}

