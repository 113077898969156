const personalDetailsFields = {
  title: '',
  firstName: '',
  lastName: '',
  gender: '',
  dob: '',
};

const addressDetailsFields = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressTown: '',
  addressCounty: '',
  addressEircode: '',
  isThisYourPostalAddress: null,
  postalAddressLine1: '',
  postalAddressLine2: '',
  postalAddressLine3: '',
  postalAddressTown: '',
  postalAddressCounty: '',
  postalAddressEircode: '',
};

const contactDetailsFields = {
  phoneNo: '',
  email: '',
};

const employmentDetailsFields = {
  employmentStatus: '',
  mainOccupation: '',
};

const drivingDetailsFields = {
  driversLicenseType: '',
  passedTestInIrelandOrUk: null,
  lastFiveYearsExperience: '',
  yearsHeld: '',
};

const BOICustomerAndPromoCodeFields = {
  bankOfIrelandCustomer: '',
  bankOfIrelandHomeInsurancePolicy: '',
  promoCode: ''
};

const insuranceFormDetailsFields = {
  coverAbroad: '',
  provider: '',
};

const insuranceType = '';

const aboutYouFormInitialValues = {
  ...personalDetailsFields,
  ...addressDetailsFields,
  ...contactDetailsFields,
  ...employmentDetailsFields,
  ...drivingDetailsFields,
  ...insuranceFormDetailsFields,
  ...BOICustomerAndPromoCodeFields,
  insuranceType,
};

export default aboutYouFormInitialValues;
