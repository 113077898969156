"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _BOIGroupLogo = require("./BOIGroupLogo");

var _BOIGroupLogo2 = _interopRequireDefault(_BOIGroupLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _BOIGroupLogo2.default;