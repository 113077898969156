/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonFormStyles.scss';
import './QuoteExtras.scss';

import { AccordionText, SimpleSwitch } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { primaryBlue } from '../../constants/colors';
import { commonFormStylesIdentifier } from '../../constants/styleConstants';

type PropValues = {
  accidentalDamage: boolean,
  ncdProtectionPlus: boolean,
  homeEmergencyAssist: boolean
};
type Props = {
  handleChangeWRecalculate: noop,
  setFieldValue: noop,
  handleBlur: noop,
  values: PropValues,
  // eslint-disable-next-line flowtype/no-weak-types
  addonDetails: any
};

const documentLinks = {
  summaryOfCover: 'https://boi.com/insurancehomeipidpr',
  policyBooklet: 'https://boi.com/insurancehomepolicybooklet',
  policyDocument:
    'https://personalbanking.bankofireland.com/insure-and-protect/insurance/home-insurance-quote/important-documents/'
};

const className = 'c-QuoteExtras';

const QuoteExtras = (props: Props) => {
  /* function body */

  const [extraQuotesState, setExtraQuotes] = useState({
    ContentsAD: false,
    NCDProtection: false,
    HomeEmergencyAssist: false
  });

  const { handleBlur, handleChangeWRecalculate, values, addonDetails } = props;

  // eslint-disable-next-line complexity
  const renderExtraQuotes = useCallback(() => {
    const quoteData = {
      ContentsAD: false,
      NCDProtection: false,
      HomeEmergencyAssist: false
    };
    addonDetails.forEach((item: any) => {
      // eslint-disable-next-line no-prototype-builtins
      if (quoteData.hasOwnProperty(item.unique_code)) {
        quoteData[item.unique_code] = true;
      }
    });
    setExtraQuotes(quoteData);
  }, [addonDetails]);

  useEffect(() => {
    if (addonDetails && addonDetails.length > 0) {
      renderExtraQuotes();
    }
  }, [addonDetails]);

  return (
    <>
      {extraQuotesState.ContentsAD && (
        <div key="accidentalDamage" className={`${className}__paddedContainer`}>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <div className={`${className}__switchContainer`}>
              <label
                htmlFor="accidentalDamage"
                className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}
              >
                Add Accidental Damage Cover for Contents
              </label>
              <SimpleSwitch
                name="accidentalDamage"
                onLabel="on"
                on={values['accidentalDamage']}
                onClick={() =>
                  handleChangeWRecalculate(
                    'accidentalDamage',
                    !values['accidentalDamage']
                  )
                }
                onBlur={handleBlur}
              />
            </div>
            <div className={`${className}__readMore`}>
              <AccordionText
                label="Read more"
                labelColor={primaryBlue}
                iconColor={primaryBlue}
                openLabel="Read less"
                inverted
              >
                <div className={`${className}__readMoreContent`}>
                  <div>
                    Accidental Damage to Contents (This cover applies only if
                    Your Schedule states that Accidental Damage is included) at
                    the Property owned by any member of Your Household or for
                    which they are responsible.
                  </div>
                  <ul className={`${className}__bulletPoints`}>
                    <li>
                      If we insure your buildings you are automatically covered
                      against Accidental Damage to the buildings
                    </li>
                    <li>
                      If you would like to cover your contents (while they are
                      in the home) against Accidental Damage which has not been
                      deliberately caused
                    </li>
                    <li>
                      Terms & Conditions apply. See your{' '}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={documentLinks.policyDocument}
                      >
                        Policy documentation
                      </a>{' '}
                      for full details of cover.
                    </li>
                  </ul>
                </div>
              </AccordionText>
            </div>
          </div>
        </div>
      )}
      {extraQuotesState.NCDProtection && (
        <div
          key="ncdProtectionPlus"
          className={`${className}__paddedContainer`}
        >
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <div className={`${className}__switchContainer`}>
              <label
                htmlFor="ncdProtectionPlus"
                className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}
              >
                Add No Claim Discount Protection Plus
              </label>
              <SimpleSwitch
                name="ncdProtectionPlus"
                onLabel="on"
                on={values['ncdProtectionPlus']}
                onClick={() =>
                  handleChangeWRecalculate(
                    'ncdProtectionPlus',
                    !values['ncdProtectionPlus']
                  )
                }
                onBlur={handleBlur}
              />
            </div>
            <div className={`${className}__readMore`}>
              <AccordionText
                label="Read more"
                labelColor={primaryBlue}
                iconColor={primaryBlue}
                openLabel="Read less"
                inverted
              >
                <div className={`${className}__readMoreContent`}>
                  <ul className={`${className}__bulletPoints`}>
                    <li>
                      You may make a single claim in the period of insurance,
                      without a reduction in the level of no claims discount
                      under your policy. If you make a second claim, your no
                      claims discount will be reduced in line with Step back
                      protection.
                    </li>
                    <li>
                      Terms and Conditions apply. See your{' '}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={documentLinks.policyDocument}
                      >
                        Policy documentation
                      </a>{' '}
                      for full details of cover
                    </li>
                  </ul>
                </div>
              </AccordionText>
            </div>
          </div>
        </div>
      )}
      {extraQuotesState.HomeEmergencyAssist && (
        <div
          key="homeEmergencyAssist"
          className={`${className}__paddedContainer`}
        >
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <div className={`${className}__switchContainer`}>
              <label
                htmlFor="homeEmergencyAssist"
                className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}
              >
                Add Home Emergency Assist Cover
              </label>
              <SimpleSwitch
                name="homeEmergencyAssist"
                onLabel="on"
                on={values['homeEmergencyAssist']}
                onClick={() =>
                  handleChangeWRecalculate(
                    'homeEmergencyAssist',
                    !values['homeEmergencyAssist']
                  )
                }
                onBlur={handleBlur}
              />
            </div>
            <div className={`${className}__readMore`}>
              <AccordionText
                label="Read more"
                labelColor={primaryBlue}
                iconColor={primaryBlue}
                openLabel="Read less"
                inverted
              >
                <div className={`${className}__readMoreContent`}>
                  <ul className={`${className}__bulletPoints`}>
                    <li>
                      Home emergency assistance provides a 24 hours, 365 days a
                      year service, through our service provider MAPFRE
                      ASSISTANCE Agency Ireland, in the event of a household
                      emergency.
                    </li>
                    <li>
                      Terms and Conditions apply. See your{' '}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={documentLinks.policyDocument}
                      >
                        Policy documentation
                      </a>{' '}
                      for full details of cover
                    </li>
                  </ul>
                </div>
              </AccordionText>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

QuoteExtras.propTypes = {
  handleBlur: PropTypes.func,
  handleChangeWRecalculate: PropTypes.func,
  values: PropTypes.shape({
    accidentalDamage: PropTypes.bool,
    ncdProtectionPlus: PropTypes.bool,
    homeEmergencyAssist: PropTypes.bool
  }),
  addonDetails: PropTypes.array
};

export default QuoteExtras;
