/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../styles/commonFormStyles.scss';
import './YourCarCoverForm.scss';

import { ButtonGroup, DatePicker, WideDivider } from '@boi/core/lib';
import { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { dividerColor } from '../../../constants/colors';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants/commonClassNames';
import { isFieldError } from '../../../helpers';
import { addDaysToDate, getCurrentDate } from '../../../helpers/DateHelper';
import type { MotorClaimType, YourCarCoverFormTypes } from '../../../types';
import MotorClaims from '../../MotorClaims';
import { AdditionalDrivers, PenaltyPoints } from './YouCarCoverFormSections';

export const yourCarCoverMainDriverPrefix = 'mainDriver';

const className = 'c-YourCarCoverForm';
const additionalDriversFieldName = 'additionalDrivers';

const YourCarCoverForm = (props: FormikProps<YourCarCoverFormTypes>) => {
  const {
    errors,
    handleBlur,
    setFieldValue,
    touched,
    values,
    setTouched,
    setFieldTouched
  } = props;

  const handleCustomBlur = (
    fieldName: string,
    e: SyntheticEvent<HTMLInputElement>
  ) => {
    setFieldValue(fieldName, values[fieldName]);
    handleBlur(e);
  };

  useEffect(() => {
    setTouched({ ...touched, motorClaims: [] });
  }, []);

  const handleOnButtonGroupClick = (
    selectedValue: boolean | string,
    fieldName: string
  ) => {
    setFieldValue(fieldName, selectedValue);
    setFieldTouched(fieldName, true);
  };

  const showAdditionalDrivers = () => {
    return values.hasAdditionalDrivers ? (
      <div className={`${className}__additionalDriversContainer`}>
        <AdditionalDrivers
          {...props}
          baseFieldName={additionalDriversFieldName}
          onBlur={handleCustomBlur}
        />
      </div>
    ) : null;
  };

  return (
    <div className={`${className}__container`}>
      <div
        className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}
      >
        <label
          htmlFor="policyStart"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Policy start date
        </label>
        <DatePicker
          error={errors.policyStart}
          name="policyStart"
          onBlur={handleBlur}
          onChange={(e: SyntheticEvent<HTMLInputElement>) =>
            setFieldValue('policyStart', e.currentTarget.value)
          }
          placeholder="Type here"
          touched={touched.policyStart}
          value={values.policyStart}
          minDate={getCurrentDate()}
          maxDate={addDaysToDate(getCurrentDate(), 45)}
        />
      </div>
      <div
        className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}
      >
        <label
          htmlFor="coverType"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Cover type
        </label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            name="coverType"
            onClick={(selectedValue: string) =>
              handleOnButtonGroupClick(selectedValue, 'coverType')
            }
            options={[
              { label: 'Comprehensive', value: 'COMPREHENSIVE' },
              {
                label: 'Third party, fire and theft',
                value: 'THIRD_PARTY_AND_THEFT'
              }
            ]}
            value={values.coverType}
            touched={touched.coverType}
            selected={values.coverType}
            error={isFieldError('coverType', touched, errors)}
            errorMessage={errors.coverType}
          />
        </div>
      </div>
      <PenaltyPoints
        driverTypePrefix={yourCarCoverMainDriverPrefix}
        showDisqualifiedOrConvictedField={true}
        onBlur={handleCustomBlur}
        {...props}
        mainContainerCustomClass={`${className}__penaltyPointsMainContainer`}
        repeatingFieldsCustomClass={`${className}__penaltyPointsRepeatingContainer`}
      />
      <div
        className={`${className}__componentContainer ${commonFormStylesIdentifier}__dividerContainer`}
      >
        <WideDivider height={1} color={dividerColor} />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <div className={`${className}__motorClaimsLabelContainer`}>
          <label
            className={`${commonFormStylesIdentifier}__fieldLabel`}
            htmlFor="motorClaims"
          >
            How many accidents or claims have you or anyone that may drive the
            car, had in the last four years?
          </label>
        </div>
        <MotorClaims
          errors={errors}
          name="motorClaims"
          onChange={(e: MotorClaimType) => setFieldValue('motorClaims', e)}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          touched={touched}
          values={values}
          onBlur={handleCustomBlur}
        />
      </div>
      <div
        className={`${className}__componentContainer ${commonFormStylesIdentifier}__dividerContainer`}
      >
        <WideDivider height={1} color={dividerColor} />
      </div>
      <div
        className={`${commonFormStylesIdentifier}__fieldContainer ${className}__componentContainer`}
      >
        <label
          htmlFor="hasAdditionalDrivers"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Are there any additional drivers?
        </label>
        <ButtonGroup
          key={`hasAdditionalDrivers`}
          name="hasAdditionalDrivers"
          onClick={(selectedValue: string) => {
            const hasAdditionalDrivers = selectedValue === 'true';
            handleOnButtonGroupClick(
              hasAdditionalDrivers,
              'hasAdditionalDrivers'
            );
            if (!hasAdditionalDrivers) {
              setFieldValue(additionalDriversFieldName, []);
              setFieldTouched(additionalDriversFieldName, []);
            }
          }}
          selected={values.hasAdditionalDrivers}
          onBlur={handleBlur}
          options={[
            { label: 'No', value: false },
            { label: 'Yes', value: true }
          ]}
          error={isFieldError('hasAdditionalDrivers', touched, errors)}
          errorMessage={errors.hasAdditionalDrivers}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        {showAdditionalDrivers()}
      </div>
    </div>
  );
};

YourCarCoverForm.propTypes = {
  values: PropTypes.object
};

export default YourCarCoverForm;
