/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import './StatementOfFact.scss';

import { Button, CardWrapper, TitleWithUnderLine, WideDivider } from '@boi/core/lib';
import { FormikProps,withFormik } from 'formik';
import React, { useState } from 'react';
import type { Location, RouterHistory } from 'react-router-dom';

import TermsAndConditionsCheckbox from '../../components/HomeTermsAndConditions/TermsAndConditionsCheckbox/TermsAndConditionsCheckbox';
import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import { dividerColor } from '../../constants/colors';
import { JOURNEY_YOUR_QUOTE, RENEWAL_JOURNEY_LABEL_TEXT } from '../../constants/journey';
import { insurancePhoneNumber } from '../../constants/phoneNumbers';
import { commonFormStylesIdentifier, commonPageStylesIdentifier } from '../../constants/styleConstants';
import { routes } from '../../routes';

const className = 'c-StatementOfFact';

interface Props {
  history: RouterHistory;
  location: Location;
}

const StatementOfFactForm = (props: FormikProps & Props) => {
  const { values, setFieldValue, match, history, location } = props;
  const insuranceType = match.params.type;
  const [noAgreeTouched, setNoAgreeTouched] = useState(false);
  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_QUOTE} labelText={RENEWAL_JOURNEY_LABEL_TEXT} stages={RENEWAL_JOURNEY_LABEL_TEXT}/>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Statement Of Fact</TitleWithUnderLine>
      </div>
      <CardWrapper alignItemsCenter={false}>
        <TermsAndConditionsCheckbox setFieldValue={setFieldValue} values={values} />
        <div className={`${commonFormStylesIdentifier}__dividerContainer ${className}__divider`}>
          <WideDivider color={dividerColor} height={1} />
        </div>
        <div className={`${className}__confirmButtonContainer`}>
          <Button
            id="StatementOfFactForm__Button"
            fluid
            disabled={!values.acceptedTermsAndConditions}
            onClick={() => {
              history.push({
                pathname: `${routes.makePayment.url}${insuranceType}`,
                state: location.state
              });
            }}
          >
            {`I confirm`}
          </Button>
        </div>
        <div className={`${className}__denyButtonContainer`}>
          <Button
            id="StatementOfFactForm__denyButton"
            fluid
            onClick={() => {
              setNoAgreeTouched(true);
            }}
          >
            {`I do not confirm`}
          </Button>
        </div>
        {noAgreeTouched && (
          <p className={`${className}__paragraph`}>Unfortunately if you cannot confirm the above statements we will not be able to offer cover, therefore you will
            unable to proceed any further, please call <a href="tel:01-488 4062">{insurancePhoneNumber}</a> for assistance.</p>
        )}
      </CardWrapper>
    </div>
  );
};

const StatementOfFact = withFormik < Props > ({
  mapPropsToValues(): {} {
    return {
      acceptedTermsAndConditions: ''
    };
  },
  handleSubmit(
    { setSubmitting }: FormikProps
  ) {
    setSubmitting(false);
  },
  displayName: 'StatementOfFactForm'
})(StatementOfFactForm);

export default StatementOfFact;

