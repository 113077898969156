/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../styles/commonFormStyles.scss';
import '../MotorViewQuote.scss';

import { AccordionText, SimpleSwitch, WideDivider } from '@boi/core/lib';
import React from 'react';

import { dividerColor } from '../../../constants/colors';
import { PRESTIGE_COVER } from '../../../constants/motor';
import { COMPREHENSIVE_TYPE } from '../../../constants/policyTypesConstants';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { MotorQuoteExcessType } from '../../../types/motor';

const className = 'c-MotorViewQuote';

const MotorQuoteExcess = (props: MotorQuoteExcessType) => {
  const {
    carInsuranceType,
    insuranceLevelOfCover,
    values,
    handleBlur,
    handleChangeWithRecalculate
  } = props;

  const renderComprehensiveSwitches = () => {
    return (
      <>
        <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
          <WideDivider height={1} color={dividerColor} />
        </div>
        <div key="personalProtectionPlus" className={`${className}__paddedContainer`}>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <div className={`${className}__switchContainer`}>
              <label htmlFor="personalProtectionPlus"
                     className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}>
                Personal protection plus
              </label>
              <SimpleSwitch
                name='personalProtectionPlus'
                onLabel="on"
                on={values.personalProtectionPlus}

                onClick={() => {
                  handleChangeWithRecalculate('personalProtectionPlus',
                    !values['personalProtectionPlus']);
                }
                }
                onBlur={handleBlur}
              />
            </div>
            <div className={`${className}__readMoreContainer`}>
              <AccordionText
                label="Read more"
                icon={null}
                labelColor="inherit"
                openLabel="Read less"
                underline
                inverted
              >
                <div className={`${className}__readMoreContent`}>
                  <div>
                    This cover provides personal accident protection for you or your spouse/civil partner in the event
                    of death or injury while travelling. The maximum benefit payable in respect of any one event is
                    €23,500. Terms & Conditions apply. See the <a target="_blank" rel="noopener noreferrer"
                                                                  href="https://boimotor.rsagroup.ie/docs/Summary%20of%20Cover.pdf">Summary
                    of Cover</a> or the <a target="_blank" rel="noopener noreferrer"
                                           href="https://boimotor.rsagroup.ie/docs/Policy_Booklet.pdf">Policy
                    Booklet</a> for full details.
                  </div>
                </div>
              </AccordionText>
            </div>
          </div>
        </div>
        <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
          <WideDivider height={1} color={dividerColor} />
        </div>
        {
          insuranceLevelOfCover === PRESTIGE_COVER ?
            <>
              <div key="ncdProtection" className={`${className}__paddedContainer`}>
                <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                  <div className={`${className}__switchContainer`}>
                    <label htmlFor="ncdProtection"
                           className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}>
                      NCD protection
                    </label>
                    <SimpleSwitch
                      name='ncdProtection'
                      onLabel="on"
                      on={values.ncdProtection}
                      onClick={() => {
                        handleChangeWithRecalculate('ncdProtection',
                          !values['ncdProtection']);
                      }
                      }
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className={`${className}__readMoreContainer`}>
                    <AccordionText
                      label="Read more"
                      icon={null}
                      labelColor="inherit"
                      openLabel="Read less"
                      underline
                      inverted
                    >
                      <div className={`${className}__readMoreContent`}>
                        <ul className={`${className}__bulletPoints`}>
                          <li>If you do not have more than one claim in any three year period, your current level of No
                            Claims
                            Discount will not be affected.
                          </li>
                          <li>If you have more than one claim, your No Claims Discount will be reduced by three years on
                            our
                            No
                            Claim Discount Scale.
                          </li>
                          <li>More than two claims could result in your No Claims Discount being reduced to Nil.</li>
                        </ul>
                        <div>
                          Terms & Conditions apply. See the <a
                          href="https://boimotor.rsagroup.ie/docs/Summary%20of%20Cover.pdf" rel="noopener noreferrer"
                          target="_blank">Summary of Cover</a> or the <a
                          rel="noopener noreferrer" target="_blank"
                          href="https://boimotor.rsagroup.ie/docs/Policy_Booklet.pdf">Policy
                          Booklet</a> for full details.
                        </div>
                      </div>
                    </AccordionText>
                  </div>
                </div>
              </div>
              <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
                <WideDivider height={1} color={dividerColor} />
              </div>
              <div key="additionalBenefitsPlus" className={`${className}__paddedContainer`}>
                <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                  <div className={`${className}__switchContainer`}>
                    <label htmlFor="additionalBenefitsPlus"
                           className={`${commonFormStylesIdentifier}__fieldLabel ${commonFormStylesIdentifier}--withNoMargin`}>
                      Additional protection plus
                    </label>
                    <SimpleSwitch
                      name='additionalBenefitsPlus'
                      onLabel="on"
                      on={values.additionalBenefitsPlus}

                      onClick={() => {
                        handleChangeWithRecalculate('additionalBenefitsPlus',
                          !values['additionalBenefitsPlus']);
                      }
                      }
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className={`${className}__readMoreContainer`}>
                    <AccordionText
                      label="Read more"
                      icon={null}
                      labelColor="inherit"
                      openLabel="Read less"
                      underline
                      inverted
                    >
                      <div className={`${className}__readMoreContent`}>
                        If you avail of this option you will get the benefit of the following increased limits and
                        additional covers:
                        <ul className={`${className}__bulletPoints`}>

                          <li><span>Courtesy Car increased from €200 to €500</span></li>
                          <li><span>Personal Belongings increased from €500 to €1,000</span></li>
                          <li><span>Replacement Locks increased from €1,000 to €1,500</span></li>
                          <li><span>The cost of replacing a child or booster seat</span></li>
                          <li><span>The cost of replacing Sat Nav Equipment and Game consoles up to €1,000</span></li>
                          <li><span>Driving Other Cars - Comprehensive cover (full policy protection while you drive someone {"else's"} car with their permission. Limits do apply in respect of the cubic capacity and replacement value of such vehicles).</span>
                          </li>
                          <li><span>Pre Accident Value - Enhanced Payment of €1,500 (If your car is stolen and not recovered or damaged beyond economical repair we will pay €1,500 in addition to the pre accident value of your car provided your car was registered as new by you and is not more than 3 years old at the time of loss or damage).</span>
                          </li>
                        </ul>
                        <div>
                          Terms & Conditions apply. See the <a
                          href="https://boimotor.rsagroup.ie/docs/Summary%20of%20Cover.pdf" rel="noopener noreferrer"
                          target="_blank">Summary of Cover</a> or the <a
                          rel="noopener noreferrer" target="_blank"
                          href="https://boimotor.rsagroup.ie/docs/Policy_Booklet.pdf">Policy
                          Booklet</a> for full details.
                        </div>
                      </div>
                    </AccordionText>
                  </div>
                </div>
              </div>
              <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
                <WideDivider height={1} color={dividerColor} />
              </div>

            </>
            :
            ''
        }

      </>
    );
  };

  if (carInsuranceType === COMPREHENSIVE_TYPE) {
    return (
      <>
        {renderComprehensiveSwitches()}
      </>
    );
  } else return <></>
};

export default MotorQuoteExcess;
