/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import * as yup from 'yup';

import { FIRST_NAME_ERROR_TEXT, LAST_NAME_ERROR_TEXT,LESS_THAN_FIFTY, MORE_THAN_TWO } from '../../constants/home';
import { nameErrorMsg, nameRegexPattern } from '../../constants/loginRegistrationConstants';
import { COMMON_ERROR_TEXT, OPTION_SEARCH_USER_CONSTANT } from '../../constants/retriveUserConstant';

const RetreiveUserYupSchema = {
    dropdownValue: yup
      .string()
      .oneOf(OPTION_SEARCH_USER_CONSTANT)
      .required(COMMON_ERROR_TEXT),
    searchInput: yup.string().when('dropdownValue', {
      is: 'email',
      then: yup.string().required('Email is required'),
      otherwise: yup.string()
      .min(2, MORE_THAN_TWO)
      .max(50, LESS_THAN_FIFTY)
      .matches(
        nameRegexPattern,
        nameErrorMsg
      )
      .test(
        // eslint-disable-next-line complexity
        function(val: string): any {
          const {dropdownValue} = this.parent
          if ((dropdownValue === 'firstName' || dropdownValue === 'lastName') && (!val || val.length < 2)) {
              return this.createError({
                message: dropdownValue === 'firstName' ? FIRST_NAME_ERROR_TEXT : LAST_NAME_ERROR_TEXT
              });
            }
          return true;
        },      
        )
    }),
  }

export default RetreiveUserYupSchema;
