import * as yup from "yup";

import { allowedDriverAge } from '../../../helpers/DateHelper';
import { ZONE_IDS } from '../YourTravelForm/values';

export const yourTravelCoverFormYupSchema = {
  excess: yup.array().of(yup.number()).required(),
  carHireExcess: yup.boolean().nullable()
    .when(['destinationZone', 'dob'], (destinationZone, dob, schema) => {
      if (destinationZone === ZONE_IDS['Europe'] && allowedDriverAge(dob)) {
        return schema.required('This field is required');
      }
    }),
  winterSportsExcess: yup.boolean().nullable().required('This field is required'),
  businessExpenseExcess: yup.boolean().nullable().required('This field is required'),
  dataProtectionConsent: yup.boolean()
    .oneOf([true], 'You must accept the Terms and Conditions'),
  chubbTOBConsent: yup.boolean()
    .oneOf([true], 'You must accept the Chubb Terms of Business and Privacy Policy.')
};

export default yourTravelCoverFormYupSchema;
