// User Specific Storage Identifiers
export const BOI_WALLET_USER_STORAGE = 'BOIWalletUser';
export const BOI_WALLET_USER_NAME_STORAGE = 'BOIWalletUserName';
export const SIGN_UP_PAGE_EMAIL_STORAGE = 'signUpPageEmail';
export const AGENT_CLICKED_REFERENCE_QUOTES = 'agentLatestQuotes';
export const AGENT_SELECTED_QUOTE = 'agentSelectedQuote';
export const USER_BRANCH = 'userAgent';
export const BOI_WALLET_TOWN_COUNTY_DATA = 'BOIWalletTownCountyData';

export const USER_QUOTE_LIST = 'UserQuotesList';
