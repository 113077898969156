// @flow

import '../../../styles/commonFormStyles.scss';
import './AddressInfo.scss';

import {Button, Dropdown, InputField, Typeahead } from '@boi/core/lib';
import React, { useCallback,useEffect, useState } from 'react';

import { commonFormStylesIdentifier } from '../../../constants';
import { LESS_THAN_FIFTY, MORE_THAN_TWO } from '../../../constants/home';
import { BOI_WALLET_TOWN_COUNTY_DATA } from '../../../constants/sessionStorage/userStorageIdentifiers';
import { getObjectFromSessionStorage, saveInSessionStorage } from '../../../helpers';
import { getAppliedAddress } from '../../../services/homeServices';
import AnimatedSpinner from '../../AnimatedSpinner/AnimatedSpinner';
import { AddressInfoInterface } from './models/AboutYouFormModels';

interface Props {
  className: string;
}

type FilteredCountiesorTown = {
  name: string,
  association: Array<string>
};

const errorType = {
  addressLine1: 'Address Line 1 is required',
  county: 'County is required',
  town: 'Town is required'
}
// eslint-disable-next-line complexity
const AddressInfo = (props: AddressInfoInterface & Props) => {

  const { className, addressLine1, addressLine2, county, eircode, town, handleBlur, handleChange,
    touched, selectedAppliedAddressIndex, correspondenceAddr, setAddressFieldData, showEircode, activateManualAddressCallback } = props;
  const [townData, setTownData] = useState([]);
  const [countyData, setCountyData] = useState([]);
  const [findMyAddressDiv, setFindMyAddressDiv] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isShowSelectAddress, setIsshowSelectAddress] = useState(false);
  const [showError, setShowError] = useState(false)
  const [isShowEirCodeDiv, setIsShowEirCodeDiv] = useState(showEircode)
  const [showFindMyAddr, setShowFindMyAddr] = useState(false)

  const componentName = 'c-AddressInfo';
  const getInputValue = (value?: string): string => {
    return value || ''
  };

  const townAndCountyData = getObjectFromSessionStorage(BOI_WALLET_TOWN_COUNTY_DATA)

  const checkTownPresentInTownAndCountyData = ()=> {
    if (townAndCountyData && townAndCountyData.counties && townAndCountyData.towns) {

      townAndCountyData.counties.forEach((item: FilteredCountiesorTown) => {
        if (item.name === county) {
          if (!item.association.find((item1: string) => item1 === town)) {
            townAndCountyData.counties.forEach((item: FilteredCountiesorTown) => {
              item.association.push(`${town}`)
            })
          }
        }
      })

      townAndCountyData.towns.forEach((item: FilteredCountiesorTown) => {
        if (item.name === town) {
          if (!item.association.find((item1: string) => item1 === county)) {
            townAndCountyData.towns.forEach((item: FilteredCountiesorTown) => {
              item.association.push(`${county}`)
            })
          }
        }
      })
    }
    saveInSessionStorage(BOI_WALLET_TOWN_COUNTY_DATA, JSON.stringify(townAndCountyData));
  }

  const countiesOrTownDataMap = (identifiter: string) => {
    // $FlowFixMe
    const obj = townAndCountyData[identifiter]?.find(
      (destinationTowns: FilteredCountiesorTown) => 
      destinationTowns.name === county
    );
    if(county !== "" ) {
      // $FlowFixMe
      obj?.association.find((item: string) => item === town) ? setAddressFieldData(correspondenceAddr, 'town', town) : setAddressFieldData(correspondenceAddr, 'town', '')
    }
      return obj
        ? obj.association.map((item: string) => ({
          value: item,
          text: item,
          key: item
        }))
        : [];
  }

  const checkTownOrCountyisEmpty = () => {
    return (town === "" && county === "")
  }

  const findAddress = async () => {
    try {
      const payload = {
        addressline1: addressLine1,
        addressline2: addressLine2,
        town,
        county
      }
      const addressFromApplied = await getAppliedAddress(payload, true)
      setSelectedAddress(addressFromApplied)
      if (addressFromApplied?.error) {
        setShowSpinner(false)
        setShowError(true)
        setShowFindMyAddr(false)
      } else {
        setShowFindMyAddr(false)
        setShowSpinner(false)
        setIsshowSelectAddress(true)
        setAddressFieldData(correspondenceAddr, 'findMyAddress', true);
        setFindMyAddressDiv(true)
        setAddressFieldData(correspondenceAddr,'selectedAppliedAddressIndex', "selectHere");
      }
    } catch (e) {
      setSelectedAddress([])
    }
  }  

  // eslint-disable-next-line complexity
  const checkErrorMessage = (val: string, errorCode: string) => {
    if(val === "") {
      return errorType[errorCode]
    } else if (val?.length <= 2) {
      return MORE_THAN_TWO
    } else if(val?.length > 250) {
      return LESS_THAN_FIFTY
    }
  }

  const checkError  = (val: string) => {
    if(val === "" || val?.length <= 2 || val?.length > 250) {
      return true
    }
  }
  
  const callToActivateManualAddress = useCallback(() => {
    activateManualAddressCallback(correspondenceAddr)
  }, [])

  // eslint-disable-next-line complexity
  useEffect(() => {
    checkTownPresentInTownAndCountyData();
    if(townAndCountyData && Array.isArray(townAndCountyData.counties) && Array.isArray(townAndCountyData.towns)) {
    const townData = checkTownOrCountyisEmpty() ? 
    townAndCountyData.towns.map((town: FilteredCountiesorTown) => ({
      value: town.name,
      text: town.name,
      key: town.name
    })) 
    : countiesOrTownDataMap("counties") 

    const countyData =
    townAndCountyData.counties.map((county: FilteredCountiesorTown) => ({
      value: county.name,
      text: county.name,
      key: county.name
    })) 

    setTownData(townData)
    setCountyData(countyData)
  }
  }, [town,county]);

  useEffect(() => {
      setShowFindMyAddr(showEircode)
      setFindMyAddressDiv(showEircode)
  }, [showEircode])

  return (
    <div className={componentName}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor='addressLine1' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Address line 1
          <div className={`${className}__infoText`}>
            For dwellings that include a name please enter this in Address Line 1 and then 
            include the first line of your address in Address Line 2.
          </div>
        </label>
        <span className={`${className}__input`}>
          <InputField
            name="addressLine1"
            data-testid='addressLine1'
            value={getInputValue(addressLine1)}
            placeholder="Type here"
            readOnly={false}
            onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
              e.preventDefault()
              callToActivateManualAddress()
              setAddressFieldData(correspondenceAddr, 'addressLine1', e.target.value)
            }}
            type="text"
            onBlur={handleBlur}
            error={addressLine1 === "" || addressLine1?.length <=2}
            errorMessage={checkErrorMessage(addressLine1, 'addressLine1')}
        />
        </span>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor='addressLine2' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Address line 2
        </label>
        <span className={`${className}__input`}>
          <InputField
            name="addressLine2"
            data-testid='addressLine2'
            value={getInputValue(addressLine2)}
            placeholder="Type here"
            readOnly={false}
            onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
              e.preventDefault()
              callToActivateManualAddress()
              setAddressFieldData(correspondenceAddr, 'addressLine2', e.target.value)
            }}
            type="text"
            onBlur={handleBlur}
          />
        </span>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor='town' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Town
        </label>
        <span className={`${className}__input`}>
          <Typeahead
            data={townData}
            data-testid='town'
            id={correspondenceAddr ? "correspondence_town" : "town"}
            name={correspondenceAddr ? "correspondence_town" : "town"}
            placeholder="Type here"
            onChange={(option: { key: string, value: string, text: string }) => {
              setAddressFieldData(correspondenceAddr, 'findMyAddress', true);
              setFindMyAddressDiv(true)
              setIsShowEirCodeDiv(true)
              callToActivateManualAddress()
              setAddressFieldData(correspondenceAddr, 'town', option ? option.value : '');
              setSelectedAddress([])
            }}
            value={getInputValue(town)}
            onBlur={handleBlur}
            error={checkError(town)}
            errorMessage={checkErrorMessage(town, 'town')}
          />
        </span>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor='county' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          County
        </label>
        <span className={`${className}__input`}>
            <Typeahead
              data={countyData}
              data-testid='county'
              id={correspondenceAddr ? "correspondence_county" : "county"}
              name= {correspondenceAddr ? "correspondence_county" : "county"}
              placeholder="Type here"
              onChange={(option: { key: string, value: string, text: string }) => {
                setAddressFieldData(correspondenceAddr, 'findMyAddress', true);
                setFindMyAddressDiv(true)
                setIsShowEirCodeDiv(true)
                callToActivateManualAddress()
                setAddressFieldData(correspondenceAddr, 'county', option ? option.value : '');
                setSelectedAddress([])
              }}
              value={getInputValue(county)}
              onBlur={handleBlur}
              error={checkError(county)}
              errorMessage={checkErrorMessage(county, 'county')}
            />
        </span>
      </div>
      {showError ? 
        <div className={`c-InputErrorMessage`}>
          {`Address Not Found`}
        </div> : null}
      {showFindMyAddr ? 
        <div className={`c-InputErrorMessage c-InputErrorMessagePadding`}>
          {`Please select your respective address`}
        </div> : null}
      {findMyAddressDiv ?
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <Button
          fluid={true}
          onClick={() => {
            findAddress()
            setShowSpinner(true)
          }}
        >
          Find my address
        </Button>
      </div> : null }
      {showSpinner ? <AnimatedSpinner size="medium"></AnimatedSpinner> : null}
      {isShowSelectAddress && !showSpinner ?
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor='county' className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Please Select Address
          </label>
          <span className={`${className}__input`}>
            <Dropdown
              placeholder="Select here"
              name="SelectAppliedAddress"
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                const targetIndex = parseInt(e.target.value);
                setAddressFieldData(correspondenceAddr,'selectedAppliedAddressIndex', e.target.value)
                if(e.target.value !== "selectHere") {
                  Object.entries(selectedAddress[targetIndex]).forEach(([key, value]: [string, mixed]) => {
                    setAddressFieldData(correspondenceAddr, key, value)
                  })
                  setAddressFieldData(correspondenceAddr, 'displayAddress', `${selectedAddress[targetIndex]?.address_line1} ${selectedAddress[targetIndex]?.address_line2} ${selectedAddress[targetIndex]?.county}`)
                }
              }}
              onBlur={handleBlur}
              error={selectedAppliedAddressIndex === "selectHere" || touched["SelectAppliedAddress"]}
              errorMessage={"Answer is Required"}
            >
              {/* $FlowFixMe */}
              <option value="selectHere">Select here</option>
              { // $FlowFixMe
              showError && selectedAddress.length > 0 ? [] : selectedAddress?.slice(0, selectedAddress?.length).map((reference: string, index: number) => {
                return <option value={index} key={`${index}_${reference}`}>{selectedAddress[index].address_line1} {selectedAddress[index].address_line2} {selectedAddress[index]?.town} {selectedAddress[index]?.county}</option>
              })}
            </Dropdown>
          </span>
        </div> : null}
      {(!isShowEirCodeDiv && eircode.length > 0) ?
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor='eircode' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Eircode
        </label>
        <span className={`${className}__input`}>
          <InputField
            name="eircode"
            data-testid='eircode'
            readOnly={true}
            value={getInputValue(eircode)}
            placeholder="Type here"
            onChange={handleChange}
            type="text"
            onBlur={handleBlur}
          />
        </span>
      </div> : null}
    </div>
    
  );
};

export default AddressInfo;
