"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _colors = require("../../../constants/colors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PhoneIcon = function PhoneIcon(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? _colors.fontColorSecondary : _ref$color;
  return _react2.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 512 512",
    style: {
      fill: color
    }
  }, _react2.default.createElement("path", {
    d: "M0,48c0,256.5,207.9,464,464,464c11.3,0,20.9-7.8,23.4-18.6l24-104c2.6-11.3-3.3-22.9-14-27.6l-112-48\nc-9.8-4.2-21.2-1.4-28,6.9l-49.6,60.6c-78.3-36.7-141.2-100.5-177.2-177.2l60.6-49.6c8.3-6.7,11.1-18.2,6.9-28l-48-112\nC145.5,3.9,133.9-2,122.6,0.6l-104,24C7.7,27.1,0,36.8,0,48z"
  }));
};

exports.default = PhoneIcon;