/* eslint-disable react/prop-types */
import { AccordionCard, Text } from '@boi/core/lib';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import GenericErrorComponent from '../../../components/GenericErrorComponent/GenericErrorComponent';
import { GET_HOME_DOCUMENT } from '../../../constants/home';
import { HOME_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { docHelper } from '../../../helpers/DocumentHelper';
import { getDownloadLink } from '../../../utils/utils';

const accordionText = 'Policy Documents';
// eslint-disable-next-line complexity
const DocumentsCard = ({
  policyDocs,
  className = '',
  insuranceType = '',
  startDate = ''
}) => {
  const [accordionData, setAccordionData] = useState({
    setOne: [],
    setTwo: []
  });
  const [accordionSet, setAccordionState] = useState({
    one: true,
    two: false
  });
  const [showDate, setShowDate] = useState(false);
  const [policyDocsText, setPolicyDocsText] = useState(
    'Renewed Policy Documents'
  );

  const {
    newBusiness,
    newBusinessAndLapse,
    renewalTransfer,
    renewalConfirmationAndNewBusiness
  } = docHelper();

  // eslint-disable-next-line react/prop-types
  const CustomerHeader = ({ dateValue, label, display_date = false }) => {
    return (
      <div className="custom-header">
        <div className="custom-header-card">
          <Text align="left">{'Document Type'}</Text>
          <Text align="left">{label}</Text>
        </div>
        {showDate && display_date && (
          <div className="custom-header-card">
            <Text align="left">{'Start Date'}</Text>
            <Text align="left">{dateValue}</Text>
          </div>
        )}
      </div>
    );
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  const handleToggleCard = (accordionID: string) => {
    setAccordionState(() => {
      return {
        ...accordionSet,
        [accordionID]: !accordionSet[accordionID]
      };
    });
  };

  const renderDocs = (labelName, documentLinks, accordionNum, display_date) => {
    return (
      <>
        {documentLinks && documentLinks.length > 0 && (
          <AccordionCard
            id="accordionCard"
            label={labelName}
            status="none"
            labelStyle="black"
            childContainerClass={`${className}__noPaddingAccordion`}
            customLabelClass={`${className}__accordionLabel`}
            isOnState={accordionSet[accordionNum]}
            cardAlignment={false}
            customHeader={
              <CustomerHeader
                label={labelName}
                dateValue={format(startDate, 'DD/MM/YYYY')}
                display_date={display_date}
              />
            }
            handleToggle={() => {
              handleToggleCard(accordionNum);
            }}
          >
            <>
              {documentLinks.map((document, index) => {
                return (
                  <div
                    className={`${className}__linksContainer`}
                    key={`travel_document_${index}`}
                  >
                    {insuranceType !== HOME_INSURANCE_TYPE ? (
                      <>
                        <a
                          href={document.url}
                          className={`${className}__link`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {document.name}
                          <FontAwesomeIcon
                            className={`${className}__linkIcon`}
                            icon={faDownload}
                          />
                        </a>
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          className={`${className}__linkIcon`}
                          icon={faDownload}
                          onClick={e => {
                            getDownloadLink(
                              e,
                              document.document_id,
                              document.display_name,
                              GET_HOME_DOCUMENT
                            );
                          }}
                        />
                        <a
                          href="#"
                          className={`${className}__link`}
                          onClick={e => {
                            getDownloadLink(
                              e,
                              document.document_id,
                              document.display_name,
                              GET_HOME_DOCUMENT
                            );
                          }}
                        >
                          {document.display_name}
                        </a>
                      </>
                    )}
                  </div>
                );
              })}
            </>
          </AccordionCard>
        )}
      </>
    );
  };

  // eslint-disable-next-line complexity
  const conditionSetter = () => {
    switch (true) {
      case newBusiness(policyDocs):
        setAccordionData(() => {
          return {
            setOne: policyDocs.NEW_BUSINESS,
            setTwo: []
          };
        });
        setPolicyDocsText(accordionText);
        break;
      case renewalConfirmationAndNewBusiness(policyDocs):
        setAccordionData(() => {
          return {
            setOne: policyDocs.RENEWAL_CONFIRMATION,
            setTwo: policyDocs.NEW_BUSINESS
          };
        });
        setShowDate(true);
        break;
      case renewalTransfer(policyDocs):
        setAccordionData(() => {
          return {
            setOne: policyDocs.RENEWAL_TRANSFER,
            setTwo: []
          };
        });
        break;
      case newBusinessAndLapse(policyDocs):
        setAccordionData(() => {
          return {
            setOne: [...policyDocs.NEW_BUSINESS, ...policyDocs.LAPSE],
            setTwo: []
          };
        });
        setPolicyDocsText(accordionText);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (policyDocs) {
      conditionSetter();
    }
  }, [policyDocs]);

  return (
    <>
      {accordionData?.setOne?.length > 0 && (
        <div>
          {renderDocs(policyDocsText, accordionData.setOne, 'one', true)}
        </div>
      )}
      {accordionData?.setTwo?.length > 0 && (
        <div>
          {renderDocs(accordionText, accordionData.setTwo, 'two', false)}
        </div>
      )}
      {accordionData?.setOne?.length === 0 &&
        accordionData.setTwo.length === 0 && (
          <div>
            <GenericErrorComponent showMobileBtn={false}>
              <h4>No Documents Found</h4>
            </GenericErrorComponent>
          </div>
        )}
    </>
  );
};

export default DocumentsCard;
