/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './index.scss';

import Auth from '@aws-amplify/auth';
import { noop } from '@boi/core/lib/utils';
import React, { useEffect, useState } from 'react';

import { BOI_WALLET_USER_STORAGE } from './constants';
import { featureFlags } from './constants/insuranceTypeConstants'
import { getItemFromSessionStorage, isAgent, saveInSessionStorage } from './helpers';
import { WebsiteLoadingPage } from './pages/WebsiteLoadingPage';
import { getFeatureFlags } from './services/common/commonServices';
import {getBranchData} from './services/profile/profile';
import type { CognitoUser } from './types/commonTypes/CognitoUser';
import { modifyBranchList } from './utils/utils';

export const CognitoContext: any = React.createContext();

interface Props {
  children: noop;
}

const CognitoProvider = ({ children }: Props) => {
  const [cognitoUser, setCognitoUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [mfaType, setMFAType] = useState('');
  const [feature, setFeature] = useState({
    flags: {}
  })
  const [branchList, setBranchList] = useState([])

  const setFeatureFlags = async () => {
    try{
      const featureFlagsData = await getFeatureFlags()
        setFeature({
          flags: {...featureFlags, ...featureFlagsData}
        })
    } catch (e) {
        setFeature({
          flags: featureFlags
        })
    }
  }

  const getBranchList = async () => {
    try{
        const branchList = await getBranchData("branches")
        setBranchList(modifyBranchList(branchList))
    } catch (e) {
       setBranchList([])
    }  
    setLoadingUser(false);
  }

  const getUser = () => {
    if(!isAgent() && Object.keys(feature.flags).length === 0) {
      setFeatureFlags()
    } else if(Object.keys(feature.flags).length === 0) {
      setFeature({
        flags: featureFlags
      })
    }

    Auth.currentAuthenticatedUser()
      // eslint-disable-next-line complexity
      .then((user: CognitoUser) => {
        if(mfaType) {
          Auth.setPreferredMFA(user, mfaType);
        }
        if(loadingUser) {
          setLoadingUser(false);
        }
        user.accessTokenKey = `${user.keyPrefix}.${user.username}.accessToken`;
        setCognitoUser(user);
        const boiWalletStorage = JSON.parse(getItemFromSessionStorage(BOI_WALLET_USER_STORAGE))
        if(!boiWalletStorage?.attributes) {
          saveInSessionStorage(BOI_WALLET_USER_STORAGE, JSON.stringify(user));
        }
        if(isAgent()) {
          getBranchList();
        }
      })
      .catch(() => {
        if(loadingUser) {
          setLoadingUser(false);
        }
        setCognitoUser(null);
      });
  };

  useEffect(() => {
    getUser();
  }, [])

  const value = {
    setCognitoUser,
    cognitoUser,
    getUser,
    loadingUser,
    feature,
    branchList,
    setMFAType
  };

  return (
    loadingUser ?
      <WebsiteLoadingPage /> :
      <CognitoContext.Provider value={value}>
        {children(cognitoUser)}
      </CognitoContext.Provider>
  );
};

export default CognitoProvider;
