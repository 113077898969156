/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './ManageMyInsuranceDashboard.scss';

import {
  CarIcon,
  HomeIcon,
  PlaneIcon, TitleWithUnderLine
} from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import type { RouterHistory } from "react-router-dom";

import ButtonWithIcons from '../../components/ButtonWithIcons/ButtonWithIcons';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, policyTypeToInsuranceTypeLookup } from '../../constants';
import { dustyGrayColor, primaryBlue } from '../../constants/colors';
import { CAR_INSURANCE_TYPE, HOME_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { routes } from '../../routes';
import type { UserProfilePolicyType, UserProfileType } from '../../types/profile';

interface Props {
  history: RouterHistory;
  userProfile?: UserProfileType
}

const defaultDisabledButtons = {
  [HOME_INSURANCE_TYPE]: true,
  [CAR_INSURANCE_TYPE]: true,
  [TRAVEL_INSURANCE_TYPE]: true
};

const RightArrowIcon = () => <FontAwesomeIcon icon={faChevronRight} color={dustyGrayColor} />;

const ColorIconBlue = (Component: noop) => <Component color={primaryBlue} />;

const ManageMyInsuranceDashboard = (props: Props) => {
  const className = 'c-ManageMyInsuranceDashboard';
  const { userProfile = {}, history } = props;
  const [disabledButtons, setDisabledButtons] = useState(defaultDisabledButtons);

  const goToPolicyPage = (insuranceType: string) => {
    history.push({
      pathname: `${routes.viewMultiplePolicies.url}${insuranceType}`
    });
  };

  const updateDisabledButtons = () => {
    const disabledButtons = { ...defaultDisabledButtons };
    if (!userProfile.policies || userProfile.policies.length === 0) {
      return setDisabledButtons(disabledButtons);
    }
    userProfile.policies.forEach((policyDetails: UserProfilePolicyType) => {
      const insuranceType = policyTypeToInsuranceTypeLookup[policyDetails.policy_type];
      disabledButtons[insuranceType] = false;
    });
    setDisabledButtons(disabledButtons);
  };

  useEffect(() => {
    updateDisabledButtons();
  }, [userProfile]);

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history}/>
      </div>
      <div className={`${className}__buttonContainer`}>
        <TitleWithUnderLine>Manage My Insurance</TitleWithUnderLine>
        <div className={`${className}__buttonSpacing`}>
          <ButtonWithIcons
            id={'ManageMyInsuranceDashboardCarButton'}
            IconLeft={() => ColorIconBlue(CarIcon)}
            IconRight={RightArrowIcon}
            onClick={() => { goToPolicyPage(CAR_INSURANCE_TYPE) }}
            disabled={disabledButtons[CAR_INSURANCE_TYPE]}
          >
            Car
          </ButtonWithIcons>
        </div>
        <div className={`${className}__buttonSpacing`}>
          <ButtonWithIcons
            id="ManageMyInsuranceDashboardHomeButton"
            IconLeft={() => ColorIconBlue(HomeIcon)}
            IconRight={RightArrowIcon}
            onClick={() => { goToPolicyPage(HOME_INSURANCE_TYPE) }}
            disabled={disabledButtons[HOME_INSURANCE_TYPE]}
          >
            Home
          </ButtonWithIcons>
        </div>
        <div className={`${className}__buttonSpacing`}>
          <ButtonWithIcons
            id="ManageMyInsuranceDashboardTravelButton"
            IconLeft={() => ColorIconBlue(PlaneIcon)}
            IconRight={RightArrowIcon}
            onClick={() => { goToPolicyPage(TRAVEL_INSURANCE_TYPE) }}
            disabled={disabledButtons[TRAVEL_INSURANCE_TYPE]}
          >
            Travel
          </ButtonWithIcons>
        </div>
      </div>
    </div>
  );
};

export default ManageMyInsuranceDashboard;
