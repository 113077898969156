/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './RetreiveUser.scss';
import '../../styles/commonPageStyles.scss';

import {
  AccordionText,
  BOIGroupLogo,
  Button,
  Dropdown,
  InputField,
  Modal,
  WideDivider
} from '@boi/core/lib';
import { colors } from '@boi/core/lib/styles';
import { noop } from '@boi/core/lib/utils';
import {
  faCheck,
  faEdit,
  faExclamationCircle,
  faInfoCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';

import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants';
import { dividerColor } from '../../constants/colors';
import { errorRed, green, primaryBlue } from '../../constants/colors';
import { TITLES_TO_SHOW } from '../../constants/home';
import {
  BUTTONTEXT,
  ERROR_TEXT,
  INVALID_PHONE_NUMBER_FORMAT,
  LABELMAPING,
  OPTION_SEARCH_USER_CONSTANT,
  PHONE_NUMBER_REGEX,
  PLACEHOLDERMAPING,
  SEARCH_USER_CONSTANT,
  TOOLTIP
} from '../../constants/retriveUserConstant';
import { isFieldError } from '../../helpers/FieldErrorHelper';
import { updateUserAttribute } from '../../services/common/commonServices';
import { getPortfolioCodes, getUsers } from '../../services/retriveUser';
import { addIrishCountryCode } from '../../utils/utils';
import AgGridTable from '../AgGridTable/AgGridTable';
import AnimatedSpinner from '../AnimatedSpinner/AnimatedSpinner';
import RetreiveUserYupSchema from './RetreiveUserYupSchema';
import UserMatchPortfolioIdModal from './UserMatchPortfolioIdModal';

type Props = {};
type Values = {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  dropdownValue: string
};

type CustomHeader = {
  displayName: string,
  tooltip: string
};
// eslint-disable-next-line complexity
const RetreiveUserForm = (props: Props & FormikProps<Values>) => {
  const className = 'c-RetreiveUser';
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    touched,
    setFieldValue,
    values
  } = props;

  const [responseError, setResponseError] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState([]);
  const [portfolioCodes, setPortfolioCodes] = useState([]);
  const [animatedSpinner, setAnimatedSpinner] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [filteredDataAgridApi, setFilteredDataAgridApi] = useState();
  const [errorText, setErrorText] = useState('');
  const [paramsCellObjectStateRef, setParamsCellObjectStateRef] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [oldPhoneNumber, setOldPhoneNumber] = useState('');
  const [matchPhoneNumberError, setMatchPhoneNumberError] = useState();
  const [matchPhoneNumberSuccess, setMatchPhoneNumberSuccess] = useState();

  const onMatchBtnClick = (params: any) => {
    const handleButtonClick = async () => {
      const portfolioCodesResponse = await getPortfolioCodes(params);
      setPortfolioCodes(portfolioCodesResponse);
      setShowModal(!showModal);
      setParams(params);
    };

    return (
      <div className={`${className}__tableButtons`}>
        <div
          className={`${className}__agGridButton ${className}__deleteButton`}
        >
          <Button onClick={handleButtonClick}> {BUTTONTEXT}</Button>
        </div>
      </div>
    );
  };

  const CustomCellRenderer = (params: any) => {
    return (
      <div className={`${className}__mobileIcon`}>
        <span className={`${className}__moblieNumber`}>
          {params.data.phone_number}
        </span>
        <FontAwesomeIcon
          className={`${className}__editIcon`}
          icon={faEdit}
          color="black"
          title={TOOLTIP}
          onClick={() => {
            setParamsCellObjectStateRef(params);
            setPhoneNumber(params.data.phone_number);
            setOldPhoneNumber(params.data.phone_number);
            setShowEditModal(true);
          }}
        />
      </div>
    );
  };

  const CustomHeaderComponent = ({ displayName, tooltip }: CustomHeader) => {
    return (
      <div className={`${className}__infoIconDiv`}>
        <span className={`${className}__titleText`}>{displayName}</span>
        <FontAwesomeIcon
          className={`${className}__infoIcon`}
          title={tooltip}
          icon={faInfoCircle}
          color={colors.secondaryColor}
        />
      </div>
    );
  };

  const columnDefs = [
    { headerName: 'First Name', field: 'first_name', floatingFilter: true },
    { headerName: 'Last Name', field: 'last_name', floatingFilter: true },
    { headerName: 'Email', field: 'email', floatingFilter: true },
    {
      headerName: 'Mobile Number',
      field: 'phone_number',
      floatingFilter: true,
      editable: false,
      cellRendererFramework: CustomCellRenderer,
      headerComponentFramework: CustomHeaderComponent,
      headerComponentParams: {
        displayName: 'Mobile Number',
        tooltip: TOOLTIP
      }
    },
    {
      headerName: '',
      field: '',
      cellRendererFramework: onMatchBtnClick,
      floatingFilter: false,
      cellClassRules: {
        'center-align': (params: noop) => {
          return params.colDef.field === '';
        }
      }
    }
  ];

  const setErrorState = () => {
    setMatchPhoneNumberError(false);
    setErrorText('');
    setMatchPhoneNumberSuccess(false);
  };

  // eslint-disable-next-line complexity
  const handleEditClick = () => {
    if (
      paramsCellObjectStateRef &&
      filteredDataAgridApi &&
      phoneNumber !== oldPhoneNumber
    ) {
      if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
        setPhoneNumber(oldPhoneNumber);
        setMatchPhoneNumberError(true);
        setErrorText(INVALID_PHONE_NUMBER_FORMAT);
      } else {
        const { data, rowIndex } = paramsCellObjectStateRef;
        const updatedRowData = [...filteredData];
        updatedRowData[rowIndex] = {
          ...updatedRowData[rowIndex],
          phone_number: phoneNumber
        };
        filteredDataAgridApi.setRowData(updatedRowData);
        setFilteredData(updatedRowData);
        updateUserAttribute(
          addIrishCountryCode(phoneNumber),
          data.username
        ).then((response: any) => {
          if (response.error || !response.success) {
            setErrorText('Failed to update mobile number');
            setPhoneNumber(oldPhoneNumber);
            setMatchPhoneNumberError(true);
          } else {
            setOldPhoneNumber(phoneNumber);
            setMatchPhoneNumberSuccess(true);
          }
        });
      }
    }
  };

  const checkPhoneNumberValidation = () => {
    return phoneNumber === oldPhoneNumber ||
      !PHONE_NUMBER_REGEX.test(phoneNumber)
      ? true
      : false;
  };

  // eslint-disable-next-line complexity
  const searchUser = async (values: any) => {
    const users = await getUsers(values);
    if (Array.isArray(users)) {
      setResponseError(ERROR_TEXT);
      setAnimatedSpinner(false);
      setFilteredData([]);
      return;
    }
    const user_list = users?.user_list || [];
    const error = users?.error || user_list.length === 0;

    setResponseError(error ? ERROR_TEXT : '');
    setAnimatedSpinner(false);
    setFilteredData(user_list);
  };

  return (
    <div className={`${className}`}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo--flex`}>
            <BOIGroupLogo color={primaryBlue} />
          </div>
          <Form>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="searchDropdown"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
                data-testid={`${commonFormStylesIdentifier}__searchDropdownLabel`}
              >
                Select A Search Option
              </label>
              <span className={`${className}__input`}>
                <Dropdown
                  data-testid={`${className}__dropdownValue`}
                  error={isFieldError('dropdownValue', touched, errors)}
                  errorMessage={errors.dropdownValue}
                  name="dropdownValue"
                  placeholder="Type here"
                  type="text"
                  value={values.dropdownValue}
                  onChange={(e: string) => {
                    handleChange(e);
                    setFilteredData([]);
                    setFieldValue('searchInput', '');
                  }}
                  onBlur={(e: string) => {
                    handleBlur(e);
                  }}
                >
                  {SEARCH_USER_CONSTANT.slice(0, TITLES_TO_SHOW).map(
                    (title: string, index: number) => {
                      return (
                        <option
                          value={OPTION_SEARCH_USER_CONSTANT[index]}
                          key={`${index}_${title}`}
                        >
                          {SEARCH_USER_CONSTANT[index]}
                        </option>
                      );
                    }
                  )}
                </Dropdown>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="searchInput"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
                data-testid={`${commonFormStylesIdentifier}__searchInputLabel`}
              >
                {LABELMAPING[`${values.dropdownValue}`]}
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={isFieldError('searchInput', touched, errors)}
                  errorMessage={errors.searchInput}
                  name="searchInput"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={PLACEHOLDERMAPING[`${values.dropdownValue}`]}
                  touched={touched.searchInput}
                  type="text"
                  value={values.searchInput}
                />
              </span>
            </div>
            <div>
              <Button
                id="retriveUser__searchButton"
                fluid
                quaternary
                onClick={() => {
                  setResponseError('');
                  setAnimatedSpinner(true);
                  searchUser(values);
                }}
                disabled={!isValid}
                type="submit"
              >
                Search
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div className={`${className}__responseError`}>
        <pre>{responseError}</pre>
      </div>
      {animatedSpinner && (
        <div>
          <AnimatedSpinner size="large" />
        </div>
      )}
      {filteredData.length > 0 && (
        <div>
          <AgGridTable
            rowData={filteredData}
            columnDefs={columnDefs}
            gridReady={(params: noop) => {
              params.api.sizeColumnsToFit();
              setFilteredDataAgridApi(params.api);
            }}
          />
        </div>
      )}
      <UserMatchPortfolioIdModal
        showModal={showModal}
        params={params}
        portfolioCodes={portfolioCodes}
        handleChange
        errors
        handleBlur
        isValid
        touched
        values={values}
        setShowModal={setShowModal}
      />
      <Modal
        width="45%"
        show={showEditModal}
        modalElementId="root"
        id={'modal-edit-type'}
        showCloseIcon={false}
        secondaryButtonClassName="No"
        primaryButtonLabel="Save"
        primaryButtonDisabled={checkPhoneNumberValidation()}
        secondaryButtonLabel="Exit"
        onSecondaryButtonClick={() => {
          setShowEditModal(false);
          setErrorState();
        }}
        onPrimaryButtonClick={() => {
          setErrorState();
          setShowConfirmModal(true);
        }}
        data-testid="modal-edit-type"
        classNameModalBody={className}
        classNameModalHeader={className}
        classNameModalFooter={className}
      >
        <div
          className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer ${className}__nameEmailDivSpacing`}
        >
          <div>
            <h4>
              Name:{' '}
              {`${paramsCellObjectStateRef?.data?.first_name} ${
                paramsCellObjectStateRef?.data?.last_name
              }`}
            </h4>
          </div>
          <div>
            <h4>Email: {`${paramsCellObjectStateRef?.data?.email}`}</h4>
          </div>
        </div>
        {matchPhoneNumberError && (
          <div className="error-modal">
            <div className="error-content">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="error-icon"
                color={errorRed}
              />
              <span className="span-text">{errorText}</span>
              <FontAwesomeIcon
                icon={faTimes}
                className="close-icon"
                onClick={() => {
                  setErrorState();
                }}
              />
            </div>
          </div>
        )}
        {matchPhoneNumberSuccess && (
          <div className="success-modal">
            <div className="success-content">
              <FontAwesomeIcon
                icon={faCheck}
                className="error-icon"
                color={green}
              />
              <span className="span-text">{`Mobile number updated successfully`}</span>
              <FontAwesomeIcon
                icon={faTimes}
                className="close-icon"
                onClick={() => {
                  setErrorState();
                }}
              />
            </div>
          </div>
        )}
        <div
          className={`${commonFormStylesIdentifier}__fieldContainer ${className}__mobileNumberDiv`}
        >
          <label
            htmlFor="phoneNumber"
            className={`${commonFormStylesIdentifier}__fieldLabel ${className}__mobileNumberLabel`}
          >
            <AccordionText
              label="Please enter the updated mobile number"
              icon="info"
              iconAlign="left"
              customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
            >
              <div className={`${className}__infoText`}>
                Phone number should start with &apos;08&apos; followed by 8
                digits or start with &apos;+3538&apos; followed by 8 digits
              </div>
            </AccordionText>
          </label>
          <span
            className={`${className}__input ${className}__mobileNumberInputField`}
          >
            <InputField
              name="phoneNumber"
              data-testid="phoneNumber"
              value={phoneNumber}
              placeholder="Type here"
              readOnly={false}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                setPhoneNumber(e.target.value);
              }}
              type="text"
              onBlur={handleBlur}
            />
          </span>
        </div>
      </Modal>
      <Modal
        width="30%"
        show={showConfirmModal}
        modalElementId="root"
        id={'modal-edit-type'}
        showCloseIcon={false}
        secondaryButtonClassName="No"
        primaryButtonLabel="No"
        secondaryButtonLabel="Yes"
        onSecondaryButtonClick={() => {
          setShowConfirmModal(false);
          handleEditClick();
        }}
        onPrimaryButtonClick={() => {
          setShowConfirmModal(false);
          setPhoneNumber(oldPhoneNumber);
        }}
        data-testid="modal-edit-type"
        classNameModalBody={className}
        classNameModalFooter={className}
      >
        <div>
          <label
            htmlFor="editConfirmation"
            className={`${commonFormStylesIdentifier}__fieldLabel  validation`}
          >
            Edit Confirmation
          </label>
          <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
            <WideDivider color={dividerColor} height={1} />
          </div>
          <p className={`${className}__paragraph`}>
            Are you sure you want to change from {oldPhoneNumber} phone number
            to {phoneNumber}?
          </p>
        </div>
      </Modal>
    </div>
  );
};

const RetreiveUser = withFormik<Props, Values>({
  mapPropsToValues(): {} {
    return {
      dropdownValue: 'email',
      portfolioCode: [],
      searchInput: ''
    };
  },
  handleSubmit(values: Values, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape(RetreiveUserYupSchema),
  displayName: 'RetreiveUserForm'
})(RetreiveUserForm);

export default RetreiveUser;
