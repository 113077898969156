/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import type { CognitoUser } from '../types/commonTypes/CognitoUser';

const SUPER_AGENT_GROUP_NAME = "SG-GS-BIISWALLETAGENT-Super-Agent-SSO";
const CUSTOM_GROUPS = "custom:groups";

export const isAgent = () => {
  const isAnAgent = process.env.REACT_APP_AGENT_ENABLED;
  return isAnAgent === 'TRUE';
};

export const isSuperAgent = (cognitoUser: CognitoUser) => {
  return cognitoUser && cognitoUser.signInUserSession && cognitoUser.signInUserSession.idToken.payload[CUSTOM_GROUPS] && cognitoUser.signInUserSession.idToken.payload[CUSTOM_GROUPS].includes(SUPER_AGENT_GROUP_NAME);
}

export const getAgentName = (cognitoUser: CognitoUser) => {
  return isAgent() ? cognitoUser?.username : ''
}
