const carPurchasedValues = [
  {
    displayName: 'Intend to purchase this vehicle',
    dropdownValue: 'INTEND_TO_PURCHASE',
  },
  {
    displayName: 'Less than 1 year',
    dropdownValue: 'LESS_THAN_A_YEAR',
  },
  {
    displayName: '1 to 2 years',
    dropdownValue: 'UP_TO_2_YEARS',
  },
  {
    displayName: '2 to 3 years',
    dropdownValue: 'UP_TO_3_YEARS',
  },
  {
    displayName: '3 to 4 years',
    dropdownValue: 'UP_TO_4_YEARS',
  },
  {
    displayName: '4 to 5 years',
    dropdownValue: 'UP_TO_5_YEARS',
  },
  {
    displayName: 'Longer than 5 years',
    dropdownValue: 'LONGER_THAN_5_YEARS',
  },
];

export default carPurchasedValues;
