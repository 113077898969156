const motorClaimsValues = [
  {
    displayName: 'Damage to another vehicle or person',
    dropdownValue: 'DAMAGE_TO_ANOTHER_VEHICLE_OR_PERSON',
  },
  {
    displayName: 'Damage to your car only',
    dropdownValue: 'DAMAGE_TO_YOUR_CAR_ONLY',
  },
  {
    displayName: 'Personal effects',
    dropdownValue: 'PERSONAL_EFFECTS',
  },
  {
    displayName: 'Fire',
    dropdownValue: 'FIRE',
  },
  {
    displayName: 'Personal accident or medical expenses',
    dropdownValue: 'PERSONAL_ACCIDENT_OR_MEDICAL_EXPENSES',
  },
  {
    displayName: 'Theft',
    dropdownValue: 'THEFT',
  },
  {
    displayName: 'Windscreen only',
    dropdownValue: 'WINDSCREEN_ONLY',
  },
  {
    displayName: 'Other type of claim',
    dropdownValue: 'OTHER_TYPE_OF_CLAIM',
  },
];

export default motorClaimsValues;
