/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './AgGridTable.scss';

import { noop } from '@boi/core/lib/utils';
// $FlowFixMe
import { AgGridReact } from 'ag-grid-react'; 
import React, { useMemo } from 'react';

type Props = {
  gridOptions?: Object;
  defaultColDef?: Object;
  rowData: any;
  columnDefs: any;
  rowSelection?: string;
  handleCellValueChanged?: any; 
  gridReady?: any;
};

const AgGridTable = (props: Props) => {

  const defaultProps = {
    gridOptions: {
      domLayout: 'autoHeight',
      gridAutoWidth: false,
      pagination: true,
      paginationPageSize: 10,
      paginationPageSizeOptions: [10, 20, 30]
    },
    defaultColDef: useMemo(()=> ({
      sortable: true,
      filter: true,
    }), []),
    rowSelection: 'single'
  };

  props = {...defaultProps, ...props};

  const {
    rowData,
    columnDefs,
    defaultColDef,
    gridOptions,
    rowSelection,
    handleCellValueChanged,
    gridReady
  } = props
  
  const tableRowData = rowData.map((row: any) => ({ ...row}));


  const onGridReady = (params: noop) => {
    if(gridReady) {
      gridReady(params);
    }
  };


  return (
    <div className="ag-theme-alpine" style={{ height: "100%"}}>
      <AgGridReact
        rowData={tableRowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowSelection={rowSelection}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        suppressColumnVirtualisation={false}
        onCellValueChanged={handleCellValueChanged}
      />
    </div>
  );
};

export default AgGridTable;
