"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./ButtonCard.scss");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("../../styles");

var _utils = require("../../utils");

var _CardWrapper = require("../CardWrapper");

var _CardWrapper2 = _interopRequireDefault(_CardWrapper);

var _Icons = require("../Icons");

var _Text = require("../Typography/Text");

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * 
 */
var className = 'c-ButtonCard';
var baseTextClassName = "".concat(className, "__main-text");

var ButtonCard = function ButtonCard(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? _styles.colors.darkBlue : _ref$color,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? '100%' : _ref$height,
      _ref$icon = _ref.icon,
      icon = _ref$icon === void 0 ? '' : _ref$icon,
      _ref$text = _ref.text,
      text = _ref$text === void 0 ? '' : _ref$text,
      _ref$onClick = _ref.onClick,
      onClick = _ref$onClick === void 0 ? _utils.noop : _ref$onClick,
      comingSoon = _ref.comingSoon,
      _ref$callInsurance = _ref.callInsurance,
      callInsurance = _ref$callInsurance === void 0 ? true : _ref$callInsurance;

  var IconTag = function IconTag() {
    var possibleIcons = {
      car: _react2.default.createElement(_Icons.CarIcon, {
        color: color
      }),
      home: _react2.default.createElement(_Icons.HomeIcon, {
        color: color
      }),
      travel: _react2.default.createElement(_Icons.PlaneIcon, {
        color: color
      }),
      pet: _react2.default.createElement(_Icons.PawIcon, {
        color: color
      }),
      health: _react2.default.createElement(_Icons.HealthIcon, {
        color: color
      }),
      life: _react2.default.createElement(_Icons.HeartbeatIcon, {
        color: color
      }),
      van: _react2.default.createElement(_Icons.ShuttleIcon, {
        color: color
      }),
      business: _react2.default.createElement(_Icons.BriefcaseIcon, {
        color: color
      }),
      pensions: _react2.default.createElement(_Icons.PiggyBankIcon, {
        color: color
      }),
      savings: _react2.default.createElement(_Icons.ChartLineIcon, {
        color: color
      })
    };
    return possibleIcons[icon] || null;
  };

  var getTextClassNames = function getTextClassNames() {
    return [baseTextClassName, comingSoon ? "".concat(baseTextClassName, "--muted") : null].filter(Boolean).join(' ');
  };

  return _react2.default.createElement("div", {
    className: className,
    onClick: onClick,
    style: {
      height: height
    }
  }, _react2.default.createElement(_CardWrapper2.default, null, _react2.default.createElement("div", {
    className: "".concat(className, "__contents")
  }, _react2.default.createElement("div", null, _react2.default.createElement("div", {
    className: "".concat(className, "__icon")
  }, _react2.default.createElement(IconTag, null)), _react2.default.createElement("div", null, _react2.default.createElement(_Text2.default, {
    align: "center",
    className: getTextClassNames()
  }, text)), comingSoon ? _react2.default.createElement("p", {
    className: "".concat(className, "__coming-soon-text")
  }, "Coming Soon") : null, !callInsurance ? _react2.default.createElement("p", {
    className: "".concat(className, "__coming-soon-text")
  }, "For ", text, " Insurance please call 01-4884062") : null))));
};

exports.default = _react2.default.memo(ButtonCard);