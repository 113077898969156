/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../../../styles/commonFormStyles.scss';
import './Claims.scss';

import {
  ButtonGroup,
  DatePicker,
  Dropdown,
  InputField,
  WideDivider} from '@boi/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import { dividerColor } from '../../../../constants/colors';
import { FUTURE_ERROR,GENERIC_FUTURE_ERROR_MESSAGE, GENERIC_REQUIRED_MESSAGE, TYPE_OF_CLAIM_OR_LOSS_OPTIONS, TYPE_OF_CLAIM_OR_LOSS_TO_SHOW, TYPE_OF_CLAIM_OR_LOSS_VALUES } from '../../../../constants/home';
import { isFieldError } from '../../../../helpers';
import { decimalNumberMask } from '../../../../helpers/NumberMask';

const NOT_SETTLED = 'NotSettled';
const SETTLED = 'Settled';

const emptyClaim = {
  claim_type: undefined,
  claim_date: undefined,
  claim_status: undefined,
  occurred_at: undefined,
  claim_amount: undefined
};

const untouchedEmptyClaim = {
  claim_type: false,
  claim_date: false,
  claim_status: false,
  occurred_at: false,
  claim_amount: false
};

const getClaimYearOptions = () => {
  const claimYearOptions = [
    <option key={'buildYear_empty'} value="">
      Select here
    </option>
  ];
  const currentYear = new Date().getFullYear();
  //one of the previous 5 years.
  const previousFiveYears = currentYear - 5;
  for (let x = previousFiveYears; x <= currentYear; x++) {
    claimYearOptions.push(
      <option key={`buildYear_${x}`} value={`${x}`}>
        {x}
      </option>
    );
  }
  return claimYearOptions;
};

// eslint-disable-next-line complexity
const Claims = (props) => {
  const className = 'c-Claims';
  const { errors, setFieldValue, setTouched, touched, values, handleBlur, setFieldTouched, onChange } = props;
  useEffect(() => {
      handleCountChange(values.claims.length || 1)
  }, []);

  useEffect(() => {
    if (values.claims && values.claims.length) {
      updateTouchedClaims();
    }
  }, [values]);

  const updateTouchedClaims = () => {
    const isTouchedArray = () => touched.claims && touched.claims.length
    const touchedArraySize = isTouchedArray() ? touched.claims.length : 0;
    const newTouchedClaims = isTouchedArray() ? [...touched.claims] : [];
    const arraysSizeDiff = values.claims.length - touchedArraySize;
    for (let i = 0; i < arraysSizeDiff; ++i) {
      newTouchedClaims.push({ ...untouchedEmptyClaim });
    }
    setTouched({ ...touched, claims: newTouchedClaims });
  };

  const claimYearOptions = getClaimYearOptions();

  const handleClaimsFieldChange = (
    value,
    fieldName,
    claimIndex
  ) => {
    const newValues = values.claims;
    newValues[claimIndex][fieldName] = value;
    setFieldValue('claims', newValues);
  };

  const handleBlurCustom = (fieldName, claimIndex) => {
    const newClaimsTouched = touched.claims && touched.claims[claimIndex] ? [...touched.claims] : [{ ...untouchedEmptyClaim }];
    newClaimsTouched[claimIndex][fieldName] = true;
    setTouched({ claims: newClaimsTouched });
  };

  // eslint-disable-next-line complexity
  const handleCountChange = (newCount) => {
    setFieldValue('claimsCount', newCount);
    if (newCount !== "") {
      let i = 0, max = Number(newCount)
      const newValues = []
      const newTouched = []
      const count = max > 3 ? 3 : max
      while (i !== count) {
        const claimExists = () => values.claims && values.claims[i]
        if (claimExists()) {
          newValues.push(values.claims[i])
        } else {
          newValues.push(Object.assign({}, emptyClaim));
          newTouched.push(Object.assign({}, untouchedEmptyClaim));
        }
        ++i
      }
      setFieldValue('claims', newValues)
      setFieldTouched('claims', newTouched)
      onChange(newValues)
    } else {
      setFieldValue('claims', []);
      onChange([])
    }
  };

  const checkClaimExists = (claimIndex) => {
    return (
      errors.claims !== undefined &&
      errors.claims[claimIndex] &&
      touched.claims !== undefined &&
      touched.claims[claimIndex]
    );
  };

  const checkFieldError = (fieldName, index) => {
    if (checkClaimExists(index)) {
      return errors.claims[index][fieldName] !== undefined && touched.claims[index][fieldName];
    }
  };

  const getErrorMessage = (fieldName, index) => {
    return checkFieldError(fieldName, index) ? errors.claims[index][fieldName] : null;
  };

  const getDatePickerError = (idx) => {
    if(checkClaimExists(idx) && errors.claims[idx]['claim_date'] !== undefined) {
        if(errors.claims[idx]['claim_date'] === FUTURE_ERROR) {
          return GENERIC_FUTURE_ERROR_MESSAGE;
        } else {
          return GENERIC_REQUIRED_MESSAGE;
        }
      }
  }

  const renderClaimAmount = (index) => {
    return values.claims && values.claims[index].claim_status === SETTLED ? (
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`claim_amount[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Amount of settlement?
        </label>
        <InputField
          error={checkFieldError('claim_amount', index)}
          placeholder="Enter an amount"
          errorMessage={getErrorMessage('claim_amount', index)}
          key={`claim_amount[${index}]`}
          name="claim_amount"
          value={values.claims && values.claims[index]['claim_amount']}
          onChange={(e) =>
            handleClaimsFieldChange(e.currentTarget.value, 'claim_amount', index)
          }
          onBlur={() => handleBlurCustom('claim_amount', index)}
          masked
          mask={decimalNumberMask}
        />
      </div>
    ) : null;
  };

  const renderClaim = () => {
    const claims = [];
    for (let index = 0; index < values.claimsCount && index < 3; index++) {
      claims.push(getClaimComponent(index));
    }
    return claims.length > 0 ? <div className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`}>{claims}</div> : <></>;
  };

  const renderClaimPropertyRelation = (index) => {
    return (
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`occurred_at[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          What property does this relate to? 
        </label>
        <Dropdown
          error={checkFieldError('occurred_at', index)}
          errorMessage={getErrorMessage('occurred_at', index)}
          key={`occurred_at[${index}]`}
          name="occurred_at"
          value={values.claims && values.claims[index].occurred_at}
          onChange={(e) =>
            handleClaimsFieldChange(e.currentTarget.value, 'occurred_at', index)
          }
          onBlur={() => handleBlurCustom('occurred_at', index)}
        >
          <option value="">Select here</option>
          <option value="CURRENT_RESIDENTIAL_ADDRESS">This property</option>
          <option value="OTHER">Another Property</option>
          <option value="PREVIOUS_RESIDENTIAL_ADDRESS">Previous residential address</option>
          <option value="UNSPECIFIED">Unspecified</option>
        </Dropdown>
      </div>
    )
  }

  // eslint-disable-next-line complexity
  const getClaimComponent = (index) => {
    return <div
      key={'Claim_' + (index + 1)}
      className={`${className}__claimSection`}
      >
        {index > 0 ? <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
          <WideDivider height={1} color={dividerColor} />
        </div> : null}
      <div className={`${commonFormStylesIdentifier}__itemCountContainer`}>
        <label className={`${commonFormStylesIdentifier}__itemCountLabel`}>
          Claim {index + 1}
        </label>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`claim_type[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Type of claim/loss 
        </label>
        <Dropdown
          error={checkFieldError('claim_type', index)}
          errorMessage={getErrorMessage('claim_type', index)}
          key={`claim_type[${index}]`}
          name="claim_type"
          value={values.claims && values.claims[index].claim_type}
          onChange={(e) =>
            handleClaimsFieldChange(e.currentTarget.value, 'claim_type', index)
          }
          onBlur={() => handleBlurCustom('claim_type', index)}
        >
          <option>Select</option>
          {TYPE_OF_CLAIM_OR_LOSS_OPTIONS.slice(0, TYPE_OF_CLAIM_OR_LOSS_TO_SHOW).map(
            (item, index) => {
              return (
                <option
                  key={`${index}_${item}`}
                  value={TYPE_OF_CLAIM_OR_LOSS_VALUES[index]}
                  >
                    {TYPE_OF_CLAIM_OR_LOSS_OPTIONS[index]}
                  </option>
              );
            }
            )}
        </Dropdown>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="claim_date"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Date of claim: DD/MM/YYYY
        </label>
        <DatePicker
          error={getDatePickerError(index)}
          name="claim_date"
          onBlur={() => handleBlurCustom('claim_date', index)}
          onChange={(e) => 
              handleClaimsFieldChange(e.currentTarget.value, 'claim_date', index)
          }
          placeholder="Date of claim: DD/MM/YYYY"
          touched={touched && touched.claims ? touched.claims[index].claim_date : false}
          value={values && values.claims && values.claims[index].claim_date}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label htmlFor={`claim_status[${index}]`} className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Has your claim been settled?
        </label>
        <ButtonGroup
          error={checkFieldError('claim_status', index)}
          errorMessage={getErrorMessage('claim_status', index)}
          key={`claim_status[${index}]`}
          name="claim_status"
          onClick={(value) => {
            handleClaimsFieldChange(value, 'claim_status', index);
            if (value === NOT_SETTLED) {
              // If value set as NOT_SETTLED then revert claim_amount to it's initial value which is undefined
              handleClaimsFieldChange(undefined, 'claim_amount', index);
            }
          }}
          selected={values.claims && values.claims[index].claim_status}
          onBlur={() => handleBlurCustom('claim_status', index)}
          options={[
            { label: 'Yes', value: SETTLED },
            { label: 'No', value: NOT_SETTLED }
          ]}
        >
          {claimYearOptions}
        </ButtonGroup>
      </div>
      {renderClaimAmount(index)}
      {renderClaimPropertyRelation(index)}
    </div>
  };

  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <Dropdown
          name="claimsCount"
          value={values.claimsCount}
          onChange={(e) => handleCountChange(e.target.value)}
          onBlur={(e) => handleBlur('claimsCount', e)}
          error={isFieldError('claimsCount', touched, errors)}
          errorMessage={errors.claimsCount}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4+</option>
        </Dropdown>
      </div>
      {(values.claims && values.claims.length > 0) && (touched.claims && touched.claims.length > 0) && renderClaim()}
    </div>
  );
};

Claims.propTypes = {
  values: PropTypes.any,
  errors: PropTypes.any,
  setFieldValue: PropTypes.func,
  setTouched: PropTypes.func,
  setFieldTouched: PropTypes.func,
  touched: PropTypes.any,
  handleBlur: PropTypes.func,
  onChange: PropTypes.func
};

export default Claims;
