/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './MobileBackButton.scss';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import type { RouterHistory } from 'react-router-dom';

import { greyDark } from '../../constants/colors';
import { LOCATION_HISTORY_STORAGE } from '../../constants/sessionStorage';
import { HOME_RESUME_QUOTE } from '../../constants/sessionStorage/homeStorageIdentifiers';
import { getItemFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';

interface Props {
  text?: string;
  history: RouterHistory;
  pageJumpCount?: number;
  onDesktopClick?: () => void;
}

const className = 'c-MobileBackButton';
const buttonId = 'MobileBackButton__goBack';

export const MobileBackButton = ({
  text = 'Back',
  history,
  pageJumpCount = -1,
  onDesktopClick
}: Props) => {
  // eslint-disable-next-line complexity
  const handleChangePage = () => {
    const isHomeResumeQuote = JSON.parse(
      getItemFromSessionStorage(HOME_RESUME_QUOTE)
    );
    if (isHomeResumeQuote && onDesktopClick) {
      onDesktopClick();
      return;
    }

    if (pageJumpCount < -1) {
      const locationHistory = JSON.parse(
        getItemFromSessionStorage(LOCATION_HISTORY_STORAGE)
      );
      // If the pageJumpCount is less than history length then go to that page else go to the Wallet landing page
      locationHistory && locationHistory.length > Math.abs(pageJumpCount)
        ? goToPreviousPage(locationHistory)
        : goToDefaultPage();
    } else {
      history.goBack();
    }
  };

  const goToPreviousPage = (locationHistory: Array<string>) => {
    const historyCopy = [...locationHistory];
    historyCopy.splice(pageJumpCount, Math.abs(pageJumpCount));
    saveInSessionStorage(LOCATION_HISTORY_STORAGE, JSON.stringify(historyCopy));
    history.go(pageJumpCount);
  };

  const goToDefaultPage = () => {
    saveInSessionStorage(LOCATION_HISTORY_STORAGE, JSON.stringify([]));
    history.push(routes.wallet.url);
  };

  return (
    <button
      type="button"
      className={`${className}__button`}
      id={buttonId}
      data-testid={buttonId}
      onClick={handleChangePage}
    >
      <FontAwesomeIcon
        icon={faArrowLeft}
        color={greyDark}
        className={`${className}__arrowLeft`}
      />
      <span className={`${className}__text`}>{text}</span>
    </button>
  );
};

export default MobileBackButton;
