/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { differenceInYears, format, isValid, parse } from 'date-fns';

type transformationOptions = {
  nullable: boolean
};

//format 1991-04-23T00:00:00
export const addZeroHoursToMakeCorrectFormat = (dateString: string) =>
  `${format(parse(dateString), 'YYYY-MM-DD')}T00:00:00`;

export const getCurrentDateAndTime = () =>
  new Date().toISOString().slice(0, 22);

//expects date in a format 1991-04-23T00:00:00 and returns in format 1991-04-23
export const getDateOnly = (dateString: string) => dateString.slice(0, 10);

// transforms dates for yup schemas
export const createDateTransformer = ({
  // should the transformer take in null values
  nullable
}: transformationOptions) => {
  if (nullable) {
    return (_: mixed, dateString: string | null) =>
      dateString !== null ? parse(dateString) : null;
  } else {
    return (_: mixed, dateString: string) => parse(dateString);
  }
};

export const allowedDriverAge = (dob: string) => {
  if (!isValid(new Date(dob))) return false;
  const years = differenceInYears(new Date(), dob);
  return years > 20 && years < 76;
};

/*
 * Irish format: DD/MM/YYYY (stored in Formik and the input field)
 * Chosen ISO format: MM/DD/YYYY (used to validate field)
 * Raw format: YYYY-MM-DD (used in the BE)
 */

export const irishToISOFormat = (date: string) => {
  if (!date) return '';
  const day = date.substring(0, 2);
  const month = date.substring(3, 5);
  const year = date.substring(6, 10);
  return `${month}/${day}/${year}`;
};

export const rawToIrishFormat = (date: string) => {
  if (!date) return '';
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);
  return `${day}/${month}/${year}`;
};

export const irishToRawFormat = (date: string) => {
  if (!date) return '';
  const day = date.substring(0, 2);
  const month = date.substring(3, 5);
  const year = date.substring(6, 10);
  return `${year}-${month}-${day}`;
};

export const ISODateConvertable = (date: string) => {
  const day = date.substring(3, 5);
  const dateObj = new Date(date);
  return parseInt(dateObj.getDate()) === parseInt(day);
};

export const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const addDaysToDate = (startingDate: string, daysToAdd: number) => {
  var newDate = new Date(startingDate);
  newDate.setDate(newDate.getDate() + parseInt(daysToAdd));
  var year = newDate.getFullYear();
  var month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  var day = newDate
    .getDate()
    .toString()
    .padStart(2, '0');
  return `${year}-${month}-${day}`;
};
