/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './WalletLandingPage.scss';

import React, { useContext, useEffect, useState } from 'react';

import AnimatedSpinner from '../../components/AnimatedSpinner/AnimatedSpinner';
import { getUserProfile } from '../../services/profile';
import { UserProfileContext } from '../../UserProfile';
import WalletLandingPage from './WalletLandingPage';

const WalletLandingPageWrapper = () => {
  const className = 'c-WalletLandingPage';
  const {
    userProfile,
    loadingUserProfile,
    updateUserProfileValue
  } = useContext(UserProfileContext);
  const [showWallet, setShowWallet] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!loadingUserProfile) {
        if (userProfile?.quote) {
          const profile = await getUserProfile();
          if (profile?.quote) {
            setShowBanner(true);
          } else {
            updateUserProfileValue(profile);
          }
        }
        setShowWallet(true);
      }
    };
    fetchData();
  }, [loadingUserProfile]);

  /* Show spinner until user profile is loaded */
  return showWallet ? (
    <WalletLandingPage userProfile={userProfile} showBanner={showBanner} />
  ) : (
    <div className={`${className}__spinnerContainer`}>
      <AnimatedSpinner size="large" />
    </div>
  );
};

export default WalletLandingPageWrapper;
