/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */
import { basePath } from '../../constants/commonConstants';
import { makeRequest } from '../../helpers';

export const getLatestQuotes = async (
  pageSize,
  pageNumber,
  forHome,
  props = {}
) => {
  const journey = forHome ? 'home' : 'motor'
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/${journey}/search/latest_quotes?page=${pageNumber}&size=${pageSize}&sort=created_date,DESC`,
    history: props.history,
  });
};

export const updateContactedStatus = async (
  quoteReference,
  contacted,
  forHome,
  props = {}
) => {
  const journey = forHome ? 'home' : 'motor'
  return await makeRequest({
    method: 'PUT',
    url: `${basePath}/${journey}/${journey}_quotes/contacted`,
    history: props.history,
    payload: {
      quote_reference: quoteReference,
      contacted: contacted,
    },
  });
};

export const getLatestQuoteFromUser = async (
  email,
  props = {}
) => {
  return await makeRequest({
    method: 'POST',
    url: `${basePath}/home/home_quotes/latest`,
    history: props.history,
    payload: { email: email },
  });
};
