import { HOME_QUOTE_THANK_YOU_STORAGE } from '../constants';
import {
  HOME_LATEST_QUOTE_STORAGE,
  HOME_RESUME_QUOTE,
  HOME_RESUME_QUOTE_OBJ
} from '../constants/sessionStorage/homeStorageIdentifiers';
import { findElementsByClasses } from './HtmlHelper';
import {
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  saveInSessionStorage
} from './sessionStorageHelper';

export const saveData = (formType, fieldName, value) => {
  const homeQuoteObj = getObjectFromSessionStorage(
    HOME_QUOTE_THANK_YOU_STORAGE
  );
  const fieldParts = fieldName.split('.');
  let updatedCover = homeQuoteObj[formType] || {};

  let innerObject = updatedCover;

  for (let i = 0; i < fieldParts.length - 1; i++) {
    const objName = fieldParts[i];
    if (!innerObject[objName]) {
      innerObject[objName] = {};
    }
    innerObject = innerObject[objName];
  }

  innerObject[fieldParts[fieldParts.length - 1]] = value;
  const updatedHomeQuoteObj = {
    ...homeQuoteObj,
    [formType]: {
      ...homeQuoteObj[formType],
      ...updatedCover
    }
  };

  saveInSessionStorage(
    HOME_QUOTE_THANK_YOU_STORAGE,
    JSON.stringify(updatedHomeQuoteObj)
  );
  const flag = JSON.parse(getItemFromSessionStorage(HOME_RESUME_QUOTE));
  updateHomeResume(formType, flag);
};

export const updateHomeResume = (formType, flag) => {
  if (flag) {
    saveInSessionStorage(HOME_RESUME_QUOTE, areValuesUpdated(formType));
  }
};

const areValuesUpdated = formType => {
  const existingObj = getObjectFromSessionStorage(HOME_RESUME_QUOTE_OBJ)[
    formType
  ];
  const newObj = getObjectFromSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE)[
    formType
  ];
  // eslint-disable-next-line complexity
  const deepCompare = (obj1, obj2) => {
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        const val1 = obj1[key];
        const val2 = obj2[key];
        if (obj1.hasOwnProperty(key)) {
          if (
            val1 !== null &&
            val1 !== undefined &&
            val1 !== '' &&
            val2 !== null &&
            val2 !== undefined &&
            val2 !== ''
          ) {
            if (typeof val2 === 'object') {
              if (!deepCompare(val1, val2)) {
                return false;
              }
            } else if (val1 !== val2) {
              return false;
            }
          }
        } else {
          return true;
        }
      }
    }
    return true;
  };

  return deepCompare(existingObj, newObj);
};

export const saveDataInLatestQuote = (obj, fieldName, value) => {
  saveInSessionStorage(
    HOME_LATEST_QUOTE_STORAGE,
    JSON.stringify({ ...obj, [fieldName]: value })
  );
};

export const scrollToFirstError = () => {
  const errorClasses = [
    'c-InputField--error',
    'c-Dropdown--error',
    'c-ButtonGroup--error',
    'c-Typeahead--error',
    'c-InputTextArea--error',
    'c-InputErrorMessage'
  ];
  const elements = findElementsByClasses(errorClasses);
  let topPosition = 10000;
  elements.forEach(element => {
    const currentY = element && element.getBoundingClientRect().top;
    if (currentY && currentY < topPosition) {
      topPosition = currentY;
    }
  });
  const scrollPosition = window.pageYOffset;
  window.scrollTo(0, topPosition - 80 + scrollPosition);
};
