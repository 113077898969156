import { basePath } from '../../constants/commonConstants';
import { makeRequest } from '../../helpers';

const defaultProfileResponse = {
  first_name: '',
  last_name: '',
  date_of_birth: '',
  phone: '',
  address: {},
  policies: [],
  isDefaultResponse: true
};

export const getUserProfileFromEmail = async (email) => {
  const url = `${basePath}/profile/profile/search/email`;

  return await makeRequest({
    method: 'POST',
    url: url,
    payload: { email: email }
  }).then(
    response =>
      new Promise(resolve =>
        resolve(response.error ? defaultProfileResponse : response)
      ),
    () => new Promise(resolve => resolve(defaultProfileResponse))
  );
};
