/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../../../styles/commonFormStyles.scss';

import { AccordionText, InputField } from '@boi/core/lib';
import { connect, FormikProps, setNestedObjectValues } from 'formik';
import React from 'react';

import {
  CAR_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../../../../constants/insuranceTypeConstants';
import {
  localPhoneNumber,
  localTravelInsurancePhoneNumber
} from '../../../../../constants/phoneNumbers';
import { commonFormStylesIdentifier } from '../../../../../constants/styleConstants';
import { isFieldError } from '../../../../../helpers/FieldErrorHelper';
import { phoneNumberMask } from '../../../../../helpers/NumberMask';
import EmailInput from '../../../../EmailInput';

interface Props {
  emailParam: string;
  insuranceType: string;
  openAccordions: () => {};
}

export const BaseContactDetails = (props: FormikProps & Props) => {
  const className = 'c-ContactDetails';
  const {
    errors,
    handleChange,
    handleBlur,
    values,
    touched,
    setValues,
    setFieldTouched,
    setTouched
  } = props.formik;
  const { insuranceType, emailParam } = props;

  const loadAgentFormValues = (response: Object) => {
    if (insuranceType === CAR_INSURANCE_TYPE) {
      setValues({
        ...values,
        ...response.values,
        ...{
          carModel: '',
          carYear: '',
          carMake: '',
          carFuelType: '',
          carEngineSize: '',
          carVersion: '',
          carRisk: ''
        }
      });
      setFieldTouched('carReg');
    } else setValues({ ...values, ...response.values });
    setTouched(setNestedObjectValues(values, true));
  };

  return (
    <div className={className}>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="phoneNo"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          Mobile Phone Number
          <div className={`${commonFormStylesIdentifier}__infoText`}>
            We may need to contact you in relation to your quote
          </div>
        </label>
        <InputField
          error={isFieldError('phoneNo', touched, errors)}
          errorMessage={errors.phoneNo}
          name="phoneNo"
          placeholder="E.g.0871234567"
          type="tel"
          onBlur={handleBlur}
          value={values.phoneNo}
          onChange={handleChange}
          mask={phoneNumberMask}
        />
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <label
          htmlFor="email"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          <AccordionText
            label="Email address"
            icon="info"
            iconAlign="right"
            customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}
          >
            <div className={`${commonFormStylesIdentifier}__infoText`}>
              Your email address should be in the correct format. We will use
              your email address to send quote details to you. A valid email
              address is required to purchase the policy online, if you do not
              have a valid email address please contact us on{' '}
              {insuranceType === TRAVEL_INSURANCE_TYPE
                ? localTravelInsurancePhoneNumber
                : localPhoneNumber}
              .
            </div>
          </AccordionText>
        </label>

        <EmailInput
          formik={props.formik}
          insuranceType={insuranceType}
          loadAgentFormValues={loadAgentFormValues}
          emailParam={emailParam}
        />
      </div>
    </div>
  );
};
const ContactDetails = connect(BaseContactDetails);
export default ContactDetails;
