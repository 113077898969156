/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { ButtonGroup, InputField } from '@boi/core/lib';
import { connect, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { commonFormStylesIdentifier } from '../../../../../constants';
import { isFieldError } from '../../../../../helpers';
import { InsuranceTypeContext } from '../../../../../pages/GetGenericQuotePage';
import { inFormStructure } from '../../../../../pages/GetGenericQuotePage/getQuoteFormStructure';
import { aboutYouFormIdentifier } from '../../../CommonFiles/commonFormConstants';

interface Props {
  fieldNamePrefix?: string,
  isSubFormComponent?: boolean,
  hideFieldsList?: Array<string>
}

export const BaseBOICustomerAndPromoCode = (props: Props & FormikProps) => {
  const insuranceType = useContext(InsuranceTypeContext);

  const className = 'c-BOICustomerAndPromoCode';
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    touched,
    values
  } = props.formik;

  const { isSubFormComponent = false } = props;

  const getFieldActiveClassNames = (isDynamicField: boolean) => {
    const classNames: Array<string> = [`${commonFormStylesIdentifier}__fieldContainer`];
    if (isDynamicField) {
      classNames.push(isSubFormComponent ? `${commonFormStylesIdentifier}__dynamicFieldsContainer--with-no-padding` : `${commonFormStylesIdentifier}__dynamicFieldsContainer`);
    } else if (!isSubFormComponent) {
      classNames.push(`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`);
    }
    return classNames.join(' ');
  };

  return (
    <div className={className}>
      {inFormStructure('bankOfIrelandCustomer', insuranceType, aboutYouFormIdentifier) &&
      <div className={getFieldActiveClassNames(false)}>
        <label htmlFor='bankOfIrelandCustomer' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Are you a Bank of Ireland Customer?
        </label>
        <div className={`${commonFormStylesIdentifier}--fieldLabelSpacing`}>
          <ButtonGroup
            key="bankOfIrelandCustomer"
            name="bankOfIrelandCustomer"
            onClick={(value: boolean) => {
              setFieldValue('bankOfIrelandCustomer', value === 'true');
              setFieldTouched('bankOfIrelandCustomer', value);
            }}
            options={[
              { label: 'No', value: false },
              { label: 'Yes', value: true }
            ]}
            error={isFieldError('bankOfIrelandCustomer', touched, errors)}
            errorMessage={errors.bankOfIrelandCustomer}
            selected={values.bankOfIrelandCustomer}
            onBlur={handleBlur}
          />
        </div>
      </div>
      }
      {inFormStructure('bankOfIrelandHomeInsurancePolicy', insuranceType, aboutYouFormIdentifier) &&
      <div className={getFieldActiveClassNames(false)}>
        <label htmlFor='bankOfIrelandHomeInsurancePolicy' className={`${commonFormStylesIdentifier}__fieldLabel`}>
          Do you have a current Bank of Ireland Home Insurance policy?
        </label>
        <ButtonGroup
          key="bankOfIrelandHomeInsurancePolicy"
          name="bankOfIrelandHomeInsurancePolicy"
          onClick={(value: boolean) => {
            setFieldValue('bankOfIrelandHomeInsurancePolicy', value === 'true');
            setFieldTouched('bankOfIrelandHomeInsurancePolicy', value);
          }}
          options={[
            { label: 'No', value: false },
            { label: 'Yes', value: true }
          ]}
          error={isFieldError('bankOfIrelandHomeInsurancePolicy', touched, errors)}
          errorMessage={errors.bankOfIrelandHomeInsurancePolicy}
          selected={values.bankOfIrelandHomeInsurancePolicy}
          onBlur={handleBlur}
        />
      </div>
      }
      {inFormStructure('promoCode', insuranceType, aboutYouFormIdentifier) &&
      <div className={getFieldActiveClassNames(false)}>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor='promoCode' className={`${commonFormStylesIdentifier}__fieldLabel`}>
            If you have a promotional code please enter it below.
          </label>
          <InputField
            error={isFieldError('promoCode', touched, errors)}
            errorMessage={errors.promoCode}
            name='promoCode'
            placeholder="Type here"
            value={values.promoCode}
            onChange={handleChange}
            type="text"
            onBlur={handleBlur}
          />
        </div>
      </div>
      }
    </div>
  );
};

BaseBOICustomerAndPromoCode.propTypes = {
  formik: PropTypes.object
};

const BOICustomerAndPromoCode = connect(BaseBOICustomerAndPromoCode);

export default BOICustomerAndPromoCode;
