/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import { addDays, endOfDay, parse, startOfDay } from 'date-fns';
import type { DateSchema } from 'yup';
import * as yup from 'yup';

import { dateOfBirthYup } from '../../../helpers';
import { createDateTransformer } from '../../../helpers/DateHelper';
import { INSURED_PERSON_TYPE, TRIP_TYPE_CODE } from './values';

const dateTransformer = createDateTransformer({ nullable: true });

export const yourTravelFormYupSchema = {
  tripType: yup.string().required('Please select your cover type'),
  travelFromDate: yup
    .date()
    .nullable()
    .transform(dateTransformer)
    .required('Please select your travel date')
    .min(startOfDay(new Date()), 'Start date must be today or in the future.')
    .when('tripType', (tripType: string, schema: DateSchema<Date>) => {
      if (tripType === TRIP_TYPE_CODE.SINGLE_TRIP) {
        return schema.max(
          endOfDay(addDays(new Date(), 180)),
          `The dates selected must be within 180 days of today's date`
        );
      } else if (tripType === TRIP_TYPE_CODE.MULTI_TRIP) {
        return schema.max(
          endOfDay(addDays(new Date(), 45)),
          `The date selected must be within 45 days of today's date`
        );
      }
    }),
  travelToDate: yup
    .date()
    .nullable()
    .transform(dateTransformer)
    .when(
      'travelFromDate',
      (travelFromDate: string, schema: DateSchema<Date>) => {
        return schema
          .min(
            parse(travelFromDate || new Date()),
            'End date needs to be after start date.'
          )
          .max(
            addDays(parse(travelFromDate || new Date()), 180),
            'End date needs to be max 180 days after the start date.'
          );
      }
    )
    .when(
      ['tripType', 'travelFromDate'],
      (tripType: string, travelFromDate: string, schema: DateSchema<Date>) => {
        if (tripType === TRIP_TYPE_CODE.SINGLE_TRIP)
          return schema
            .required(' Please select your travel date')
            .max(
              endOfDay(addDays(travelFromDate || new Date(), 180)),
              'End date needs to be max 180 days after the start date.'
            );
        return schema;
      }
    ),
  destinationZone: yup.string().required('Please select your destination zone'),
  destinationCountry: yup.string().when('tripType', {
    is: TRIP_TYPE_CODE.SINGLE_TRIP,
    then: yup.string().required('Please select your destination country'),
    else: yup.string().notRequired()
  }),
  insuredPerson: yup
    .string()
    .oneOf([
      INSURED_PERSON_TYPE.YOU,
      INSURED_PERSON_TYPE.YOU_AND_PARTNER,
      INSURED_PERSON_TYPE.YOU_AND_FAMILY,
      INSURED_PERSON_TYPE.YOU_AND_CHILDREN
    ])
    .required('Please select who you would like to insure'),
  numOfChildren: yup.number().when('insuredPerson', {
    is: (insuredPerson: string) => ['3', '4'].indexOf(insuredPerson) > -1,
    then: yup
      .number()
      .min(1)
      .max(7)
      .required('Number of children is required')
  }),
  partnerFirstName: yup.string().when('insuredPerson', {
    is: (insuredPerson: string) => ['2', '3'].indexOf(insuredPerson) > -1,
    then: yup.string().required('Partner first name is required')
  }),
  partnerLastName: yup.string().when('insuredPerson', {
    is: (insuredPerson: string) => ['2', '3'].indexOf(insuredPerson) > -1,
    then: yup.string().required('Partner last name is required')
  }),
  partnerDOB: yup
    .mixed()
    .nullable()
    .when('insuredPerson', {
      is: (insuredPerson: string) => ['2', '3'].indexOf(insuredPerson) > -1,
      then: dateOfBirthYup
    })
};

export default yourTravelFormYupSchema;
