// @flow
import './MarketingConsentForm.scss';

import Auth from '@aws-amplify/auth';
import {
  Button,
  CardWrapper,
  SimpleSwitch,
  TitleWithUnderLine
} from '@boi/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import type { Location } from 'react-router-dom';
import * as yup from 'yup';

import { config, customer_config } from '../../amplify.config';
import { CognitoContext } from '../../CognitoUtils';
import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import { desktopBackButtonClass } from '../../constants/commonConstants';
import { FORCE_LOGIN } from '../../constants/home';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import {
  HOME_JOURNEY,
  JOURNEY_YOUR_QUOTE,
  MOTOR_TRAVEL_JOURNEY,
  STAGES_HOME_JOURNEY,
  STAGES_MOTOR_TRAVEL_JOURNEY
} from '../../constants/journey';
import { marketingPhoneNumber } from '../../constants/phoneNumbers';
import { DIRECT_DEBIT_STORAGE } from '../../constants/sessionStorage';
import { HOME_RESUME_QUOTE } from '../../constants/sessionStorage/homeStorageIdentifiers';
import {
  commonFormStylesIdentifier,
  commonPageStylesIdentifier
} from '../../constants/styleConstants';
import {
  getObjectFromSessionStorage,
  isAgent,
  saveInSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import { getUsers } from '../../services/retriveUser';
import type { DirectDebitType } from '../../types/commonTypes';
import { UserProfileContext } from '../../UserProfile';
import AnalyzeTransaction from './AnalyzeTransaction';
import ConsentText from './ConsentText';
import ContactType from './ContactType';

const className = 'c-MarketingConsentForm';

interface Props {
  location: Location;
}

// eslint-disable-next-line complexity
const MarketingConsent = (props: FormikProps & Props) => {
  const { values, setFieldValue, match, setValues, location, history } = props;
  const { cognitoUser } = useContext(CognitoContext);
  const { userProfile } = useContext(UserProfileContext);
  const insuranceType = match.params.type;
  const FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER = `${insuranceType}${DIRECT_DEBIT_STORAGE}`;
  const state =
    location?.state ||
    getObjectFromSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER);
  const bindingQuote = getObjectFromSessionStorage(`${insuranceType}Quote`);

  useEffect(() => {
    if (state.consent) {
      setFormValues();
    }
  }, [location.state]);

  useEffect(() => {
    if (userProfile?.quote) {
      saveInSessionStorage(HOME_RESUME_QUOTE, true);
    }
  }, []);

  const setFormValues = () => {
    const { email, phone, text, post } = state.consent;
    const { analyzeTransaction } = state;
    let storedConsent = false;
    let storedAnalyzeTransaction = false;
    if ([email, phone, text, post].includes(true)) {
      storedConsent = true;
    }
    if (analyzeTransaction) {
      storedAnalyzeTransaction = analyzeTransaction;
    }
    setValues({
      ...state.consent,
      consent: storedConsent,
      analyzeTransaction: storedAnalyzeTransaction
    });
  };

  // eslint-disable-next-line complexity
  const journey = (
    quoteData: any,
    userExistOrLoggedIn: any,
    isAgent: boolean
  ) => {
    if (isAgent) {
      Auth.configure(config);
    }
    if (userExistOrLoggedIn) {
      history.push(`${routes.makePayment.url}${insuranceType}`);
    } else {
      saveInSessionStorage(
        FORCE_LOGIN,
        JSON.stringify({ forceLogIn: true, insuranceType: insuranceType })
      );
      history.push(
        !isAgent
          ? `${routes.forceLogin.url}${insuranceType}`
          : `${routes.signUp.url}`
      );
    }
  };

  const goToNextPage = () => {
    const quoteData = {
      ...state,
      consent: {
        email: values.email,
        phone: values.phone,
        post: values.post,
        text: values.text
      },
      analyzeTransaction: values.analyzeTransaction
    };
    saveInSessionStorage(
      FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER,
      JSON.stringify(quoteData)
    );
    if (isAgent()) {
      Auth.configure(customer_config);
      getUsers({
        dropdownValue: 'email',
        searchInput: bindingQuote?.aboutYouObj?.email || ''
      })
        .then((res: any) => {
          if (res?.user_list?.length > 0) {
            journey(quoteData, true, true);
          } else {
            journey(quoteData, false, true);
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      journey(quoteData, cognitoUser, false);
    }
  };

  const isNextBtnDisabled = () =>
    !(
      !values.consent ||
      (values.consent &&
        [values.email, values.phone, values.post, values.text].includes(true))
    );

  const onDesktopClick = () => {
    if (insuranceType === HOME_INSURANCE_TYPE && userProfile?.quote) {
      saveInSessionStorage(HOME_RESUME_QUOTE, true);
      history.push('/wallet/viewQuote/home');
    }
  };

  return (
    <Form>
      <div className={`${className}`}>
        <div
          className={`${commonPageStylesIdentifier} ${desktopBackButtonClass(
            insuranceType
          )}`}
        >
          <MobileBackButton history={history} onDesktopClick={onDesktopClick} />
        </div>
        <ProgressBar
          stage={JOURNEY_YOUR_QUOTE}
          stages={
            insuranceType === HOME_INSURANCE_TYPE
              ? STAGES_HOME_JOURNEY
              : STAGES_MOTOR_TRAVEL_JOURNEY
          }
          labelText={
            insuranceType === HOME_INSURANCE_TYPE
              ? HOME_JOURNEY
              : MOTOR_TRAVEL_JOURNEY
          }
        />
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <TitleWithUnderLine>Your Marketing Preferences</TitleWithUnderLine>
        </div>
        <CardWrapper alignItemsCenter={false}>
          <ConsentText />
          <div className={`${className}__giveConsentSwitch`}>
            <label>Would you like to hear from us?</label>
            <SimpleSwitch
              name="consent"
              onLabel="on"
              on={values.consent}
              onClick={() => {
                const newConsentValue = !values.consent;
                if (newConsentValue === false) {
                  setFieldValue('post', false);
                  setFieldValue('email', false);
                  setFieldValue('text', false);
                  setFieldValue('phone', false);
                }
                setFieldValue('consent', newConsentValue);
              }}
              readOnly={false}
              isYesNoQuestion
            />
          </div>
          {values.consent && <ContactType {...props} />}
          <div className={`${className}__giveConsentSwitch`}>
            <label>
              Can we analyse your transactions to help identify products,
              services and offers that may be of interest to you?{' '}
            </label>
            <SimpleSwitch
              name="analyzeTransaction"
              onLabel="on"
              on={values.analyzeTransaction}
              onClick={() => {
                const newAnalyzeTransaction = !values.analyzeTransaction;
                setFieldValue('analyzeTransaction', newAnalyzeTransaction);
              }}
              isYesNoQuestion
            />
          </div>
          <div
            className={`${commonFormStylesIdentifier}__infoText ${className}__consentInfoText`}
          >
            The more you allow us to know about you, the better we can make your
            experience. Having consent to analyse your individual transaction
            data allows us to personalise product and offer information you
            receive from Bank of Ireland Group.
          </div>
          <AnalyzeTransaction />
          <div className={`${className}__blueInfo`}>
            When you submit this form, we will use what you&apos;ve told us here
            to update any previous marketing preferences you&apos;ve given us.
            You can change your preference at any time online or by calling us
            on{' '}
            <a href={`tel:${marketingPhoneNumber}`}>{marketingPhoneNumber}</a>.
          </div>
          <div className={`${className}__consentNextButton`}>
            <Button
              id="MarketingConsent__nextButton"
              disabled={isNextBtnDisabled()}
              fluid={true}
              onClick={goToNextPage}
            >
              Next
            </Button>
          </div>
        </CardWrapper>
      </div>
    </Form>
  );
};

const marketingConsentYupSchema = yup.object().shape({});

const MarketingConsentForm = withFormik({
  mapPropsToValues(): {} {
    return {
      consent: false,
      email: false,
      phone: false,
      text: false,
      post: false,
      analyzeTransaction: false
    };
  },
  handleSubmit(values: DirectDebitType, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: marketingConsentYupSchema,
  displayName: 'MarketingConsentForm'
})(MarketingConsent);

export default MarketingConsentForm;
