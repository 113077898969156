/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../styles/commonPageStyles.scss';
import './GetQuotePage.scss';

import { AccordionCard, Button, Title, TitleWithUnderLine } from '@boi/core/lib';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import FormikAboutYouForm from '../../components/Home/AboutYouForm';
import FormikYourCoverForm from '../../components/Home/YourCoverForm';
import FormikYourHomeForm from '../../components/Home/YourHomeForm';
import LegalText from '../../components/LegalText';
import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import {
  BOI_WALLET_USER_NAME_STORAGE,
  HOME_QUOTE_THANK_YOU_STORAGE
} from '../../constants';
import { commonPageStylesIdentifier } from '../../constants';
import {
  NO_PREPOPULATION,
  PREPOPULATION_PREFORMED
} from '../../constants/home';
import { HOME_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { JOURNEY_GET_QUOTE } from '../../constants/journey';
import { GET_QUOTE_VALUES_STORAGE, SUBMITTED_GET_QUOTE_STORAGE } from '../../constants/sessionStorage';
import { PAYMENT_SUCCESS, POLICY_RENEWAL } from '../../constants/sessionStorage/genericStorageIdentifiers';
import { AGENT_CLICKED_REFERENCE_QUOTES, BOI_WALLET_TOWN_COUNTY_DATA } from '../../constants/sessionStorage/userStorageIdentifiers';
import {
  findElementsByClasses,
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  isAgent,
  removeFromSessionStorage,
  removeSessionStorageAfterGetQuote,
  saveInSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import { getTownAndCounty } from '../../services/homeServices';
import UserContext from '../../UserContext';
import {
  aboutYouObjInitialValues,
  yourCoverObjInitialValues,
  yourHomeObjInitialValues
} from './InitalValues';
import useGetQuotePageValidity from './useGetQuotePageValidity';
import useRebuildCostStatus from './useRebuildCostStatus';
import useSectionOpenedStatus from './useSectionOpenedStatus';

const className = 'c-GetQuotePage';

/**
 * Get Quote form for the Home journey
 *
 * Working in this component can be a bit tricky due to the need for a lot
 * of handwritten syncronisation between three seperate Formik stores in the
 * `AboutYou`, `YourHome` and `YourCover` sub-sections. The Motor and Travel
 * journey's use a single Formik context in `GetGenericQuotePage` which
 * eliminates a lot of issues that are present here.
 *
 * @author DogPawHat
 *
 * */
// eslint-disable-next-line complexity
const GetQuotePage = ({ latestQuote, history }) => {
  const [
    prepopulationStatus,
    setPrepopulationStatus
  ] = useState(NO_PREPOPULATION);

  let emailParam;
  

  /**
   * This *kinda* emulates `submitCount` from formik. It's incremented every
   * time the getQuote button is clicked, and is passed to the subforms to
   * indicate that all fields should be touched.
   **/
  const [getQuoteTrackCount, setGetQuoteTrackCount] = useState(0);
  const [validity, handleFormSubmit] = useGetQuotePageValidity();
  const [opened, handleFormOpen] = useSectionOpenedStatus();
  const [aboutYouObj, setAboutYou] = useState(
    Object.assign({}, aboutYouObjInitialValues)
  );
  const [yourHomeObj, setYourHome] = useState(
    Object.assign({}, yourHomeObjInitialValues)
  );
  const [yourCoverObj, setYourCover] = useState(
    Object.assign({}, yourCoverObjInitialValues)
  );

  const [townAndCountyData, setTownsAndCountyData] = useState([]);

  const [triggerSubmitButton, setTriggerSubmitButton] = useState(false)

  if(isAgent() && getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)) {
    emailParam = getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)
  }

  const [aboutYouAccordion, setAboutYouAccordion] = useState(!!emailParam || prepopulationStatus === PREPOPULATION_PREFORMED)
  const [yourHomeAccordion, setYourHomeAccordion] = useState(prepopulationStatus === PREPOPULATION_PREFORMED)
  const [yourCoverAccordion, setYourCoverAccordion] = useState(prepopulationStatus === PREPOPULATION_PREFORMED)



  const { userDispatch } = useContext(UserContext);
  const { cognitoUser } = useContext(CognitoContext)

  const sessionStoredValues = getObjectFromSessionStorage(
    `${HOME_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`
  );
  removeSessionStorageAfterGetQuote(HOME_INSURANCE_TYPE);

  const openAccordions = () => {
    setAboutYouAccordion(true)
    setYourHomeAccordion(true)
    setYourCoverAccordion(true)
    handleFormOpen('aboutYou')
    handleFormOpen('yourHome')
    handleFormOpen('yourCover')
    setGetQuoteTrackCount(getQuoteTrackCount + 1);
  }

  const scrollToFirstError = () => {
    const errorClasses = ['c-InputField--error', 'c-Dropdown--error', 'c-ButtonGroup--error', 'c-Typeahead--error', 'c-InputTextArea--error', 'c-InputErrorMessage']
    const elements = findElementsByClasses(errorClasses)
    let topPosition = 10000
    elements.forEach((element) => {
      const currentY = element && element.getBoundingClientRect().top
      if (currentY && currentY < topPosition) {
        topPosition = currentY
      }
    })
    const scrollPosition = window.pageYOffset;
    window.scrollTo(0, topPosition - 80 + scrollPosition)
  }

  const renderSubmitButton = () => (
    <div className={`${className}__button`}>
      <a
        data-ga
        id={`GetQuote__getCoveredButton`}
        data-testid={`GetQuote__getCoveredButton`}
      >
        <Button
          quaternary
          fluid
          onClick={() => {
            openAccordions();
            setTriggerSubmitButton(true);
            if (validity.allForms) {
              goToThankYouPage();
            } else setTimeout(scrollToFirstError, 1);
          }}
        >
          Get your quote
        </Button>
      </a>
    </div>
  );

  const [
    rebuildCostStatus,
    dispatchToRebuildCostStatus
  ] = useRebuildCostStatus();

  const getFormStatus = (formName) => {
    if (opened[formName]) {
      return validity[formName] ? 'success' : 'warning';
    }
    return 'default';
  };

  const loadAboutYou = (object, override) => {
    const replaceOrKeepValue = (key) => key in object && object[key] !== '' ? object[key] : aboutYouObj[key]
    setAboutYou(
      Object.keys(aboutYouObj).reduce((newObj, key) => {
        newObj[key] = override ? object[key] : replaceOrKeepValue(key)
        return newObj
      }, {}))
  }

  const loadYourHome = (object, override) => {
    const replaceOrKeepValue = (key) => key in object && object[key] !== '' ? object[key] : yourHomeObj[key]
    setYourHome(
      Object.keys(yourHomeObj).reduce((newObj, key) => {
        newObj[key] = override ? object[key] : replaceOrKeepValue(key)
        return newObj
      }, {}))
  }

  const loadYourCover = (object, override) => {
    const replaceOrKeepValue = (key) => key in object && object[key] !== '' ? object[key] : yourCoverObj[key]
    setYourCover(
      Object.keys(yourCoverObj).reduce((newObj, key) => {
        newObj[key] = override ? object[key] : replaceOrKeepValue(key)
        return newObj
      }, {}))
  }

  const preloadForm = () => {
    const objMaker = (oldObj, newObj) => Object.assign({}, oldObj, newObj || {})
    const hasValues = (obj) => typeof obj === 'object' && Object.keys(obj).length > 0
    const submitted = getItemFromSessionStorage(`${HOME_INSURANCE_TYPE}${SUBMITTED_GET_QUOTE_STORAGE}`)
    const values = getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
    const unSubmitted = () => !submitted && hasValues(values)
    const submittedLatest = () => hasValues(latestQuote) && !isAgent()
    const submittedSession = () => hasValues(sessionStoredValues) && !isAgent()
    if (unSubmitted()) {
      setAboutYou((oldAboutYou) => objMaker(oldAboutYou, values.aboutYouObj));
      setYourHome((oldYourHome) => objMaker(oldYourHome, values.yourHomeObj));
      setYourCover((oldYourCover) => objMaker(oldYourCover, values.yourCoverObj));
      setPrepopulationStatus(PREPOPULATION_PREFORMED)
    } else if (submittedLatest()) {
      setAboutYou((oldAboutYou) => objMaker(oldAboutYou, latestQuote.aboutYou));
      setYourHome((oldYourHome) => objMaker(oldYourHome, latestQuote.yourHome));
      setYourCover((oldYourCover) => objMaker(oldYourCover, latestQuote.yourCover));
      setPrepopulationStatus(PREPOPULATION_PREFORMED)
    } else if (submittedSession()) {
      loadAboutYou(sessionStoredValues, false)
      loadYourHome(sessionStoredValues, false)
      loadYourCover(sessionStoredValues, false)
      setPrepopulationStatus(PREPOPULATION_PREFORMED)
    }
  }

  const setTownsAndCounty = async () => {
    try{
      const townAndCountyData = await getTownAndCounty()
      saveInSessionStorage(BOI_WALLET_TOWN_COUNTY_DATA, JSON.stringify(townAndCountyData));
      setTownsAndCountyData(townAndCountyData)
    } catch (e) {
      saveInSessionStorage(BOI_WALLET_TOWN_COUNTY_DATA, []);
      setTownsAndCountyData([])
    }
  }

  useEffect(() => {
    setTownsAndCounty()
    removeFromSessionStorage(POLICY_RENEWAL)
    removeFromSessionStorage(PAYMENT_SUCCESS)
  },[])

  useEffect(() => {
    preloadForm()
  }, [latestQuote]);

  const personaliseUsingLatestQuoteFirstName = () => {
    if (latestQuote.aboutYou && latestQuote.aboutYou.firstName) {
      updateFirstName(latestQuote.aboutYou.firstName);
    }
  };


  useEffect(() => {
    personaliseUsingLatestQuoteFirstName();
  }, [cognitoUser]);

  const updateFirstName = (value) => {
    userDispatch({
      type: 'setFirstName',
      payload: { firstName: value }
    });
    saveInSessionStorage(BOI_WALLET_USER_NAME_STORAGE, value);
  };

  const setUpdatedAboutYouValues = async (values) => {
    setAboutYou(values);
    updateFirstName(values.firstName);
  };

  const setUpdatedYourHomeValues = (values) =>
    setYourHome(values);

  const setUpdatedYourCoverValues = (values) =>
    setYourCover(values);

  const loadAgentFormValues = (forms) => {
    openAccordions()
    if (forms.aboutYou)
      loadAboutYou(forms.aboutYou, true)
    if (forms.yourCover)
      loadYourCover(forms.yourCover, true)
    if (forms.yourHome)
      loadYourHome(forms.yourHome, true)
    setPrepopulationStatus(PREPOPULATION_PREFORMED)
  }

  const setAboutYouObjForUpdatedFields = (
    fieldName,
    value
  ) => {
    const newValues = { ...aboutYouObj };
    newValues[fieldName] = value;
    if (fieldName === 'firstName') updateFirstName(value.toString());
    setAboutYou(newValues);
  };

  const setAboutYouObjForMultipleUpdatedFields = (updatedFields) => {
    const newValues = { ...aboutYouObj, ...updatedFields };
    if (updatedFields.firstName !== undefined)
      updateFirstName(updatedFields.firstName.toString());
    setAboutYou(newValues);
  };

  const setYourHomeObjForUpdatedFields = (
    fieldName,
    value
  ) => {
    const newValues = { ...yourHomeObj };
    newValues[fieldName] = value;
    setYourHome(newValues);
  };

  const setYourCoverObjForUpdatedFields = (
    fieldName,
    value
  ) => {
    const newValues = { ...yourCoverObj };
    newValues[fieldName] = value;
    setYourCover(newValues);
  };

  const modifyYourCover = (obj) => {
    if(!obj['specifiedItemsSwitch']) {
       obj['specifiedItems'] = 
        [
          {
            item: '',
            value: '',
            description: ''
          }
        ]
    } 
    if(!obj['unspecifiedItemsSwitch']) {
       obj['unspecifiedItems'] = '0'
    }
    return obj;
  }

  const goToThankYouPage = () => {
    const linkAddress = `${routes.termsAndConditions.url}${HOME_INSURANCE_TYPE}`;

    const quoteData = {
      aboutYouObj: aboutYouObj,
      yourHomeObj: yourHomeObj,
      yourCoverObj: modifyYourCover(yourCoverObj)
    };
    saveInSessionStorage(
      HOME_QUOTE_THANK_YOU_STORAGE,
      JSON.stringify(quoteData)
    );
    if(isAgent() && getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)) {
      removeFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)
    }
    history.push({
      pathname: linkAddress,
      state: quoteData
    });
  };

  const getQuoteForm = () => (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_GET_QUOTE} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Get Quotes</TitleWithUnderLine>
      </div>
      <div className={`${className}__subHeading`}>
        <Title type="h4" align="left" variant="black">
          Please complete the following:
        </Title>
      </div>
      <div className={`${className}__accordions`}>
        <AccordionCard
          id={`GetQuote__aboutYou`}
          label="About you"
          status={getFormStatus('aboutYou')}
          childContainerClass={`${className}__noPaddingFormContainer`}
          isOnState={aboutYouAccordion}
          handleToggle={() => {
            setAboutYouAccordion(!aboutYouAccordion)
            handleFormOpen('aboutYou')
          }}
        >
          <FormikAboutYouForm
            aboutYouValid={validity.aboutYou}
            formOpened={handleFormOpen}
            validForm={handleFormSubmit}
            setUpdatedAboutYouValues={setUpdatedAboutYouValues}
            aboutYouValues={aboutYouObj}
            setAboutYouObjForMultipleUpdatedFields={
              setAboutYouObjForMultipleUpdatedFields
            }
            setAboutYouObjForUpdatedFields={setAboutYouObjForUpdatedFields}
            isLoggedIn={!!cognitoUser}
            getQuoteTrackCount={getQuoteTrackCount}
            prepopulationStatus={prepopulationStatus}
            loadAgentFormValues={loadAgentFormValues}
            emailParam={emailParam}
            cognitoUser={cognitoUser}
            triggerSubmitButton={triggerSubmitButton}
            townAndCountyData = {townAndCountyData}
          />
        </AccordionCard>
        <div className={`${className}--spacing`}>
          <AccordionCard
            id={`GetQuote__yourHome`}
            status={getFormStatus('yourHome')}
            label="Your home"
            childContainerClass={`${className}__noPaddingFormContainer`}
            isOnState={yourHomeAccordion}
            handleToggle={() => {
              setYourHomeAccordion(!yourHomeAccordion)
              handleFormOpen('yourHome')
            }}
          >
            <FormikYourHomeForm
              YourHomeValid={validity.yourHome}
              validForm={handleFormSubmit}
              formOpened={handleFormOpen}
              setUpdatedYourHomeValues={setUpdatedYourHomeValues}
              yourHomeValues={yourHomeObj}
              setYourHomeObjForUpdatedFields={setYourHomeObjForUpdatedFields}
              updateRebuildCostStatus={dispatchToRebuildCostStatus}
              getQuoteTrackCount={getQuoteTrackCount}
              prepopulationStatus={prepopulationStatus}
              aboutYouObj={aboutYouObj}
            />
          </AccordionCard>
        </div>

        <div className={`${className}--spacing`}>
          <AccordionCard
            id={`GetQuote__yourCover`}
            status={getFormStatus('yourCover')}
            label="Your cover"
            isOnState={yourCoverAccordion}
            handleToggle={() => {
              setYourCoverAccordion(!yourCoverAccordion)
              handleFormOpen('yourCover')
            }}
          >
            <FormikYourCoverForm
              yourCoverValid={validity.yourCover}
              formOpened={handleFormOpen}
              validForm={handleFormSubmit}
              setUpdatedYourCoverValues={setUpdatedYourCoverValues}
              yourCoverFormValues={yourCoverObj}
              setYourCoverObjForUpdatedFields={setYourCoverObjForUpdatedFields}
              showRebuildCost={rebuildCostStatus}
              getQuoteTrackCount={getQuoteTrackCount}
              prepopulationStatus={prepopulationStatus}
              yourHomeObj={yourHomeObj}
              aboutYouObj={aboutYouObj}
            />
          </AccordionCard>
        </div>
      </div>
      {renderSubmitButton()}
      <div className={`${className}__legalTextContainer`}>
        <LegalText insuranceType="home" />
      </div>
    </div>
  );

  return getQuoteForm();
};

export default GetQuotePage;
