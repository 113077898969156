// Generic Storage Identifiers
export const GET_QUOTE_RESPONSE_STORAGE = 'GetQuoteResponse';
export const SUBMITTED_GET_QUOTE_STORAGE = 'SubmittedGetQuote';
export const GET_QUOTE_VALUES_STORAGE = 'GetQuoteValues';
export const RECALCULATED_QUOTE_VALUES_STORAGE = 'RecalculatedQuoteValues';
export const POLICY_NUMBER_STORAGE = 'PolicyNumber';
export const PAYMENT_SUCCESS = 'PaymentDetails';
export const POLICY_RENEWAL = 'PolicyRenewal';
export const ALTERNATIVE_POLICY = 'AlternativePolicy';
export const DIRECT_DEBIT_STORAGE = 'DirectDebit';
export const EMAIL_TO_REGISTER_STORAGE = 'EmailToRegister';
export const LOCATION_HISTORY_STORAGE = 'locationHistory';
export const AUTO_RENEWAL_EXISTING_PAYMENT = 'auto-renewal';
export const AGENT_LATEST_QUOTE_LOADED = 'agentEmailLoaded';
export const HOME_BIND_STATUS = 'bindStatus';
export const HOME_BUY_NOW_BIND_RESPONSE = 'buyNowBindResponse';
export const HOME_PAYMENT_BACK_PRESSED = 'homePaymentBackPressed';

