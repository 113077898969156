/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../styles/commonFormStyles.scss';
import './YourHomeForm.scss';

import { ButtonGroup, Dropdown, InputField, WideDivider } from '@boi/core/lib';
import { noop } from '@boi/core/lib/utils';
import { isDate, parse } from 'date-fns';
import {
  Form,
  FormikProps,
  getIn,
  setNestedObjectValues,
  withFormik
} from 'formik';
import React, { Component } from 'react';
import * as yup from 'yup';

import type AboutYouFormValues from '../../../components/Home/AboutYouForm';
import { commonFormStylesIdentifier } from '../../../constants';
import { dividerColor } from '../../../constants/colors';
import type { HomePrepoulationCheck } from '../../../constants/home';
import { GENERIC_REQUIRED_MESSAGE } from '../../../constants/home';
import { FUTURE_ERROR, PREPOPULATION_PREFORMED } from '../../../constants/home';
import { saveData } from '../../../helpers/HomeCommonMethods';
import { yearLimit } from '../../../helpers/NumberMask';
import type Claim from './Claims';
import Claims from './Claims';
import CoverTypeDependantFields from './CoverTypeDependantFields';

export type YourHomeFormValues = {
  coverType: string,
  isFamilyUnit: string,
  privateLiving: string,
  homeType: string,
  yearOfConstruction: string,
  hasBeenReroofed: string,
  hasBeenRewired: string,
  numBedrooms: string,
  numBathrooms: string,
  alarmType: string,
  numberOfSmokeDetectors: string,
  userAgreement: boolean,
  // neighbourhoodWatch: string,
  claims: any,
  claimsValue: string
};

interface Props {
  validForm: noop;
  formOpened: noop;
  YourHomeValid: boolean;
  setUpdatedYourHomeValues: noop;
  yourHomeValues: YourHomeFormValues;
  setYourHomeObjForUpdatedFields: noop;
  isPreFilled: boolean;
  yourHomeFormTrackCount: number;
  prepopulationStatus: HomePrepoulationCheck;
  aboutYouObj: AboutYouFormValues;
}

const className = 'c-YourHomeForm';

const currentYear = new Date().getFullYear();

class YourHomeForm extends Component<Props & FormikProps<YourHomeFormValues>> {
  prepoulateWithLatest = () => {
    if (this.props.prepopulationStatus === PREPOPULATION_PREFORMED) {
      this.props.setValues(this.props.yourHomeValues);
    }
  };

  //eslint-disable-next-line complexity
  touchAllFields = () => {
    if (
      this.props.aboutYouObj?.policyLength !== undefined &&
      (this.props.aboutYouObj.policyLength > 0 ||
        this.props.aboutYouObj.policyLength === 0) &&
      this.props.yourHomeFormTrackCount === 0
    ) {
      this.props.setFieldTouched(
        setNestedObjectValues(this.props.values !== '', true)
      );
    } else if (
      this.props.yourHomeFormTrackCount > 0 ||
      this.props.prepopulationStatus === PREPOPULATION_PREFORMED
    ) {
      this.props.setTouched(setNestedObjectValues(this.props.values, true));
    }
  };

  componentDidMount() {
    const { formOpened, setTouched } = this.props;
    formOpened('yourHome');
    setTouched({
      ...this.props.touched,
      claims: []
    });
    this.touchAllFields();
    this.prepoulateWithLatest();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.yourHomeFormTrackCount !== prevProps.yourHomeFormTrackCount ||
      this.props.prepopulationStatus !== prevProps.prepopulationStatus
    ) {
      this.touchAllFields();
    }

    if (this.props.prepopulationStatus !== prevProps.prepopulationStatus) {
      this.prepoulateWithLatest();
    }
  }

  isFieldError = (path: string) => {
    const { errors, touched } = this.props;
    return getIn(touched, path) && getIn(errors, path) !== undefined;
  };

  setField(fieldName: string, value: string) {
    const { setFieldValue } = this.props;
    setFieldValue(fieldName, value);

    this.props.setYourHomeObjForUpdatedFields(fieldName, value);
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      errors,
      isSubmitting,
      isValid,
      setFieldValue,
      setTouched,
      setFieldTouched,
      touched,
      values,
      handleChange,
      handleBlur,
      setYourHomeObjForUpdatedFields
    } = this.props;

    if (!isSubmitting && isValid !== this.props.YourHomeValid) {
      this.props.validForm('yourHome', isValid);
      this.props.setUpdatedYourHomeValues(this.props.values);
    }

    const handleCustomBlur = (
      fieldName: string,
      e: SyntheticEvent<HTMLInputElement>
    ) => {
      setYourHomeObjForUpdatedFields(fieldName, values[fieldName]);
      handleBlur(e);
    };

    const updateButtonGroupValue = (fieldName: string, value: mixed) => {
      setYourHomeObjForUpdatedFields(fieldName, value);
    };

    const showOccupiedByMemberQuestion = () => {
      if (values.coverType !== 'ownLive' && values.coverType !== 'rent') {
        return <></>;
      } else {
        return (
          <>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="occupiedByMember"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Is this property your main residence and solely occupied by you
                and members of your household?
              </label>
              <span className={`${className}__input`}>
                <div className={`${className}--fieldLabelSpacing`}>
                  <ButtonGroup
                    name="occupiedByMember"
                    onClick={(e: string) => {
                      setFieldValue('occupiedByMember', e === 'true');
                      setFieldTouched('occupiedByMember', e);
                      saveData('yourHomeObj', 'occupiedByMember', e === 'true');
                    }}
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false }
                    ]}
                    selected={values.occupiedByMember}
                    onBlur={handleCustomBlur.bind(this, 'occupiedByMember')}
                    error={this.isFieldError('occupiedByMember')}
                    errorMessage={errors.occupiedByMember}
                  />
                </div>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
          </>
        );
      }
    };

    return (
      <div className={`${className}__container`}>
        <Form>
          <div
            className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
          >
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="coverType"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Who owns and lives at this property?
              </label>
              <span className={`${className}__input`}>
                <Dropdown
                  placeholder="Select here"
                  name="coverType"
                  value={values.coverType}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setYourHomeObjForUpdatedFields('coverType', e.target.value);
                    setFieldValue('userAgreement', false);
                    setFieldValue('occupiedByMember', '');
                    saveData('yourHomeObj', 'coverType', e.target.value);
                  }}
                  onBlur={handleCustomBlur.bind(this, 'coverType')}
                  error={this.isFieldError('coverType')}
                  errorMessage={errors.coverType}
                >
                  <option value="">Select here</option>
                  <option value="ownLive">
                    I own and live in this property
                  </option>
                  <option value="ownRent">
                    {' '}
                    I own and rent out this property
                  </option>
                  <option value="ownHoliday">
                    I own this property and use it as a holiday home
                  </option>
                  <option value="rent">I rent from someone</option>
                  <option value="ownUnoccupied">
                    I own this property and it is unoccupied
                  </option>
                  <option value="ownConstruction">
                    I own this property and it is under construction
                  </option>
                  <option value="ownRenovation">
                    I own this property and it is under renovation
                  </option>
                </Dropdown>
              </span>
            </div>
            {showOccupiedByMemberQuestion()}
            <CoverTypeDependantFields
              errors={errors}
              handleChange={handleChange}
              handleCustomBlur={handleCustomBlur}
              setFieldValue={setFieldValue}
              updateButtonGroupValue={updateButtonGroupValue}
              touched={touched}
              values={values}
            />
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="homeType"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                What type of property do you want to insure?
              </label>
              <span className={`${className}__input`}>
                <Dropdown
                  placeholder="Select here"
                  name="homeType"
                  value={values.homeType}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setYourHomeObjForUpdatedFields('homeType', e.target.value);
                    saveData('yourHomeObj', 'homeType', e.target.value);
                  }}
                  onBlur={handleCustomBlur.bind(this, 'homeType')}
                  error={this.isFieldError('homeType')}
                  errorMessage={errors.homeType}
                >
                  <option value="">Select here</option>
                  <option value="Detached house">Detached house</option>
                  <option value="Detached house with basement">
                    Detached house with basement
                  </option>
                  <option value="Semi-Detached house">
                    Semi-detached house
                  </option>
                  <option value="Semi-detached house with basement">
                    Semi-detached house with basement
                  </option>
                  <option value="Terraced house">Terraced house</option>
                  <option value="Terraced house with basement">
                    Terraced house with basement
                  </option>
                  <option value="Bungalow">Bungalow</option>
                  <option value="Bungalow with basement">
                    Bungalow with basement
                  </option>
                  <option value="Purpose built apartment">
                    Purpose built apartment
                  </option>
                </Dropdown>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="yearOfConstruction"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                What year was the property built?
              </label>
              <span className={`${className}__input`}>
                <InputField
                  error={this.isFieldError('yearOfConstruction')}
                  placeholder="Enter year of construction or best estimate "
                  errorMessage={errors.yearOfConstruction}
                  name="yearOfConstruction"
                  value={values.yearOfConstruction}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    saveData(
                      'yourHomeObj',
                      'yearOfConstruction',
                      e.target.value
                    );
                  }}
                  onBlur={handleCustomBlur.bind(this, 'yearOfConstruction')}
                  masked
                  mask={yearLimit}
                />
              </span>
            </div>
            {values.yearOfConstruction <= 1970 &&
              values.yearOfConstruction > 1500 && (
                <div
                  className={`${commonFormStylesIdentifier}__fieldContainer`}
                >
                  <label
                    htmlFor="hasBeenRewired"
                    className={`${commonFormStylesIdentifier}__fieldLabel`}
                  >
                    Has this property been rewired and replumbed in the past 40
                    years?
                  </label>
                  <span className={`${className}__input`}>
                    <div className={`${className}--fieldLabelSpacing`}>
                      <ButtonGroup
                        name="hasBeenRewired"
                        onClick={(e: string) => {
                          setFieldValue('hasBeenRewired', e === 'true');
                          setFieldTouched('hasBeenRewired', e);
                          saveData(
                            'yourHomeObj',
                            'hasBeenRewired',
                            e === 'true'
                          );
                        }}
                        options={[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false }
                        ]}
                        selected={values.hasBeenRewired}
                        onBlur={handleCustomBlur.bind(this, 'hasBeenRewired')}
                        error={this.isFieldError('hasBeenRewired')}
                        errorMessage={errors.hasBeenRewired}
                      />
                    </div>
                  </span>
                </div>
              )}
            {values.yearOfConstruction <= 1920 &&
              values.yearOfConstruction > 1500 && (
                <div
                  className={`${commonFormStylesIdentifier}__fieldContainer`}
                >
                  <label
                    htmlFor="hasBeenReroofed"
                    className={`${commonFormStylesIdentifier}__fieldLabel`}
                  >
                    Has this property been reroofed in the past 40 years?
                  </label>
                  <span className={`${className}__input`}>
                    <div className={`${className}--fieldLabelSpacing`}>
                      <ButtonGroup
                        name="hasBeenReroofed"
                        onClick={(e: string) => {
                          setFieldValue('hasBeenReroofed', e === 'true');
                          setFieldTouched('hasBeenReroofed', e);
                          saveData(
                            'yourHomeObj',
                            'hasBeenReroofed',
                            e === 'true'
                          );
                        }}
                        options={[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false }
                        ]}
                        selected={values.hasBeenReroofed}
                        onBlur={handleCustomBlur.bind(this, 'hasBeenReroofed')}
                        error={this.isFieldError('hasBeenReroofed')}
                        errorMessage={errors.hasBeenReroofed}
                      />
                    </div>
                  </span>
                </div>
              )}
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="numBedrooms"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                How many bedrooms does this property have?
              </label>
              <span className={`${className}__input`}>
                <Dropdown
                  name="numBedrooms"
                  value={values['numBedrooms']}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    saveData('yourHomeObj', 'numBedrooms', e.target.value);
                  }}
                  onBlur={handleCustomBlur.bind(this, 'numBedrooms')}
                  error={this.isFieldError('numBedrooms')}
                  errorMessage={errors['numBedrooms']}
                >
                  <option value="">Select a number</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((k: number) =>
                    k === 8 ? (
                      <option value={8} key={`${k}-bedrooms`}>
                        {'8+'}
                      </option>
                    ) : (
                      <option key={`${k}-bedrooms`}>{k}</option>
                    )
                  )}
                </Dropdown>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="numBathrooms"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                How many bathrooms does this property have?
              </label>
              <span className={`${className}__input`}>
                <Dropdown
                  name="numBathrooms"
                  value={values['numBathrooms']}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    saveData('yourHomeObj', 'numBathrooms', e.target.value);
                  }}
                  onBlur={handleCustomBlur.bind(this, 'numBathrooms')}
                  error={this.isFieldError('numBathrooms')}
                  errorMessage={errors['numBathrooms']}
                >
                  <option value="">Select a number</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((k: number) =>
                    k === 8 ? (
                      <option value={8} key={`${k}-bathrooms`}>
                        {'8+'}
                      </option>
                    ) : (
                      <option key={`${k}-bathrooms`}>{k}</option>
                    )
                  )}
                </Dropdown>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="heatingSource"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                What is your main source of heating in this property?
              </label>
              <div className={`${commonFormStylesIdentifier}__infoText`}>
                If your property has more than one heating source, please select
                the most used source of your heating.
              </div>
              <span className={`${className}__input`}>
                <Dropdown
                  name="heatingSource"
                  value={values['heatingSource']}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    handleChange(e);
                    saveData('yourHomeObj', 'heatingSource', e.target.value);
                  }}
                  onBlur={handleCustomBlur.bind(this, 'heatingSource')}
                  error={this.isFieldError('heatingSource')}
                  errorMessage={errors['heatingSource']}
                >
                  <option value="">Select here</option>
                  <option value="Solid Fuel">Solid Fuel</option>
                  <option value="Electric">Electric</option>
                  <option value="Gas">Gas</option>
                  <option value="Oil">Oil</option>
                  <option value="Solar/Wind">Solar/Wind</option>
                  <option value="Wood/Wood Pellet">Wood/Wood Pellet</option>
                  <option value="Mixture inc Oil">Mixture inc Oil</option>
                  <option value="Heat Exchange">
                    Heat Exchange (includes Air to Water heating or Geo thermal)
                  </option>
                  <option value="Not listed">Not listed</option>
                </Dropdown>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="alarmType"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Do you have a working burglar alarm installed?
              </label>
              <span className={`${className}__input`}>
                <div className={`${className}--fieldLabelSpacing`}>
                  <ButtonGroup
                    name="alarmType"
                    onClick={(e: string) => {
                      updateButtonGroupValue('alarmType', e);
                      setFieldValue('alarmType', e);
                      saveData('yourHomeObj', 'alarmType', e);
                    }}
                    options={[
                      { label: 'No', value: 'No alarm fitted' },
                      {
                        label:
                          'Yes - The alarm was  installed by a registered installer but is not monitored',
                        value: 'Registered installer but is not monitored'
                      },
                      {
                        label:
                          'Yes - The alarm was  installed by a registered installer and is centrally monitored',
                        value: 'Registered installer centrally monitored'
                      },
                      {
                        label:
                          'Yes -The alarm was not installed by a registered installer’',
                        value: 'Not registered installer'
                      }
                    ]}
                    spaceBetween={true}
                    selected={values.alarmType}
                    onBlur={handleCustomBlur.bind(this, 'alarmType')}
                    error={this.isFieldError('alarmType')}
                    errorMessage={errors.alarmType}
                  />
                </div>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="numberOfSmokeDetectors"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                How many working smoke detectors are fitted in this property?
              </label>
              <span className={`${className}__input`}>
                <div className={`${className}--fieldLabelSpacing`}>
                  <Dropdown
                    placeholder="Select here"
                    name="numberOfSmokeDetectors"
                    value={values.numberOfSmokeDetectors}
                    onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setYourHomeObjForUpdatedFields(
                        'numberOfSmokeDetectors',
                        e.target.value
                      );
                      saveData(
                        'yourHomeObj',
                        'numberOfSmokeDetectors',
                        e.target.value
                      );
                    }}
                    onBlur={handleCustomBlur.bind(
                      this,
                      'numberOfSmokeDetectors'
                    )}
                    error={this.isFieldError('numberOfSmokeDetectors')}
                    errorMessage={errors.numberOfSmokeDetectors}
                  >
                    <option value="">Select here</option>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10+</option>
                  </Dropdown>
                </div>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="claims"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Have you or any member of your household made any home insurance
                claims or suffered any losses in the last 5 years? You must tell
                us of all claims you have made or losses that you have suffered
                in the last 5 years, starting with the most recent claim(s),
                whether insured or not at this or any other address
              </label>
              <span className={`${className}__input`}>
                <div className={`${className}--fieldLabelSpacing`}>
                  <ButtonGroup
                    name="claimsValue"
                    onClick={(value: string) => {
                      if (value === 'false') {
                        setFieldValue('claimsCount', '0');
                        setFieldValue('claims', []);
                        setFieldValue('claimsValue', 'false');
                        setYourHomeObjForUpdatedFields('claims', []);
                        saveData('yourHomeObj', 'claims', []);
                        saveData('yourHomeObj', 'claimsValue', 'false');
                        saveData('yourHomeObj', 'claimsCount', '0');
                      } else {
                        setFieldValue('claimsValue', 'true');
                        saveData('yourHomeObj', 'claimsValue', 'true');
                      }
                      setFieldTouched('claimsValue', value);
                      saveData('yourHomeObj', 'claimsValue', value);
                    }}
                    options={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    touched={touched.claimsValue}
                    selected={values.claimsValue}
                    onBlur={handleCustomBlur.bind(this, 'claimsValue')}
                    error={this.isFieldError('claimsValue')}
                    errorMessage={errors.claimsValue}
                  />
                </div>
              </span>
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              {values.claimsValue === 'true' && (
                <span className={`${className}__input`}>
                  <label
                    htmlFor="claims"
                    className={`${commonFormStylesIdentifier}__fieldLabel`}
                  >
                    How many?
                  </label>
                  <Claims
                    errors={errors}
                    name="claims"
                    onChange={(e: Claim) => {
                      setFieldValue('claims', e);
                      setTouched('claims', e);
                      setYourHomeObjForUpdatedFields('claims', e);
                      saveData('yourHomeObj', 'claims', e);
                    }}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    values={values}
                    handleBlur={handleCustomBlur}
                    onBlur={handleCustomBlur.bind(this, 'claims')}
                  />
                </span>
              )}
            </div>
          </div>

          <div
            className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
          >
            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label
                htmlFor="noClaimYears"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                How many years have you had home insurance without making any
                claim?
              </label>
              <span className={`${className}__input`}>
                <div className={`${className}--fieldLabelSpacing`}>
                  <Dropdown
                    placeholder="Select here"
                    name="noClaimYears"
                    value={values.noClaimYears}
                    onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setYourHomeObjForUpdatedFields(
                        'noClaimYears',
                        e.target.value
                      );
                      saveData('yourHomeObj', 'noClaimYears', e.target.value);
                    }}
                    onBlur={handleCustomBlur.bind(this, 'noClaimYears')}
                    error={this.isFieldError('noClaimYears')}
                    errorMessage={errors.noClaimYears}
                  >
                    <option value="">Select here</option>
                    <option value={-1}>No previous insurance</option>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6+</option>
                  </Dropdown>
                </div>
              </span>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const ownRentHiddenBoolFieldYupSchema = () =>
  yup.boolean().when('coverType', {
    is: 'ownRent',
    then: yup.boolean().required(GENERIC_REQUIRED_MESSAGE)
  });
const parseDateString = (_value: Date, originalValue: Date) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'dd-MM-yyyy', new Date());
  return parsedDate;
};
const FormikYourHomeForm = withFormik<Props, YourHomeFormValues>({
  mapPropsToValues(): YourHomeFormValues {
    let initialValues = {
      coverType: '',

      // ownRent fields
      privateLiving: '',
      isFamilyUnit: '',
      numTenants: '',
      tenantType: '',
      occupiedAtNight: '',
      tenancyAgreement: '',

      // ownHolidayFields
      isInUse: '',
      holidayHomeIsRented: '',
      isLetMoreThan5PerYear: '',

      occupiedByMember: '',

      homeType: '',
      yearOfConstruction: '',
      hasBeenRewired: '',
      hasBeenReroofed: '',
      numBedrooms: '',
      numBathrooms: '',
      heatingSource: '',
      alarmType: '',
      numberOfSmokeDetectors: '',
      // neighbourhoodWatch: '',
      claims: [],
      claimsValue: '',
      claimsCount: '',
      noClaimYears: ''
    };
    const valuesObject = {};
    Object.keys(initialValues).forEach((key: string) => {
      valuesObject[key] = initialValues[key];
    });
    return valuesObject;
  },
  handleSubmit(values: YourHomeFormValues, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },

  isInitialValid: (props: Props) => props.YourHomeValid,
  validationSchema: yup.object().shape({
    coverType: yup
      .string()
      .oneOf([
        'ownLive',
        'ownRent',
        'ownHoliday',
        'rent',
        'ownUnoccupied',
        'ownConstruction',
        'ownRenovation'
      ])
      .required('Answer is required'),

    // ownRent fields
    privateLiving: ownRentHiddenBoolFieldYupSchema(),
    isFamilyUnit: ownRentHiddenBoolFieldYupSchema(),
    tenantType: yup.string().when('isFamilyUnit', {
      is: false,
      then: yup.string().required(GENERIC_REQUIRED_MESSAGE)
    }),
    numTenants: yup.number().when('tenantType', {
      is: 'NonStudents',
      then: yup.number().required(GENERIC_REQUIRED_MESSAGE)
    }),
    tenancyAgreement: ownRentHiddenBoolFieldYupSchema(),
    occupiedByMember: yup
      .boolean()
      .when('coverType', {
        is: 'ownLive',
        then: yup.boolean().required('Answer is required')
      })
      .when('coverType', {
        is: 'rent',
        then: yup.boolean().required(GENERIC_REQUIRED_MESSAGE)
      }),
    // ownHoliday schema
    isInUse: yup.boolean().when('coverType', {
      is: 'ownHoliday',
      then: yup.boolean().required(GENERIC_REQUIRED_MESSAGE),
      else: yup.string().notRequired()
    }),
    holidayHomeIsRented: yup.boolean().when('coverType', {
      is: 'ownHoliday',
      then: yup.boolean().required(GENERIC_REQUIRED_MESSAGE),
      else: yup.string().notRequired()
    }),
    isLetMoreThan5PerYear: yup.boolean().when('holidayHomeIsRented', {
      is: true,
      then: yup.boolean().required(GENERIC_REQUIRED_MESSAGE),
      else: yup.string().notRequired()
    }),
    homeType: yup
      .string()
      .oneOf([
        'Detached house',
        'Detached house with basement',
        'Semi-Detached house',
        'Semi-detached house with basement',
        'Terraced house',
        'Terraced house with basement',
        'Bungalow',
        'Bungalow with basement',
        'Purpose built apartment'
      ])
      .required('Property type is required'),
    yearOfConstruction: yup
      .number()
      .test('len', 'Please enter a valid year (e.g 1920)', (val: number) => {
        if (val) return val.toString().length === 4;
      })
      .max(currentYear, 'Please enter a year not in the future')
      .required('Year built is required'),
    hasBeenRewired: yup
      .boolean()
      .when('yearOfConstruction', (val: number) =>
        val <= 1970 && val > 1500
          ? yup.boolean().required(GENERIC_REQUIRED_MESSAGE)
          : yup.boolean()
      ),
    hasBeenReroofed: yup
      .boolean()
      .when('yearOfConstruction', (val: number) =>
        val <= 1920 && val > 1500
          ? yup.boolean().required(GENERIC_REQUIRED_MESSAGE)
          : yup.boolean()
      ),
    numBedrooms: yup
      .number()
      .max(8, 'Please select from the dropdown')
      .required('Number of Bedrooms is required'),
    numBathrooms: yup
      .number()
      .max(8, 'Please select from the dropdown')
      .required('Number of Bathrooms is required'),
    heatingSource: yup
      .string()
      .oneOf([
        'Solid Fuel',
        'Electric',
        'Gas',
        'Oil',
        'Solar/Wind',
        'Wood/Wood Pellet',
        'Mixture inc Oil',
        'Heat Exchange',
        'Not listed'
      ])
      .required('Main source of heating is required'),
    alarmType: yup
      .string()
      .oneOf([
        'Registered installer but is not monitored',
        'Registered installer centrally monitored',
        'Not registered installer',
        'No alarm fitted'
      ])
      .required(GENERIC_REQUIRED_MESSAGE),
    numberOfSmokeDetectors: yup
      .number()
      .required('Number of Working Smoke Detectors is required'),
    /* BMIW-926 Neighbourhood watch should not be displayed to users 
    neighbourhoodWatch: yup
      .string()
      .oneOf(['false', 'true'])
      .required(GENERIC_REQUIRED_MESSAGE),*/
    claimsValue: yup.string().required('Answer is required'),
    claims: yup.array().of(
      yup.object().shape({
        claim_type: yup.string().required(GENERIC_REQUIRED_MESSAGE),
        claim_date: yup
          .date()
          .transform(parseDateString)
          .max(new Date(), FUTURE_ERROR)
          .required(GENERIC_REQUIRED_MESSAGE),
        claim_status: yup.string().required(GENERIC_REQUIRED_MESSAGE),
        claim_amount: yup.string().when('claim_status', {
          is: 'Settled',
          then: yup.string().required(GENERIC_REQUIRED_MESSAGE),
          otherwise: yup.string().notRequired()
        }),
        occurred_at: yup.string().required(GENERIC_REQUIRED_MESSAGE)
      })
    ),
    claimsCount: yup.number().required(GENERIC_REQUIRED_MESSAGE),
    noClaimYears: yup.number().required('Answer is required')
  }),
  displayName: 'YourHomeForm'
})(YourHomeForm);

export default FormikYourHomeForm;
