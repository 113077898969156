/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import './GetGenericQuotePage.scss';

import {
  AccordionCard,
  Button,
  Title,
  TitleWithUnderLine
} from '@boi/core/lib';
import { Form, Formik, FormikProps, setNestedObjectValues } from 'formik';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import type { RouterHistory } from 'react-router-dom';
import * as yup from 'yup';

import {
  AboutYouForm,
  aboutYouFormInitialValues,
  aboutYouFormYupSchema
} from '../../components/Forms/AboutYouForm/AboutYouForm';
import {
  aboutYouFormIdentifier,
  yourCarCoverFormIdentifier,
  yourCarFormIdentifier,
  yourTravelCoverFormIdentifier,
  yourTravelFormIdentifier
} from '../../components/Forms/CommonFiles/commonFormConstants';
import YourCarCoverForm, {
  yourCarCoverFormInitialValues,
  yourCarCoverFormYupSchema
} from '../../components/Forms/YourCarCoverForm';
import YourCarForm, {
  yourCarFormInitialValues,
  yourCarFormYupSchema
} from '../../components/Forms/YourCarForm';
import YourTravelCoverForm, {
  yourTravelCoverFormInitialValues,
  yourTravelCoverFormYupSchema
} from '../../components/Forms/YourTravelCoverForm';
import YourTravelForm, {
  yourTravelFormInitialValues,
  yourTravelFormYupSchema
} from '../../components/Forms/YourTravelForm';
import LegalText from '../../components/LegalText';
import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import {
  BOI_WALLET_USER_NAME_STORAGE,
  GET_QUOTE_VALUES_STORAGE,
  RECALCULATED_QUOTE_VALUES_STORAGE
} from '../../constants';
import { commonPageStylesIdentifier } from '../../constants';
import {
  CAR_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import {
  JOURNEY_GET_QUOTE,
  MOTOR_TRAVEL_JOURNEY,
  STAGES_MOTOR_TRAVEL_JOURNEY
} from '../../constants/journey';
import { SUBMITTED_GET_QUOTE_STORAGE } from '../../constants/sessionStorage';
import { AGENT_CLICKED_REFERENCE_QUOTES } from '../../constants/sessionStorage/userStorageIdentifiers.js';
import {
  findElementsByClasses,
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  removeSessionStorageAfterGetQuote,
  saveInSessionStorage
} from '../../helpers';
import { mapGetQuote } from '../../mappings/home/MapGetQuote.js';
import { mapMotorQuote } from '../../mappings/motor/MapMotorQuote';
import { mapTravelQuote } from '../../mappings/travel/MapTravelQuote';
import { routes } from '../../routes';
import type {
  CognitoUserAttributesType,
  LatestQuoteType,
  YupSchemaType
} from '../../types';
import UserContext from '../../UserContext';
import Accordions from './Accordions';
import { getQuoteFormStructure } from './getQuoteFormStructure';

interface Props {
  history: RouterHistory;
  insuranceType: string;
  latestQuote: LatestQuoteType;
  cognitoUserData: CognitoUserAttributesType;
}

interface Values {
  firstName: string;
}

const AWAITING_PREPOPULATION_CHECK =
  'GetGenericQuotePage/AWAITING_PREPOPULATION_CHECK';
const NO_PREPOPULATION = 'GetGenericQuotePage/NO_PREPOPULATION';
const PREPOPULATION_REQUESTED = 'GetGenericQuotePage/PREPOPULATION_REQUESTED';
const PREPOPULATION_FINISHED = 'GetGenericQuotePage/PREPOPULATION_FINISHED';

export const InsuranceTypeContext = createContext<string>('home');

const getValidationForForm = (
  insuranceType: string,
  formName: string,
  formYupSchema: YupSchemaType
) => {
  if (
    !getQuoteFormStructure[insuranceType] ||
    !getQuoteFormStructure[insuranceType][formName]
  )
    return {};
  const fieldsToValidate = getQuoteFormStructure[insuranceType][formName];
  const yupSchema = {};
  fieldsToValidate.forEach((fieldName: string) => {
    yupSchema[fieldName] = formYupSchema[fieldName];
  });
  return yupSchema;
};

const checkIfObjectEmpty = (obj?: { [key: string]: mixed }) =>
  typeof obj !== 'object' || Object.keys(obj).length === 0;

const GetGenericQuotePage = (props: Props) => {
  const [prepopulationStatus, setPrepopulationStatus] = useState(
    AWAITING_PREPOPULATION_CHECK
  );

  const emailParam = getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES);

  const className = 'c-GetGenericQuotePageForm';
  const {
    insuranceType = '',
    history,
    cognitoUserData = {},
    latestQuote = { values: {} }
  } = props;
  const formStructure = { ...getQuoteFormStructure[insuranceType] };

  const getinsuranceTypeConst = {
    car: CAR_INSURANCE_TYPE,
    travel: TRAVEL_INSURANCE_TYPE
  };

  const sessionStoredValues =
    insuranceType && getinsuranceTypeConst[insuranceType]
      ? getObjectFromSessionStorage(
          `${getinsuranceTypeConst[insuranceType]}${GET_QUOTE_VALUES_STORAGE}`
        )
      : {};
  removeSessionStorageAfterGetQuote(insuranceType);

  if (insuranceType === TRAVEL_INSURANCE_TYPE) {
    removeFromSessionStorage(
      `${TRAVEL_INSURANCE_TYPE}${RECALCULATED_QUOTE_VALUES_STORAGE}`
    );
  }
  const latestValues = latestQuote.values;

  useEffect(() => {
    const cognitoUserDataIsEmpty = checkIfObjectEmpty(cognitoUserData);
    const latestIsEmpty = checkIfObjectEmpty(latestValues);
    const sessonStorageIsEmpty = checkIfObjectEmpty(sessionStoredValues);

    if (!cognitoUserDataIsEmpty || !latestIsEmpty || !sessonStorageIsEmpty) {
      setPrepopulationStatus(PREPOPULATION_REQUESTED);
    } else {
      setPrepopulationStatus(NO_PREPOPULATION);
    }
  }, [latestValues]);

  const [
    status,
    hasOpened,
    switchFormStatus,
    switchFormHasOpened,
    fillStatus,
    fillHasOpened
  ] = Accordions();

  useEffect(() => {
    fillStatus(prepopulationStatus === PREPOPULATION_FINISHED);
    fillHasOpened(prepopulationStatus === PREPOPULATION_FINISHED);
    switchFormStatus(
      aboutYouFormIdentifier,
      !!emailParam || prepopulationStatus === PREPOPULATION_FINISHED
    );
    switchFormHasOpened(
      aboutYouFormIdentifier,
      !!emailParam || prepopulationStatus === PREPOPULATION_FINISHED
    );
  }, []);

  const mapDataAndRouteToAssumptionsPage = (
    insuranceType: string,
    values: mixed
  ) => {
    const mapDataDependingOnInsuranceType = {
      travel: mapTravelQuote,
      car: mapMotorQuote,
      home: mapGetQuote //TODO wont work until values is one object
    };
    const mappingsData =
      insuranceType === CAR_INSURANCE_TYPE
        ? {}
        : mapDataDependingOnInsuranceType[insuranceType](values);
    const url =
      insuranceType === CAR_INSURANCE_TYPE
        ? `${routes.termsAndConditions.url}${insuranceType}`
        : `${routes.getQuoteThankYouPage.url}${insuranceType}`;
    // Used to ensure a call to the backend is only made going forward through the journey
    saveInSessionStorage(
      `${insuranceType}${SUBMITTED_GET_QUOTE_STORAGE}`,
      true
    );
    saveInSessionStorage(
      `${insuranceType}${GET_QUOTE_VALUES_STORAGE}`,
      JSON.stringify(values)
    );
    history.push({
      pathname: url,
      state: {
        values: values,
        insuranceType: insuranceType,
        mappingsData: mappingsData
      }
    });
  };

  const { userDispatch } = useContext(UserContext);

  const updateFirstName = (value: string) => {
    userDispatch({
      type: 'setFirstName',
      payload: { firstName: value }
    });
    saveInSessionStorage(BOI_WALLET_USER_NAME_STORAGE, value);
  };

  const openAccordions = () => {
    fillHasOpened(true);
    fillStatus(true);
  };

  // eslint-disable-next-line complexity
  const FormInterior = ({ formik }: FormikProps<Values>) => {
    const {
      errors,
      touched,
      setTouched,
      setValues,
      submitForm,
      values
    } = formik;

    if (prepopulationStatus === PREPOPULATION_REQUESTED) {
      const newValues = {
        ...values,
        ...cognitoUserData,
        ...latestValues,
        ...sessionStoredValues
      };
      setValues(newValues);
      if (
        (newValues.policyLength === 0 || newValues.policyLength > 0) &&
        newValues.policyLength !== undefined
      ) {
        setTouched(setNestedObjectValues(newValues != '', true));
      } else {
        setTouched(setNestedObjectValues(newValues, true));
      }
      setPrepopulationStatus(PREPOPULATION_FINISHED);
      openAccordions();
    }

    const isFormValid = (formName: string) => {
      if (!hasOpened[formName]) {
        return false;
      }
      const errorFields = formStructure[formName];
      let valid = true;
      errorFields.forEach((field: string) => {
        if (errors[field]) {
          valid = false;
        }
      });
      return valid;
    };

    const getFormStatus = (formName: string) => {
      if (hasOpened[formName]) {
        return isFormValid(formName) ? 'success' : 'warning';
      }
      return 'default';
    };

    const scrollToFirstError = () => {
      const errorClasses = [
        'c-InputField--error',
        'c-Dropdown--error',
        'c-ButtonGroup--error',
        'c-Typeahead--error',
        'c-InputTextArea--error'
      ];
      const elements = findElementsByClasses(errorClasses);
      let topPosition = 10000;
      elements.forEach((element: any) => {
        const currentY = element && element.getBoundingClientRect().top;
        if (currentY && currentY < topPosition) {
          topPosition = currentY;
        }
      });
      const scrollPosition = window.pageYOffset;
      window.scrollTo(0, topPosition - 80 + scrollPosition);
    };

    const setFormTouched = () => {
      /* If the user has never touched the form before then store that they have touched the form
         This touched form is used for form validation */
      if (!touched) {
        setTouched({ form: true });
      } else if (!touched.form) {
        setTouched({ ...touched, form: true });
      }
    };

    const toggleStatus = (formName: string) => {
      if (!hasOpened[formName]) {
        switchFormHasOpened(formName, true);
        setFormTouched();
      }
      switchFormStatus(formName);
    };

    const getAboutYouForm = () => {
      return formStructure[aboutYouFormIdentifier] ? (
        <AccordionCard
          id={`GetQuote__aboutYou`}
          label="About you"
          isOnState={status[aboutYouFormIdentifier]}
          handleToggle={() => {
            toggleStatus(aboutYouFormIdentifier);
          }}
          status={getFormStatus(aboutYouFormIdentifier)}
          childContainerClass={`${className}__noPaddingFormContainer`}
        >
          <AboutYouForm insuranceType={insuranceType} emailParam={emailParam} />
        </AccordionCard>
      ) : null;
    };

    const getYourCarForm = () => {
      return formStructure[yourCarFormIdentifier] ? (
        <div className={`${className}--spacing`}>
          <AccordionCard
            id={`GetQuote__yourCar`}
            label="Your car"
            isOnState={status[yourCarFormIdentifier]}
            handleToggle={() => {
              toggleStatus(yourCarFormIdentifier);
            }}
            status={getFormStatus(yourCarFormIdentifier)}
          >
            <YourCarForm {...formik} {...props} />
          </AccordionCard>
        </div>
      ) : null;
    };

    const getYourCoverForm = () => {
      return formStructure[yourCarCoverFormIdentifier] ? (
        <div className={`${className}--spacing`}>
          <AccordionCard
            id={`GetQuote__yourCover`}
            label="Your cover"
            isOnState={status[yourCarCoverFormIdentifier]}
            handleToggle={() => {
              toggleStatus(yourCarCoverFormIdentifier);
            }}
            status={getFormStatus(yourCarCoverFormIdentifier)}
            childContainerClass={`${className}__noPaddingFormContainer`}
          >
            <YourCarCoverForm
              formFilledBefore={!!latestValues}
              {...formik}
              {...props}
            />
          </AccordionCard>
        </div>
      ) : null;
    };

    const getYourTravelForm = () => {
      return formStructure[yourTravelFormIdentifier] ? (
        <div className={`${className}--spacing`}>
          <AccordionCard
            id={`GetQuote__yourTrip`}
            isOnState={status[yourTravelFormIdentifier]}
            label="Your trip"
            handleToggle={() => {
              toggleStatus(yourTravelFormIdentifier);
            }}
            status={getFormStatus(yourTravelFormIdentifier)}
            childContainerClass={`${className}__noPaddingFormContainer`}
          >
            <YourTravelForm {...formik} {...props} />
          </AccordionCard>
        </div>
      ) : null;
    };

    const getYourTravelCoverForm = () => {
      return formStructure[yourTravelCoverFormIdentifier] ? (
        <div className={`${className}--spacing`}>
          <AccordionCard
            id={`GetQuote__yourCover`}
            isOnState={status[yourTravelCoverFormIdentifier]}
            label="Your cover"
            handleToggle={() => {
              toggleStatus(yourTravelCoverFormIdentifier);
            }}
            status={getFormStatus(yourTravelCoverFormIdentifier)}
          >
            <YourTravelCoverForm {...formik} {...props} />
          </AccordionCard>
        </div>
      ) : null;
    };

    const showForm =
      prepopulationStatus === NO_PREPOPULATION ||
      prepopulationStatus === PREPOPULATION_FINISHED;

    const submitButton = () => {
      // Expand all cards
      openAccordions();
      // Run validation (this does not submit)
      submitForm();
      // Attempt to scroll to an error
      scrollToFirstError();
      // SUBMIT. (Formik's inability to synthetic submit on the current tick)
      setTimeout(submitForm, 1);
    };

    return (
      <div
        className={className}
        style={{
          display: `${showForm ? 'block' : 'hidden'}`
        }}
      >
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <ProgressBar
          stage={JOURNEY_GET_QUOTE}
          stages={STAGES_MOTOR_TRAVEL_JOURNEY}
          labelText={MOTOR_TRAVEL_JOURNEY}
        />
        <div className={`${commonPageStylesIdentifier}__pageTitle`}>
          <TitleWithUnderLine>Get Quotes</TitleWithUnderLine>
        </div>
        <div className={`${className}__subHeading`}>
          <Title type="h4" align="left" variant="black">
            Please complete the following:
          </Title>
        </div>
        <Form>
          <div className={`${className}__accordions`}>
            {getAboutYouForm()}
            {getYourCarForm()}
            {getYourCoverForm()}
            {getYourTravelForm()}
            {getYourTravelCoverForm()}
          </div>

          <div className={`${className}__button`}>
            <Button
              id="GenericQuotePageForm__getQuote"
              data-testid="GenericQuotePageForm__getQuote"
              fluid={true}
              quaternary
              onClick={submitButton}
              type="button"
            >
              Get your quote
            </Button>
          </div>
        </Form>
        <div className={`${className}__legalTextContainer`}>
          <LegalText insuranceType={insuranceType} />
        </div>
      </div>
    );
  };

  const initValues = useMemo(
    () => ({
      ...getValidationForForm(
        insuranceType,
        aboutYouFormIdentifier,
        aboutYouFormInitialValues
      ),
      ...getValidationForForm(
        insuranceType,
        yourCarFormIdentifier,
        yourCarFormInitialValues
      ),
      ...getValidationForForm(
        insuranceType,
        yourCarCoverFormIdentifier,
        yourCarCoverFormInitialValues
      ),
      ...getValidationForForm(
        insuranceType,
        yourTravelFormIdentifier,
        yourTravelFormInitialValues
      ),
      ...getValidationForForm(
        insuranceType,
        yourTravelCoverFormIdentifier,
        yourTravelCoverFormInitialValues
      )
    }),
    [insuranceType]
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        ...getValidationForForm(
          insuranceType,
          aboutYouFormIdentifier,
          aboutYouFormYupSchema
        ),
        ...getValidationForForm(
          insuranceType,
          yourCarFormIdentifier,
          yourCarFormYupSchema
        ),
        ...getValidationForForm(
          insuranceType,
          yourCarCoverFormIdentifier,
          yourCarCoverFormYupSchema
        ),
        ...getValidationForForm(
          insuranceType,
          yourTravelFormIdentifier,
          yourTravelFormYupSchema
        ),
        ...getValidationForForm(
          insuranceType,
          yourTravelCoverFormIdentifier,
          yourTravelCoverFormYupSchema
        )
      }),
    [insuranceType]
  );

  const handleSubmit = (values: Values, { setSubmitting }: FormikProps) => {
    mapDataAndRouteToAssumptionsPage(insuranceType, values);
    updateFirstName(values.firstName);
    setSubmitting(true);
  };

  const initialFormValues = { ...initValues };
  return (
    <InsuranceTypeContext.Provider value={insuranceType}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        isInitialValid={() => schema.isValidSync(initialFormValues)}
        onSubmit={handleSubmit}
      >
        {(formik: FormikProps<Values>) => <FormInterior formik={formik} />}
      </Formik>
    </InsuranceTypeContext.Provider>
  );
};

export default GetGenericQuotePage;
