import { useState } from 'react';

import {
  aboutYouFormIdentifier,
  yourCarCoverFormIdentifier,
  yourCarFormIdentifier,
  yourTravelCoverFormIdentifier,
  yourTravelFormIdentifier
} from '../../components/Forms/CommonFiles/commonFormConstants';


const Accordions = () => {
  const formsObj = (valueToFill) => ({
    [aboutYouFormIdentifier]: valueToFill,
    [yourCarFormIdentifier]: valueToFill,
    [yourCarCoverFormIdentifier]: valueToFill,
    [yourTravelFormIdentifier]: valueToFill,
    [yourTravelCoverFormIdentifier]: valueToFill
  })

  const [status, setStatus] = useState(formsObj(false));
  const [hasOpened, setHasOpened] = useState(formsObj(false));

  const switchFormStatus = (formName, value = null) => {
    setStatus({ ...status, [formName]: value !== null ? value : !status[formName] })
  }

  const switchFormHasOpened = (formName, value = null) => {
    setHasOpened({ ...hasOpened, [formName]: value !== null ? value : !hasOpened[formName] })
  }

  const fillStatus = (value) => setStatus(formsObj(value))

  const fillHasOpened = (value) => setHasOpened(formsObj(value))

  return [
    status,
    hasOpened,
    switchFormStatus,
    switchFormHasOpened,
    fillStatus,
    fillHasOpened
  ]
}

export default Accordions;
