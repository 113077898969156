/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../../styles/commonFormStyles.scss';
import './CardPayment.scss';

import { InputField, Label } from '@boi/core/lib';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import { SELECTED_HOME_QUOTE_STORAGE } from '../../../constants';
import { HOME_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { getObjectFromSessionStorage } from '../../../helpers';
import { initPaysafe } from '../../../services/paysafeService';
import CardLogo from './CardLogo';
import { getOptions } from './PaySafeConfig';

const className = 'c-CardPayment';

interface CardPaymentProps {
  history: RouterHistory;
  location: Location;
  match: Match;
  insuranceType: string;
  setIsValid: boolean => void;
  setCardBrand: string => void;
}

const errorMessages = {
  cardNumber: 'Invalid card number.',
  expiryDate: 'Invalid expiration date.',
  cvv: 'Invalid CVV number'
};

// for some reason paysafe doesnt see changes in state variable
let paysafeCardHolderName = '';
const CardPayment = (props: FormikProps<CardPaymentProps>) => {
  const {
    insuranceType,
    setCardBrand,
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
    values
  } = props;

  const [isValidCard, setIsValidCard] = useState(false);
  const [validFields, setValidFields] = useState({});
  const [paysafeInitialized, setPaysafeInitialized] = useState(false);

  const setCardStatus = (isValid: boolean) => {
    setIsValidCard(isValid);
    if (props.setIsValid)
      props.setIsValid(!!isValid && !!paysafeCardHolderName);
  };

  const getInsuranceType = () => {
    // Home has multiple paysafe accounts
    if (insuranceType === HOME_INSURANCE_TYPE) {
      const selectedQuote = getObjectFromSessionStorage(
        `${SELECTED_HOME_QUOTE_STORAGE}`
      );
      return selectedQuote.insurer;
    } else return insuranceType;
  };

  useEffect(() => {
    const paySafeOptions = getOptions(
      setCardStatus,
      (cardBrand: string) => {
        if (setCardBrand) setCardBrand(cardBrand);
      },
      getInsuranceType(),
      setValidFields
    );

    if (!paysafeInitialized) {
      setPaysafeInitialized(true);
      // paysafe executes on already rendered elements, this is to make sure
      // that react will finish all rendering
      setTimeout(() => {
        initPaysafe(paySafeOptions);
      }, 500);
    }
  }, [validFields]);
  const getErrorMessage = (key: string, isVisible: boolean) => {
    if (!isVisible) return null;
    return (
      <div className={`${className}__errorMessage`}>{errorMessages[key]}</div>
    );
  };

  const handleNameOnCardChanges = (ev: SyntheticEvent<HTMLInputElement>) => {
    const name = ev.currentTarget.value;
    paysafeCardHolderName = name;
    setFieldValue('nameOnCard', name);
    if (props.setIsValid) props.setIsValid(!!isValidCard && !!name);
  };

  return (
    <div className={className}>
      <div className={`panel-content ${className}__paddedContainer`}>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <Label
            htmlFor="nameOnCard"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Name on card
          </Label>
          <InputField
            name="nameOnCard"
            placeholder="Type here"
            type="text"
            error={errors.nameOnCard && touched.nameOnCard}
            errorMessage={errors.nameOnCard}
            onChange={handleNameOnCardChanges}
            onBlur={() => setFieldTouched('nameOnCard', true)}
            value={values.nameOnCard}
          />
        </div>

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="card-number"
            className={`${commonFormStylesIdentifier}__fieldLabel ${className}__cardNumberLabelContainer`}
          >
            <div className={`${className}__cardNumberLabel`}>Card Number</div>
            <div className={`${className}__cardNumberLogoContainer`}>
              <CardLogo cardBrand={'visa'} />
              <CardLogo cardBrand={'mastercard'} />
            </div>
          </label>
          <div id="card-number" className={`${className}__paysafeField`} />
          {getErrorMessage('cardNumber', validFields.cardNumber === false)}
        </div>

        <div className={`${className}__paysafeLogoContainer`}>
          <div className={`${className}__detailsContainer`}>
            <div
              className={`${className}__fieldContainer ${className}--withRightMargin ${className}--small`}
            >
              <label
                htmlFor="cvv"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                CVV
              </label>
              <div id="cvv" className={`${className}__paysafeField`} />
              {getErrorMessage('cvv', validFields.cvv === false)}
            </div>

            <div className={`${className}__fieldContainer ${className}--small`}>
              <label
                htmlFor="expiration-date"
                className={`${commonFormStylesIdentifier}__fieldLabel`}
              >
                Expiry Date
              </label>
              <div
                id="expiration-date"
                className={`${className}__paysafeField`}
              />
              {getErrorMessage('expiryDate', validFields.expiryDate === false)}
            </div>
          </div>

          <div className={`${className}__paysafeLogo`}>Powered by Paysafe</div>
        </div>
      </div>
    </div>
  );
};
export default CardPayment;
