/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import * as yup from "yup";

export const nullableBooleanRequired = (testName: string, message: string) => {
  return yup.boolean().test(testName, message,
    function(value: boolean): boolean {
      return value !== null;
    }).nullable(true)
};

export const stringRequired = (message: string) => {
  return yup.string().required(message)
};
