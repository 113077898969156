import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';

import { agent_config, config } from './amplify.config';
import { isAgent } from './helpers';

const analyticsConfig = {
  AWSPinpoint: {
        appId: process.env.REACT_APP_PINPOINT_ID,
        region: process.env.REACT_APP_COGNITO_REGION,
        mandatorySignIn: false,
  }
}

if(isAgent()) {
  Auth.configure(agent_config);
} else {
  Auth.configure(config);
  Analytics.configure(analyticsConfig);
}

export const amplifyAnalytics = (record) => {
  if(!isAgent()) {
    Analytics.record(record);
  } 
}

